import {
  ESCUELAS_RECIBIDAS,
  SET_ARG_ESCUELA,
  ESTADOSESCUELAS_RECIBIDOS,
  MUNICIPIOS_RECIBIDOS,
} from "../actions/types";

const INITIAL_STATE = {
  escuelas: null,
  estados: null,
  municipios: null,
  args: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ESCUELAS_RECIBIDAS:
      return { ...state, escuelas: payload };
    case SET_ARG_ESCUELA:
      const { key, value } = payload;
      return { ...state, args: { ...state.args, [key]: value } };
    case ESTADOSESCUELAS_RECIBIDOS:
      return { ...state, estados: payload };
    case MUNICIPIOS_RECIBIDOS:
      return { ...state, municipios: payload };
    default:
      return { ...state };
  }
};
