import {
  ESTADOS_RECIBIDOS,
  SET_ESTADO,
  PATROCINIOS_RECIBIDOS,
  SET_PATROCIONIOS,
} from "./types";
import ConfiguracionJuegos from "../services/ConfiguracionJuegos";
import { displayError } from "../utils";

export const getJuegos = () => (dispatch) => {
  ConfiguracionJuegos.getJuegos()
    .then((res) => {
      const { estados } = res.data;
      dispatch({ type: ESTADOS_RECIBIDOS, payload: estados });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const setEstado = (nombre, activo) => (dispatch) => {
  ConfiguracionJuegos.putJuego(nombre, activo)
    .then((res) => {
      dispatch({ type: SET_ESTADO, payload: { nombre, activo } });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const getPatrocinios = () => (dispatch) => {
  ConfiguracionJuegos.getPatrocinios()
    .then((res) => {
      const { estado } = res.data;
      dispatch({ type: PATROCINIOS_RECIBIDOS, payload: estado });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const setPatrocinio = (estado) => (dispatch) => {
  ConfiguracionJuegos.putPatrocinios(estado)
    .then((error) => {
      dispatch({ type: SET_PATROCIONIOS, payload: estado });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};
