import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container } from "react-bootstrap";
import Home from "./Home";
import Auth from "./Auth";
import Menu from "./components/Menu";
import { showOpciones } from "./actions/misJuegosActions";
import { userLoggedIn, signOut, signIn } from "./actions/userActions";
import { setJuego } from "./actions/jugarActions";
import { connect } from "react-redux";
import { Router, Redirect, navigate } from "@reach/router";
import Jugador from "./view/Jugador";
import { postPreguntas } from "./actions/preguntasActions";
import { showOpcionesPatrocinador } from "./actions/patrocinadoresActions";
import Error401 from "./view/Error401";
import Error404 from "./view/Error404";
import Error503 from "./view/Error503";
import Recovery from "./view/Recovery";
import ResetPassword from "./view/ResetPassword";
import PlayAlone from "./view/PlayAlone/PlayAlone";


function General(props) {
  function renderComponent(user) {
    if(props.location.pathname === '/signup' || props.location.pathname === '/login'){
      return <Auth spinner={props.spinner} />;
    }
    //if (user !== null) return (
      return (<>
        <Menu {...props} />
        <Home />
      </>)
    //);
    
  }

  return (
    <>
      <Container className="px-0" onClick={(e) => props.showOpciones(e)} fluid>
        {renderComponent(props.user)}
      </Container>
    </>
  );
}

class Main extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-173307199");
    this.props.userLoggedIn();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.created && !prevProps.created) {
      //this.props.signIn(this.props.correo, this.props.password);
      navigate("/explorar", { replace: true });
    }
  }

  postPreguntas(callback) {
    if (this.props.juego) {
      this.props.postPreguntas(
        this.props.preguntas,
        this.props.juego,
        this.props.juego.idJuego,
        callback
      );
    }
  }

  render() {
    return (
      <Router>
        <Redirect from="/" to="/jugar" noThrow />
        <Jugador
          path="/jugar"
          user={this.props.user}
          spinner={this.props.spinner}
        />
        <ResetPassword
          path="/reset-password"
        />
        <Error401
          path="/401"
          user={this.props.user}
          disabled={this.props.disabled}
          signOut={this.props.signOut}
          showOpciones={this.props.showOpciones}
          setJuego={this.props.setJuego}
          juego={this.props.juego}
          guardarJuego={(callback) => this.postPreguntas(callback)}
          spinner={this.props.spinner}
        />
        <Error404
          path="/404"
          user={this.props.user}
          disabled={this.props.disabled}
          signOut={this.props.signOut}
          showOpciones={this.props.showOpciones}
          setJuego={this.props.setJuego}
          juego={this.props.juego}
          guardarJuego={(callback) => this.postPreguntas(callback)}
          spinner={this.props.spinner}
        />
        <Error503
          path="/503"
          user={this.props.user}
          disabled={this.props.disabled}
          signOut={this.props.signOut}
          showOpciones={this.props.showOpciones}
          setJuego={this.props.setJuego}
          juego={this.props.juego}
          guardarJuego={(callback) => this.postPreguntas(callback)}
          spinner={this.props.spinner}
        />
        <General
          path="/*"
          user={this.props.user}
          disabled={this.props.disabled}
          signOut={this.props.signOut}
          showOpciones={(e) => {
            e.stopPropagation();
            this.props.showOpciones();
            this.props.showOpcionesPatrocinador();
          }}
          setJuego={this.props.setJuego}
          juego={this.props.juego}
          guardarJuego={(callback) => this.postPreguntas(callback)}
          spinner={this.props.spinner}
        />
        <Recovery path="/recovery" />
        <PlayAlone path="/play/alone/*" />

      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  spinner: state.user.spinner,
  juego: state.juego.juego,
  preguntas: state.juego.preguntas,
  disabled: state.juego.disabled,
  created: state.user.created,
  correo: state.user.correo,
  password: state.user.password,
});

export default connect(mapStateToProps, {
  signIn,
  signOut,
  setJuego,
  userLoggedIn,
  showOpciones,
  postPreguntas,
  showOpcionesPatrocinador,
})(Main);
