import React, { Component } from 'react';
import { connect } from "react-redux";
import Header from './Header';
import CrosswordContent from './CrosswordContent';
import Footer from './Footer';
import Lobby from './Lobby';
import { Container, Row, Col } from 'react-bootstrap';
import { createJugarSoloSesion, clearStore, postSugerencia } from "../../actions/jugarSoloActions";
import IconsLoading from "../../components/IconsLoading";
import { Rectangulo } from "../../assets/icons.js";
import io from "socket.io-client";
import { BASE_URL, errorMessageSoloCrucigrama } from '../../utils';
import { toast } from 'react-toastify';


class AloneCrossword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: 'question',//ENUM defining the current status so <TriviaContent> and <Footer> can choose what to render component to render [lobbyquestion | question | gameending]
            socket: null,
            currentView: 'lobby', //ENUM defining the current component to render [lobby | main_content]
            lobbyPreguntaTimer: null,//timer for window before pregunta with options
            currentTimeLobbyPregunta: 0,//time counter for window before pregunta with options
            questionPreguntaTimer: null,//timer for window with question and options
            currentTimePregunta: 0,//time counter for window with question and options
            loading: false,//if we are in the state of loading something from the back
            timeAtStart: null,//timestamp to indicate start of game, used when sending answer to backend
            apodo: null,
            idSesion: null,
            sugerencia: {
                recomendacionJuego: null,
                showThankYouModal: false
            },
            juego: {
                puntos: 0,//total points
                pregunta: '¡Estamos terminando de acomodar las palabras en tu crucigrama!',//current question text (EN CRUCIGRAMA SIEMPRE ES ESTE)
                idAdjunto: 'icons-loading',//Question idAdjunto (EN CRUCIGRAMA SIEMPRE ES ESTE)
                streak: false,//if the user is on streak
            },//se guardan todos los datos del juego
        }
        this.responder = this.responder.bind(this);
        this.setSugerenciaProperty = this.setSugerenciaProperty.bind(this);
        this.postSugerencia = this.postSugerencia.bind(this);
        this.startTimerQuestion = this.startTimerQuestion.bind(this);
        this.connectSockets = this.connectSockets.bind(this);
    }
    responder(idPregunta){
        this.state.socket.emit('respuesta', { idPregunta, tiempo: Date.now() - this.state.timeAtStart });
    }
    postSugerencia(){
        this.props.postSugerencia({
            idSesion: this.state.idSesion,
            recomendacionJuego: this.state.sugerencia.recomendacionJuego,
        });
        const sugerencia = {...this.state.sugerencia, showThankYouModal: true };
        this.setState({ sugerencia });
    }
    setSugerenciaProperty({ key, value }){
        const sugerencia = {...this.state.sugerencia};
        sugerencia[key] = value;
        this.setState({sugerencia});
    }
    componentDidMount(){
        this.props.clearStore();//reset redux to empty
        this.props.createJugarSoloSesion({//create sesion in back with HTTP
            idJuego: this.props.urlQuery.juego,
            tipo: this.props.urlQuery.tipo,
            segmento: this.props.urlQuery.segmento
        }).then(() => {//when back returns response connect to websockets
            const juego = {...this.state.juego, preguntasTotales: this.props.preguntasTotales};//save preguntasTotales from redux to state for consistency
            this.setState({
                juego,
                currentTimePregunta: this.props.tiempoEsperaPregunta
            })
            this.connectSockets();
        })
    }
    connectSockets(){
        const base_url = BASE_URL.replace("/api", "");
        let socket = io(`${base_url}/${this.props.namespace}`);
        this.setState({ socket });//save socket in state
        socket.on('apodo', ({ apodo }) => {//When back saves apodo
            this.setState({ apodo, currentView: 'main_content', timeAtStart: Date.now() });
            this.startTimerQuestion();
        });
        socket.on('respuesta', ({ puntos, streak, terminado }) => {
            if(terminado){
                clearInterval(this.state.questionPreguntaTimer);
                socket.emit('terminarJuego');
                this.setState({ component: 'gameending' });
            }else{
                const juego = {...this.state.juego, puntos, streak};
                this.setState({ juego });
            }
        });
        socket.on('juegoTerminado', ({puntos, idSesion, porcentajeCorrectas, porcentajeIncorrectas }) => {
            //console.log(porcentajeCorrectas, porcentajeIncorrectas);
            const juego = {...this.state.juego};
            juego.puntos = puntos;
            juego.porcentajeCorrectas = porcentajeCorrectas;
            juego.porcentajeIncorrectas = porcentajeIncorrectas;
            this.setState({
                juego,
                idSesion,
                component: 'gameending',
                loading: false
            });
        });
        socket.on('handle_error', ({ code, status }) => {
            let message = errorMessageSoloCrucigrama[code];
            if(!errorMessageSoloCrucigrama[code]){//Set default message if not found in utils
                message = "¡Ups! Parece que hubo un problema en la partida";
            }
            toast.error(message, {
                position: "bottom-right",
                autoClose: 12000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
        socket.on("disconnect", () => {
            if(this.state.component !== 'gameending'){//If server disconnects before ending game, show error
                toast.error("¡Ups! Parece que hubo un problema con la conexión", {
                    position: "bottom-right",
                    autoClose: 12000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }
    startTimerQuestion(){//Handle start timer question
        const timeInterval = setInterval(() => {//set timer
            //start question timer
            this.setState((prevState) => {
                if(prevState.currentTimePregunta - 1 <= 0){//if timer reaches 0s
                    //stop interval
                    clearInterval(this.state.questionPreguntaTimer);
                    return {
                        ...prevState,
                        questionPreguntaTimer: null
                    }
                }
                return {//if not reduce by 1
                    ...prevState,
                    currentTimePregunta: prevState.currentTimePregunta - 1 
                }
            }, () => {
                if(this.state.currentTimePregunta - 1 <= 0 && this.state.questionPreguntaTimer !== null){//if timer reaches 0s
                    //end game
                    console.log('rerminarjuego on timer end')

                    this.state.socket.emit('terminarJuego');
                    this.setState({
                        loading: true
                    })
                }
            });
        }, 1000);//run every second      
        this.setState({ questionPreguntaTimer: timeInterval });//save time interval in state
    }
    render() {
        if(this.state.currentView === 'lobby'){//Render lobby
            return (
                <>
                    <Lobby 
                        tipo={'crucigrama'}
                        loadingLobby={this.props.loadingLobby}
                        nombre={this.props.nombre}
                        patrocinador={this.props.patrocinador}
                        user={this.props.user}
                        creador={this.props.creador}
                        setApodo={(apodo) => {
                            this.state.socket.emit('apodo', { apodo })
                        }}//send apodo to back
                    />
                </>
            );
        }//if not render main content

        return(
            <>
                {!this.props.isMobile &&
                    <Header
                        component={this.state.component}
                        juego={this.state.juego}
                        isMobile = {this.props.isMobile}
                        titulo={this.props.nombre}
                        tiempoLobby={this.state.currentTimeLobbyPregunta}
                        tiempoPregunta={this.state.currentTimePregunta}
                        icon = 
                        {
                            <Rectangulo 
                                className='header-icon'
                                color='var(--crossword)' 
                                width='22px'
                                height='22px'
                            />
                        }
                    />
                }
                {this.state.loading ? 
                    <Container className='fondo-gris-full vertical-center'>
                        <Row>
                            <Col>
                                <IconsLoading /> 
                            </Col>
                        </Row>
                    </Container>
                    : 
                    <CrosswordContent
                        apodo={this.state.apodo}
                        juego={this.state.juego}
                        patrocinador={this.props.patrocinador}
                        component={this.state.component}
                        responder={this.responder}//callback to send answer to back
                        setSugerenciaProperty={this.setSugerenciaProperty}
                        showThankYouModal={this.state.sugerencia.showThankYouModal}
                        isMobile={this.props.isMobile}
                        user={this.props.user}
                        urlQuery={this.props.urlQuery}
                        tiempoPregunta={this.state.currentTimePregunta}
                        terminarJuego={() => { 
                            this.state.socket.emit('terminarJuego');
                            clearInterval(this.state.questionPreguntaTimer);
                            this.setState({
                                loading: true
                            })
                        }}
                    />
                }
                {this.state.component !== 'question' &&
                    <Footer
                        juego={this.state.juego}
                        idJuego={this.props.urlQuery.juego}
                        type={this.props.urlQuery.tipo}
                        patrocinador={this.state.patrocinador}
                        isMobile={this.props.isMobile}
                        component={this.state.component}
                        siguientePregunta={this.siguientePregunta}
                        postSugerencia={this.postSugerencia}
                        loading={this.state.loading}
                        user={this.props.user}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    tipo: state.jugarSolo.tipo,
    nombre: state.jugarSolo.nombre,
    patrocinador: state.jugarSolo.patrocinador,
    loadingLobby: state.jugarSolo.loadingLobby,
    namespace: state.jugarSolo.namespace,
    tiempoEsperaLobby: state.jugarSolo.tiempoEsperaLobby,
    preguntasTotales: state.jugarSolo.cantidadPreguntas,
    tiempoEsperaPregunta: state.jugarSolo.tiempoEsperaPregunta,
    user: state.user.user,
    preguntas: state.crucigramaSolo.preguntas,
    creador: state.jugarSolo.creador
});

export default connect(mapStateToProps, {
    createJugarSoloSesion,
    clearStore,
    postSugerencia
  })(AloneCrossword);