import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";

export default function ({ tipos, tipo, createNuevoJuego, handleFilter, signOut }) {
  const [show, setShow] = useState(false);

  function TipoRow({ tipo }) {
    let Icon = tipo.icon;
    return (
      <Row className="align-items-center my-2">
        <Col md={12}>
          <h6
            onClick={() => {
              if (handleFilter){
                handleFilter(tipo.filter ? tipo.filter : tipo.title);
                setShow(!show);
              }
            }}
          >
            <Icon style={{width: 22}} className='pr-2' />
            {tipo.title}
          </h6>
        </Col>
      </Row>
    );
  }

  function renderTipos() {
    if (tipos && tipos !== null && show) {
      if (tipo === null) tipos = tipos.slice(1);
      else tipos = tipos.filter((type) => type.title !== tipo);
      return tipos.map((tipo) => {
        return <TipoRow key={tipo.title} tipo={tipo} />;
      });
    }
  }

  function renderTab() {
    let selected = tipos.find((type) => type.title === tipo);
    if (!selected) selected = tipos[0];
    return <TipoRow tipo={selected} />;
  }

  return (
    <Container fluid className="py-2 dropdown show-mobile">
      <Row className="align-items-center dropdown-tabs">
        <Col xs={10}>
          {renderTab()}
        </Col>
        <Col xs={2}>
          <Button
            variant="link"
            className="text-dark py-0"
            onClick={() => setShow(!show)}
          >
            <i className={`fa fa-chevron-${show ? "up" : "down"}`}></i>
          </Button>
        </Col>
      </Row>
      {renderTipos()}
      {show && (
        <Row className="align-self-end justify-content-center text-center my-3">
          <Col xs={12}>
            <Button
                variant="outline-danger"
                className="border-red-new"
                onClick={signOut}
                block
                style={{ color: "#FF3833" }}
                >
                  <h6 className="py-2 my-0"><i className="fas fa-sign-out-alt"></i> Cerrar sesión</h6>
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}
