import React, { Component } from "react";
import CardJuego from "../components/CardJuego";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { seleccionarJuego } from "../actions/juegoActions";
import { getJuegos } from "../actions/configuracionJuegosActions";
import { connect } from "react-redux";
import { alert } from "../actions/modalActions";
import { navigate } from "@reach/router";
//import { userLoggedIn, signOut, signIn } from "./actions/userActions";

class Crear extends Component {

  componentDidMount() {
    if(!this.props.user){
      navigate("/signup");
    }
    this.props.getJuegos();
    window.scrollTo(0, 0);
  }
  renderTipos() {
    if (this.props.juegos === null) return <Spinner animation="border" variant="dark" />;
      return this.props.juegos.map((tipo) => (
        <CardJuego
          seleccionarJuego={this.props.seleccionarJuego}
          juego={tipo}
          key={tipo.nombre}
          icon={tipo.icon}
          color={tipo.color}
          activo={tipo.activo}
          alert={this.props.alert}
          width="100px"
          height="100px"
          fontClass="h4 my-0"
        />
      ));
  }

  render() {
    return (
      <Container className="vertical-center fondo-gris-full" fluid>
        <Row className="justify-content-md-center text-center my-3 pt-5">
          <Col>
            <h1>Crear Juego Nuevo</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center">{this.renderTipos()}</Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  juegos: state.configuracion.juegos,
  user: state.user.user,
});

export default connect(mapStateToProps, { seleccionarJuego, alert, getJuegos })(Crear);
