import {
  MISJUEGOS_RECIBIDOS,
  MISJUEGOS_SORT,
  DUPLICAR_JUEGO,
  SHOW_OPCIONES,
  ELIMINAR_JUEGO,
  CLEAR_JUEGO,
  SET_PROPIEDAD_MIJUEGO,
} from "./types";
import MisJuegosService from "../services/MisJuegosService";
import PreguntasService from "../services/PreguntasService";
import AdjuntoService from "../services/AdjuntosService";
import {
  procesarPreguntasOpciones,
  duplicarPreguntas,
  procesarOpcionesTrivia,
  procesarOpcionesJuegos,
  displaySuccess,
  displayError,
} from "../utils";
import ReactGA from "react-ga";

export const getMisJuegos = () => (dispatch) => {
  MisJuegosService.getMisJuegos()
    .then((res) => {
      const misjuegos = res.data;
      dispatch({ type: MISJUEGOS_RECIBIDOS, payload: misjuegos });
    })
    .catch((error) => {
      displayError(
        dispatch,
        "Hubo un error al cargar tus juegos. Ya estamos revisando que sucedió. Intentalo más tarde"
      );
    });
};

export const sortJuegos = (opcion) => (dispatch) => {
  dispatch({ type: MISJUEGOS_SORT, payload: { opcion } });
};

export const duplicarJuego = (idJuego) => (dispatch) => {
  MisJuegosService.getJuego(idJuego)
    .then((res) => {
      ReactGA.event({
        category: "Duplicar",
        action: "Duplicar Juego",
        label: "Duplicar Juego",
        value: idJuego,
      });
      let { juego, preguntas, opciones } = res.data;
      juego = { ...juego, idJuego: "nuevo" };
      let { tipo } = juego;
      tipo = tipo.toLowerCase();
      preguntas = duplicarPreguntas(preguntas);
      if (tipo === "trivia") {
        opciones = procesarOpcionesTrivia(opciones);
      } else {
        opciones = procesarOpcionesJuegos(preguntas);
      }
      preguntas = procesarPreguntasOpciones(preguntas, opciones);
      const promises = [];
      preguntas.forEach((pregunta) => {
        if (pregunta.idAdjunto && pregunta.idAdjunto !== null) {
          const promise = new Promise((resolve, reject) => {
            AdjuntoService.duplicarAdjunto(pregunta.idAdjunto)
              .then((res) => {
                const { idAdjunto } = res.data;
                pregunta.idAdjunto = idAdjunto;
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          });
          promises.push(promise);
        }
      });
      Promise.all(promises).then(() => {
        MisJuegosService.postJuego(juego)
          .then((res) => {
            idJuego = res.data.juego.idJuego;
            juego.idJuego = idJuego;
            PreguntasService.postPreguntas(idJuego, preguntas)
              .then((res) => {
                dispatch({ type: DUPLICAR_JUEGO, payload: juego });
                displaySuccess(dispatch, "Juego duplicado con éxito.");
              })
              .catch((error) => {
                displayError(
                  dispatch,
                  "Hubo un error al guardar las preguntas. Inténtalo más tarde."
                );
              });
          })
          .catch((error) => {
            displayError(
              dispatch,
              "Hubo un error al guardar el juego. Inténtalo más tarde."
            );
          });
      });
    })
    .catch((error) => {
      displayError(
        dispatch,
        "Hubo un error al cargar tu juego. Ya estamos revisando que sucedió.\nIntentalo más tarde"
      );
    });
};

export const showOpciones = (idJuego) => (dispatch) => {
  dispatch({ type: SHOW_OPCIONES, payload: idJuego });
};

export const eliminarJuego = (idJuego) => (dispatch) => {
  MisJuegosService.deleteJuego(idJuego)
    .then(() => {
      ReactGA.event({
        category: "Eliminar",
        action: "Eliminar Juego",
        label: "Eliminar Juego",
        value: idJuego,
      });
      dispatch({ type: ELIMINAR_JUEGO, payload: idJuego });
      displaySuccess(dispatch, "Juego eliminado con éxito");
    })
    .catch((error) => {
      displayError(dispatch, "Hubo un error al eliminar el juego.");
    });
};

export const clearJuego = () => (dispatch) => {
  dispatch({ type: CLEAR_JUEGO });
};

export const setPropiedadMiJuego = (idJuego, key, value) => (dispatch) => {
  if (key === "favorito") {
    MisJuegosService.putFavorito(idJuego, value);
  }
  dispatch({ type: SET_PROPIEDAD_MIJUEGO, payload: { idJuego, key, value } });
};
