import React, { useState } from "react";
import ReactGA from "react-ga";
import { Row, Col, Button, Container } from "react-bootstrap";
import { tipos } from "../utils";
import { Link, navigate } from "@reach/router";
import { Modal } from "react-bootstrap";
import knowMoreVideo from '../assets/knowmoreulu.mp4'

const KnowMoreModal = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='know-more-modal'
    >
      <Modal.Body>
        <button type="button" class="close" onClick={props.onHide} >
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close</span>
        </button>
        <video width='100%' controls autoPlay>
            <source src={knowMoreVideo} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  );
}

export default (props) => {
  const [modalShow, setModalShow] = useState(false);
  const { handleFilter, filter } = props;

  function handleClick() {
    ReactGA.event({
      category: "Crear Juego",
      action: "Crear Juego Sidebar",
      label: "Crear Juego Sidebar",
    });
    navigate("/seleccionar");
  }

  function renderButton() {
    if (props.admin)
      return (
        <Button
          variant="outline-danger"
          className="border-red-new"
          onClick={() => props.signOut(props.usuario.idUsuario)}
          block
          style={{ color: "#FF3833" }}
        >
          <h5 className="py-2 my-0">
            <i className="fas fa-sign-out-alt pr-2"></i> Cerrar sesión
          </h5>
        </Button>
      );
    return (
      <Button
        onClick={() => handleClick()}
        className="border-red-new"
        block
      >
        <h5 className="py-2 my-0">
          Crear nuevo ULU
        </h5>
      </Button>
    );
  }

  function handleClickRow(tipo) {
    if (handleFilter) {
      handleFilter(tipo.filterSidebar ? tipo.filterSidebar : tipo.title);
    }
  }

  function renderRow(tipo) {
    let Icon = tipo.icon;
    return (
      <Row
        key={tipo.title}
        className={`py-3 align-items-center px-0 pl-3 pr-0 mx-0 hover-cursor hover-${tipo.slug
          } ${filter === tipo.title || filter === tipo.slug ? `bg-${tipo.slug}` : ""
          }`}
        onClick={() => handleClickRow(tipo)}
      >
        <Col xs={1} className="px-0">
          <p>
            <Icon width='16' height='16'/>
          </p>
        </Col>
        <Col xs={10} className="pl-1">
          <Row className="align-items-center mx-0">
            <p style={{ color: ((filter === tipo.title || filter === tipo.slug) ? `${tipo.color}` : "#2f2f2f") }}>
              <b>{tipo.title}</b>
            </p>
          </Row>
        </Col>
      </Row>
    );
  }

  function renderContent() {
    if (props.reportes) {
      return (
        <Link to="/misjuegos" className="text-secondary mx-3 d-block mt-4">
          {"< "}Regresar a Mis Juegos
        </Link>
      );
    }
    if(props.page === "Explore"){
      return tipos.map((tipo) => {
        if(tipo.title !== "Favoritos") return renderRow(tipo);
      });
    }
    return props.opciones
      ? props.opciones.map((opcion) => renderRow(opcion))
      : tipos.map((tipo) => renderRow(tipo));
  }

  return (
    <>
      <div className='sidebar'>
        <Row>
          <Col xs={12}>
            {renderContent()}
          </Col>
        </Row>
        <div
          className={`col-md-3 col-xl-2 align-items-center justify-content-center text-center ${props.movil ? "mt-5" : "corner-bl"
            } px-0`}
          style={{ left: "-4px" }}
        >
          <Button
            variant="link"
            className="text-dark text-left px-0"
            onClick={() => setModalShow(true)}
          >
            <p className='link-gray text-smaller'>¡Conoce más sobre PlayULU!</p>
          </Button>
          <hr style={{ margin: '15px 0' }} />
          <Container fluid style={{ minWidth: "100%" }}>
            {renderButton()}
          </Container>
        </div>
      </div>
      <KnowMoreModal 
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
