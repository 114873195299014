import {
    SOLO_CLEAR_STORE,
    SOLO_CRUCIGRAMA_RECIBIDO,
    SOLO_CRUCIGRAMA_SELECT_QUESTION,
    SOLO_CRUCIGRAMA_SET_QUESTION_STATUS
} from "../actions/types";

const INITIAL_STATE = {
    preguntas: null,
    selectedQuestion: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SOLO_CRUCIGRAMA_RECIBIDO:
            const {
                preguntas,
            } = action.payload;
            const firstIndex = preguntas.keys().next().value;
            const selectedQuestion = preguntas.get(firstIndex).idPregunta;
            return {
                ...state,
                preguntas,
                selectedQuestion
            };
        case SOLO_CLEAR_STORE:
            return INITIAL_STATE;
        case SOLO_CRUCIGRAMA_SELECT_QUESTION:
            return {
                ...state,
                selectedQuestion: action.payload
            };
        case SOLO_CRUCIGRAMA_SET_QUESTION_STATUS:
            const preguntasMap = state.preguntas;
            const idPregunta = action.payload
            preguntasMap.set(
                idPregunta,
                {
                    ...preguntasMap.get(idPregunta),
                    contestada: true
                }
            );
            return {
                ...state,
                preguntas: preguntasMap
            };
        default:
            return { ...state };
    }
};
