import React, { Component } from 'react';

class Keyboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsHangman: [
                ['1','2','3','4','5','6','7','8','9','0'],
                ['q','w','e','r','t','y','u','i','o','p'],
                ['a','s','d','f','g','h','j','k','l'],
                ['z','x','c','v','b','n','m'],
            ],
            rowsCrossword: [
                ['1','2','3','4','5','6','7','8','9','0'],
                ['q','w','e','r','t','y','u','i','o','p'],
                ['a','s','d','f','g','h','j','k','l'],
                ['z','x','c','v','b','n','m', 'backspace'],
                [''],
            ],
        }
    }

    render() {
        return(
            <>
                {this.props.gameType === 'crucigrama' ? 
                    <>
                    {this.state.rowsCrossword.map((row, i) => (
                        <ul key={`keyboard-div-${i}`} className={`hangman-keyboard-row text-center keyboard-row-${i}`}>
                            {row.map((letter, j) => (
                                <li key={`keyboard-li-${j}`}  className={`hangman-keyboard-letter ${this.props.clickedLetters?.includes(letter) && 'keyboard-letter-selected'}`} onClick={() => this.props.onClick({key: letter})}>
                                    <div className='vertical-center'>
                                        <h6>
                                            {
                                                (this.props.gameType === 'crucigrama' && letter === 'backspace') ? 
                                                    <i class="fas fa-backspace"></i> 
                                                    :
                                                    letter
                                            }
                                        </h6>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ))}
                    </>
                    :
                    <>
                    {this.state.rowsHangman.map((row, i) => (
                        <ul key={`keyboard-div-${i}`} className={`hangman-keyboard-row text-center keyboard-row-${i}`}>
                            {row.map((letter, j) => (
                                <li key={`keyboard-li-${j}`}  className={`hangman-keyboard-letter ${this.props.clickedLetters?.includes(letter) && 'keyboard-letter-selected'}`} onClick={() => this.props.onClick({key: letter})}>
                                    <div className='vertical-center'>
                                        <h6>{letter}</h6>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ))}
                    </>
                }
            </>
        );
    }
}

export default Keyboard;