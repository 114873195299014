import api from "./api";

const route = "/patrocinadores/codigos";

export default {
  getCodigos: (idPremio) => api.get(`${route}/${idPremio}`),
  putCodigos: (idPremio, nuevos, editar, eliminar, tipoCodigo) =>
    api.put(`${route}/${idPremio}`, { nuevos, editar, eliminar, tipoCodigo }),
  putCodigosExcel: (idPremio, formData) =>
    api.put(`${route}/excel/${idPremio}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};
