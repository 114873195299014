import React, { Component } from 'react';

class HangmanLives extends Component {
    render() {
        let { livesLeft } = this.props;
        return (
            <>
            <div className='aspect-ratio-11 background-red-muted mb-2'>
                <svg className='cara-ahorcado' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="70%" height="70%" viewBox="0 0 270 230">
                    <defs>
                        <clipPath id="b">
                            <rect width="270" height="230"/>
                        </clipPath>
                    </defs>
                    <g id="a" clipPath="url(#b)">
                        <g transform="translate(-3195 -618)">
                            <g transform="translate(3195.32 618.334)">
                                <g transform="translate(-3195.32 -618.334)" fill={livesLeft <= 3 ? 'var(--ulu-red)' : 'none'}>
                                    <path d="M3251.111,649.018h-16.055l-8.62,51.034H3195.32l13.8-81.719h67.931l13.8,81.719h-31.116Z" stroke="none"/>
                                    <path d="M 3213.3447265625 623.3336181640625 L 3201.23486328125 695.0523681640625 L 3222.20947265625 695.0523681640625 L 3230.1259765625 648.185302734375 L 3230.829833984375 644.01806640625 L 3235.05615234375 644.01806640625 L 3251.111328125 644.01806640625 L 3255.337646484375 644.01806640625 L 3256.04150390625 648.185302734375 L 3263.9580078125 695.0523681640625 L 3284.9326171875 695.0523681640625 L 3272.82275390625 623.3336181640625 L 3213.3447265625 623.3336181640625 M 3209.1181640625 618.3336181640625 L 3277.04931640625 618.3336181640625 L 3290.84765625 700.0523681640625 L 3259.731689453125 700.0523681640625 L 3251.111328125 649.01806640625 L 3235.05615234375 649.01806640625 L 3226.435791015625 700.0523681640625 L 3195.31982421875 700.0523681640625 L 3209.1181640625 618.3336181640625 Z" stroke="none" fill="#ff3833"/>
                                </g>
                                <g transform="translate(-3196.048 -618.334)" fill={livesLeft <= 2 ? 'var(--ulu-red)' : 'none'}>
                                    <path d="M3421.926,649.018h-16.055l-8.62,51.034h-31.116l13.8-81.719h67.931l13.8,81.719h-31.116Z" stroke="none"/>
                                    <path d="M 3384.1591796875 623.3336181640625 L 3372.04931640625 695.0523681640625 L 3393.024169921875 695.0523681640625 L 3400.9404296875 648.185302734375 L 3401.644287109375 644.01806640625 L 3405.87060546875 644.01806640625 L 3421.926025390625 644.01806640625 L 3426.15234375 644.01806640625 L 3426.856201171875 648.185302734375 L 3434.7724609375 695.0523681640625 L 3455.7470703125 695.0523681640625 L 3443.63720703125 623.3336181640625 L 3384.1591796875 623.3336181640625 M 3379.9326171875 618.3336181640625 L 3447.86376953125 618.3336181640625 L 3461.662109375 700.0523681640625 L 3430.546142578125 700.0523681640625 L 3421.926025390625 649.01806640625 L 3405.87060546875 649.01806640625 L 3397.25048828125 700.0523681640625 L 3366.13427734375 700.0523681640625 L 3379.9326171875 618.3336181640625 Z" stroke="none" fill="#ff3833"/>
                                </g>
                                <g transform="translate(-3195.753 -618.334)" fill={livesLeft <= 1 ? 'var(--ulu-red)' : 'none'}>
                                    <path d="M3312.433,765.143a2.916,2.916,0,0,0,2.916,2.916h66.7V737.374h-36.875a2.056,2.056,0,0,1-2.056-2.055V618.334h-30.684Z" stroke="none"/>
                                    <path d="M 3377.048095703125 763.0590209960938 L 3377.048095703125 742.3740234375 L 3345.173095703125 742.3740234375 C 3341.2822265625 742.3740234375 3338.116943359375 739.2091674804688 3338.116943359375 735.3189697265625 L 3338.116943359375 623.333984375 L 3317.43310546875 623.333984375 L 3317.43310546875 763.0590209960938 L 3377.048095703125 763.0590209960938 M 3382.048095703125 768.0590209960938 L 3315.34912109375 768.0590209960938 C 3313.738037109375 768.0590209960938 3312.43310546875 766.7529907226562 3312.43310546875 765.1430053710938 L 3312.43310546875 618.333984375 L 3343.116943359375 618.333984375 L 3343.116943359375 735.3189697265625 C 3343.116943359375 736.4539794921875 3344.0380859375 737.3740234375 3345.173095703125 737.3740234375 L 3382.048095703125 737.3740234375 L 3382.048095703125 768.0590209960938 Z" stroke="none" fill="#ff3833"/>
                                </g>
                                <g transform="translate(95.689 205.61) rotate(-40)">
                                    <g transform="translate(0)" fill={livesLeft <= 0 ? 'var(--ulu-red)' : 'none'}>
                                        <path d="M0,30.684V0C66.815,0,122.4,48.894,132.917,112.775H101.671A104.2,104.2,0,0,0,0,30.684Z" stroke="none"/><path d="M 4.999992370605469 5.094619750976562 L 4.999992370605469 25.79719543457031 C 16.03659820556641 26.29682159423828 26.84526824951172 28.45244598388672 37.19815826416016 32.22393798828125 C 48.70285034179688 36.4150390625 59.36737060546875 42.49295806884766 68.89546966552734 50.28889465332031 C 78.33866119384766 58.01533126831055 86.37401580810547 67.18810272216797 92.77835083007812 77.55239868164062 C 98.59564971923828 86.96672058105469 102.9096755981445 97.11573028564453 105.6295318603516 107.7743759155273 L 126.8897857666016 107.7745056152344 C 124.1314010620117 94.76100921630859 119.3908309936523 82.30745697021484 112.7623901367188 70.68022155761719 C 109.0637435913086 64.19230651855469 104.7700958251953 57.9761848449707 100.0007171630859 52.20455932617188 C 95.25132751464844 46.45714569091797 89.97344970703125 41.08168792724609 84.31365966796875 36.22750091552734 C 78.6341552734375 31.35639190673828 72.50201416015625 26.95193481445312 66.08761596679688 23.13645935058594 C 59.60230255126953 19.27883148193359 52.74588775634766 15.97445678710938 45.70882415771484 13.31513977050781 C 32.65915679931641 8.3836669921875 18.9884033203125 5.625007629394531 4.999992370605469 5.094619750976562 M 0 0 C 66.81494903564453 0.00037384033203125 122.39599609375 48.89397811889648 132.917236328125 112.7745361328125 L 101.6708908081055 112.7743530273438 C 91.554931640625 65.91899871826172 49.832763671875 30.68451690673828 0 30.68426513671875 L 0 0 Z" stroke="none" fill="#ff3833"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <p>{livesLeft} vidas</p>
            </>
        );
    }
}

export default HangmanLives;