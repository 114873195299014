import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Navbar, Image } from "react-bootstrap";
//Trivia Imports
import HostPregunta from "./host/trivia/HostPregunta";
import HostOpciones from "./host/trivia/HostOpciones";
//Crucigrama Imports
import HostCrucigrama from "./host/crucigrama/HostCrucigrama";
//General Host Imports
import HostPin from "./host/HostPin";
import Scoreboard from "./host/Scoreboard";
import FinalScoreboard from "./host/FinalScoreboard";
import Espera from "./Espera";
import FooterHost from "../components/FooterHost";
import io from "socket.io-client";
import { BASE_URL, getTipo } from "../utils";
import {
  agregarJugador,
  setPregunta,
  joinJuego,
  setOpciones,
  agregarRespuesta,
  setCounter,
  setHoraServer,
  hostJuego,
  setJuego,
  finishJuego,
  setScoreboard,
  clearJuego,
} from "../actions/jugarActions";
import {
  setPreguntasCrucigrama,
  agregarRespuestaCrucigrama,
} from "../actions/crucigramaActions";
import {
  agregarRespuestaAhorcado,
  clearContestadas,
  jugadorAhorcado,
  jugadorFinished,
} from "../actions/ahorcadoActions";
import { downloadReporte } from "../actions/reportesActions";
import { confirm } from "../actions/modalActions";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import HostPreguntaAhorcado from "./host/ahorcado/HostPreguntaAhorcado";
import logo from "../assets/logo.png";
import RespuestasCrucigrama from "./host/crucigrama/RespuestasCrucigrama";
import moment from "moment";

class Host extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      socket: null,
      component: null,
      callback: null,
      hidePregunta: false,
    };

    this.state = { ...this.initialState };

    this.timeout = null;

    this.next = this.next.bind(this);
    this.salir = this.salir.bind(this);
    this.getParams = this.getParams.bind(this);
    this.setCounter = this.setCounter.bind(this);
    this.displayPin = this.displayPin.bind(this);
    this.finishJuego = this.finishJuego.bind(this);
    this.displayScore = this.displayScore.bind(this);
    this.clearCounter = this.clearCounter.bind(this);
    this.nextPregunta = this.nextPregunta.bind(this);
    this.iniciarJuego = this.iniciarJuego.bind(this);
    this.connectSocket = this.connectSocket.bind(this);
    this.displayEspera = this.displayEspera.bind(this);
    this.displayPregunta = this.displayPregunta.bind(this);
    this.displayOpciones = this.displayOpciones.bind(this);
    this.disconnectSocket = this.disconnectSocket.bind(this);
    this.displayFinalScore = this.displayFinalScore.bind(this);
  }

  componentDidMount() {
    if (this.props.idJuego === null) navigate("/");
    else this.props.hostJuego(this.props.idJuego);
    this.time = moment();
    window.onclose = this.finishJuego;
    let menu = document.getElementById("menu");
    if (menu && menu !== null) menu.style.display = "none";
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.juego === null && this.props.juego !== null) {
      this.displayPin();
      let time = moment().diff(this.time, "miliseconds");
      ReactGA.timing({
        category: "Jugar",
        variable: "miliseconds",
        value: time,
        label: `Host ${getTipo(this.props.juego)}`,
      });
      if (this.props.juego.tipo === "Crucigrama")
        this.setState({ hidePregunta: true });
    }
    if (
      prevProps.scoreboard !== this.props.scoreboard &&
      this.state.component === "final"
    ) {
      this.setState({ component: "final" });
    }
    if (
      this.props.respuestasCrucigrama !== null &&
      this.props.preguntas !== null &&
      this.props.jugadores !== null
    ) {
      let respuestas = this.props.respuestasCrucigrama;
      respuestas = respuestas.filter((respuesta) => respuesta.puntos > 0);
      if (
        respuestas.length ===
          this.props.preguntas.length * this.props.jugadores.length &&
        this.state.component !== "scoreboard" &&
        this.state.component !== "final"
      ) {
        this.displayScore();
      }
    } else if (this.props.jugadores.length > 0) {
      if (
        this.props.jugadores.length ===
          this.props.ahorcados.length + this.props.finished.length &&
        this.state.component === "pregunta"
      ) {
        this.displayScore();
      }
    }
  }

  componentWillUnmount() {
    if (this.state.component === "final") {
      let time = moment().diff(this.time, "seconds");
      ReactGA.timing({
        category: "Tiempo en Pantalla",
        label: "Tiempo en Final Score",
        variable: "seconds",
        value: time,
      });
    }
    this.finishJuego();
    this.setState({ ...this.initialState });
    let menu = document.getElementById("menu");
    if (menu && menu !== null) menu.style.display = "flex";
  }

  finishJuego() {
    if (
      this.props.component !== "scoreboard" &&
      this.props.component !== "final" &&
      !this.props.finished
    ) {
      this.props.finishJuego(this.props.idSesion, getTipo(this.props.juego));
    }
    this.disconnectSocket();
    window.onclose = null;
    this.props.clearJuego();
    let menu = document.getElementById("menu");
    if (menu !== null) menu.style.display = "flex";
  }

  getScore() {
    let { socket } = this.state;
    socket.emit("get_scoreboard", this.props.idSesion);
  }

  getParams() {
    const numPregunta = this.props.numPregunta;
    const puntosPregunta =
      this.props.pregunta !== null ? this.props.pregunta.puntos : 1000;
    const respuestas = this.props.respuestas.length;
    const total = this.props.juego !== null ? this.props.juego.preguntas : 0;
    return { numPregunta, puntosPregunta, respuestas, total };
  }

  displayPin() {
    this.time = moment();
    this.setState({
      component: "pin",
    });
  }

  displayPregunta() {
    this.setState({
      component: "pregunta",
    });
  }

  displayOpciones() {
    this.setState({
      component: "opciones",
    });
  }

  displayScore() {
    let { socket } = this.state;
    let { numPregunta, total } = this.getParams();
    let index = numPregunta - 1;
    if (index <= total) {
      socket.emit("closePregunta", { idSesion: this.props.idSesion, index });
    }
    let tipo = getTipo(this.props.juego);
    if (tipo !== "crucigrama") {
      setTimeout(() => {
        this.setState({
          component: "scoreboard",
        });
      }, 800);
    } else {
      this.setState({ component: "scoreboard" });
    }
    this.clearCounter();
    if (tipo === "ahorcado") {
      this.props.clearContestadas();
    }
  }

  displayFinalScore() {
    let { socket } = this.state;
    socket.emit("game_over", this.props.idSesion);
    this.setState({
      component: "final",
    });
    if (getTipo(this.props.juego) === "crucigrama") {
      this.props.finishJuego(this.props.idSesion, getTipo(this.props.juego));
    }
    this.time = moment();
  }

  displayEspera() {
    this.setState({
      component: "espera",
    });
  }

  setCounter(segundos, callback) {
    if (this.timeout !== null) clearTimeout(this.timeout);
    let ms = segundos * 1000;
    this.props.setCounter(segundos);
    this.interval = setInterval(() => {
      this.props.setCounter(this.props.segundos - 1);
    }, 1000);
    if (callback) this.setState({ callback });
    this.timeout = setTimeout(() => {
      window.clearInterval(this.interval);
      this.interval = null;
      if (this.state.callback !== null) this.state.callback();
    }, ms);
  }

  clearCounter() {
    window.clearInterval(this.interval);
    window.clearTimeout(this.timeout);
    this.interval = null;
    this.timeout = null;
    this.setState({ callback: null });
  }

  iniciarJuego() {
    if (this.props.jugadores.length > 0) {
      let time = moment().diff(this.time, "seconds");
      ReactGA.timing({
        category: "usuario",
        variable: "espera",
        value: time,
        label: "Tiempo de PIN",
      });
      this.nextPregunta();
      this.getScore();
    }
  }

  salir() {
    this.props.confirm(
      `¿Estás seguro que quieres salir? ¡${this.props.jugadores.length} jugadores perderán sus puntos!`,
      async () => {
        await navigate("/explorar");
        window.scrollTo(0, 0);
        ReactGA.event({
          category: "Salir",
          action: "Salir de Juego",
          label: "Salir de Juego",
        });
      }
    );
  }

  next() {
    if (this.props.juego.tipo === "Crucigrama") this.displayFinalScore();
    else this.nextPregunta();
  }

  nextPregunta() {
    let { socket } = this.state;
    let { numPregunta } = this.getParams();
    let index = numPregunta > 0 ? numPregunta : 0;
    if (!socket) {
      this.connectSocket(this.props.pin, () => {
        socket.emit("solicitarPregunta", {
          idSesion: this.props.idSesion,
          index,
        });
      });
    } else {
      socket.emit("solicitarPregunta", {
        idSesion: this.props.idSesion,
        index,
      });
    }
  }

  connectSocket(pin, callback) {
    let base_url = BASE_URL.replace("/api", "");
    let socket = io(`${base_url}/${pin}`);

    socket.on("connect", () => {
      if (callback) callback();
    });

    socket.on("connect_error", (error) => {
      console.log(JSON.stringify(error));
    });

    socket.on("pregunta", (pregunta) => {
      if (pregunta !== null) {
        this.props.setPregunta(pregunta);
        if (pregunta.opciones) this.props.setOpciones(pregunta.opciones);
        this.displayPregunta();
      } else {
        this.props.finishJuego(this.props.idSesion, getTipo(this.props.juego));
        this.displayFinalScore();
      }
    });

    socket.on("preguntas_crucigrama", (preguntas) => {
      this.props.setPreguntasCrucigrama(preguntas);
      this.displayPregunta();
    });

    socket.on("horaServer", (horaServer) => {
      this.props.setHoraServer(horaServer);
    });

    socket.on("usuario", (apodo) => {
      this.props.agregarJugador(apodo);
      socket.emit("datos_juego", this.props.juego);
    });

    socket.on("respondida", (data) => {
      this.getScore();
      let { apodo, puntos, idOpcion, idRespuesta, letra } = data;
      let { tipo } = this.props.juego;
      tipo = tipo.toLowerCase();
      switch (tipo) {
        case "crucigrama":
          this.props.agregarRespuestaCrucigrama(apodo, puntos, idRespuesta);
          break;
        case "ahorcado":
          this.props.agregarRespuestaAhorcado(apodo, letra, puntos);
          break;
        default:
          this.props.agregarRespuesta(apodo, puntos, idOpcion);
          if (this.props.respuestas.length === this.props.jugadores.length) {
            this.displayScore();
          }
      }
    });

    socket.on("ahorcado", (apodo) => {
      this.props.jugadorAhorcado(apodo);
    });

    socket.on("finished", (apodo) => {
      this.props.jugadorFinished(apodo);
    });

    socket.on("scoreboard", (scoreboard) => {
      this.props.setScoreboard(scoreboard);
    });

    socket.on("closed", () => {
      this.getScore();
    });

    this.props.joinJuego(pin);

    this.setState({ socket });
  }

  disconnectSocket() {
    let { socket } = this.state;
    if (socket && socket !== null) {
      socket.emit("borrar");
    }
  }

  renderComponent() {
    const { total, numPregunta, puntosPregunta, respuestas } = this.getParams();
    let tipo = getTipo(this.props.juego);
    switch (this.state.component) {
      case "pin":
        return (
          <HostPin
            connectSocket={this.connectSocket}
            iniciarJuego={this.iniciarJuego}
            pin={this.props.pin}
            patrocinador={this.props.patrocinador}
            jugadores={this.props.jugadores}
            juego={this.props.juego}
          />
        );
      case "pregunta":
        if (this.props.juego.tipo === "Crucigrama")
          return (
            <HostCrucigrama
              pin={this.props.pin}
              tiempo={this.props.segundos}
              setCounter={this.setCounter}
              juego={this.props.juego}
              puntosPregunta={puntosPregunta}
              displayFinalScore={this.displayFinalScore}
              preguntas={this.props.preguntas}
              respuestas={this.props.respuestasCrucigrama}
              displayScore={this.displayScore}
            />
          );
        if (this.props.juego.tipo === "Ahorcado")
          return (
            <HostPreguntaAhorcado
              total={total}
              numPregunta={numPregunta}
              pin={this.props.pin}
              pregunta={this.props.pregunta}
              juego={this.props.juego}
              segundos={this.props.segundos}
              setCounter={this.setCounter}
              displayScore={this.displayScore}
              clearContestadas={this.props.clearContestadas}
              patrocinador={this.props.patrocinador}
              jugadores={this.props.jugadores}
              base_url={BASE_URL}
              respuestas={this.props.respuestasAhorcado}
            />
          );
        return (
          <HostPregunta
            pin={this.props.pin}
            total={total}
            numPregunta={numPregunta}
            tiempo={this.props.segundos}
            puntosPregunta={puntosPregunta}
            pregunta={this.props.pregunta}
            setPregunta={this.props.setPregunta}
            setCounter={this.setCounter}
            onClick={this.displayOpciones}
            displayOpciones={this.displayOpciones}
            patrocinador={this.props.patrocinador}
            juego={this.props.juego}
            respuestas={respuestas}
          />
        );
      case "opciones":
        return (
          <HostOpciones
            total={total}
            juego={this.props.juego}
            numPregunta={numPregunta}
            tiempo={this.props.segundos}
            puntosPregunta={puntosPregunta}
            opciones={this.props.opciones}
            pregunta={this.props.pregunta}
            setOpciones={this.props.setOpciones}
            respuestas={respuestas}
            setCounter={this.setCounter}
            displayScore={this.displayScore}
            patrocinador={this.props.patrocinador}
            respuestasAhorcado={this.props.respuestasAhorcado}
          />
        );
      case "scoreboard":
        if (tipo === "crucigrama") {
          return (
            <RespuestasCrucigrama
              pin={this.props.pin}
              tiempo={this.props.segundos}
              setCounter={this.setCounter}
              juego={this.props.juego}
              puntosPregunta={puntosPregunta}
              displayFinalScore={this.displayFinalScore}
              preguntas={this.props.preguntas}
              respuestas={this.props.respuestasCrucigrama}
            />
          );
        }
        return (
          <Scoreboard
            pregunta={this.props.pregunta}
            opciones={this.props.opciones}
            numPregunta={numPregunta}
            total={total}
            tiempo={this.props.segundos}
            correctas={this.props.correctas}
            next={this.nextPregunta}
            scoreboard={this.props.scoreboard}
            patrocinador={this.props.patrocinador}
            juego={this.props.juego}
            respuestas={respuestas}
          />
        );
      case "final":
        let primero = this.props.scoreboard[0];
        let segundo = this.props.scoreboard[1];
        let tercero = this.props.scoreboard[2];
        return (
          <FinalScoreboard
            idSesion={this.props.idSesion}
            idJuego={this.props.idJuego}
            juego={this.props.juego}
            primero={primero}
            segundo={segundo}
            tercero={tercero}
            patrocinador={this.props.patrocinador}
            reporte={this.props.reporte}
            downloadReporte={this.props.downloadReporte}
          />
        );
      default:
        return <Espera patrocinador={this.props.patrocinador} />;
    }
  }

  renderFooter() {
    let { total } = this.getParams();
    let { component } = this.state;
    let tipo = getTipo(this.props.juego);
    if (component !== null && component !== "pin" && component !== "final")
      return (
        <FooterHost
          pin={this.props.pin}
          component={component}
          tipo={tipo}
          numPregunta={this.props.numPregunta}
          total={total}
          hidePregunta={this.state.hidePregunta}
          displayScore={this.displayScore}
          continuar={this.next}
          salir={this.salir}
        />
      );
  }

  renderJugadores() {
    if (this.props.jugadores && this.props.jugadores !== null)
      return <h4>{this.props.jugadores.length} jugadores</h4>;
  }

  render() {
    return (
      <>
        {this.state.component === "pin" && (
          <Container className="bg-white shadow-sm py-3 px-5" fluid>
            <Row>
              <Col>
                <Navbar.Brand href="/">
                  <Image src={logo} width={100} />
                </Navbar.Brand>
              </Col>
              <Col className="text-center">
                <h4>Únete en playulu.com/jugar</h4>
              </Col>
              <Col className="text-right">{this.renderJugadores()}</Col>
            </Row>
            <Row>
              <Col className="text-center">
                {/*
                <p className="h3 mb-n2" style={{ color: "#7E2CFF" }}>
                  
                </p>
                */}
                <p className="display-4 mb-n2" style={{ color: "#FF3833", fontWeight: "bold" }}>
                  <b style={{ color: "#7E2CFF" }}>PIN: </b><u>{this.props.pin}</u>
                </p>
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid={this.state.component === 'pin'}>
          {this.renderComponent()}
          {this.renderFooter()}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  idJuego: state.jugar.idJuego,
  jugadores: state.jugar.jugadores,
  iniciado: state.jugar.iniciado,
  pin: state.jugar.pin,
  pregunta: state.jugar.pregunta,
  opciones: state.jugar.opciones,
  respuestas: state.jugar.respuestas,
  respuestasCrucigrama: state.crucigrama.respuestas,
  respuestasAhorcado: state.ahorcado.respuestas,
  segundos: state.jugar.segundos,
  scoreboard: state.jugar.scoreboard,
  juego: state.jugar.juego,
  numPregunta: state.jugar.numPregunta,
  idSesion: state.jugar.idSesion,
  patrocinador: state.jugar.patrocinador,
  correctas: state.jugar.correctas,
  preguntas: state.crucigrama.preguntas,
  ahorcados: state.ahorcado.ahorcados,
  finished: state.ahorcado.finished,
  reporte: state.jugar.reporte,
  finishedJuego: state.jugar.finished,
});

export default connect(mapStateToProps, {
  agregarJugador,
  setPregunta,
  joinJuego,
  setOpciones,
  clearJuego,
  agregarRespuesta,
  jugadorFinished,
  setCounter,
  setHoraServer,
  hostJuego,
  setJuego,
  confirm,
  jugadorAhorcado,
  finishJuego,
  setScoreboard,
  clearContestadas,
  downloadReporte,
  setPreguntasCrucigrama,
  agregarRespuestaAhorcado,
  agregarRespuestaCrucigrama,
})(Host);
