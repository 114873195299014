import React, { Component } from 'react';
import {
    Row,
    Col,
    Button
} from "react-bootstrap";
import {
    Asterisco,
    Rayas,
    Rectangulo,
} from "../assets/icons.js";
import starFull from "../assets/estrella_llena.svg";
import gameImgRed from "../assets/juego-sin-foto-rojo.png";
import gameImgYellow from "../assets/juego-sin-foto-amarillo.png";
import gameImgBlue from "../assets/juego-sin-foto-azul.png";
import gameImgPurple from "../assets/juego-sin-foto-morado.png";
import gameImgOrange from "../assets/juego-sin-foto-naranja.png";
import gameImgGreen from "../assets/juego-sin-foto-verde.png";
import userRed from "../assets/usuario-rojo.png";
import userYellow from "../assets/usuario-amarillo.png";
import userBlue from "../assets/usuario-azul.png";
import userPurple from "../assets/usuario-morado.png";
import userOrange from "../assets/usuario-naranja.png";
import userGreen from "../assets/usuario-verde.png";
import { BASE_URL, BASE_URL_S3_BUCKET, playerCount, selectCreatorImg } from '../utils/index.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import base64url from 'base64url';
import copy from 'clipboard-copy';

class ExploreGameCard extends Component {
    constructor(props){
        super(props);
        const getUrl = window.location;
        const baseUrl = getUrl.protocol + "//" + getUrl.host;
        let queryURL = `juego=${this.props.game.idJuego}&tipo=${this.props.game.type}&segmento=${this.props.user ? this.props.user.nivel : ''}`;
        queryURL = base64url(queryURL);
        this.state = {
            userImgs: [userRed, userBlue, userGreen, userOrange, userPurple, userYellow],
            gameImgs: [gameImgRed, gameImgBlue, gameImgGreen, gameImgOrange, gameImgPurple, gameImgYellow],
            cardOptions: false,
            invitationUrl: `${baseUrl}/play/alone/${queryURL}`
        }
        this.renderCardTypePill = this.renderCardTypePill.bind(this);
    }


    selectGameImg() {
        let gameImg;
        gameImg = this.state.gameImgs[this.props.game.idJuego % 6];
        return gameImg
    }

    renderCardTypePill() {
        let gameType = this.props.game.type.toLowerCase();

        if (gameType === 'trivia') {
            return (
                <div className={`card-type-pill background-${gameType} vertical-center`}>
                    <p className='text-smallest'><Rayas style={{height: 12, width: 12}} color='#7e2cff' className='mr-2'/><b>{this.props.game.amountQuestions === 1 ? `${this.props.game.amountQuestions} pregunta` : `${this.props.game.amountQuestions} preguntas`}</b></p>
                </div>
            );
        } else if (gameType === 'crucigrama') {
            return(
                <div className={`card-type-pill background-${gameType} vertical-center`}>
                    <p className='text-smallest'><Rectangulo style={{height: 18, width: 18}} color='#26dd8e' className='mr-2'/><b>{this.props.game.amountQuestions === 1 ? `${this.props.game.amountQuestions} pregunta` : `${this.props.game.amountQuestions} preguntas`}</b></p>
                </div>
            );
        } else if (gameType === 'ahorcado') {
            return(
                <div className={`card-type-pill background-${gameType} vertical-center`}>
                    <p className='text-smallest'><Asterisco style={{height: 18, width: 18}} color='var(--hangman)' className='mr-2'/><b>{this.props.game.amountQuestions === 1 ? `${this.props.game.amountQuestions} pregunta` : `${this.props.game.amountQuestions} preguntas`}</b></p>
                </div>
            );
        } else {
            return;
        }
    }

    renderCardOptions() {
        if (this.state.cardOptions) {
          return (
            <div
              className="card border-0 shadow px-3 py-1"
              style={{
                position: "absolute",
                top: 20,
                right: 0,
                borderRadius: 10,
                zIndex: 1050,
                width: 150,
              }}
            >
              <Button
                variant="link"
                className="text-dark text-left px-0"
                onClick={() => {
                    copy(this.state.invitationUrl);
                    toast.dark("Link copiado!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({cardOptions: false})
                }}
              >
                <i className="fas fa-link"></i> Copiar link
              </Button>
              <hr style={{ marginTop: 5, marginBottom: 5 }} />
              <Button
                variant="link"
                className="text-danger text-left px-0"
              >
                <i className="far fa-flag"></i> Reportar
              </Button>
            </div>
          );
        }
      }


    render() {
        let gameType = this.props.game.type.toLowerCase();
        let playerAmount = this.props.game.timesPlayed;
        playerAmount = playerCount(playerAmount) 
        return(
            <li className={`sponsor-game-card game-card-border-${gameType}`}>
                <img className='sponsor-game-img' src={this.props.game.img ? `${BASE_URL_S3_BUCKET}${this.props.game.img}` : this.selectGameImg()} alt={this.props.game.alt} />
                <div className='p-2'>
                    <Row className='no-gutters'>
                        <Col xs={11}>
                            <h6 className='sponsor-game-title mb-0'>{this.props.game.title}</h6>
                        </Col>
                        <Col xs={1} className='text-right'>
                            {/*
                            <Button
                                variant="link"
                                className="text-dark p-0"
                                onClick={() => this.setState({cardOptions: !this.state.cardOptions})}
                            >
                                <i className="fas fa-ellipsis-v py-1"></i>
                            </Button>
                            {this.renderCardOptions()}
                            */}

                            <Button
                                variant="link"
                                className="text-dark text-left p-0"
                                onClick={() => {
                                    copy(this.state.invitationUrl);
                                    toast.dark("Link copiado!", {
                                        position: "bottom-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    this.setState({cardOptions: false})
                                }}
                            >
                                <i className="fas fa-share-alt fa-xs"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='no-gutters mt-2 justify-content-between'>
                        <Col xs='auto'>
                            <img className='sponsor-game-logo' src={this.props.game.creator?.logo  ? `${BASE_URL}/adjuntos/${this.props.game.creator.logo}` : selectCreatorImg(this.props.game.creator)} alt='' />
                        </Col>
                        <Col xs={4} className='vertical-center'>
                            <a className='link-gray p-0 sponsor-game-username text-smallest' href={`/user/${this.props.game.creator?.idPerfil}`}>{this.props.game.creator && this.props.game.creator.username ? this.props.game.creator.username : ""}</a> 
                        </Col>
                        <Col xs={6} className='text-right text-smallest'>
                            <p className='text-gray mb-1'><i className='fas fa-user'></i> {playerAmount}</p>
                            <p className='text-gray'><img src={starFull}  className="sponsor-game-rating" alt='' /> {this.props.game.rating.toFixed(1)}</p>
                        </Col>
                    </Row>
                    <div className='sponsor-game-footer'>
                        <Row className='justify-content-between no-gutters mt-2'>
                            <Col xs={this.props.isMobile ? 12 : 'auto'}  className='text-left'>
                                {
                                  <>
                                    <Button
                                        className="border-red-new py-2 px-2"
                                        block
                                        onClick={() => {
                                          this.props.navigate(this.state.invitationUrl);
                                        }}
                                    >
                                        <p className='my-auto bold'>Jugar solo</p>
                                    </Button>
                                    </>
                                }
                            </Col>
                            {!this.props.isMobile &&
                            <Col xs='auto' className='text-right ml-2'>
                                <Button
                                    className="new-red-button py-2 px-2"
                                    onClick={() => {
                                            if(!this.props.user){
                                                return this.props.navigate("/signup");
                                            }
                                            this.props.setJuego(this.props.game.idJuego);
                                            this.props.navigate("/host");
                                      }}
                                    block
                                >
                                    <p className='my-auto bold'>Ser anfitrión</p>
                                </Button>
                            </Col>
                            }
                        </Row>
                    </div>
                    {this.renderCardTypePill()}
                </div>
            </li>
        );
    }
}

export default ExploreGameCard;