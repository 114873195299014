import api from "./api";

export default {
  putFrases: (idPatrocinador, nuevos, editar, eliminar) =>
    api.put(`/patrocinadores/${idPatrocinador}/frases`, {
      nuevos,
      editar,
      eliminar,
    }),
};
