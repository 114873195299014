import React, { Component } from 'react';
import QuestionList from './QuestionList';
import FooterMobile from './FooterMobile';

class Switcher extends Component {
    render() {
        return(
            <>
            {!this.props.isMobile ?
                <QuestionList 
                    juego={this.props.juego}
                    patrocinador={this.props.patrocinador}
                    selectQuestion={this.props.selectQuestion}
                    selectedQuestion={this.props.selectedQuestion}
                    isMobile = {this.props.isMobile}
                    component = {this.props.component}
                    allFinished = {false}
                    terminarJuego={this.props.terminarJuego}
                />
                :
                <FooterMobile
                    juego={this.props.juego}
                    patrocinador={this.props.patrocinador}
                    selectQuestion={this.props.selectQuestion}
                    selectedQuestion={this.props.selectedQuestion}
                    isMobile = {this.props.isMobile}
                    component = {this.props.component}
                    allFinished = {false}
                    terminarJuego={this.props.terminarJuego}
                    tiempoPregunta={this.props.tiempoPregunta}
                />
            }
            </>
        );
    }
}

export default Switcher;