import React, { Component } from 'react';

class AnswerLines extends Component {

    render() {
        const sentence = this.props.stringToRender.split(/[ ,]+/);
        const words = sentence.map(word => word.split(''));

        const originalSentence = this.props.originalString.split(/[ ,]+/);
        const originalWords = originalSentence.map(word => word.split(''));

        const letters = words.map((word, i) => (
            <ul key={`hangman-letter-${word}-${i}`} className='hangman-word'>
                {word.map((letter, j) => (
                    <li
                    key={`hangman-letter-${word}-${i}-${letter}-${j}`}
                    className={`hangman-letter text-center ${this.props.answered ? (letter !== '_' ? 'correct' : 'incorrect') : ''}`}
                    >
                        {
                            this.props.answered ? 
                                <h4 className={'visible'}>
                                    {originalWords[i][j]}
                                </h4>
                            :
                                <h4 className={letter !== '_' ? 'visible' : ''}>
                                    {letter}
                                </h4>
                        }
                    </li>
                ))}
                <li>&nbsp;</li>
            </ul>
        ));

        return letters;
    }
}

export default AnswerLines;