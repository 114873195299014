import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import logoCara from "../../assets/logo-cara.png";
import { navigate } from "@reach/router";
import { BASE_URL } from "../../utils";
import medalla1 from "../../assets/Medalla_dorada.svg";
import medalla2 from "../../assets/Medalla_plateada.svg";
import medalla3 from "../../assets/Medalla_cobre.svg";
import estrellaLlena from "../../assets/estrella_llena.svg";
import estrellaOutline from "../../assets/Estrella_outline.svg";
import estrellaVacia from "../../assets/estrella_vacia.svg";
import QRCode from "qrcode.react";
import InputULU from "../../components/global/InputULU";
import Rating from 'react-rating';

function ModalAgradecimientos(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    > 
      <Modal.Body>
        <h5 className='text-center'>¡Muchas gracias por tus comentarios!</h5>
        <p>
          ¡Tu eres parte importante de lo que hacemos!
          <br></br>
          Gracias a tus comentarios podemos seguir mejorando <b style={{color: '#FF3833'}}>ULU®</b> para que más personas tengan la oportunidad de seguir divirtiendose mientras aprnden.
          <br></br>
          Aún estamos en versión Beta, por lo que agradecemos de todo corazón la confianza y el entusiasmo que tuviste al jugar con nosotros.
          <br></br>
          ¡Te esperamos de nuevo!
          <br></br>
          <b style={{color: '#FF3833'}}>- FamiliaULU®</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Row className='justify-content-end'>
          <Col md={12} className='text-right'>
            <Button 
              onClick={props.onHide}
              variant="link"
              className="link-gray px-4 py-4"
              size='lg'
              >
                <h6 className="m-0 py-2">Salir</h6>
              </Button>
            <Button
              variant="outline-danger"
              size="lg"
              className="rounded-button border-red-new ml-2"
              href='/jugar'
            >
              <h6 className="py-2 my-0">Jugar nuevo ULU</h6>
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

class FinalScore extends Component {
  constructor(props) {
    super(props);
    //const getUrl = window.location;
    //const baseUrl = getUrl.protocol + "//" + getUrl.host;
    //let queryURL = `juego=${this.props.idJuego}&tipo=${this.props.juego.tipo}&segmento=${this.props.user ? this.props.user.nivel : ''}`;
    //queryURL = base64url(queryURL);
    this.state = {
      email: "",
      calificacion: null,
      mostrarModalAgradecimientos: false,
      prizeSent: false,
      //invitationUrl: `${baseUrl}/play/alone/${queryURL}`,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSugerencia = this.handleSugerencia.bind(this);
    this.enviarSugerencia = this.enviarSugerencia.bind(this);
    this.handleCalificacion = this.handleCalificacion.bind(this);
    this.handleRecomendacion = this.handleRecomendacion.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({prizeSent: true})
    const mail = this.props.user !== null ? this.props.user.correo : this.state.email;
    this.props.enviarPremio({
      idPatrocinador: this.props.patrocinador.idPatrocinador,
      idSesion: this.props.idSesion,
      mail,
      apodo: this.props.apodo
    });
  }

  handleClick() {
    if (this.props.user) navigate("/misjuegos");
    else navigate("/login");
  }

  handleCalificacion(calificacion){
    this.setState({calificacion});
  }

  handleRecomendacion(e){
    this.setState({recomendacion: e.currentTarget.value});
  }

  handleSugerencia(e){
    this.setState({sugerencia: e.currentTarget.value});
  }

  enviarSugerencia(){
    this.setState({mostrarModalAgradecimientos: true});
    //Aquí se agrega el axios
    const { calificacion } = this.state;
    const { idSesion, apodo } = this.props;
    const enumRecomendaciones = {
      'nada': 0,
      'algo': 1,
      'poco': 2,
      'mucho': 3
    }
    this.props.enviarSugerencias({
      calificacion,
      idSesion,
      apodo
    });
  }

  verCodigo(codigo) {
    this.props.modalComponent(
      "Tu código es el siguiente",
      <>
        <h4>
          ¡También lo hemos mandado a tu correo por si llegas a necesitarlo
          después!
        </h4>
        <QRCode value={codigo} />
        <p>{codigo}</p>
      </>
    );
  }

  renderPatrocinador() {
    if (
      this.props.patrocinador &&
      this.props.patrocinador !== null &&
      this.props.patrocinador.nombre
    ) {
      let url = `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`;
      return (
          <Col xs={6} md={3}>
            <p className='text-small'>Impulsado por</p>
            <Image
              src={url}
              className="pb-2 contain-img-finalScore"
            />
            <p className='text-small'>{this.props.patrocinador.nombre}</p>
          </Col>
      );
    }
  }

  renderLugar() {
    if (this.props.lugar === 1) {
      return (
        <>
          <Row className="text-center">
            <Col>
              <h5 className='non-bold'>Felicidades</h5>
            </Col>
          </Row>
          <Row className="text-center my-2">
            <Col>
              <Image src={medalla1} style={{ width: 30 }} className="pb-2" />
              <span className={`m-0 h2 pl-3 lugar-${this.props.lugar}`} >
                <b>{this.props.apodo}</b>
              </span>
            </Col>
          </Row>
        </>
      );
    } else if (this.props.lugar === 2) {
      return (
        <>
          <Row className="text-center">
            <Col>
              <h5 className="m-0"><b>Felicidades</b></h5>
            </Col>
          </Row>
          <Row className="text-center my-2">
            <Col>
              <Image src={medalla2} style={{ width: 40 }} className="pb-2" />
              <span className={`m-0 h1 pl-3 lugar-${this.props.lugar}`} >
                <b>{this.props.apodo}</b>
              </span>
            </Col>
          </Row>
        </>
      );
    } else if (this.props.lugar === 3) {
      return (
        <>
          <Row className="text-center">
            <Col>
              <h5 className="m-0"><b>Felicidades</b></h5>
            </Col>
          </Row>
          <Row className="text-center my-2">
            <Col>
              <Image src={medalla3} style={{ width: 40 }} className="pb-2" />
              <span className={`m-0 h1 pl-3 lugar-${this.props.lugar}`} >
                <b>{this.props.apodo}</b>
              </span>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row className="text-center justify-content-center">
            <Col md={12}>
              <h1 className='m-0'><b>{this.props.apodo}</b></h1>
            </Col>
          </Row>
          <Row className="text-center mb-2">
            <Col md={12}>
              <h4 className='m-0'><b>Quedaste en el lugar:</b></h4>
            </Col>
          </Row>
          <Row className="text-center mb-2">
            <Col md={12}>
              <p className='display-3 m-0' style={{fontWeight: 'bold !important'}}><b>#{this.props.lugar}</b></p>
            </Col>
          </Row>
          {/*
          <Row className="justify-content-center pb-3">
            <p className="h5">{this.props.mensaje}</p>
          </Row>
          */}
        </>
      );
    }
  }

  renderPremio() {
    if (this.props.patrocinador && this.props.patrocinador !== null) {
      if (this.props.patrocinador.premios.length > 0) {
        let indexPremio = this.props.patrocinador.premios.findIndex((premio) => premio.lugar === this.props.lugar);
        if(indexPremio !== -1){
          const premio = this.props.patrocinador.premios[indexPremio];
          const premio_src = `${BASE_URL}/adjuntos/${premio.idAdjunto}`;
          return (
            <>
            <Row className="text-center justify-content-center">
              <Col xs={6} md={3}>
                <p className='text-small'>Ganaste</p>
                <Image 
                  src={premio_src} 
                  className="pb-2 contain-img-finalScore"
                />
                <p className='text-small'>{premio.nombre}</p>
              </Col>
              {this.renderPatrocinador()}
            </Row>
            <Row className='justify-content-center'>
              <Col md={3} sm={12}>
              {this.props.user !== null && (
                <h5 className="py-3 mb-4 text-center">
                  ¡Hemos mandado el código del premio a tu correo!
                </h5>
              )}
              {this.props.user === null && (
                <div className="py-3 m-0 px-0">
                  <h6 className="text-center">Déjanos tu correo para enviarte tu premio</h6>
                  <Form onSubmit={this.handleSubmit} className='text-right'>
                    <InputULU
                      label="Correo electrónico"
                      type="email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      maxLength={70}
                    />
                    <Button 
                      type="submit"
                      variant="outline-danger"
                      className="border-red-new"
                      disabled={this.state.prizeSent}
                    >
                      <h6>Enviar premio</h6>
                    </Button>
                  </Form>
                </div>
              )}
              </Col>
            </Row>
            </>
          );
        }
      }
    }
    return (<Row className="text-center justify-content-center">{this.renderPatrocinador()}</Row>);
  }

  renderScore() {
    if (this.props.lugar === 1 || this.props.lugar === 2 || this.props.lugar === 3) {
      return (
        <>
          {this.renderLugar()}
          {this.renderPremio()}
          {/*
            <Row className="justify-content-center mb-5">
              <Button
                variant="outline-danger"
                size="lg"
                className="rounded-button border-red-new"
                onClick={() => navigate("/")}
              >
                <h6 className="py-2 my-0">Jugar Nuevo ULU</h6>
              </Button>
            </Row>
          */}
        </>
      );
    }
    return (
      <>
        {this.renderLugar()}
        <Row className="text-center justify-content-center">{this.renderPatrocinador()}</Row>
        {/*
          {this.props.user === null && (
            <Row className="justify-content-center my-4">
              <Button
                variant="danger"
                className="rounded-button new-red-button"
                size='lg'
                onClick={() => {
                  navigate("/signup");
                  ReactGA.event({
                    category: "Signup",
                    action: "Signup después de Jugar",
                    label: "Signup después de Jugar",
                  });
                }}
              >
                <h6 className="py-2 my-0">Regístrate</h6>
              </Button>
            </Row>
          )}
          {this.props.user !== null && (
            <Row className="justify-content-center mb-5">
              <Button
                variant="outline-danger"
                size='lg'
                className="rounded-button border-red-new"
                onClick={() => this.handleClick()}
              >
                <h6 className="py-2 my-0">
                  {this.props.user ? "Ir a Mis Juegos" : "Iniciar sesión"}
                </h6>
              </Button>
            </Row>
          )}
        */}
      </>
    );
  }

  renderEstrellas(){
    return(
      <>
        <Row className='justify-content-center'>
          <Col md={4} sm={12} className='text-center'>
            <p><b>¿Cómo calificarías este ULU?</b></p>
          </Col>
        </Row>
        <Row className='justify-content-center mb-4'>
          <Col md={4} sm={12} className='text-center'>
            {/*
            <Form>
              <Form.Check inline className={this.state.calificacion >= 1 ? 'starCheckbox-active':'starCheckbox'} label="1" type='checkbox' id='1' value={1} checked={this.state.calificacion >= 1} onChange={this.handleCalificacion} />
              <Form.Check inline className={this.state.calificacion >= 2 ? 'starCheckbox-active':'starCheckbox'} label="2" type='checkbox' id='2' value={2} checked={this.state.calificacion >= 2} onChange={this.handleCalificacion}  />
              <Form.Check inline className={this.state.calificacion >= 3 ? 'starCheckbox-active':'starCheckbox'} label="3" type='checkbox' id='3' value={3} checked={this.state.calificacion >= 3} onChange={this.handleCalificacion} />
              <Form.Check inline className={this.state.calificacion >= 4 ? 'starCheckbox-active':'starCheckbox'} label="4" type='checkbox' id='4' value={4} checked={this.state.calificacion >= 4} onChange={this.handleCalificacion} />
              <Form.Check inline className={this.state.calificacion === 5 ? 'starCheckbox-active':'starCheckbox'} label="5" type='checkbox' id='5' value={5} checked={this.state.calificacion === 5} onChange={this.handleCalificacion} />
            </Form>
            */}
            <Rating
              initialRating={this.state.calificacion}
              emptySymbol={<Image src={estrellaVacia} className="icon pr-1" style={{height: '25px',width: '25px'}} />}
              placeholderSymbol={<Image src={estrellaOutline} className="icon pr-1" style={{height: '25px',width: '25px'}} />}
              fullSymbol={<Image src={estrellaLlena}  className="icon pr-1" style={{height: '25px',width: '25px'}} />}
              onChange={this.handleCalificacion}
            />
          </Col>
        </Row>
      </>
    );
  }

  renderBotones(){
    return(
      <>
        <Row className='justify-content-center mb-5'>
          {/*
          <Col md='auto' sm={12} className='text-center vertical-center'>
            <Button
              variant="link"
              className="text-dark text-left px-0"
              onClick={() => {
                  copy(this.state.invitationUrl);
                  toast.dark("Link copiado!", {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
                  this.setState({cardOptions: false})
              }}
            >
              <i className="fas fa-share-alt mr-1"></i> Compartir ULU
            </Button>
          </Col>
            */}
          <Col md='auto' sm={12} className='text-center'>
            <Button
              variant="danger"
              size='lg'
              className="rounded-button new-red-button ml-2"
              onClick={() => 
                { 
                  this.enviarSugerencia();
                  navigate("/explorar");
                }
              }
            >
              <h6 className="py-2 my-0">Seguir jugando</h6>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid style={{ overflowX: "hidden" }} className='vertical-center fondo-gris-full'>
          <Row className="justify-content-center mt-2">
            <Col xs={10} sm={8} md={6} lg={6} xl={4} className="mb-2">
                <Image
                  src={logoCara}
                  className="d-block m-auto py-2"
                  style={{ maxWidth: 40 }}
                  onClick={() => navigate('/explorar')}
                />
            </Col>
          </Row>
          {this.renderScore()}
          {this.renderEstrellas()}
          {this.renderBotones()}
        </Container>

        <ModalAgradecimientos
          show={this.state.mostrarModalAgradecimientos}
          onHide={() => this.setState({mostrarModalAgradecimientos: false})}
        />
      </>
    );
  }
}

export default FinalScore;
