import {
  ESCUELAS_RECIBIDAS,
  SET_ARG_ESCUELA,
  ESTADOSESCUELAS_RECIBIDOS,
  MUNICIPIOS_RECIBIDOS,
} from "./types";
import EscuelasService from "../services/EscuelasService";
import { displayError } from "../utils";

export const getEscuelas = (args) => (dispatch) => {
  if (Object.keys(args).length > 0) {
    EscuelasService.getEscuelas(args)
      .then((res) => {
        const { escuelas } = res.data;
        dispatch({ type: ESCUELAS_RECIBIDAS, payload: escuelas });
      })
      .catch((error) => {
        displayError(dispatch, error);
      });
  }
};

export const getEstados = () => (dispatch) => {
  EscuelasService.getEstados()
    .then((res) => {
      dispatch({ type: ESTADOSESCUELAS_RECIBIDOS, payload: res.data });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const getMunicipios = (estado) => (dispatch) => {
  EscuelasService.getMunicipios(estado)
    .then((res) => {
      dispatch({ type: MUNICIPIOS_RECIBIDOS, payload: res.data });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const setArgEscuela = (key, value) => (dispatch) => {
  dispatch({ type: SET_ARG_ESCUELA, payload: { key, value } });
};
