import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Keyboard from './Keyboard';
import AnswerLines from './AnswerLines';
import Question from './Question';
import { BASE_URL, setCharAt } from '../../utils';
import HangmanLives from './HangmanLives';

class HangmanQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stringToRender: '',
            letras: null,
            clickedLetters: []
            
        }
        this.registerKey = this.registerKey.bind(this);
    }
    componentWillUnmount(){
        document.removeEventListener('keydown', this.registerKey, true);
    }
    componentDidMount() {
        document.addEventListener('keydown', this.registerKey);
        let miPalabra = this.props.juego.respuesta.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let stringToRender = "";
        for (let i = 0; i < miPalabra.length; i++) {
            const letra = miPalabra[i];
            if(letra !== ' '){
                stringToRender += "_";
            }
            else{
                stringToRender += " ";
            }
        }

        const letras = new Map();

        for (let i = 0; i < miPalabra.length; i++) {
            const letra = miPalabra[i];
            if(letras.has(letra)){
                const letraData = letras.get(letra);
                letras.set(letra, {
                    contestada: false,
                    posiciones: [...letraData.posiciones, i]
                });
            }
            else{
                letras.set(letra, {
                    contestada: false,
                    posiciones: [i]
                });
            }
        }

        this.setState({stringToRender, letras});

    }

    registerKey(e) {
        //recibes una letra del teclado.
        const letraIngresada = e.key.toLowerCase();

        let stringToRender = this.state.stringToRender;
        let letras = this.state.letras;
        let clickedLetters = this.state.clickedLetters;

        if(this.props.juego.contestada || clickedLetters.includes(letraIngresada))//If question has been answered or letter has already been clicked, do nothing
            return;
            
        //send answer to back
        this.props.responder(letraIngresada);
        clickedLetters.push(letraIngresada);
        this.setState({clickedLetters});

        //actualizas el mapa
        const letraData = letras.get(letraIngresada);

        if(!letraData) 
            return;

        letras.set(letraIngresada, {
            ...letraData,
            contestada: true
        });

        //Actualizas el string to Render basado en los indices
        letraData.posiciones.forEach(pos => {
            stringToRender = setCharAt(stringToRender, pos, letraIngresada);
        });

        this.setState({stringToRender, letras});
    }

    render() {
        return(
            <Row className='justify-content-md-between justify-content-center'>
                <Col xs={12} md={4} className='text-center text-md-left mb-2 hangman-media-col'>
                    <Row className='justify-content-center'>
                        {(this.props.juego.idAdjunto && this.props.juego.idAdjunto !== null) &&
                            <Col xs={5} md='auto' className='text-center mb-md-2 mb-0'>
                                <img loading='lazy' alt="imagen pregunta" src={`${BASE_URL}/adjuntos/${this.props.juego.idAdjunto}`} className='question-img' />
                            </Col>
                        }
                        <Col xs={5} md='auto' className='text-center mt-md-2 mt-0 mb-3 mb-md-0'>
                            <HangmanLives livesLeft={this.props.juego.vidas} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} className='text-center text-md-left'>
                            <Question 
                                isMobile={this.props.isMobile}
                                question={this.props.juego.pregunta}
                            />
                        </Col>
                    </Row>
                    <Row className='no-gutters mt-3'>
                        <Col xs={12} className='text-center text-md-left'>
                            <AnswerLines
                                stringToRender={this.state.stringToRender}
                                answered={this.props.juego.contestada}
                                originalString={this.props.juego.respuesta}
                            />
                        </Col>
                    </Row>
                    {!this.props.juego.contestada &&
                    <Row className='no-gutters mt-3'>
                        <Col xs={12} className='text-center'>
                            {!this.props.isMobile  && <h6 className='text-small mb-2 text-gray'>Puedes usar tu teclado también. ¡Inténtalo!</h6>}
                            <Keyboard  onClick={this.registerKey} clickedLetters={this.state.clickedLetters} gameType={this.props.urlQuery.tipo.toLowerCase()}/>
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
        );
    }
}

export default HangmanQuestion;