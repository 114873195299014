import React, { useState } from "react";
import { Modal, Image, Container, Row, Col, Button } from "react-bootstrap";

export default function ({
  file,
  idAdjunto,
  nombre,
  show,
  handleClose,
  base_url,
}) {
  const [current, setCurrent] = useState(null);
  const [src, setSrc] = useState("");

  if (idAdjunto && src === "") {
    setSrc(`${base_url}/adjuntos/${idAdjunto}`);
  }

  if (idAdjunto && idAdjunto !== current) {
    setCurrent(idAdjunto);
    setSrc(`${base_url}/adjuntos/${idAdjunto}`);
  }

  if (file && src === "") {
    const reader = new FileReader();
    reader.onload = (e) => {
      setSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="p-0">
        <Container fluid>
          <Row className="justify-content-center">
            <Image fluid src={src} className="p-5" />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="py-3">
        <Container>
          <Row>
            <Col>
              <p className="m-0">{nombre}</p>
            </Col>
            <Col className="text-right">
              <Button
                variant="link"
                className="link-gray p-0"
                onClick={handleClose}
              >
                Salir
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
