import React, { Component } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import logo from "../../../assets/logo-blanco.png";
import { BASE_URL } from "../../../utils";
import caraFeliz from "../../../assets/Artboard37.png";
import caraTriste from "../../../assets/Triste_negra.svg";
import rayas from "../../../assets/Rayas.svg";
import asterisco from "../../../assets/Asterisco.svg";
import rectangulo from "../../../assets/Rectangulo.svg";

class FeedbackRespuesta extends Component {
  componentDidMount() {
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
  }
  
  renderPatrocinador() {
    if (
      this.props.patrocinador &&
      this.props.patrocinador !== null &&
      this.props.patrocinador.nombre
    ) {
      let src = logo;
      if (this.props.patrocinador.logos.length > 0) {
        const random = Math.floor(
          Math.random() * this.props.patrocinador.logos.length
        );
        src = `${BASE_URL}/adjuntos/${this.props.patrocinador.logos[random].idAdjunto}`;
      } else if (this.props.patrocinador.idAdjunto !== null) {
        src = `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`;
      }
      return (
        <>
          <hr style={{ marginTop: 5, marginBottom: 5 }} className="mx-4" />
          <Row className="my-3">
            <Col className="text-center px-4">
              <p className="h6 mb-2">Impulsado por</p>
              <h6 className="mt-2">{this.props.patrocinador.nombre}</h6>
              <Image
                src={src}
                className="logo-width"
              />
            </Col>
          </Row>
        </>
      );
    }
  }
  

  render() {
    let icon;
    if (this.props.juego.tipo.toLowerCase() === "crucigrama") icon = rectangulo;
    else if (this.props.juego.tipo.toLowerCase() === "ahorcado")
      icon = asterisco;
    else icon = rayas;
    return (
      <Container
        fluid
        className="no-bg-img vertical-center"
      >
        <Row className="justify-content-center">
          <Col xs={5} sm={5} md={1} className="mb-4 text-center">
            <Image
              src={this.props.puntos > 0 ? caraFeliz : caraTriste}
              style={{ maxWidth: '100%', color: "#2f2f2f" }}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="text-center">
            <h3 className="m-0">
              <Image src={icon} style={{ width: 30 }} className="pb-2 mr-2" />
              {this.props.juego ? this.props.juego.titulo : ""}
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={3}>
            <Card
              className="rounded-card shadow mx-auto"
              style={{
                borderColor: this.props.puntos > 0 ? "#26DD8E" : "#FF3833",
                backgroundColor: "white",
                borderWidth: 3,
              }}
            >
              <Row className="text-center my-3">
                <Col>
                  <h5>Lugar #{this.props.lugar}</h5>
                  <h4 className="m-0">
                    {this.props.puntos > 0 ? "+" : ""}
                    {this.props.puntos} pts
                  </h4>
                </Col>
              </Row>
              {this.renderPatrocinador()}
              <Row className="justify-content-center text-center mb-2">
                <Col>
                  <h6 className="m-0">{this.props.mensaje}</h6>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FeedbackRespuesta;
