import {
  EDIT_CODIGO,
  SET_PROPERTY_CODIGO,
  SAVE_CODIGO,
  EDITAR_CODIGOS,
  CODIGOS_RECIBIDOS,
  CLEAR_CODIGOS,
  DELETE_CODIGO,
  SET_PROPIEDAD_CODIGO,
  SET_PROPIEDAD_EXCEL,
  SET_EXCEL,
  ERROR_CODIGOS,
  CAMBIAR_TIPO,
} from "../actions/types";

const schema = (index) => ({
  idCodigo: `nuevo-${index}`,
  idPremio: "",
  codigo: "",
  cantidad: undefined,
  canjeados: 0,
  ganado: "Sin otorgar",
  tipoCodigo: "",
  tipoCodigoBarras: "",
  codificacion: "",
});

const initialState = {
  codigos: null,
  codigo: null,
  editadoCodigos: false,
  errorCodigos: undefined,
  file: null,
  excel: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CODIGOS_RECIBIDOS:
      return { ...state, codigos: payload };
    case EDITAR_CODIGOS:
      return {
        ...state,
        nuevo: schema(state.codigos !== null ? state.codigos.length + 1 : 1),
        editadoCodigos: true,
      };
    case CLEAR_CODIGOS:
      return { ...initialState };
    case EDIT_CODIGO:
      return { ...state, codigo: payload };
    case SET_PROPERTY_CODIGO:
      const { key, value } = payload;
      const codigo = { ...state.codigo };
      if (key === "cantidad") {
        if (parseInt(value) < 0) {
          return { ...state };
        }
      }
      codigo[key] = value;
      return { ...state, codigo };
    case SET_PROPIEDAD_CODIGO: {
      const { key, value } = payload;
      const nuevo = { ...state.nuevo };
      nuevo[key] = value;
      return { ...state, nuevo };
    }
    case SET_PROPIEDAD_EXCEL: {
      const { key, value } = payload;
      const excel = { ...state.excel };
      excel[key] = value;
      return { ...state, excel };
    }
    case CAMBIAR_TIPO: {
      let codigos = [...state.codigos];
      const excel = state.excel;
      const { key, tipo } = payload;
      if (excel !== null) excel[key] = tipo;
      codigos = codigos.map((codigo) => ({ ...codigo, [key]: tipo }));
      return { ...state, codigos, excel, [key]: tipo, tipo };
    }
    case SET_EXCEL: {
      const excel = {};
      if (state.codigos !== null) {
        if (state.codigos.length > 0) {
          const {
            tipoCodigo,
            tipoCodigoBarras,
            codificacion,
          } = state.codigos[0];
          excel.tipoCodigo = tipoCodigo;
          excel.tipoCodigoBarras = tipoCodigoBarras;
          excel.codificacion = codificacion;
        }
      }
      return { ...state, file: payload, excel };
    }
    case SAVE_CODIGO:
      const codigos = [...state.codigos];
      const igual = codigos
        .filter((codigo) => String(codigo.idCodigo)[0] !== "d")
        .find((codigo) => String(codigo.codigo) === String(payload.codigo));
      if (igual) {
        if (igual.idCodigo !== payload.idCodigo) {
          alert("El código no puede repetirse. Elige otro.");
          return { ...state };
        }
      }
      let index = codigos.findIndex(
        (codigo) => String(codigo.idCodigo) === String(payload.idCodigo)
      );
      if (index !== -1) codigos[index] = { ...payload };
      else codigos.push(payload);
      return {
        ...state,
        codigos,
        codigo: null,
        nuevo: schema(codigos.length + 1),
      };
    case DELETE_CODIGO: {
      const codigos = [...state.codigos];
      let index = codigos.findIndex(
        (codigo) => String(codigo.idCodigo) === String(payload)
      );
      if (index !== -1)
        codigos[index].idCodigo = `delete-${codigos[index].idCodigo}`;
      return { ...state, codigos };
    }
    case ERROR_CODIGOS:
      return { ...state, errorCodigos: true };
    default:
      return state;
  }
};
