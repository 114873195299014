import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Button } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import SwitchULU from "../components/global/SwitchULU";
import PreguntaFAQ from "../components/PreguntaFAQ";
import UsuarioForm from "../components/perfil/UsuarioForm";
import DropdownPerfilUsuario from "../components/DropdownPerfilUsuario";
import {
  Usuario,
  Candado,
  Campana,
  Moneda,
  Interrogacion,
  Ojo,
} from "../assets/icons";
import {
  signOut,
  setPropiedadUser,
  updatePassword,
  putUsuario,
  deleteUsuario,
  getUsuario,
} from "../actions/userActions";
import {
  getEscuelas,
  setArgEscuela,
  getMunicipios,
  getEstados,
} from "../actions/escuelasActions";
import { modalComponent, alert, confirm } from "../actions/modalActions";
import { connect } from "react-redux";
import "@material/react-material-icon/dist/material-icon.css";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import { navigate, Link } from "@reach/router";
import { BASE_URL } from "../utils";
import { throttle } from "throttle-debounce";
import PasswordStrengthBar from "react-password-strength-bar";

class PerfilUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcionesSidebar: [
        {
          title: "Vista general",
          filter: null,
          filterSidebar: 'Vista general',
          icon: (props) => <Ojo color="#2F2F2F" {...props} />,
        },
        {
          title: "Cuenta",
          filter: null,
          filterSidebar: 'Cuenta',
          icon: (props) => <Usuario color="#2F2F2F" {...props} />,
        },
        {
          title: "Notificaciones",
          filter: null,
          filterSidebar: 'Notificaciones',
          icon: (props) => <Campana color="#2F2F2F" {...props} />,
        },/*
        {
          title: "Patrocinadores",
          filter: null,
          filterSidebar: 'Patrocinadores',
          icon: (props) => <Billete color="#2F2F2F" {...props} />,
        },*/
        {
          title: "Seguridad",
          filter: null,
          filterSidebar: 'Seguridad',
          icon: (props) => <Candado color="#2F2F2F" {...props} />,
        },/*
        {
          title: "ULU coins",
          filter: null,
          filterSidebar: 'ULU coins',
          icon: (props) => <Moneda color="#2F2F2F" {...props} />,
        },*/
        {
          title: "Ayuda y soporte",
          filter: null,
          filterSidebar: 'Ayuda y Soporte',
          icon: (props) => <Interrogacion color="#2F2F2F" {...props} />,
        },
      ],
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
      visibleOld: false,
      visibleNew: false,
      visibleNew2: false,
      queryEstado: "",
      queryMunicipio: "",
      queryNivel: "",
      queryEscuela: null,
      showEscuelas: false,
      component: "Vista general",
      filter: null,
      escuelaManual: false,
      nombreEscuelaManual: '',
    };
    this.signOut = this.signOut.bind(this);
    this.deleteUsuario = this.deleteUsuario.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleChangeEscuela = this.handleChangeEscuela.bind(this);
    this.enableEscuelaManual = this.enableEscuelaManual.bind(this);
    this.handleChangeEmpresa = this.handleChangeEmpresa.bind(this);
    this.autocompleteSearchThrottled = throttle(500, this.autocompleteSearch);
  }

  componentDidMount() {
    this.props.getUsuario();
    ReactGA.pageview("/perfil");
    this.props.getEstados();
    this.props.getEscuelas(this.props.args);
    window.scrollTo(0, 0);
    this.setEscuela();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.escuelas !== this.props.escuelas &&
      this.props.escuelas &&
      this.props.escuelas !== null
    ) {
      if (this.props.escuelas[0]) {
        if (this.props.escuelas[0].idEscuela !== this.props.user.idEscuela) {
          this.setState({ showEscuelas: true });
        }
      }
    }
    if (prevProps.user && this.props.user) {
      if (prevProps.user.idEscuela !== this.props.user.idEscuela) {
        this.setState({ showEscuelas: false });
      }
    }
    if (
      this.props.user.nombre_escuela !== null &&
      this.state.queryEscuela === null
    ) {
      this.setEscuela();
    }
  }

  handleFilter(opcion) {
    this.setState({ component: opcion, filter: opcion });
  }

  setEscuela() {
    let { nombre_escuela, nivel, municipio, entidad } = this.props.user;
    entidad =
      entidad !== undefined && entidad !== null ? entidad.toUpperCase() : "";
    municipio =
      municipio !== undefined && municipio !== null
        ? municipio.toUpperCase()
        : "";
    this.setState({
      queryEscuela: nombre_escuela,
      queryNivel: nivel,
      queryEstado: entidad,
      queryMunicipio: municipio,
    });
    this.props.setArgEscuela("escuela", nombre_escuela);
    this.props.setArgEscuela("nivel", nivel);
    this.props.setArgEscuela("municipio", municipio);
    this.props.setArgEscuela("estado", entidad);
  }

  deleteUsuario() {
    this.props.confirm(
      "¿Estás seguro que deseas eliminar tu perfil? Esto borrará TODOS los juegos que has creado y las estadísticas de los mismos. Esta acción NO puede deshacerse.",
      () => {
        setTimeout(() => {
          this.props.confirm(
            "¡PRECAUCIÓN! Estás a punto de ELIMINAR TODOS los datos de tu perfil",
            this.props.deleteUsuario
          );
        }, 500);
      }
    );
  }

  signOut(idUsuario) {
    ReactGA.event({
      category: "Cerrar Sesión",
      action: "Pre Cerrar Sesión",
      label: "Pre Cerrar Sesión",
    });
    this.props.confirm(
      "¿Estás seguro que deseas cerrar tu sesión?",() => {
          this.props.signOut(idUsuario); 
          setTimeout(() => {
            navigate("/login");
          }, 100);
      }
    );
  }
  handleChangeEmpresa(nombre){
    this.setState({nombreEmpresa: nombre});
  }
  handleChangeEscuela(key, event, showEscuelas) {
    const { value } = event.target;
    let prop = `query${key[0].toUpperCase()}${key.slice(1)}`;
    this.setState({ [prop]: value }, () => {
      if (key === "estado") this.props.getMunicipios(value);
      if (key === "municipio")
        this.autocompleteSearchThrottled(this.state.queryMunicipio);
      if (key === "nivel")
        this.autocompleteSearchThrottled(this.state.queryNivel);
      if (key === "escuela")
        this.autocompleteSearchThrottled(this.state.queryEscuela);
      if (key === 'escuelaManual')
        this.setState({escuelaManual: value});
      if (key === 'nombreEscuelaManual'){
        this.setState({nombreEscuelaManual: value});
      }
    });
    this.props.setArgEscuela(key, value);
    if (showEscuelas !== undefined) {
      setTimeout(() => {
        this.setState({ showEscuelas });
      }, 500);
    }
  }

  autocompleteSearch() {
    this.props.getEscuelas(this.props.args);
  }

  updatePassword() {
    if (this.state.newPassword.length < 6)
      return this.props.alert("La contraseña debe tener al menos 6 caracteres");
    if (this.state.newPassword !== this.state.newPassword2)
      return this.props.alert("Las contraseñas no coinciden");
    this.props.updatePassword(
      this.props.user.correo,
      this.state.oldPassword,
      this.state.newPassword
    );
  }
  enableEscuelaManual(enableEscuelaBool){
    if(enableEscuelaBool){
      this.setState({escuelaManual: true, idEscuela: null});
    }
    else{
      this.setState({escuelaManual: false });
    }
  }

  renderCuenta() {
    let {
      user,
      setPropiedadUser,
      escuelas,
      niveles,
      estados,
      municipios,
    } = this.props;
    if (this.state.component === "Vista general" || this.state.component === "Cuenta") {
      if (estados !== null && municipios === null) {
        if (this.props.user.entidad)
          this.props.getMunicipios(this.props.user.entidad);
        else this.props.getMunicipios(estados[0]);
      }
      if (estados === null) estados = [];
      else
        estados = estados.map((estado) => ({
          name: estado,
          value: estado,
        }));
      if (municipios === null) municipios = [];
      else
        municipios = municipios.map((municipio) => ({
          name: municipio,
          value: municipio,
        }));
      let nivel;
      if (this.props.user.nivel)
        nivel =
          this.props.user.nivel.charAt(0) +
          this.props.user.nivel.substring(1).toLowerCase();
      return (
        <>
          <Row className="mt-2">
            <Col>
              <p className="m-0 h1">
                <i className="fas fa-user pr-3"></i>
                <span>Cuenta</span>
              </p>
            </Col>
          </Row>
          <UsuarioForm
            user={user}
            setPropiedadUser={setPropiedadUser}
            enableEscuelaManual={this.enableEscuelaManual}
            escuelas={escuelas}
            niveles={niveles}
            base_url={`${BASE_URL}/adjuntos/`}
            queryEstado={this.state.queryEstado}
            queryMunicipio={this.state.queryMunicipio}
            queryNivel={this.state.queryNivel}
            queryEscuela={this.state.queryEscuela}
            escuela={this.state.escuela}
            showEscuelas={this.state.showEscuelas}
            nombreEmpresa={this.state.nombreEmpresa}
            deleteUser={this.deleteUsuario}
            handleChangeEscuela={this.handleChangeEscuela}
            handleChangeEmpresa={this.handleChangeEmpresa}
            estados={estados}
            municipios={municipios}
            nivel={nivel}
            escuelaManual={this.state.escuelaManual}
            nombreEscuelaManual={this.state.nombreEscuelaManual}
          />
          <hr></hr>
        </>
      );
    }
  }

  renderNotificaciones() {
    if (
      this.state.component === "Vista general" ||
      this.state.component === "Notificaciones"
    )
      return (
        <>
          <Row className="pt-2">
            <Col>
              <p className="m-0 h1">
                <i className="fas fa-bell pr-3"></i>
                <span>Notificaciones</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="m-0 h5">
                Me gustaría recibir notificaciones a mi correo electrónico
                cuando gano algún premio.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <SwitchULU
                value={this.props.user.notificacionActivado}
                modifier={this.props.setPropiedadUser}
                args="notificacionActivado"
                checkClassName="custom-control-lg"
              />
            </Col>
          </Row>
          <hr></hr>
        </>
      );
  }

  renderPatrocinadores() {
    if (
      this.state.component === "Vista general" ||
      this.state.component === "Patrocinadores"
    )
      return (
        <>
          <Row className="pt-2">
            <Col>
              <p className="m-0 h1">
                <i className="fas fa-money-bill-wave pr-3"></i>
                <span>Patrocinadores</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="m-0 h5">
                Me gustaría que patrocinadores encuentren mis juegos y los
                patrocinen.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <SwitchULU
                value={this.props.user.patrocinadorActivado}
                modifier={this.props.setPropiedadUser}
                args="patrocinadorActivado"
                checkClassName="custom-control-lg"
              />
            </Col>
          </Row>
          <hr></hr>
        </>
      );
  }

  renderSeguridad() {
    if (
      this.state.component === "Vista general" ||
      this.state.component === "Seguridad"
    )
      return (
        <>
          <Row className="pt-2">
            <Col>
              <p className="m-0 h1">
                <i className="fas fa-lock pr-3"></i>
                <span>Seguridad</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="m-0 h3">Cambiar contraseña</p>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col sm={6}>
              <TextField
                outlined
                className="w-100"
                label="Contraseña actual"
                onTrailingIconSelect={() =>
                  this.setState((prevState) => ({
                    visibleOld: !prevState.visibleOld,
                  }))
                }
                trailingIcon={
                  <i
                    className={
                      this.state.visibleOld
                        ? "far fa-eye"
                        : "far fa-eye-slash"
                    }
                  />
                }
                characterCounter={<CharacterCounter />}
                maxLength={255}          
              >
                <Input
                  type={this.state.visibleOld ? "" : "password"}
                  id="oldPassword"
                  maxLength={255}
                  value={this.state.oldPassword}
                  onChange={(event) =>
                    this.setState({ oldPassword: event.target.value })
                  }
                />
              </TextField>
              <Link to="/recovery" variant="link" className="text-muted">
                Olvidé mi contraseña
              </Link>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6}>
              <TextField
                outlined
                className="w-100"
                label="Contraseña nueva"
                onTrailingIconSelect={() =>
                  this.setState((prevState) => ({
                    visibleNew: !prevState.visibleNew,
                  }))
                }
                trailingIcon={
                  <i
                    className={
                      this.state.visibleNew
                        ? "far fa-eye"
                        : "far fa-eye-slash"
                    }
                  />
                }
                characterCounter={<CharacterCounter />}
                maxLength={255}
              >
                <Input
                  type={this.state.visibleNew ? "" : "password"}
                  maxLength={255}
                  id="newPassword1"
                  value={this.state.newPassword}
                  onChange={(event) =>
                    this.setState({ newPassword: event.target.value })
                  }
                />
              </TextField>
            {this.state.newPassword !== "" && (
              <Col xs={6} className="px-0">
                <PasswordStrengthBar
                  password={this.state.newPassword}
                  minLength={6}
                  shortScoreWord={["Muy corta"]}
                  scoreWords={[
                    "Muy débil",
                    "Débil",
                    "Regular",
                    "Fuerte",
                    "Muy fuerte",
                  ]}
                />
              </Col>
            )}
            </Col>
          </Row>
          <Row className="pt-3">
            <Col md={6}>
              <TextField
                outlined
                className="w-100"
                label="Repetir contraseña nueva"
                onTrailingIconSelect={() =>
                  this.setState((prevState) => ({
                    visibleNew2: !prevState.visibleNew2,
                  }))
                }
                trailingIcon={
                  <i
                    className={
                      this.state.visibleNew2
                        ? "far fa-eye"
                        : "far fa-eye-slash"
                    }
                  />
                }
                characterCounter={<CharacterCounter />}
                maxLength={255}
              >
                <Input
                  type={this.state.visibleNew2 ? "" : "password"}
                  maxLength={255}
                  id="newPassword2"
                  value={this.state.newPassword2}
                  onChange={(event) =>
                    this.setState({ newPassword2: event.target.value })
                  }
                />
              </TextField>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="outline-danger"
                className="border-red-new px-4 py-2"
                onClick={this.updatePassword}
                size='lg'
              >
                <h6 className="m-0 py-2">Guardar Contraseña</h6>
              </Button>
            </Col>
          </Row>
          <hr></hr>
        </>
      );
  }

  renderUluCoins() {
    if (this.state.component === "Vista general" || this.state.component === "ULU coins")
      return (
        <>
          <Row className="pt-2">
            <Col>
              <p className="m-0 h1">
                <Moneda
                  color="#000000"
                  width="2.5rem"
                  height="2.5rem"
                  className="mb-1"
                />

                <span className="pl-3">ULU Coins</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="m-0">
                Actualmente tienes:{" "}
                {this.props.user.coins ? this.props.user.coins : 0} ULU Coins
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="outline-danger"
                className="border-red-new px-4 py-2"
                onClick={() => navigate("/tienda")}
                size='lg'
              >
                <h6 className="m-0 py-2">
                  <i className="fas fa-shopping-basket pr-2"></i>
                  <span>Ir a tienda</span>
                </h6>
              </Button>
            </Col>
          </Row>
          <hr></hr>
        </>
      );
  }

  renderFAQs() {
    if (this.props.faq)
      return this.props.faq.map((pregunta) => (
        <PreguntaFAQ key={pregunta.idFAQ} pregunta={pregunta} />
      ));
  }

  renderAyudaSoporte() {
    if (this.state.component === "Vista general" || this.state.component === "Ayuda y Soporte")
      return (
        <>
          <Row className="pt-2">
            <Col>
              <p className="m-0 h1">
                <i className="fas fa-question-circle pr-3"></i>
                <span>Ayuda y soporte</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {/* <Accordion onSelect={(activeKey) => this.setState({ activeKey })}>
                {this.renderFAQs()}
              </Accordion> */}
              <div>
                <form>
                  <label htmlFor="ayuda-soporte">
                    <p className="m-0 mt-3 h4">¿En qué te podemos ayudar?</p>
                  </label>
                  <br></br>
                  <textarea
                    id="ayuda-soporte"
                    rows="5"
                    style={{ width: "-webkit-fill-available" }}
                    //onChange={() => this.props.setComment()}
                  ></textarea>
                </form>
              </div>
              <Button
                variant="outline-danger"
                className="border-red-new px-4 py-2"
                //onClick={() => this.props.putComment()}
                size="lg"
              >
                <h6 className="m-0 py-2">Enviar</h6>
              </Button>
            </Col>
          </Row>
          <hr></hr>
        </>
      );
  }

  render() {
    return (
      <Container fluid className="mb-xs-65px">
        <Row>
          <Col
            sm={4}
            md={3}
            xl={2}
            className="bg-white px-0 hide-mobile"
            style={{ minHeight: "100vh" }}
          >
            <Sidebar
              admin={true}
              usuario={this.props.user}
              signOut={this.signOut}
              opciones={this.state.opcionesSidebar}
              handleFilter={(filterSidebar) => this.setState({ component: filterSidebar })}
            />
          </Col>
          <DropdownPerfilUsuario
            tipos={this.state.opcionesSidebar}
            tipo={this.state.filter}
            handleFilter={this.handleFilter}
            signOut={this.signOut}
          />
          <Col>
            <Row className="px-3 py-2">
              <Col xs={12} md={12}>
                {this.renderCuenta()}
                {this.renderNotificaciones()}
                {/*this.renderPatrocinadores()*/}
                {this.renderSeguridad()}
                {/*this.renderUluCoins()*/}
                {this.renderAyudaSoporte()}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='fixed-bottom-right'>
          {/*<Button
            variant="link"
            className="link-gray px-4 py-4"
            onClick={async () => {
              await navigate("/misjuegos");
              window.scrollTo(0, 0);
            }}
            size='lg'
          >
            <h6 className='mb-0'>Salir</h6>
          </Button>*/}
          <Button
            variant="danger"
            className="new-red-button px-3 py-3"
            size='lg'
            onClick={() => {
              this.props.putUsuario(this.props.user, this.props.getUsuario)
            }}
          >
            <h6>Guardar cambios</h6>
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  escuelas: state.escuelas.escuelas,
  niveles: state.escuelas.niveles,
  args: state.escuelas.args,
  estados: state.escuelas.estados,
  municipios: state.escuelas.municipios,
});

export default connect(mapStateToProps, {
  alert,
  confirm,
  signOut,
  getEstados,
  getUsuario,
  getMunicipios,
  putUsuario,
  getEscuelas,
  deleteUsuario,
  setArgEscuela,
  modalComponent,
  updatePassword,
  setPropiedadUser,
})(PerfilUsuario);
