import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import logo from "../../../assets/logo-cara.png";
import HintPalabra from "../../../components/crucigrama/HintPalabra";
import rectangulo from "../../../assets/Rectangulo.svg";
import { navigate } from "@reach/router";

class HostCrucigrama extends Component {
  componentDidMount() {
    let { tiempo } = this.props.juego;
    if (tiempo === null) tiempo = this.props.juego.tiempoDefault;
    this.props.setCounter(tiempo, this.props.displayScore);
    window.scrollTo(0, 0);
  }

  renderVerticales() {
    if (this.props.preguntas !== null) {
      return this.props.preguntas
        .filter((pregunta) => pregunta.direccion === "vertical")
        .map((pregunta, index) => (
          <HintPalabra
            key={pregunta.idPregunta}
            idPregunta={pregunta.idPregunta}
            texto={pregunta.hint}
            respuestas={pregunta.respuestas}
            index={pregunta.index}
            direccion={pregunta.direccion}
          />
        ));
    }
  }

  renderHorizontales() {
    if (this.props.preguntas !== null) {
      return this.props.preguntas
        .filter((pregunta) => pregunta.direccion === "across")
        .map((pregunta, index) => (
          <HintPalabra
            key={pregunta.idPregunta}
            idPregunta={pregunta.idPregunta}
            texto={pregunta.hint}
            respuestas={pregunta.respuestas}
            index={pregunta.index}
            direccion={pregunta.direccion}
          />
        ));
    }
  }

  render() {
    let { tiempo } = this.props;
    if (tiempo === null || !tiempo) tiempo = this.props.juego.tiempoDefault;
    const minutos = parseInt(tiempo / 60);
    const segundos = tiempo % 60;
    return (
      <>
        <Row className="pt-3 justify-content-center">
          <Col xs='auto'>
            <Image src={logo} style={{ width: "80px" }} onClick={() => navigate('/explorar')} />
          </Col>
          <Col>
            <Row className="justify-content-center align-items-center text-center">
              <Col>
                <h3>
                  <Image src={rectangulo} style={{ width: "2vw" }} />
                  <span className="ml-3">
                    {this.props.juego ? this.props.juego.titulo : "Crucigrama"}
                  </span>
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center text-center">
              <Col>
                <p className="h4 text-muted">
                  <i className="far fa-clock"></i>{" "}
                  <span>
                    {minutos}m {segundos}s
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-3 justify-content-between">
          <Col xs={5} className="max-h-60">
            <Row>
              <Col xs={10}>
                <h4>
                  Verticales:
                </h4>
              </Col>
              <Col xs={2} className="text-right align-items-center">
                <i className="far fa-check-circle"></i>
              </Col>
            </Row>
            {this.renderVerticales()}
          </Col>
          <Col xs={5} className="max-h-60">
            <Row>
              <Col xs={10}>
                <h4>
                  Horizontales:
                </h4>
              </Col>
              <Col xs={2} className="text-right align-items-center">
                <i className="far fa-check-circle"></i>
              </Col>
            </Row>
            {this.renderHorizontales()}
          </Col>
        </Row>
      </>
    );
  }
}

export default HostCrucigrama;
