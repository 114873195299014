import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  Container,
  Spinner,
  Row,
  Col,
  Dropdown,
  Breadcrumb,
} from "react-bootstrap";
import DropdownMisJuegos from "../components/DropdownMisJuegos";
import TablaJuegos from "../components/tablaJuegos";
import Sidebar from "../components/Sidebar";
import { getJuego } from "../actions/juegoActions";
import { getReportes, downloadReporte } from "../actions/reportesActions";
import { tipos, CustomToggle, getTipo } from "../utils";
import { Circulo, Rectangulo, Rayas, Asterisco } from "../assets/icons";
import { connect } from "react-redux";
import FilaReporte from "../components/FilaReporte";

class Reportes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movil: false,
      option: "Más reciente",
    };
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    window.scrollTo(0, 0);
    this.props.getReportes(this.props.idJuego);
    this.props.getJuego(this.props.idJuego);
    ReactGA.pageview("/reportes");
  }

  resize() {
    let currentNav = window.innerWidth <= 760;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  handleSort(option) {
    this.setState({ option });
  }

  getOption() {
    let { option } = this.state;
    if (typeof option === "object") option = option.name;
    return option;
  }

  renderSort() {
    let options = [
      { value: "fecha-desc", name: "Más reciente" },
      { value: "fecha-asc", name: "Menos reciente" },
    ];
    if (options)
      return options.map((option) => (
        <Dropdown.Item
          key={option.value}
          onClick={() => this.handleSort(option)}
        >
          {option.name}
        </Dropdown.Item>
      ));
  }

  renderReportes() {
    if (!this.props.reportes)
      return <Spinner animation="border" variant="dark" />;
    if (this.props.reportes.length === 0)
      return <p className="px-2">No hay reportes para este juego.</p>;
    let { reportes } = this.props;
    let option = this.getOption();
    if (option === "Más reciente") {
      reportes = reportes.sort((a, b) =>
        a.fechaHoraInicio < b.fechaHoraInicio ? 1 : -1
      );
    } else {
      reportes = reportes.sort((a, b) =>
        a.fechaHoraInicio > b.fechaHoraInicio ? 1 : -1
      );
    }
    return (
      <>
        <TablaJuegos>
          {
            reportes.map((reporte, index) => {
              return(
                <div>
                <FilaReporte
                  reporte={reporte}
                  downloadReporte={this.props.downloadReporte}
                />
                </div>
              )
            })
          }
        </TablaJuegos>
        <Row className="py-5" />
      </>
    );
  }

  render() {
    const titulo = this.props.juego ? this.props.juego.titulo : "Reportes";
    let Icon;
    const tipo = getTipo(this.props.juego);
    switch (tipo) {
      case "crucigrama":
        Icon = (props) => <Rectangulo color="#26DD8E" {...props} />;
        break;
      case "trivia":
        Icon = (props) => <Rayas color="#7E2CFF" {...props} />;
        break;
      case "ahorcado":
        Icon = (props) => <Asterisco color="var(--hangman)" {...props} />;
        break;
      default:
        Icon = (props) => <Circulo color="#FF3833" {...props} />;
    }
    let option = this.getOption();
    return (
      <Container fluid className="no-bg-img h-100">
        <Row>
          <Col
            sm={4}
            md={3}
            xl={2}
            className="bg-white px-0 hide-mobile"
            style={{ minHeight: "100vh" }}
          >
            <Sidebar movil={this.state.movil} reportes usuario={this.props.usuario}/>
          </Col>
          <DropdownMisJuegos tipos={tipos} />
          <Col sm={12} md={9} xl={10} className="pt-4">
            <Row>
              <Col>
                <h1 className="pb-2">
                  <Icon className="pb-1" width="5vh" height="5vh" />
                  <span className="pl-3">
                    {this.props.juego ? this.props.juego.titulo : "Reportes"}
                  </span>
                </h1>
              </Col>
            </Row>
            <Row className="mt-n4">
              <Col>
                <Breadcrumb
                  className="bg-transparent"
                  bsPrefix="breadcrumb pl-2 mb-0 pb-0"
                >
                  <Breadcrumb.Item
                    href="/misjuegos"
                    style={{ color: "#212529" }}
                  >
                    Mis Juegos
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active style={{ color: "#212529" }}>
                    {titulo}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} lg={7} className="pt-3 pt-sm-0" />
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={5}
                className="sort-container pr-sm-4 pb-1"
              >
                <span className="pl-2 mr-1 px-0 font-weight-bold">
                  Ordenar por:
                </span>

                <Dropdown
                  drop="down"
                  style={{ backgroundColor: "transparent" }}
                  className="d-inline custom-toggle"
                >
                  <Dropdown.Toggle
                    id="ordenar"
                    as={CustomToggle}
                    className="text-left"
                  >
                    {option}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-right">
                    {this.renderSort()}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row className="overflow-x-auto">
              <Container fluid>{this.renderReportes()}</Container>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  reportes: state.reportes.reportes,
  juego: state.juego.juego,
  usuario: state.user.user
});

export default connect(mapStateToProps, {
  getJuego,
  getReportes,
  downloadReporte,
})(Reportes);
