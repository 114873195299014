import { REPORTES_RECIBIDOS } from "../actions/types";

const initialState = {
  reportes: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REPORTES_RECIBIDOS:
      return { ...state, reportes: payload };
    default:
      return state;
  }
};
