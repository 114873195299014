import {
  CREATE_PREMIO,
  EDIT_PREMIO,
  PREMIOS_RECIBIDOS,
  SET_PROPIEDAD_PREMIO,
  SAVE_PREMIO,
  DELETE_PREMIO,
  ERROR_PREMIO,
  EDITAR_CODIGOS
} from "../actions/types";

const schema = {
  idPremio: "nuevo",
  nombre: "",
  idAdjunto: null,
  descripcion: "",
  fechaHoraVencimiento: "",
  activo: true
};

const initialState = {
  premios: null,
  premio: null,
  editado: null,
  error: undefined
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PREMIOS_RECIBIDOS:
      return { ...state, premios: payload };
    case EDIT_PREMIO:
      return { ...state, editado: payload, error: undefined };
    case EDITAR_CODIGOS:
      return { ...state, premio: payload };
    case CREATE_PREMIO:
      return { ...state, editado: schema };
    case SET_PROPIEDAD_PREMIO:
      const { key, value } = payload;
      const editado = { ...state.editado };
      editado[key] = value;
      return { ...state, editado };
    case SAVE_PREMIO:
      const premios = [...state.premios];
      let index = premios.findIndex(premio => parseInt(premio.idPremio) === parseInt(payload.idPremio));
      if(index === -1) premios.push(payload);
      else premios[index] = payload;
      return { ...state, premios, editado: null, error: undefined };
    case DELETE_PREMIO: {
      const premios = [...state.premios];
      const index = premios.findIndex(premio => parseInt(premio.idPremio) === parseInt(payload));
      if(index !== -1) premios.splice(index, 1);
      return { ...state, premios };
    }
    case ERROR_PREMIO:
      return { ...state, error: true };
    default:
      return state;
  }
};
