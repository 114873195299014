import React, { Component, Fragment } from "react";
import { Card, Image, Button, Col, Container, Row } from "react-bootstrap";

const DroppedImage = (props) => (
  <>
    <Card
      style={{
        backgroundColor: "transparent",
        border: 0,
        height: "100%",
        width: "100%",
        position: "relative",
      }}
      className="mb-4"
    >
      <Image
        src={props.src}
        className="mr-3 image-150"
        style={{ maxHeight: 150, width: "auto" }}
      />
    </Card>
    <Button
      variant="outline-danger"
      style={{ position: "absolute", top: 0, left: "100%" }}
      onClick={() => props.removeImage(props.src)}
    >
      <i className="fa fa-trash" />
    </Button>
  </>
);

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: props.file,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleModifier = this.handleModifier.bind(this);
  }

  componentDidMount() {
    this.setDropZone();
    this.setImage({});
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.idAdjunto &&
      prevProps.idAdjunto !== null &&
      (!this.props.idAdjunto || this.props.idAdjunto === null)
    ) {
      setTimeout(() => {
        this.setDropZone();
      }, 150);
    }
    if (
      prevProps.file &&
      prevProps.file !== null &&
      (!this.props.file || this.props.file === null || !this.props.file.name)
    ) {
      setTimeout(() => {
        this.setDropZone();
      }, 150);
    }
    if (
      prevProps.file &&
      this.props.file &&
      this.props.file !== null &&
      prevProps.file !== null
    ) {
      if (
        prevProps.file.name !== this.props.file.name &&
        this.props.file.name
      ) {
        this.setFile(this.props.file);
      }
    } else {
      var file = this.refs.fileInputRef;
      file.value = null;
    }
    this.setImage(prevProps);
  }

  setImage(prevProps) {
    if (
      this.props.idAdjunto &&
      this.props.idAdjunto !== null &&
      !isNaN(this.props.idAdjunto) &&
      this.props.idAdjunto !== prevProps.idAdjunto
    ) {
      let src = `${this.props.base_url}${this.props.idAdjunto}`;
      this.setState({ src });
    } else if (this.props.idAdjunto !== prevProps.idAdjunto) {
      this.setState({ src: undefined });
    }
    if (prevProps.file && !this.props.file) {
      if (!this.props.idAdjunto || this.props.idAdjunto == null) {
        this.handleModifier();
        this.setState({ src: undefined });
      }
    }
  }

  setFile(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      let src = e.target.result;
      this.setState({ src, height: 150 });
    };
    reader.readAsDataURL(file);
  }

  setDropZone() {
    let area = this.refs.dropArea;
    if (area && area !== null) {
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        area.addEventListener(eventName, this.preventDefaults, false);
      });
      area.addEventListener("drop", this.handleDrop, false);
    }
  }

  preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDrop(e) {
    let files = e.dataTransfer.files;
    this.handleSelect({ target: { files } });
  }

  handleSelect(evt) {
    let file = evt.target.files[0];
    if (file) {
      if (!this.props.excel && !String(file.type).includes("image")) {
        return this.props.alert("El archivo debe ser una imagen");
      }
      if (this.props.accept) {
        if (!String(file.type).includes(this.props.accept)) {
          return alert(`El archivo debe ser ${this.props.accept}`);
        }
      }
      const size = parseFloat(file.size / 1024 / 1024).toFixed(2);
      if (size > 2) {
        return alert(`El tamaño máximo para subir es de 2MB. Tamaño de archivo actual: ${size}MB`);
      }
      this.setFile(file);
      this.handleModifier(file);
    }
  }

  handleFiles(files) {
    if (files)
      for (let i = 0; i < files.length; i++) this.handleModifier(files[i]);
  }

  handleModifier(file) {
    if (this.props.modifier) {
      if (this.props.args) {
        if (Array.isArray(this.props.args))
          this.props.modifier(...this.props.args, file);
        else this.props.modifier(this.props.args, file);
      } else {
        this.props.modifier(file);
      }
    }
  }

  renderImages() {
    if (this.state.src) {
      if (this.props.excel) {
        return (
          <>
            <i
              className={`fas fa-${
                this.props.excel ? "file-excel" : "image"
              } fa-2x`}
            ></i>{" "}
            {this.props.file.name}
          </>
        );
      }
      return (
        <DroppedImage
          key={this.state.src}
          src={this.state.src}
          removeImage={this.props.removeImage}
        />
      );
    }
  }

  renderDropZone() {
    if (!this.state.src) {
      return (
        <Container fluid className="px-0">
          <div
            ref="dropArea"
            style={{
              height: this.props.height ? this.props.height : 150,
              width: "100%",
            }}
            className="bg-light border text-center pt-3 dashed-gray px-3"
            onClick={() => this.refs.fileInputRef.click()}
          >
            <Row>
              <label htmlFor="fileInput" className="d-block text-center m-auto">
                {this.props.placeholder}
                <i
                  className={`fas fa-${
                    this.props.excel ? "file-excel" : "image"
                  } fa-2x`}
                ></i>
                <p className="py-0 mb-0">
                  {this.props.excel
                    ? "Arrastra un archivo de excel desde tu computadora"
                    : "Arrastra una foto desde tu computadora"}
                </p>
                <p className="py-0 mb-1">
                  Tamaño límite 2MB
                  {this.props.message ? this.props.message : ""}
                </p>
              </label>
            </Row>
            <Row>
              <Col xs={2}>
                <input
                  className="d-block text-center hidden"
                  type="file"
                  multiple={this.props.multiple}
                  ref="fileInputRef"
                  accept={
                    this.props.excel
                      ? "application/xml-spreadsheet"
                      : "image/x-png,image/gif,image/jpeg,image/jpg"
                  }
                  onChange={this.handleSelect}
                />
              </Col>
              <Col xs={8}>
                <label
                  htmlFor="fileInput"
                  className="px-2"
                  style={{
                    border: "solid",
                    borderColor: "rgb(34, 37, 41)",
                    borderWidth: 1,
                  }}
                >
                  Escoger archivo
                </label>
              </Col>
              <Col xs={2} />
            </Row>
          </div>
        </Container>
      );
    } else {
      return (
        <Container fluid className="px-0">
          <Button
            className="border-red-new mt-4 pt-2"
            onClick={() => this.refs.fileInputRef.click()}
          >
            Cambiar {this.props.excel ? "Archivo" : "Imagen"}
          </Button>
          <input
            className="d-block text-center hidden"
            type="file"
            multiple={this.props.multiple}
            ref={"fileInputRef"}
            accept="image/x-png,image/gif,image/jpeg,image/jpg"
            onChange={this.handleSelect}
          />
        </Container>
      );
    }
  }

  render() {
    let images = this.renderImages();
    return (
      <Fragment>
        <div
          style={{
            position: "relative",
          }}
        >
          {images}
        </div>
        {this.renderDropZone()}
      </Fragment>
    );
  }
}

export default Dropzone;
