import React, { Component } from 'react';
import ReviewForm from './ReviewForm';

class GameEnding extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return(
            <ReviewForm 
                patrocinador={this.props.patrocinador}
                porcentajeCorrectas={this.props.porcentajeCorrectas}
                apodo={this.props.apodo}
                setSugerenciaProperty={this.props.setSugerenciaProperty}
                showThankYouModal={this.props.showThankYouModal}
                user={this.props.user}
            />
        );
    }
}

export default GameEnding;