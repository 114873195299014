import React from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import Dropzone from "./Dropzone";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import placeholderImage from "../assets/jugar-sin-imagen.png";
import moment from "moment";

class PremioForm extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      lugares: props.premio.lugares ? props.premio.lugares : []
    } 
    this.isChecked = this.isChecked.bind(this);
  }

  updateStateList(e, value){
    if (e.target.checked){
      //append to array
      this.setState({
        lugares: this.state.lugares.concat([value])
      }, () => {
        this.props.modifier("lugares", this.state.lugares);
      });
    } else {
      //remove from array
      this.setState({
        lugares : this.state.lugares.filter(function(val) {return val!==value})
      }, () => {
        this.props.modifier("lugares", this.state.lugares);
      });
    }
  }

  isChecked(n){
    return this.state.lugares.includes(n);
  }

  render() {
    let idAdjunto = this.props.premio.adjuntoPremio;
    if(idAdjunto || idAdjunto === null) idAdjunto = this.props.premio.adjuntoPatrocinador;
    if(idAdjunto || idAdjunto === null) idAdjunto = placeholderImage;
    let { fechaHoraVencimiento } = this.props.premio;
    if(fechaHoraVencimiento === ''){
      fechaHoraVencimiento = new Date();
    }
    fechaHoraVencimiento = moment(fechaHoraVencimiento).format(
      "YYYY-MM-DDTHH:mm"
    );
    return (
      <Modal.Body>
        <Container>
          <Row>
            <Col className="px-0 pb-3">
              <div style={{ width: "100%" }}>
                <TextField
                  outlined
                  className="w-100"
                  label="Nombre"
                  characterCounter={<CharacterCounter />}
                  maxLength={120}
                >
                  <Input
                    value={this.props.premio.nombre}
                    maxLength={120}
                    onChange={(event) =>
                      this.props.modifier("nombre", event.target.value)
                    }
                  />
                </TextField>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="px-0 pb-3">
              <div style={{ width: "100%" }}>
                <TextField
                  textarea
                  outlined
                  className="w-100"
                  label="Descripción"
                  characterCounter={<CharacterCounter />}
                  maxLength={3000}
                >
                  <Input
                    value={this.props.premio.descripcion}
                    maxLength={3000}
                    onChange={(event) =>
                      this.props.modifier("descripcion", event.target.value)
                    }
                    style={{ resize: "none" }}
                  />
                </TextField>
              </div>
            </Col>
          </Row>
          <Row>
            <Dropzone
              modifier={this.props.modifier}
              base_url={this.props.base_url}
              idAdjunto={idAdjunto}
              removeImage={() => this.props.modifier("adjuntoPremio")}
              args={"adjuntoPremio"}
              file={this.props.premio.file}
              alert={this.props.alert}
              height="auto"
            />
          </Row>
          <Row>
            <Col className="px-0 pt-4">
                <p className="m-0">Fecha y hora de vencimiento</p>
              <div style={{ width: "100%" }}>
                <TextField
                  outlined
                  className="w-100"
                >
                  <Input
                    value={fechaHoraVencimiento}
                    dateTime={fechaHoraVencimiento}
                    onChange={(event) =>
                      this.props.modifier("fechaHoraVencimiento", event.target.value)
                    }
                    type="datetime-local"
                  />
                </TextField>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="px-0 pt-4">
                <p className="m-0">Lugares asignados:</p>
                <Form>
                  <Form.Check checked={this.isChecked(1)} inline label="1er lugar" type='checkbox' id='1er lugar' value={1} onChange={(event) => this.updateStateList(event, Number(event.target.value))}/>
                  <Form.Check checked={this.isChecked(2)} inline label="2do lugar" type='checkbox' id='2do lugar' value={2} onChange={(event) => this.updateStateList(event, Number(event.target.value))}/>
                  <Form.Check checked={this.isChecked(3)} inline label="3er lugar" type='checkbox' id='3er lugar' value={3} onChange={(event) => this.updateStateList(event, Number(event.target.value))}/>
                </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    );
  }
}

export default PremioForm;
