import React, { Component } from "react";
import { Container, Row, Button, Col, Image} from "react-bootstrap";
import { recoverPassword } from "../actions/userActions";
import { connect } from "react-redux";
import { Link, navigate } from "@reach/router";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import logoCara from "../assets/logo-cara.png";

class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    this.setState({ validated: true });
    this.props.recoverPassword(this.state.email);
  };

  render() {
    return (
      <Container fluid className="mx-0">
        <Row className="align-items-center justify-content-md-center">
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-rojo-mitad order-2 order-lg-1 py-5 py-sm-0'>
            <Row className="justify-content-md-center">
              <Col md={12} className="text-center text-white pl-4">
                <h2 className='pb-2'>¿Buscas jugar un ULU?</h2>
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  className='white-button'
                  href="/jugar"
                >
                  <h6 className="py-2 my-0">¡Juega aquí!</h6>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-gris order-1 order-lg-2 py-5 py-sm-0'>
            <Row className="justify-content-md-center">
              <Col md={12} className="text-center pl-4">
                <Image
                  src={logoCara}
                  className="d-block mx-auto mb-3"
                  style={{ height: 100 }}
                  onClick={() => navigate('/explorar')}
                />
                <h1>Recupera tu contraseña</h1>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-1 pb-3">
              <Col md={6}>
                <TextField
                  outlined
                  className="w-100"
                  label="Correo electrónico"
                  characterCounter={<CharacterCounter/>}
                  maxLength={255}
                >
                  <Input
                    value={this.state.email}
                    maxLength={255}
                    type="email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    onSubmit={this.handleSubmit}
                  />
                </TextField>
              </Col>
            </Row>
            <Row className="justify-content-md-center pb-3">
              <Col md={6} className="text-right pt-2">
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  className="new-red-button"
                  onClick={this.handleSubmit}
                >
                  <h6 className="py-2 my-0">Recuperar contraseña</h6>
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className='text-center'>
                <hr></hr>
                <p className="pr-2">
                  ¿No tienes cuenta? 
                  <Link to="/signup" variant="link" style={{display: 'inline-block'}} className="ml-1">
                    Regístrate aquí
                  </Link>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        
      </Container>
    );
  }
}

export default connect(null, { recoverPassword })(Recovery);
