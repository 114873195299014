import React from "react";
import {
  Row,
  Image,
  Col,
  Container
} from "react-bootstrap";
import cara from "../assets/logo-cara.png";
import rayas from "../assets/Rayas.svg";
import rectangulo from "../assets/Rectangulo.svg";
import asterisco from "../assets/Asterisco.svg";
import { navigate }  from '@reach/router';

class HeaderHost extends React.Component{
    renderTipo(tipo) {
        switch(tipo) {
            case "crucigrama": return rectangulo;
            case "trivia": return rayas;
            case "ahorcado": return asterisco;
            default: return "";
        }
    }

    render() {
        let icon;
        if (this.props.tipo.toLowerCase() === "crucigrama") icon = rectangulo;
        else if (this.props.tipo.toLowerCase() === "ahorcado")
        icon = asterisco;
        else icon = rayas;
        return(
            <Container className="fixed-top pt-3">
                <Row>
                    <Col xs={3}>
                        <Image src={cara} className="mw-25 w-25" onClick={() => navigate('/explorar')} />
                    </Col>
                    <Col xs={6} className="text-center">
                        <h1>
                            <Image src={icon} style={{ width: 30 }} className="pb-2 mr-2" />
                            {this.props.titulo !== null ? this.props.titulo : ""}
                        </h1>
                    </Col>
                    {(this.props.tipo.toLowerCase() === 'trivia' && (this.props.respuestas !== null || this.props.respuestas !== undefined)) && 
                        <Col xs={3} className='text-right'>
                            <h5>{this.props.respuestas !== 0 ? this.props.respuestas : '0'} respuestas</h5>
                        </Col>
                    }
                </Row>
                {!this.props.hideClock &&
                    <Row className="align-items-center text-center">
                        <Col>
                            {!this.props.hideClock ? (
                            <h5>
                                <i className="inline fas fa-clock pb-1 pr-1"></i>
                                <span className="text-muted">
                                Tiempo restante: {this.props.tiempo}s
                                </span>
                            </h5>
                            ) : (
                            ""
                            )}
                        </Col>
                    </Row>
                }
            </Container>
        )
    }
}

export default HeaderHost;
