import React from "react";
import { Form } from "react-bootstrap";

export default function ({
  label,
  value,
  formClassName,
  checkClassName,
  modifier,
  args,
}) {
  return (
    <Form className={formClassName}>
      <p className="m-0 h5">{label}</p>
      <Form.Check
        type="switch"
        className={checkClassName}
        label=""
        id={`check-${label ? label : args}`}
        checked={value}
        onChange={(e) => modifier(args, e.target.checked)}
      />
    </Form>
  );
}
