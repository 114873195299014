import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import AuthService from "../services/AuthService";
import PasswordStrengthBar from "react-password-strength-bar";
import logoCara from "../assets/logo-cara.png";
import { navigate } from "@reach/router";

class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            password: '',
            passwordBackup: '',
            visible: false,
        }
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        if(this.state.password.length < 6){
            alert('Oops! La contraseña debe de tener 6 o más caracteres');
        }
        else if(this.state.password !== this.state.passwordBackup){
            alert('Oops! Las contraseñas no coinciden');
        } else {
            let token = this.props.location.search;
            token = token.substring(token.lastIndexOf("=")+1);
            AuthService.resetPassword({newPassword: this.state.password, token}).then(() => {
                navigate("/login", { replace: true });
            }).catch((e) => {
                if(e.response.data.error.code === "auth/restore_password_expired"){
                    alert("Ha expirado el tiempo para restaruar la contraseña, vuelve a intentarlo");
                }
            });   
        }
    }
    render(){
        return(
            <Container fluid className='fondo-gris-full vertical-center'>
                <Row className="justify-content-md-center pb-4">
                    <Col md={12} className="text-center">
                        <img
                            src={logoCara}
                            className="d-block mx-auto mb-3"
                            style={{ height: 60 }}
                            onClick={() => navigate("/explorar")}
                            alt='Logo cara ULU'
                        />
                        <h2>Reestablece tu contraseña</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center text-center pb-4'>
                    <Col xs={12} md={3}>
                        <TextField
                            outlined
                            className="w-100"
                            label="Contraseña nueva"
                            onTrailingIconSelect={() =>
                                this.setState((prevState) => ({
                                    visible: !prevState.visible,
                                }))
                            }
                            trailingIcon={
                                <i
                                    className={
                                    this.state.visible
                                        ? "far fa-eye"
                                        : "far fa-eye-slash"
                                    }
                                />
                            }
                            characterCounter={<CharacterCounter />}
                            maxLength={255}
                        >
                            <Input
                                type={this.state.visible ? "text" : "password"}
                                maxLength={255}
                                value={this.state.password}
                                onChange={(event) =>
                                    this.setState({ password: event.target.value })
                                }
                            />
                        </TextField>
                        <Row>
                            <Col xs={7}>
                                <PasswordStrengthBar
                                    password={this.state.password}
                                    minLength={6}
                                    shortScoreWord={["Muy corta"]}
                                    scoreWords={[
                                        "Muy débil",
                                        "Débil",
                                        "Regular",
                                        "Fuerte",
                                        "Muy fuerte",
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center text-center'>
                    <Col xs={12} md={3}>
                        <TextField
                            outlined
                            className="w-100"
                            label="Reescribir contraseña nueva"
                            onTrailingIconSelect={() =>
                                this.setState((prevState) => ({
                                    visible: !prevState.visible,
                                }))
                            }
                            trailingIcon={
                                <i
                                    className={
                                    this.state.visible
                                        ? "far fa-eye"
                                        : "far fa-eye-slash"
                                    }
                                />
                            }
                            characterCounter={<CharacterCounter />}
                            maxLength={255}
                        >
                            <Input
                                type={this.state.visible ? "text" : "password"}
                                maxLength={255}
                                value={this.state.passwordBackup}
                                onChange={(event) =>
                                    this.setState({ passwordBackup: event.target.value })
                                }
                            />
                        </TextField>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center text-right'>
                    <Col xs={12} md={3}>
                        <Button
                            variant="danger"
                            size="lg"
                            className='new-red-button mt-4'
                            onClick={this.handleClick}
                            type="submit"
                        >
                            <h6 className="py-2 my-0">Reestablecer contraseña</h6>
                        </Button>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center text-center'>
                    <Col xs={12} md={3}>
                        <hr className="mt-4 mb-3" style={{borderTopColor: 'lightgrey'}} />
                        <Button
                            variant='link'
                            className="inline py-0"
                            onClick={() => navigate("/explorar")}
                        >
                            Regresar a PlayULU
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ResetPassword;