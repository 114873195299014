import React, { Component } from "react";
import { Row, Col, Button, Container, Card } from "react-bootstrap";
import { navigate } from "@reach/router";

class DeletePatrocinador extends Component {
  deletePatrocinador(patrocinador) {
    this.props.confirm(
      `¿Estás seguro que deseas eliminar el patrocinador ${patrocinador.nombre}?`,
      async () => {
        await this.props.deletePatrocinador(patrocinador.idPatrocinador);
        navigate("/admin/patrocinadores");
      }
    );
  }

  render() {
    return (
      <Row className="pt-2">
        <Col className="pl-3 pl-sm-4">
          <p className="m-0 h5 pb-2">Eliminar patrocinador</p>
          <Button
            variant="outline-danger"
            className="border-red-new py-2 mx-auto my-0"
            onClick={() => this.deletePatrocinador(this.props.patrocinador)}
          >
            <i className="far fa-trash-alt pr-2"></i>
            <span>Eliminar</span>
          </Button>
        </Col>
        <Col>
          <Card className="card-advertencia py-2">
            <Container>
              <Row>
                <Col>
                  <p className="m-0">
                    <i
                      className="fas fa-exclamation-triangle pr-2"
                      style={{ color: "#FF3833" }}
                    ></i>
                    <span>Advertencia</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="m-0 text-muted h6">
                    Si eliminas este patrocinador no aparecerá más en los ULUs
                    pero los códigos ya otorgados podrán seguir siendo
                    utilizados
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default DeletePatrocinador;
