import React from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import rayas from "../assets/Rayas.svg";
import Dropzone from "./Dropzone";
import OpcionForm from "./OpcionForm";
import { BASE_URL, getColorName, getColor, getIcon } from "../utils";
import { navigate } from "@reach/router";
import fondo from "../assets/fondo-nuevo.png";
import logo from "../assets/logo.png";
import placeholderImage from "../assets/jugar-sin-imagen.png";

class PreguntaTrivia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: placeholderImage,
    };
  }

  onUnload = e => { // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
 }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pregunta.idAdjunto !== null &&
      this.state.src === placeholderImage
    ) {
      this.setState({
        src: `${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}`,
      });
    }
    if (this.props.pregunta !== prevProps.pregunta) {
      if (this.props.pregunta.idAdjunto !== null) {
        this.setState({
          src: `${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}`,
        });
      } else {
        this.setState({
          src: placeholderImage,
        });
      }
    }
    if (this.props.pregunta.file && this.state.src === placeholderImage) {
      if (this.props.pregunta.file.name !== undefined) {
        this.readFile();
      }
    }
    if (
      prevProps.pregunta.file !== this.props.pregunta.file &&
      this.state.src !== placeholderImage
    ) {
      if (this.props.pregunta.file) {
        if (this.props.pregunta.file.name) {
          this.readFile();
        }
      }
    }
  }

  readFile() {
    const reader = new FileReader();
    reader.onload = (e) => {
      const src = e.target.result;
      this.setState({ src });
    };
    reader.readAsDataURL(this.props.pregunta.file);
  }

  handleChange(evt, name) {
    let { value } = evt.target;
    this.props.setPropiedadPregunta(name, value);
  }

  renderOpcionesVistaPrevia() {
    let components = ["", "", "", ""];
    if (this.props.pregunta.opciones) {
      for (let i = 0; i < 4; i++) {
        if (this.props.pregunta.opciones[i].texto !== "" || i <= 1) {
          let component = (
            <div
              className="mx-0 py-0 my-0 col-6"
              key={this.props.pregunta.opciones[i].idOpcion}
            >
              <button
                type="button"
                className={`option button-white no-hover my-1 d-inline-block border-${getColorName(
                  i
                )}`}
                style={{ height: "10%", width: "100%" }}
              >
                <div
                  className="align-items-center text-left text-wrap row"
                  style={{ height: "100%" }}
                >
                  <div className="pr-0 pl-3 col-2">
                    {getIcon(i, {
                      style: { width: "15px" },
                      color: getColor(i),
                      newclass: "mb-0",
                    })}
                  </div>
                  <div className="pr-0 pl-2 col-10">
                    <p
                      className="px-3 mx-0 py-0 my-0 texto-opcion"
                      style={{ fontSize: "10px" }}
                    >
                      {this.props.pregunta.opciones[i].texto}
                    </p>
                  </div>
                </div>
              </button>
            </div>
          );
          components.push(component);
        }
      }
    }
    return components;
  }

  renderRow1() {
    let row = (
      <Row className="px-3 py-1">
        {this.props.pregunta.opciones
          .map((opcion, i) => {
            if (i < 2)
              return (
                <div
                  className="pl-0 pr-2 mx-0 py-0 my-0 col-6"
                  key={this.props.pregunta.opciones[i].idOpcion}
                >
                  <button
                    type="button"
                    className={`option button-white no-hover my-1 d-inline-block ${
                      this.props.pregunta.opciones[i].correcta
                        ? `outline-fill-${getColorName(i)}`
                        : `outline-${getColorName(i)}`
                    }`}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="align-items-center text-left text-wrap row"
                      style={{ height: "100%" }}
                    >
                      <div className="pr-0 pl-3 col-2">
                        {getIcon(i, {
                          style: { width: "15px" },
                          color: this.props.pregunta.opciones[i].correcta
                            ? "#fff"
                            : getColor(i),
                          newclass: "mb-0",
                        })}
                      </div>
                      <div className="pr-0 pl-2 col-10">
                        <p
                          className="px-3 mx-0 py-0 my-0 texto-opcion"
                          style={{ fontSize: "10px" }}
                        >
                          {this.props.pregunta.opciones[i].texto}
                        </p>
                      </div>
                    </div>
                  </button>
                </div>
              );
            return null;
          })
          .filter((opcion) => opcion !== null)}
      </Row>
    );
    return row;
  }

  renderRow2() {
    let row = (
      <Row className="px-3 py-1">
        {this.props.pregunta.opciones
          .map((opcion, i) => {
            if (i > 1 && opcion.texto !== "")
              return (
                <div
                  className="pl-0 pr-2 mx-0 py-0 my-0 col-6"
                  key={this.props.pregunta.opciones[i].idOpcion}
                >
                  <button
                    type="button"
                    className={`option button-white no-hover my-1 d-inline-block ${
                      this.props.pregunta.opciones[i].correcta
                        ? `outline-fill-${getColorName(i)}`
                        : `outline-${getColorName(i)}`
                    }`}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="align-items-center text-left text-wrap row"
                      style={{ height: "100%" }}
                    >
                      <div className="pr-0 pl-3 col-2">
                        {getIcon(i, {
                          style: { width: "15px" },
                          color: this.props.pregunta.opciones[i].correcta
                            ? "#fff"
                            : getColor(i),
                          newclass: "mb-0",
                        })}
                      </div>
                      <div className="pr-0 pl-2 col-10">
                        <p
                          className="px-3 mx-0 py-0 my-0 texto-opcion"
                          style={{ fontSize: "10px" }}
                        >
                          {this.props.pregunta.opciones[i].texto}
                        </p>
                      </div>
                    </div>
                  </button>
                </div>
              );
            return null;
          })
          .filter((opcion) => opcion !== null)}
      </Row>
    );
    return row;
  }

  renderContenidoVistaPrevia() {
    let minutos;
    let segundos;
    if (this.props.juego) {
      minutos = Math.floor(this.props.juego.tiempo / 60);
      segundos = this.props.juego.tiempo % 60;
    }
    return (
      <Container className="box-border mx-0 px-0 vista-previa" fluid>
        <Image src={fondo} className="h-100 w-100 vista-previa-bg" />
        <Col className="vista-previa-elements d-flex flex-column">
          <Row className="justify-content-center pt-1">
            <Col xs={3} className="">
              <Image src={logo} style={{ width: "30%" }} />
            </Col>
            <Col xs={6}>
              <Row className="justify-content-center align-items-center text-center">
                <Col>
                  <p
                    className="px-0 py-0 mx-0 my-0"
                    style={{ fontSize: "12px" }}
                  >
                    <Image src={rayas} style={{ width: "5%" }} />
                    <span className="font-weight-bold pl-1">
                      {this.props.juego.titulo
                        ? this.props.juego.titulo
                        : "Trivia"}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center text-center">
                <Col>
                  <p
                    className="text-muted px-0 py-0 mx-0 my-0"
                    style={{ fontSize: "10px" }}
                  >
                    <i className="far fa-clock"></i>{" "}
                    <span>
                      {this.props.juego.tiempo ? minutos : "0"}m :{" "}
                      {this.props.juego.tiempo
                        ? segundos.toString().length === 2
                          ? segundos
                          : "0" + segundos
                        : "15"}
                      s
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={3}></Col>
          </Row>
          <Row className="flex-grow-1 py-3">
            <Col md={4}>
              <Image
                src={this.state.src}
                className="d-block w-100 mw-100 m-auto"
                style={{ maxHeight: 150, objectFit: "cover" }}
              />
            </Col>
            <Col md={8} ref="parent" className="align-content-around px-0">
              <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "12px" }}>
                {this.props.pregunta.texto}
              </p>
              <Container fluid className="px-0" id="opciones-vista-previa">
                {this.renderRow1()}
                {this.renderRow2()}
                {/* <Col xs={6} className="px-3 mx-0 py-0 my-0" key={ 123 }><Button className={`option button-white no-hover d-inline-block border-${ getColorName(0) }`} style={{ height: "10%", width: "100%" }}><Row className="align-items-center text-left text-wrap" style={{ height: "100%" }}><Col xs={2} className="pr-0 pl-2">{ getIcon(0, { style: { width: "15px" }, color: getColor(0), newclass: "mb-0"}) }</Col><Col xs={10} className="pr-0 pl-2"><p className="px-3 mx-0 py-0 my-0 texto-opcion" style={{ fontSize: "10px" }}>hola</p></Col></Row></Button></Col> */}
              </Container>
            </Col>
          </Row>
          <Row className="pb-2 align-items-center">
            <Col xs={4} className="">
              <Row className="pl-3">
                <p
                  className="px-0 py-0 mx-0 my-0"
                  style={{ fontWeight: "bold", fontSize: "10px" }}
                >
                  PIN del juego:
                </p>
              </Row>
              <Row className="pl-3">
                <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "10px" }}>
                  xxxx
                </p>
              </Row>
            </Col>
            <Col xs={4} className="text-center">
              <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "12px" }}>
                Pregunta {this.props.index + 1}/{this.props.preguntas.length} -{" "}
                {this.props.juego.puntos ? this.props.juego.puntos : 1000}pts
              </p>
            </Col>
            <Col xs={4} className="text-right pl-0 pr-3">
              <Button
                variant="link"
                className="link-gray pl-0 pr-2"
                style={{ fontSize: "10px" }}
              >
                Salir del juego
              </Button>
              <Button
                variant="outline-danger"
                className="rounded-button border-red-new py-1"
                style={{ fontSize: "10px" }}
              >
                Continuar
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }

  renderVistaPrevia() {
    let minutos;
    let segundos;
    if (this.props.juego) {
      minutos = Math.floor(this.props.juego.tiempo / 60);
      segundos = this.props.juego.tiempo % 60;
    }
    if (!this.props.hideVistaPrevia) {
      return (
        <>
          <Row className="mx-0">
            <p className="h6 mt-4 pt-3">Vista previa</p>
          </Row>
          <Row className="mx-0">{this.renderContenidoVistaPrevia()}</Row>
          <Row className="align-items-center pl-3 pt-2">
            <p>
              <i
                className={
                  this.props.juego.publico
                    ? "fas fa-users pr-1"
                    : "fas fa-user color-000 pr-1"
                }
              />
              <span className="pr-3">
                {this.props.juego.publico ? " Todos" : " Solo yo"}
              </span>
              <i className="far fa-clock pr-1" />
              <span className="pr-3">
                {this.props.juego.tiempo ? minutos : "0"}m :{" "}
                {this.props.juego.tiempo
                  ? segundos.toString().length === 2
                    ? segundos
                    : "0" + segundos
                  : "15"}
                s
              </span>
            </p>
          </Row>
        </>
      );
    }
  }

  renderOpciones() {
    if (this.props.pregunta.opciones) {
      return this.props.pregunta.opciones.map((opcion, index) => (
        <Row key={opcion.idOpcion} className="px-2">
          <OpcionForm
            color={getColorName(index)}
            style={
              this.props.opcion
                ? this.props.opcion.correcta
                  ? "outline-fill"
                  : "outline"
                : "dashed"
            }
            icon={getIcon(index, {
              style: { width: "100%" },
              color: opcion.correcta ? "#ffffff" : getColor(index),
            })}
            modifier={this.props.setPropiedadOpcion}
            {...opcion}
            index={index}
          />
        </Row>
      ));
    }
  }

  async handleClick() {
    this.props.createNuevaPregunta(this.props.preguntas.length + 1);
    await navigate(
      this.props.idJuego
        ? `/editar/${this.props.idJuego}/pregunta/nueva-${
            this.props.preguntas.length + 1
          }`
        : `/crear/pregunta/nueva-${this.props.preguntas.length + 1}`
    );
    window.scrollTo(0, 0);
  }

  render() {
    let images;
    if (this.props.pregunta) {
      if (
        this.props.pregunta.idAdjunto &&
        this.props.pregunta.idAdjunto !== null
      ) {
        images = [
          { src: `${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}` },
        ];
      }
    }

    return (
      <Row>
        <Col sm={12} md={6} className="px-4">
          <Row className="pb-2">
            <Container className="ml-0">
              <h1 className="my-3">
              <Image src={rayas} style={{ width: 30 }} className="pb-3" />
                <span className="ml-2">Pregunta {this.props.index + 1}</span>
              </h1>
            </Container>
          </Row>
          <Row className="px-2">
            <div className="w-100">
              <TextField
                outlined
                className="w-100"
                label="Pregunta"
                characterCounter={<CharacterCounter />}
                maxLength={140}
                textarea
              >
                <Input
                  type="text"
                  value={this.props.pregunta.texto}
                  maxLength={140}
                  onChange={(event) => this.handleChange(event, "texto")}
                  style={{ resize: "none" }}
                />
              </TextField>
            </div>
          </Row>
          <Row className="px-2 py-3">
            <h5>Imagen de la pregunta</h5>
            <Dropzone
              images={images}
              modifier={this.props.setPropiedadPregunta}
              base_url={`${BASE_URL}/adjuntos/`}
              idAdjunto={this.props.pregunta.idAdjunto}
              removeImage={() => {
                this.props.setPropiedadPregunta("idAdjunto");
                this.setState({ src: placeholderImage });
              }}
              args={["idAdjunto"]}
              multiple={false}
              file={this.props.pregunta.file}
              alert={this.props.alert}
            />
          </Row>
          <Row className="px-2">
            <h5>Respuestas</h5>
          </Row>
          {this.renderOpciones()}
          <Row
            className="py-5"
            style={
              {
                /*no lo borren se ve feo sin esta row vacia*/
              }
            }
          />
        </Col>
        <Col sm={12} md={6} className="mt-5 border-left-1 hide-mobile">
          {this.renderVistaPrevia()}
          {/*
          <Row className='fixed-bottom mb-4 mr-3' style={{zIndex: 9}}>
            <Col md={12} className='text-right'>
              <Button
                variant="outline-danger"
                className="border-red-new show-mobile-button px-3 py-3 mr-3"
                style={{ width: 60, height: 60, color: "#FF3833", borderRadius: '70px' }}
                onClick={() => {
                  this.handleClick();
                }}
                size='lg'
              >
                <h6><i className="fas fa-plus"></i></h6>
              </Button>
              <Button
                size='lg'
                variant="danger"
                className="new-red-button px-4 py-4"
                onClick={this.props.handleSubmit}
                disabled={this.props.disabled}
              >
                <h6 className='mb-0'>{this.props.button}</h6>
              </Button>
            </Col>
          </Row>
          */}
        </Col>
      </Row>
    );
  }
}

export default PreguntaTrivia;
