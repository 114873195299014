import {
    EXPLORE_PATROCINADORES_RECIBIDOS,
    EXPLORE_JUEGOS_PATROCINADORES_RECIBIDOS,
    EXPLORE_JUEGOS_DESTACADOS_RECIBIDOS,
    EXPLORE_ALL_JUEGOS_RECIBIDOS,
    EXPLORE_SEARCH_JUEGOS_RECIBIDOS,
    EXPLORE_SEARCH_NEXT_PAGE_JUEGOS_RECIBIDOS,
    EXPLORE_RESET_JUEGOS
} from "../actions/types";

const INITIAL_STATE = {
    patrocinadores: [],
    juegosPatrocinadores: [],
    juegosDestacados: [],
    allJuegos: [],
    lengthData: 0,
    search: "",
    searchResults: [],
    searchResultsLength: 0
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case EXPLORE_PATROCINADORES_RECIBIDOS:
            return { ...state, patrocinadores: payload };
        case EXPLORE_JUEGOS_PATROCINADORES_RECIBIDOS:
            return { ...state, juegosPatrocinadores: payload };
        case EXPLORE_JUEGOS_DESTACADOS_RECIBIDOS:
            return { ...state, juegosDestacados: payload };
        case EXPLORE_ALL_JUEGOS_RECIBIDOS:
            return { ...state, allJuegos: [...state.allJuegos, ...payload.cards], lengthData: payload.n };
        case EXPLORE_SEARCH_JUEGOS_RECIBIDOS:
            return { ...state, searchResults: payload.cards, searchResultsLength: payload.n };
        case EXPLORE_SEARCH_NEXT_PAGE_JUEGOS_RECIBIDOS:
            return { ...state, searchResults: [...state.searchResults, ...payload.cards], searchResultsLength: payload.n };
        case EXPLORE_RESET_JUEGOS:
            return { ...state, searchResults: [], allJuegos: [],juegosPatrocinadores: [],juegosDestacados: [] };
        default:
            return { ...state };
    }
};
