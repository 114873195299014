import React from "react";

export default ({ id, name, placeholder, value, onChange }) => (
  <div className="w-100">
    <input
      className="w-100 px-3 py-1 border-morado"
      style={{
        borderColor: "rgba(0, 0, 0, 0.50)",
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: 'transparent'
      }}
      placeholder={placeholder}
      type="text"
      value={value}
      onChange={onChange}
      id={id}
      name={name}
      autoComplete="off"
    />
  </div>
);
