import {
  EDIT_PATROCINADOR,
  CREATE_PATROCINADOR,
  SET_PROPIEDAD_PATROCINADOR,
  PATROCINADORES_RECIBIDOS,
  SAVE_PATROCINADOR,
  SHOW_OPCIONES_PATROCINADOR,
  PATROCINADOR_RECIBIDO,
  PREMIOS_RECIBIDOS,
  SEGMENTOS_DISPONIBLES,
  FRASES_RECIBIDAS,
  SHOW_LOGO_PATROCINADOR,
  AGREGAR_LOGO_PATROCINADOR,
  LOGOS_RECIBIDOS,
  SET_LOGO,
  EDIT_LOGO,
  SET_PROPIEDAD_LOGO,
  DELETE_LOGO,
  SAVE_LOGO,
  CLEAR_EDITADO,
  SET_SEGMENTO_PATROCINADOR,
} from "./types";
import AdjuntosService from "../services/AdjuntosService";
import PatrocinadoresService from "../services/PatrocinadoresService";
import {
  displayError,
  displaySuccess,
  formatPatrocinador,
  agregarSegmentos,
  procesarFrases,
  procesarLogos,
  validarSegmentos,
} from "../utils";
import FrasesService from "../services/FrasesService";
import SegmentosService from "../services/SegmentosService";
import moment from "moment";
export const getPatrocinadores = () => (dispatch) => {
  PatrocinadoresService.getPatrocinadores().then((res) => {
    const patrocinadores = res.data;
    dispatch({ type: PATROCINADORES_RECIBIDOS, payload: patrocinadores });
  });
};

export const getPatrocinador = (idPatrocinador) => (dispatch) => {
  PatrocinadoresService.getPatrocinador(idPatrocinador)
    .then((res) => {
      let patrocinador = res.data;
      const { premios, segmentos, frases, logos } = patrocinador;
      patrocinador = agregarSegmentos(patrocinador, segmentos);
      if (patrocinador.fechaVencimiento !== null)
        patrocinador.fechaVencimiento = moment
          .utc(patrocinador.fechaVencimiento)
          .local()
          .format();
      dispatch({ type: PATROCINADOR_RECIBIDO, payload: patrocinador });
      dispatch({ type: PREMIOS_RECIBIDOS, payload: premios });
      dispatch({ type: FRASES_RECIBIDAS, payload: frases });
      dispatch({ type: LOGOS_RECIBIDOS, payload: logos });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const editPatrocinador = (patrocinador) => (dispatch) => {
  dispatch({ type: EDIT_PATROCINADOR, payload: patrocinador });
};

export const createPatrocinador = () => (dispatch) => {
  dispatch({ type: CREATE_PATROCINADOR });
};

export const setPropiedadPatrocinador = (key, value) => (dispatch) => {
  dispatch({ type: SET_PROPIEDAD_PATROCINADOR, payload: { key, value } });
};

export const getSegmentosDisponibles = (idPatrocinador) => (dispatch) => {
  PatrocinadoresService.getSegmentosDisponibles(idPatrocinador)
    .then((res) => {
      const { segmentos } = res.data;
      dispatch({ type: SEGMENTOS_DISPONIBLES, payload: segmentos });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const postPatrocinador = (patrocinador) => (dispatch) => {
  const segmentos = [
    "primaria",
    "secundaria",
    "preparatoria",
    "universidad",
    "profesional",
  ];
  for (let i = 0; i < segmentos.length; i++) {
    if (patrocinador[segmentos[i]] === "") patrocinador[segmentos[i]] = 0;
    if (isNaN(Number(patrocinador[segmentos[i]])))
      return displayError(
        dispatch,
        "El porcentaje de cada segmento debe estar entre 0 y 100."
      );
  }
  if (patrocinador.nombre === "")
    return displayError(
      dispatch,
      "El nombre del patrocinador no puede estar vacío."
    );
  patrocinador = formatPatrocinador(patrocinador);
  if (patrocinador.idPatrocinador === "nuevo") {
    const promises = [];
    if (patrocinador.file !== null) {
      const formData = new FormData();
      formData.append("adjunto", patrocinador.file);
      const promise = AdjuntosService.postAdjunto(formData);
      promise.then((res) => {
        const { idAdjunto } = res.data;
        patrocinador.idAdjunto = idAdjunto;
      });
      promises.push(promise);
    }
    Promise.all(promises).then(() => {
      PatrocinadoresService.postPatrocinador(patrocinador)
        .then((res) => {
          const { idPatrocinador } = res.data;
          patrocinador.idPatrocinador = idPatrocinador;
          patrocinador.file = null;
          dispatch({ type: SAVE_PATROCINADOR, payload: patrocinador });
          displaySuccess(dispatch, "Patrocinador agregado con éxito.");
        })
        .catch((error) => {
          if (error.response.status === 409)
            return displayError(
              dispatch,
              "Ya existe un patrocinador con ese nombre"
            );
          displayError(dispatch, error);
        });
    });
  } else {
    const promises = [];
    if (patrocinador.file) {
      promises.push(
        new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("adjunto", patrocinador.file);
          AdjuntosService.postAdjunto(formData)
            .then((res) => {
              const { idAdjunto } = res.data;
              patrocinador.idAdjunto = idAdjunto;
              patrocinador.file = null;
              resolve();
            })
            .catch((error) => {
              displayError(dispatch, error);
            });
        })
      );
    }
    Promise.all(promises).then(() => {
      PatrocinadoresService.putPatrocinador(
        patrocinador.idPatrocinador,
        patrocinador
      )
        .then((res) => {
          dispatch({ type: SAVE_PATROCINADOR, payload: patrocinador });
        })
        .catch((error) => {
          displayError(dispatch, error);
        });
    });
  }
};

export const showOpcionesPatrocinador = (idPremio) => (dispatch) => {
  dispatch({ type: SHOW_OPCIONES_PATROCINADOR, payload: idPremio });
};

export const deletePatrocinador = (idPatrocinador) => (dispatch) => {
  PatrocinadoresService.deletePatrocinador(idPatrocinador)
    .then((res) => {
      displaySuccess(dispatch, "Patrocinador eliminado con éxito");
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

/**
 * Logos
 */

export const showLogoPatrocinador = (logo) => (dispatch) => {
  dispatch({ type: SHOW_LOGO_PATROCINADOR, payload: logo });
};

export const agregarLogo = (logo) => (dispatch) => {
  dispatch({
    type: AGREGAR_LOGO_PATROCINADOR,
    payload: logo,
  });
};

export const setLogo = (file) => (dispatch) => {
  if (file) {
    dispatch({
      type: SET_LOGO,
      payload: { nombre: file.name, activo: true, file },
    });
  } else {
    dispatch({ type: SET_LOGO });
  }
};

export const editLogo = (logo) => (dispatch) => {
  dispatch({ type: EDIT_LOGO, payload: logo });
};

export const setPropiedadLogo = (key, value, alter) => (dispatch) => {
  if (!value && alter) value = alter;
  if (key === "activo" && !value) {
    dispatch({
      type: SET_PROPIEDAD_PATROCINADOR,
      payload: { key: "idAdjunto", value: null },
    });
  }
  dispatch({ type: SET_PROPIEDAD_LOGO, payload: { key, value } });
};

export const deleteLogo = (idAdjunto) => (dispatch) => {
  dispatch({ type: DELETE_LOGO, payload: idAdjunto });
};

export const saveLogo = (logo) => (dispatch) => {
  dispatch({ type: SAVE_LOGO, payload: logo });
};

export const putPatrocinador = (
  patrocinador,
  frases,
  logos,
  disponibles,
  modifier
) => (dispatch) => {
  const { idPatrocinador } = patrocinador;
  if (patrocinador.desactivar === false) patrocinador.fechaVencimiento = null;
  else if (patrocinador.fechaVencimiento === null) {
    return displayError(
      dispatch,
      "Debe de definir una fecha para desactivar automaticamente o deshabilita esa opción"
    );
  }
  patrocinador = formatPatrocinador(patrocinador);
  const validSegmentos = validarSegmentos(patrocinador, disponibles, modifier);
  if (validSegmentos) {
    SegmentosService.putSegmentos(idPatrocinador, patrocinador.segmentos);
  } else {
    return displayError(
      dispatch,
      "El porcentaje por segmento excede el disponible"
    );
  }
  if (patrocinador.nombre === "") {
    return displayError(
      dispatch,
      "El nombre del patrocinador no puede estar vacío"
    );
  }
  if (logos && logos !== null) {
    const promises = [];
    const { nuevos, editar, eliminar } = procesarLogos(logos);
    if (logos.length > 0) {
      nuevos.forEach((logo) => {
        const promise = new Promise((resolve, reject) => {
          const { file } = logo;
          const formData = new FormData();
          formData.append("adjunto", file);
          AdjuntosService.postAdjunto(formData)
            .then((res) => {
              const { idAdjunto } = res.data;
              logo.idAdjunto = idAdjunto;
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
        promises.push(promise);
      });
    }
    const { idAdjunto } = patrocinador;
    Promise.all(promises).then(() => {
      PatrocinadoresService.putLogos(
        idPatrocinador,
        nuevos,
        editar,
        eliminar,
        idAdjunto
      )
        .then((res) => {})
        .catch((error) => {
          displayError(dispatch, error);
        });
    });
  }
  const { nuevos, editar, eliminar } = procesarFrases(frases);
  FrasesService.putFrases(idPatrocinador, nuevos, editar, eliminar)
    .then((res) => {})
    .catch((error) => {
      displayError(dispatch, error);
    });
  if (patrocinador.fechaVencimiento !== null)
    patrocinador.fechaVencimiento = moment(patrocinador.fechaVencimiento)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
  PatrocinadoresService.putPatrocinador(idPatrocinador, patrocinador)
    .then((res) => {
      displaySuccess(dispatch, "Patrocinador actualizado con éxito");
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const clearEditado = () => (dispatch) => {
  dispatch({ type: CLEAR_EDITADO });
};

export const setPropiedadPatrocinadorTabla = (
  idPatrocinador,
  segmento,
  reducer,
  porcentaje
) => (dispatch) => {
  if (porcentaje !== "") {
    porcentaje = parseInt(porcentaje);
    if (isNaN(porcentaje)) return;
    porcentaje = Math.abs(porcentaje);
  }
  dispatch({
    type: SET_SEGMENTO_PATROCINADOR,
    payload: { idPatrocinador, segmento, porcentaje },
  });
};

export const postSegmentos = (patrocinadores) => (dispatch) => {
  const patrocinadoresActivos = patrocinadores.filter(p => p.activo === 1);
  const segmentos = patrocinadoresActivos.map((patrocinador) => ({
    idPatrocinador: patrocinador.idPatrocinador,
    primaria: patrocinador.segmentos[0].porcentaje,
    secundaria: patrocinador.segmentos[1].porcentaje,
    preparatoria: patrocinador.segmentos[2].porcentaje,
    universidad: patrocinador.segmentos[3].porcentaje,
    profesional: patrocinador.segmentos[4].porcentaje,
  }));
  PatrocinadoresService.putSegmentos(segmentos)
    .then((res) => {
      displaySuccess(dispatch, "Segmentos actualizados con éxito");
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};
