import { BASE_URL } from "../utils";
import axios from "axios";
import AuthService from "./AuthService";
import { navigate } from "@reach/router";
const instance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,

});
let isRefreshing = false;
instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if(error.response &&
        error.response.status === 401 && error.config.url === '/auth' && error.config.method === 'delete'){
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
    if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
    else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'auth/JWT_refresh_failed'
    ) {
        
        //Signout
        return AuthService.signOut().then(() => {
            localStorage.removeItem('user');
            navigate("/login");
            window.location.reload();
            return new Promise((resolve, reject) => {
                reject(error);
            });
        })
        .catch((error) => {
            localStorage.removeItem('user');
            navigate("/login");
            window.location.reload();
            return Promise.reject(error);
        });
    }
    else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === "auth/missing_JWT"
    ) {
        if(isRefreshing){
            return new Promise((resolve, reject) => {
                // in a 100ms time interval checks the store state
                const intervalId = setInterval(() => {
                  // if the state indicates that there is no refresh token request anymore, it clears the time interval and retries the failed API call with updated token data
                  if (!isRefreshing) {
                    clearInterval(intervalId);
                    const config = error.config;
                    axios.request(config).then(response => {
                        resolve(response);
                        }).catch((error) => {
                        reject(error);
                    })
                  }
                }, 100);
              });
        }
        else{
            //Refresh token
            isRefreshing = true;
            return AuthService.refreshToken().then(() => {
                const config = error.config;
                return new Promise((resolve, reject) => {
                    axios.request(config).then(response => {
                        isRefreshing = false;
                        resolve(response);
                    }).catch((error) => {
                        isRefreshing = false;
                        reject(error);
                    })
                });
            })
            .catch((error) => {
                return Promise.reject(error);
            });
        }
    }
    return Promise.reject(error);
});
export default instance;