import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, navigate } from "@reach/router";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";

const PreguntasContainer = SortableContainer(
  ({
    preguntas,
    tipo,
    duplicarPregunta,
    eliminarPregunta,
    reestablecerPregunta,
    idJuego,
  }) => {
    if (preguntas && tipo) {
      return (
        <div>
          {preguntas.map((pregunta, index) => {
            if (String(pregunta.idPregunta)[0] === "d")
              return (
                <PreguntaDeleted
                  key={pregunta.idPregunta}
                  index={index}
                  number={index}
                  pregunta={pregunta}
                  reestablecerPregunta={reestablecerPregunta}
                />
              );
            return (
              <PreguntaLink
                key={pregunta.idPregunta}
                index={index}
                number={index}
                pregunta={pregunta}
                idJuego={idJuego}
                duplicarPregunta={duplicarPregunta}
                eliminarPregunta={eliminarPregunta}
              />
            );
          })}
        </div>
      );
    }
  }
);

const DragHandle = sortableHandle(() => (
  <span className="pr-1">
    <i className="fas fa-grip-lines"></i>
  </span>
));

const PreguntaDeleted = SortableElement(
  ({ reestablecerPregunta, pregunta, number, index }) => {
    return (
      <Row
        key={pregunta.idPregunta}
        className="mt-4"
      >
        <Col xs={12}>
          <span className="text-muted">Pregunta {number + 1} eliminada. </span>
          <Button
            variant="link"
            className="text-muted mx-2 py-0"
            onClick={() => reestablecerPregunta(pregunta.idPregunta)}
          >
            <i className="fa fa-undo"></i>
          </Button>
        </Col>
      </Row>
    );
  }
);

const PreguntaLink = SortableElement(
  ({
    pregunta,
    duplicarPregunta,
    eliminarPregunta,
    idJuego,
    number,
    index,
  }) => {
    return (
      <Row
        key={pregunta.idPregunta}
        className="mt-4"
      >
        <Col xs={7} className="py-2 pr-0">
          <DragHandle />
          <a
            className='create-game-question'
            onClick={async () => {
              await navigate(
                idJuego
                  ? `/editar/${idJuego}/pregunta/${pregunta.idPregunta}`
                  : `/crear/pregunta/${pregunta.idPregunta}`
              );
              document.documentElement.scrollTop = 0;
            }}
          >
            Pregunta {number + 1}
          </a>
        </Col>
        <Col xs={5}>
          <Button
            variant="link"
            className="text-dark px-0"
            onClick={() => duplicarPregunta(pregunta)}
          >
            <i className="far fa-clone pr-2"></i>
          </Button>
          <Button
            variant="link"
            className="text-danger px-0"
            onClick={() => eliminarPregunta(pregunta.idPregunta)}
          >
            <i className="far fa-trash-alt px-0"></i>
          </Button>
        </Col>
      </Row>
    );
  }
);

class SidebarForm extends React.Component {
  async handleClick() {
    this.props.createNuevaPregunta(this.props.preguntas.length + 1);
    await navigate(
      this.props.idJuego
        ? `/editar/${this.props.idJuego}/pregunta/nueva-${
            this.props.preguntas.length + 1
          }`
        : `/crear/pregunta/nueva-${this.props.preguntas.length + 1}`
    );
    document.documentElement.scrollTop = 0;
  }

  renderBoton() {
    if (this.props.tipo) {
      //if (this.props.tipo.toLowerCase() !== "crucigrama")
      if (
        (this.props.tipo.toLowerCase() === "crucigrama" &&
          this.props.preguntas.length < 9) ||
        this.props.tipo.toLowerCase() !== "crucigrama"
      )
        return (
          <Row>
            <Col md={3} xl={2} className="corner-bl">
              <hr />
              <Button
                className="border-red-new"
                onClick={() => this.handleClick()}
                block
              >
                <h5 className="py-2 my-0">
                  Agregar pregunta
                </h5>
              </Button>
            </Col>
          </Row>
        );
    }
  }

  renderPreguntas() {
    if (this.props.tipo && this.props.tipo !== null) {
      return (
        <PreguntasContainer
          idJuego={this.props.idJuego}
          tipo={this.props.tipo}
          preguntas={this.props.preguntas}
          reestablecerPregunta={this.props.reestablecerPregunta}
          duplicarPregunta={this.props.duplicarPregunta}
          eliminarPregunta={this.props.eliminarPregunta}
          onSortEnd={this.props.reordenarPreguntas}
          pressDelay={100}
          useDragHandle
        />
      );
    }
  }

  render() {
    //this.scrollWindow();
    return (
      <div className='sidebar'>
        <div className='sidebar-scroll-div'>
          <Row className="mt-4 align-items-center">
            <Col md={12}>
              <Link
                style={{ color: "#2f2f2f" }}
                to={this.props.idJuego ? `/editar/${this.props.idJuego}` : "/crear"}
              >
                <i className="fas fa-cog pr-1"></i>
                <span>Información general</span>
              </Link>
            </Col>
          </Row>
          {this.renderPreguntas()}
        </div>
        {this.renderBoton()}
      </div>
    );
  }
}

export default SidebarForm;
