import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import logo from "../../assets/logo.png"; //placeholder
import rayas from "../../assets/Rayas.svg";
import asterisco from "../../assets/Asterisco.svg";
import rectangulo from "../../assets/Rectangulo.svg";
import { navigate } from "@reach/router";
import { BASE_URL } from "../../utils";
import moment from "moment";

class TituloJuego extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: undefined,
      nombre: undefined,
      patrocinador: undefined,
    };
  }

  componentDidMount() {
    this.time = moment();
    if (!this.props.apodo) navigate("/jugar");
    window.scrollTo(0, 0);
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
    const time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      label: "Tiempo en Lobby",
      category: "Lobby",
      value: time,
      variable: "seconds",
    });
  }

  renderPatrocinador() {
    if (
      this.props.patrocinador &&
      this.props.patrocinador !== null &&
      this.props.patrocinador.nombre
    ) {
      const src =
        this.props.patrocinador.idAdjunto !== null
          ? `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`
          : null;
      return (
        <Col md={8} className="text-center">
          <h6 className='my-0'>Impulsado por</h6>
          {src !== null && <Image src={src} className="logo-width" />}
          <h6 className='my-0'>{this.props.patrocinador.nombre}</h6>
        </Col>
      );
    }
  }

  render() {
    let icon;
    if (this.props.juego) {
      if (this.props.juego.tipo.toLowerCase() === "crucigrama")
        icon = rectangulo;
      else if (this.props.juego.tipo.toLowerCase() === "ahorcado")
        icon = asterisco;
      else icon = rayas;
    }
    return (
      <Container className='vertical-center' style={{ height: '100vh' }}>
        <Row className="justify-content-center">
          <Col md={12} className="mb-3 text-center">
            <Image
              src={logo}
              className="d-block m-auto"
              style={{ maxWidth: 250 }}
            />
          </Col>
          <Col xs={12} md={8} lg={5} xl={5}>
            <Card className="py-4 px-3 rounded-card shadow mx-auto text-center">
              <Card.Title>
                <Row>
                  <Col>
                    <h1>
                    {icon && (
                      <Image src={icon} style={{ width: 30 }} className="pb-2 mr-2" />
                    )}
                      {this.props.juego !== null ? this.props.juego.titulo : ""}
                    </h1>
                  </Col>
                </Row>
              </Card.Title>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <Row className="text-center justify-content-center">
                    <h3>Jugando como:</h3>
                  </Row>
                  <Row className="mb-3 text-center justify-content-center">
                    <h3>{this.props.apodo}</h3>
                  </Row>
                  <Row className='justify-content-center'>
                    {this.renderPatrocinador()}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TituloJuego;
