import {
  PIN_RECIBIDO,
  SET_APODO,
  INICIAR_JUEGO,
  PREGUNTA_RECIBIDA,
  FEEDBACK_RECIBIDO,
  MONGOID_RECIBIDO,
  NEXT,
  JUGADOR_RECIBIDO,
  OPCIONES_RECIBIDAS,
  RESPUESTA_RECIBIDA,
  SCOREBOARD_RECIBIDO,
  SET_COUNTER,
  SET_SCORE,
  SET_HORA_SERVER,
  SET_ID_JUEGO,
  SET_ID_SESION,
  CLEAR_JUEGO,
  SET_DATOS_JUEGO,
  SET_LUGAR,
  SET_PATROCINADOR_JUEGO,
  ENABLE_REPORTE,
  DISABLE_REPORTE,
  FINISHED_JUEGO,
} from "./types";
import JugarService from "../services/JugarService";
import { displayError, displaySuccess } from "../utils";
import { navigate } from "@reach/router";
import PremiosService from "../services/PremiosService";

export const setJuego = (idJuego) => (dispatch) => {
  dispatch({ type: SET_ID_JUEGO, payload: idJuego });
};

export const hostJuego = (idJuego) => (dispatch) => {
  JugarService.hostJuego(idJuego)
    .then((res) => {
      const { pin, juego, idSesion, patrocinador } = res.data;
      dispatch({ type: PIN_RECIBIDO, payload: { pin, juego, idSesion } });
      dispatch({ type: SET_PATROCINADOR_JUEGO, payload: patrocinador });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 412) {
          navigate("/");
          return displayError(
            dispatch,
            "Por el momento no se puede crear este tipo de ULU"
          );
        }
      }
      displayError(dispatch, error);
    });
};

export const setDatosJuego = (juego) => (dispatch) => {
  dispatch({ type: SET_DATOS_JUEGO, payload: juego });
};

export const setPatrocinador = (patrocinador) => (dispatch) => {
  dispatch({ type: SET_PATROCINADOR_JUEGO, payload: patrocinador });
};

export const finishJuego = (idSesion, tipo) => (dispatch) => {
  if (!idSesion) return;
  dispatch({ type: DISABLE_REPORTE });
  dispatch({ type: FINISHED_JUEGO });
  JugarService.finishJuego(idSesion, tipo)
    .then((res) => {
      dispatch({ type: ENABLE_REPORTE });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status !== 404) {
          displayError(dispatch, error);
        }
      }
    });
};

export const clearJuego = () => (dispatch) => {
  dispatch({ type: CLEAR_JUEGO });
};

export const joinJuego = (pin) => (dispatch) => {
  dispatch({ type: PIN_RECIBIDO, payload: { pin } });
};

export const iniciarJuego = () => (dispatch) => {
  dispatch({ type: INICIAR_JUEGO });
};

export const setApodo = (apodo) => (dispatch) => {
  dispatch({ type: SET_APODO, payload: apodo });
};

export const setIdSesion = (idSesion) => (dispatch) => {
  dispatch({ type: SET_ID_SESION, payload: idSesion });
};

export const setPregunta = (pregunta) => (dispatch) => {
  dispatch({ type: PREGUNTA_RECIBIDA, payload: pregunta });
};

export const setOpciones = (opciones) => (dispatch) => {
  dispatch({ type: OPCIONES_RECIBIDAS, payload: opciones });
};

export const setFeedback = (puntos, mensaje, idRespuesta) => (dispatch) => {
  dispatch({
    type: FEEDBACK_RECIBIDO,
    payload: { puntos, mensaje, idRespuesta },
  });
};

export const setMongoID = (mongoID) => (dispatch) => {
  dispatch({ type: MONGOID_RECIBIDO, payload: mongoID });
};

export const next = () => (dispatch) => {
  dispatch({ type: NEXT });
};

export const agregarJugador = (jugador) => (dispatch) => {
  dispatch({ type: JUGADOR_RECIBIDO, payload: jugador });
};

export const agregarRespuesta = (apodo, puntos, idOpcion) => (dispatch) => {
  dispatch({ type: RESPUESTA_RECIBIDA, payload: { apodo, idOpcion, puntos } });
};

export const setScoreboard = (scoreboard) => (dispatch) => {
  dispatch({ type: SCOREBOARD_RECIBIDO, payload: scoreboard });
};

export const setScore = (puntos) => (dispatch) => {
  dispatch({ type: SET_SCORE, payload: puntos });
};

export const setCounter = (segundos) => (dispatch) => {
  dispatch({ type: SET_COUNTER, payload: segundos });
};

export const setHoraServer = (horaServer) => (dispatch) => {
  dispatch({ type: SET_HORA_SERVER, payload: horaServer });
};

export const setLugar = (lugar, puntos) => (dispatch) => {
  dispatch({ type: SET_LUGAR, payload: { lugar, puntos } });
};

export const enviarPremio = ({
  mail,
  apodo,
  idPatrocinador,
  idSesion
}) => (dispatch) => {
  PremiosService.enviarPremio({
      mail,
      apodo,
      idPatrocinador,
      idSesion
  })
  .then((res) => {
      displaySuccess(dispatch, "Premio enviado con éxito");
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const enviarSugerencias = ({
  calificacion,
  recomendacion,
  sugerencias,
  idSesion,
  apodo 
}) => dispatch => {
  JugarService.enviarSugerencias({
    calificacion,
    recomendacion,
    sugerencias,
    idSesion,
    apodo 
  }).then((res) => {
    
  })
  .catch((error) => {
    displayError(dispatch, error);
  });
}