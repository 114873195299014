import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CardJuego from "../components/CardJuego";
import { confirm, alert } from "../actions/modalActions";
import { getJuegos, setEstado, getPatrocinios, setPatrocinio } from "../actions/configuracionJuegosActions";
import { connect } from "react-redux";
import { isAdmin } from '../actions/adminActions';
class ConfiguracionJuegos extends Component {
  constructor(props) {
    super(props);
    this.confirmarJuego = this.confirmarJuego.bind(this);
    this.confirmarPatrocinio = this.confirmarPatrocinio.bind(this);
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.getJuegos();
    this.props.getPatrocinios();
    window.scrollTo(0, 0);
  }

  confirmarJuego(nombre) {
    let juego = this.props.juegos.find(juego => juego.nombre === nombre);
    this.props.confirm(
      `¿Seguro que quieres ${
        juego.activo ? "desactivar" : "activar"
      } los juegos de: ${juego.nombre[0].toUpperCase()}${juego.nombre.slice(1)}? ${
        juego.activo
          ? "Ningún usuario podrá ver ni jugar los juegos que desactives."
          : "Todos los usuarios podrán ver y jugar los juegos activos."
      }`,
      () => this.props.setEstado(nombre, !juego.activo)
    );
  }

  confirmarPatrocinio() {
    this.props.confirm(
      `¿Seguro que quieres ${
        this.props.patrocinios ? "desactivar" : "activar"
      } los patrocinios? ${
        this.props.patrocinios
          ? "Ningún usuario podrá ver ni jugar los juegos que desactives."
          : "Todos los usuarios podrán ver y jugar los juegos activos."
      }`,
      () => this.props.setPatrocinio(!this.props.patrocinios)
    );
  }

  renderJuegos() {
    if(this.props.juegos && this.props.juegos !== null)
    return this.props.juegos.map((juego) => (
      <CardJuego
        toggleJuegoActivo={this.confirmarJuego}
        juego={juego}
        key={juego.nombre}
        icon={juego.icon}
        color={juego.color}
        width="100px"
        height="100px"
        fontClass="h4 my-0"
        bottomContainerColor={juego.activo}
        activo={juego.activo}
        admin
      />
    ));
  }

  renderPatrocinio() {
    if(this.props.patrocinios !== null)
    return (
      <CardJuego
        togglePatrociniosActivos={this.confirmarPatrocinio}
        key={4}
        width="100px"
        height="100px"
        fontClass="h4 my-0"
        bottomContainerColor={this.props.patrocinios}
        activo={this.props.patrocinios}
        patrocinios
        admin
      />
    );
  }

  render() {
    if(this.props.loadingAdmin){
      return <></>
    }
    return (
      <Container fluid className="no-bg-img">
        <Row>
          <Col>
            <h1 className="pl-2 pt-4">
              <i className="fas fa-cog"></i>
              <span className="pl-3">Configuración de juegos</span>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="h4 pt-4 pl-2">Activar / desactivar juegos</p>
          </Col>
        </Row>
        <Row>{this.renderJuegos()}</Row>
        <Row>
          <Col>
            <p className="h4 pt-4 pl-2">Activar / desactivar patrocinios</p>
          </Col>
        </Row>
        <Row>{this.renderPatrocinio()}</Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  juegos: state.configuracion.juegos,
  patrocinios: state.configuracion.patrocinios,
  loadingAdmin: state.admin.loadingAdmin
});

export default connect(mapStateToProps, {
  alert,
  confirm,
  getJuegos,
  setEstado,
  setPatrocinio,
  getPatrocinios,
  isAdmin
})(ConfiguracionJuegos);
