import React, { Component } from "react";
import { Card, Col, Row, Image } from "react-bootstrap";
import asterisco from "../assets/Asterisco.svg"; // Sí, sí sé que hay un file icons.js
import rayas from "../assets/Rayas.svg"; // No, no lo usé porque no se querían resize bien
import rectangulo from "../assets/Rectangulo.svg"; // Esta fue la única solución que encontre
import estrella from "../assets/Estrella.svg"; // Porque si no, o se veia mal aquí o en HintPalabra
import { navigate } from "@reach/router";
import { getColor, getColorName } from "../utils";
import billete from "../assets/money-bill-wave-solid.svg";
import asteriscoOutline from "../assets/Asterisco_outline.svg";
import rayasOutline from "../assets/Rayas_outline.svg";
import rectanguloOutline from "../assets/Rectangulo_outline.svg";
import estrellaOutline from "../assets/Estrella_outline.svg";
import billeteOutline from "../assets/Billete_outline.svg";

class CardJuego extends Component {
  seleccionarJuego() {
    if (!this.props.activo && !this.props.admin) {
      this.props.alert("Por el momento no se puede crear este tipo de ULU");
    } else if (this.props.seleccionarJuego) {
      this.props.seleccionarJuego(this.props.juego.nombre);
      navigate("/crear");
    }
    if (this.props.toggleJuegoActivo) {
      this.props.toggleJuegoActivo(this.props.juego.nombre);
    }
    if (this.props.togglePatrociniosActivos) {
      this.props.togglePatrociniosActivos();
    }
  }

  renderIcon(icon) {
    switch (icon) {
      case "Asterisco":
        return (
          <Image
            src={this.props.activo ? asterisco : asteriscoOutline}
            style={{/*
              width: this.props.width ? this.props.width : "10vw",
              height: this.props.height ? this.props.height : "10vw",
            */}}
            className="mb-2 card-juego-icon"
          />
        );
      case "Rayas":
        return (
          <Image
            src={this.props.activo ? rayas : rayasOutline}
            style={{/*
              width: this.props.width ? this.props.width : "10vw",
              height: this.props.height ? this.props.height : "10vw",
            */}}
            className="mb-2 card-juego-icon"
          />
        );
      case "Rectangulo":
        return (
          <Image
            src={this.props.activo ? rectangulo : rectanguloOutline}
            style={{/*
              width: this.props.width ? this.props.width : "10vw",
              height: this.props.height ? this.props.height : "10vw",
            */}}
            className="mb-2 card-juego-icon"
          />
        );
      case "Billete":
        return (
          <Image
            src={this.props.activo ? billete : billeteOutline}
            style={{
              width: this.props.width ? this.props.width : "10vw",
              height: this.props.height ? this.props.height : "10vw",
            }}
            className="mb-2"
          />
        );
      default:
        return (
          <Image
            src={this.props.activo ? estrella : estrellaOutline}
            style={{
              width: this.props.width ? this.props.width : "10vw",
              height: this.props.height ? this.props.height : "10vw",
            }}
            className="mb-2"
          />
        );
    }
  }

  renderCard() {
    let icon;
    let color;
    let hex;
    if (this.props.juego) {
      icon = this.props.juego.icon;
      let tipo = this.props.juego.tipo;
      if (!tipo) tipo = this.props.juego.nombre;
      tipo = tipo.toLowerCase();
      color = getColorName(
        tipo === "ahorcado"
          ? 4
          : tipo === "trivia"
          ? 3
          : tipo === "crucigrama"
          ? 1
          : 2
      );
      hex = getColor(
        tipo === "ahorcado"
          ? 4
          : tipo === "trivia"
          ? 3
          : tipo === "crucigrama"
          ? 1
          : 2
      );
      if (!icon) {
        icon =
          tipo === "ahorcado"
            ? "Asterisco"
            : tipo === "trivia"
            ? "Rayas"
            : tipo === "crucigrama"
            ? "Rectangulo"
            : "";
      }
    } else if (this.props.patrocinios) {
      icon = "Billete";
      color = "gray";
      hex = "#2f2f2f";
    }

    return (
      <Card
        className={`rounded-card-${color} aspect-ratio-11 ${
          this.props.activo ? '' : "thin-border"
        }`}
        style={{ borderColor: hex }}
        onClick={() => this.seleccionarJuego()}
      >
        <Card.Body className='py-auto px-0 card-juego vertical-center'>
          <Row className="text-center my-0">
            <Col md={12}
              className={`pb-4 my-0 ${
                this.props.activo ? `rounded-top-${color}` : "rounded-top"
              }`}
            >
              <div>{this.renderIcon(icon)}</div>
            </Col>
          </Row>
          <Row className="text-center my-0 pt-2">
            <Col md={12}
              className={`rounded-bottom ${
                this.props.bottomContainerColor && this.props.activo
                  ? `rounded-bottom-${color}`
                  : ""
              }`}
            >
              <p
                className={`${this.props.activo ? "" : "text-dark"} ${
                  this.props.fontClass ? this.props.fontClass : "h2"
                }`}
              >
                <b>
                  {this.props.juego
                    ? this.props.juego.nombre[0].toUpperCase()
                    : "P"}
                  {this.props.juego
                    ? this.props.juego.nombre.slice(1)
                    : "atrocinios"}
                </b>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  render() {
    return (
      <Col xs={6} sm={4} md={3} xl={2} className="mb-2">
        {this.renderCard()}
      </Col>
    );
  }
}

export default CardJuego;
