
import React from 'react';

const Button = (props) => {
    return(
        <button 
            type={props.type ? props.type : 'button'}
            className={`button ${props.block && 'block'} ${props.variant === 'primary' ? 'primary' : 'secondary'} ${props.className && props.className}`}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            <p className={`${props.size === 'lg' && 'h5'} ${props.size === 'md' && ''} ${(!props.size || props.size === 'sm') && 'text-smaller'}`}><b>{props.label}</b></p>
        </button>
    );
}

export default Button;