import React from "react";

export const Asterisco = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 298.971 298.969"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="298.971" height="298.97" />
      </clipPath>
    </defs>
    <path
      d="M2220.774,1570.745a38.366,38.366,0,0,0-38.294-38.453l-18.46-.033,13.071-13.024a38.38,38.38,0,0,0-54.174-54.38l-13.082,13.035.033-18.462a38.38,38.38,0,0,0-76.76-.146l-.033,18.465-13.028-13.074a38.38,38.38,0,0,0-54.381,54.174l13.033,13.08-18.455-.033a38.381,38.381,0,0,0-.146,76.761l18.47.032-13.077,13.031a38.38,38.38,0,0,0,54.174,54.381l13.078-13.032-.032,18.451a38.38,38.38,0,0,0,76.759.146l.033-18.464,13.026,13.073a38.38,38.38,0,0,0,54.381-54.174l-13.033-13.08,18.457.033A38.372,38.372,0,0,0,2220.774,1570.745Z"
      transform="translate(-1921.804 -1420.988)"
      fill={props.color}
    />
  </svg>
);

export const Estrella = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 309.307 298.59"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="309.306" height="298.591" />
      </clipPath>
    </defs>
    <g transform="translate(-1916.636 -987.604)">
      <path
        d="M2111.751,1012.756l12.835,26.007a45.124,45.124,0,0,0,33.974,24.683l28.7,4.17c37.01,5.378,51.788,50.86,25.008,76.964l-20.768,20.243a45.121,45.121,0,0,0-12.976,39.939l4.9,28.583c6.322,36.86-32.367,64.97-65.469,47.566l-25.67-13.495a45.12,45.12,0,0,0-41.994,0l-25.669,13.495c-33.1,17.4-71.792-10.706-65.47-47.566l4.9-28.583a45.124,45.124,0,0,0-12.977-39.939l-20.767-20.243c-26.781-26.1-12-71.586,25.007-76.964l28.7-4.17a45.124,45.124,0,0,0,33.974-24.683l12.835-26.007C2047.378,979.22,2095.2,979.22,2111.751,1012.756Z"
        fill={props.color}
      />
    </g>
  </svg>
);

export const Circulo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 313.814 313.814"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="313.814" height="313.814" />
      </clipPath>
    </defs>
    <g transform="translate(-2762.092 -979.993)">
      <path
        d="M2919,1293.806c-86.517,0-156.9-70.387-156.9-156.9s70.387-156.909,156.9-156.909,156.91,70.387,156.91,156.909S3005.518,1293.806,2919,1293.806Zm0-243.927a87.02,87.02,0,1,0,87.023,87.023A87.12,87.12,0,0,0,2919,1049.879Z"
        fill={props.color}
      />
    </g>
  </svg>
);

export const Hexagono = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 318.73 298.979"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="318.731" height="298.979" />
      </clipPath>
    </defs>
    <path
      d="M2641.951,1943.836l-36.858-63.841a85.649,85.649,0,0,0-74.174-42.824H2457.2a85.645,85.645,0,0,0-74.173,42.824l-36.859,63.841a85.647,85.647,0,0,0,0,85.648l36.859,63.842a85.647,85.647,0,0,0,74.173,42.824h73.717a85.651,85.651,0,0,0,74.174-42.824l36.858-63.842A85.645,85.645,0,0,0,2641.951,1943.836Z"
      transform="translate(-2334.695 -1837.171)"
      fill={props.color}
    />
  </svg>
);

export const Pin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 260.576 290.457"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="260.576" height="290.456" />
      </clipPath>
    </defs>
    <path
      d="M2494.061,1425.245h0c-115.841,0-173.855,140.058-91.943,221.97l51.9,51.9a56.631,56.631,0,0,0,80.087,0l51.9-51.9C2667.917,1565.3,2609.9,1425.245,2494.061,1425.245Z"
      transform="translate(-2363.773 -1425.245)"
      fill={props.color}
    />
  </svg>
);

export const Rayas = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 282.307 263.994"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="282.307" height="263.995" />
      </clipPath>
    </defs>
    <path
      d="M2212.442,1986.972A43.872,43.872,0,0,0,2170.4,1943a43.96,43.96,0,0,0-1.852-87.878l-194.36-.456a43.961,43.961,0,0,0-2.021,87.88,43.931,43.931,0,0,0,0,87.781,43.96,43.96,0,0,0,1.854,87.878l194.36.456a43.96,43.96,0,0,0,2.017-87.88A43.942,43.942,0,0,0,2212.442,1986.972Z"
      transform="translate(-1930.136 -1854.663)"
      fill={props.color}
    />
  </svg>
);

export const Rectangulo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    viewBox="0 0 298.969 298.971"
    {...props}
  >
    <defs>
      <clipPath id="b">
        <rect width="298.969" height="298.97" />
      </clipPath>
    </defs>
    <path
      d="M2643.545,1026.265v-.411a38.107,38.107,0,0,0-43.533-38.051l-216.571-.388h-.426a38.1,38.1,0,0,0-38.051,43.532l-.388,216.572v.426A38.106,38.106,0,0,0,2388.109,1286l216.578.388h.419a38.107,38.107,0,0,0,38.051-43.533l.388-216.571Zm-221.88,37.979,145.051.259-.259,145.052-145.052-.26Z"
      transform="translate(-2344.576 -987.414)"
      fill={props.color}
    />
  </svg>
);

export const Billete = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="money-bill-wave"
    className="svg-inline--fa fa-money-bill-wave fa-w-20"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M621.16 54.46C582.37 38.19 543.55 32 504.75 32c-123.17-.01-246.33 62.34-369.5 62.34-30.89 0-61.76-3.92-92.65-13.72-3.47-1.1-6.95-1.62-10.35-1.62C15.04 79 0 92.32 0 110.81v317.26c0 12.63 7.23 24.6 18.84 29.46C57.63 473.81 96.45 480 135.25 480c123.17 0 246.34-62.35 369.51-62.35 30.89 0 61.76 3.92 92.65 13.72 3.47 1.1 6.95 1.62 10.35 1.62 17.21 0 32.25-13.32 32.25-31.81V83.93c-.01-12.64-7.24-24.6-18.85-29.47zM48 132.22c20.12 5.04 41.12 7.57 62.72 8.93C104.84 170.54 79 192.69 48 192.69v-60.47zm0 285v-47.78c34.37 0 62.18 27.27 63.71 61.4-22.53-1.81-43.59-6.31-63.71-13.62zM320 352c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 27.78c-17.52-4.39-35.71-6.85-54.32-8.44 5.87-26.08 27.5-45.88 54.32-49.28v57.72zm0-236.11c-30.89-3.91-54.86-29.7-55.81-61.55 19.54 2.17 38.09 6.23 55.81 12.66v48.89z"
    ></path>
  </svg>
);

export const Graduado = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="graduation-cap"
    className="svg-inline--fa fa-graduation-cap fa-w-20"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M622.34 153.2L343.4 67.5c-15.2-4.67-31.6-4.67-46.79 0L17.66 153.2c-23.54 7.23-23.54 38.36 0 45.59l48.63 14.94c-10.67 13.19-17.23 29.28-17.88 46.9C38.78 266.15 32 276.11 32 288c0 10.78 5.68 19.85 13.86 25.65L20.33 428.53C18.11 438.52 25.71 448 35.94 448h56.11c10.24 0 17.84-9.48 15.62-19.47L82.14 313.65C90.32 307.85 96 298.78 96 288c0-11.57-6.47-21.25-15.66-26.87.76-15.02 8.44-28.3 20.69-36.72L296.6 284.5c9.06 2.78 26.44 6.25 46.79 0l278.95-85.7c23.55-7.24 23.55-38.36 0-45.6zM352.79 315.09c-28.53 8.76-52.84 3.92-65.59 0l-145.02-44.55L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.18-113.47-145.03 44.56z"
    ></path>
  </svg>
);

export const Boton = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="power-off"
    className="svg-inline--fa fa-power-off fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
    ></path>
  </svg>
);

export const Rueda = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="cog"
    className="svg-inline--fa fa-cog fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
    ></path>
  </svg>
);

export const Comentario = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="comment-alt"
    className="svg-inline--fa fa-comment-alt fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z"
    ></path>
  </svg>
);

export const Trofeo = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="trophy"
    className="svg-inline--fa fa-trophy fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z"
    ></path>
  </svg>
);

export const Usuario = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="user"
    className="svg-inline--fa fa-user color-000 fa-w-14" // es el unico icon que no cambia de color
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
    ></path>
  </svg>
);

export const Candado = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="lock"
    className="svg-inline--fa fa-lock fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
    ></path>
  </svg>
);

export const Campana = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="bell"
    className="svg-inline--fa fa-bell fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
    ></path>
  </svg>
);

export const Red = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="share-alt"
    className="svg-inline--fa fa-share-alt fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"
    ></path>
  </svg>
);

export const Moneda = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 298.971 298.969"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <defs>
      <clipPath id="clip-ulu_coins">
        <rect width="298.971" height="298.97" />
      </clipPath>
    </defs>
    <g id="ulu_coins" data-name="ulu coins" clipPath="url(#clip-ulu_coins)">
      <g
        id="Group_1466"
        data-name="Group 1466"
        transform="translate(-1449.638 -947.643)"
      >
        <path
          id="Subtraction_103"
          data-name="Subtraction 103"
          d="M146.145,292.276a147.229,147.229,0,0,1-29.453-2.969,145.347,145.347,0,0,1-52.258-21.989,146.566,146.566,0,0,1-52.949-64.3A145.4,145.4,0,0,1,2.969,175.59a147.556,147.556,0,0,1,0-58.9,145.332,145.332,0,0,1,21.99-52.255,146.57,146.57,0,0,1,64.3-52.946,145.411,145.411,0,0,1,27.433-8.515,147.574,147.574,0,0,1,58.906,0,145.346,145.346,0,0,1,52.257,21.989,146.565,146.565,0,0,1,52.949,64.3,145.4,145.4,0,0,1,8.516,27.431,147.556,147.556,0,0,1,0,58.9,145.334,145.334,0,0,1-21.99,52.255,146.569,146.569,0,0,1-64.3,52.947,145.415,145.415,0,0,1-27.433,8.515A147.227,147.227,0,0,1,146.145,292.276Zm0-225.279a22.52,22.52,0,0,0-16.842,7.5,24.461,24.461,0,0,0-3.863,5.834l-6.57,13.786a23.166,23.166,0,0,1-17.39,13.085L86.8,109.414a22.981,22.981,0,0,0-6.453,1.959,22.376,22.376,0,0,0-5.31,3.535A24.123,24.123,0,0,0,68.283,125.3a24.913,24.913,0,0,0-.774,12.9,23.775,23.775,0,0,0,2.276,6.313A24.323,24.323,0,0,0,74,150.217l10.632,10.732a24.452,24.452,0,0,1,6.641,21.173l-2.509,15.155a24.623,24.623,0,0,0,.572,10.862,23.888,23.888,0,0,0,12.558,14.934,22.011,22.011,0,0,0,20.376-.581l13.137-7.156a22.382,22.382,0,0,1,21.49,0l13.139,7.156a22.01,22.01,0,0,0,20.373.581,23.893,23.893,0,0,0,12.56-14.933,24.632,24.632,0,0,0,.575-10.864l-2.51-15.155a24.456,24.456,0,0,1,6.643-21.173L218.3,150.217a24.325,24.325,0,0,0,4.213-5.707,23.773,23.773,0,0,0,2.276-6.313,24.913,24.913,0,0,0-.774-12.9,24.122,24.122,0,0,0-6.751-10.394,22.376,22.376,0,0,0-5.31-3.535,22.981,22.981,0,0,0-6.453-1.959l-14.691-2.21a23.164,23.164,0,0,1-17.389-13.085l-6.57-13.786a24.461,24.461,0,0,0-3.864-5.834A22.521,22.521,0,0,0,146.149,67Z"
          transform="translate(1452.494 950.498)"
          fill="#2f2f2f"
        />
      </g>
    </g>
  </svg>
);

export const Interrogacion = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="question-circle"
    className="svg-inline--fa fa-question-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
    ></path>
  </svg>
);

export const Ojo = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="eye"
    className="svg-inline--fa fa-eye fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
    ></path>
  </svg>
);

export const Basurero = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="trash-alt"
    className="svg-inline--fa fa-trash-alt fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    {...props}
  >
    <path
      fill="currentColor"
      d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
    ></path>
  </svg>
);

export const SadFace = (props) =>  (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    className={props.className}
    viewBox="0 0 304 232"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_2340" data-name="Rectangle 2340" width="304" height="232" transform="translate(154 67)" fill="#fff" stroke="#707070" stroke-width="1"/>
      </clipPath>
      <clipPath id="clip-Triste_negra">
        <rect width="304" height="232"/>
      </clipPath>
    </defs>
    <g id="Triste_negra" data-name="Triste negra" clip-path="url(#clip-Triste_negra)">
      <g id="Mask_Group_39" data-name="Mask Group 39" transform="translate(-154 -67)" clip-path="url(#clip-path)">
        <g id="Group_1597" data-name="Group 1597" transform="translate(153.994 66.999)">
          <g id="Group_1595" data-name="Group 1595">
            <g id="Group_1594" data-name="Group 1594">
              <g id="Group_1592" data-name="Group 1592">
                <path id="Path_418" data-name="Path 418" d="M3252.659,649.87h-16.5l-8.86,52.45H3195.32l14.18-83.987h69.816L3293.5,702.32h-31.979Z" transform="translate(-3195.32 -618.334)" fill="#2f2f2f"/>
                <path id="Path_419" data-name="Path 419" d="M3423.474,649.87h-16.5l-8.859,52.45h-31.979l14.18-83.987h69.816l14.181,83.987h-31.98Z" transform="translate(-3193.01 -618.334)" fill="#2f2f2f"/>
                <path id="Path_420" data-name="Path 420" d="M3312.433,769.217a3,3,0,0,0,3,3h68.55V740.677h-37.9a2.113,2.113,0,0,1-2.114-2.112V618.334h-31.534Z" transform="translate(-3193.516 -618.334)" fill="#2f2f2f"/>
              </g>
              <g id="Group_1593" data-name="Group 1593" transform="translate(96.662 205.243) rotate(-40)">
                <path id="Path_421" data-name="Path 421" d="M0,31.536V0C68.669,0,125.793,50.251,136.606,115.9H104.492A107.094,107.094,0,0,0,0,31.536Z" transform="translate(0 0)" fill="#2f2f2f"/>
              </g>
            </g>
          </g>
          <g id="Group_1596" data-name="Group 1596" transform="translate(278.418 0.001)">
            <path id="Path_422" data-name="Path 422" d="M2805.922,631.36a12.946,12.946,0,0,1,13.047-13.026,13.01,13.01,0,1,1-13.047,13.026Zm24.323-.07a11.313,11.313,0,1,0-11.346,11.572A11.322,11.322,0,0,0,2830.245,631.29Zm-16.1-7.063h4.789c3.081,0,5.21,1.242,5.21,4.116v.074a3.654,3.654,0,0,1-3.083,3.761l3.9,6.071h-2.8l-3.651-5.643h-1.7v5.643h-2.661Zm4.753,6.5c1.7,0,2.585-.745,2.585-2.2v-.1c0-1.563-.955-2.133-2.585-2.133h-2.093v4.44Z" transform="translate(-2805.922 -618.334)" fill="#2f2f2f"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const HappyFace = (props) =>  (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    height={
      props.style ? (props.style.width ? props.style.width : "2vw") : "2vw"
    }
    className={props.className}
    viewBox="0 0 270 230"
  >
      <defs>
          <clipPath id="b">
              <rect width="270" height="230"/>
          </clipPath>
      </defs>
      <g id="a" clipPath="url(#b)">
          <g transform="translate(-3195 -618)">
              <g transform="translate(3195.32 618.334)">
                  <g transform="translate(-3195.32 -618.334)" fill={'var(--ulu-red)'}>
                      <path d="M3251.111,649.018h-16.055l-8.62,51.034H3195.32l13.8-81.719h67.931l13.8,81.719h-31.116Z" stroke="none"/>
                      <path d="M 3213.3447265625 623.3336181640625 L 3201.23486328125 695.0523681640625 L 3222.20947265625 695.0523681640625 L 3230.1259765625 648.185302734375 L 3230.829833984375 644.01806640625 L 3235.05615234375 644.01806640625 L 3251.111328125 644.01806640625 L 3255.337646484375 644.01806640625 L 3256.04150390625 648.185302734375 L 3263.9580078125 695.0523681640625 L 3284.9326171875 695.0523681640625 L 3272.82275390625 623.3336181640625 L 3213.3447265625 623.3336181640625 M 3209.1181640625 618.3336181640625 L 3277.04931640625 618.3336181640625 L 3290.84765625 700.0523681640625 L 3259.731689453125 700.0523681640625 L 3251.111328125 649.01806640625 L 3235.05615234375 649.01806640625 L 3226.435791015625 700.0523681640625 L 3195.31982421875 700.0523681640625 L 3209.1181640625 618.3336181640625 Z" stroke="none" fill="#ff3833"/>
                  </g>
                  <g transform="translate(-3196.048 -618.334)" fill={'var(--ulu-red)'}>
                      <path d="M3421.926,649.018h-16.055l-8.62,51.034h-31.116l13.8-81.719h67.931l13.8,81.719h-31.116Z" stroke="none"/>
                      <path d="M 3384.1591796875 623.3336181640625 L 3372.04931640625 695.0523681640625 L 3393.024169921875 695.0523681640625 L 3400.9404296875 648.185302734375 L 3401.644287109375 644.01806640625 L 3405.87060546875 644.01806640625 L 3421.926025390625 644.01806640625 L 3426.15234375 644.01806640625 L 3426.856201171875 648.185302734375 L 3434.7724609375 695.0523681640625 L 3455.7470703125 695.0523681640625 L 3443.63720703125 623.3336181640625 L 3384.1591796875 623.3336181640625 M 3379.9326171875 618.3336181640625 L 3447.86376953125 618.3336181640625 L 3461.662109375 700.0523681640625 L 3430.546142578125 700.0523681640625 L 3421.926025390625 649.01806640625 L 3405.87060546875 649.01806640625 L 3397.25048828125 700.0523681640625 L 3366.13427734375 700.0523681640625 L 3379.9326171875 618.3336181640625 Z" stroke="none" fill="#ff3833"/>
                  </g>
                  <g transform="translate(-3195.753 -618.334)" fill={'var(--ulu-red)'}>
                      <path d="M3312.433,765.143a2.916,2.916,0,0,0,2.916,2.916h66.7V737.374h-36.875a2.056,2.056,0,0,1-2.056-2.055V618.334h-30.684Z" stroke="none"/>
                      <path d="M 3377.048095703125 763.0590209960938 L 3377.048095703125 742.3740234375 L 3345.173095703125 742.3740234375 C 3341.2822265625 742.3740234375 3338.116943359375 739.2091674804688 3338.116943359375 735.3189697265625 L 3338.116943359375 623.333984375 L 3317.43310546875 623.333984375 L 3317.43310546875 763.0590209960938 L 3377.048095703125 763.0590209960938 M 3382.048095703125 768.0590209960938 L 3315.34912109375 768.0590209960938 C 3313.738037109375 768.0590209960938 3312.43310546875 766.7529907226562 3312.43310546875 765.1430053710938 L 3312.43310546875 618.333984375 L 3343.116943359375 618.333984375 L 3343.116943359375 735.3189697265625 C 3343.116943359375 736.4539794921875 3344.0380859375 737.3740234375 3345.173095703125 737.3740234375 L 3382.048095703125 737.3740234375 L 3382.048095703125 768.0590209960938 Z" stroke="none" fill="#ff3833"/>
                  </g>
                  <g transform="translate(95.689 205.61) rotate(-40)">
                      <g transform="translate(0)" fill={'var(--ulu-red)'}>
                          <path d="M0,30.684V0C66.815,0,122.4,48.894,132.917,112.775H101.671A104.2,104.2,0,0,0,0,30.684Z" stroke="none"/><path d="M 4.999992370605469 5.094619750976562 L 4.999992370605469 25.79719543457031 C 16.03659820556641 26.29682159423828 26.84526824951172 28.45244598388672 37.19815826416016 32.22393798828125 C 48.70285034179688 36.4150390625 59.36737060546875 42.49295806884766 68.89546966552734 50.28889465332031 C 78.33866119384766 58.01533126831055 86.37401580810547 67.18810272216797 92.77835083007812 77.55239868164062 C 98.59564971923828 86.96672058105469 102.9096755981445 97.11573028564453 105.6295318603516 107.7743759155273 L 126.8897857666016 107.7745056152344 C 124.1314010620117 94.76100921630859 119.3908309936523 82.30745697021484 112.7623901367188 70.68022155761719 C 109.0637435913086 64.19230651855469 104.7700958251953 57.9761848449707 100.0007171630859 52.20455932617188 C 95.25132751464844 46.45714569091797 89.97344970703125 41.08168792724609 84.31365966796875 36.22750091552734 C 78.6341552734375 31.35639190673828 72.50201416015625 26.95193481445312 66.08761596679688 23.13645935058594 C 59.60230255126953 19.27883148193359 52.74588775634766 15.97445678710938 45.70882415771484 13.31513977050781 C 32.65915679931641 8.3836669921875 18.9884033203125 5.625007629394531 4.999992370605469 5.094619750976562 M 0 0 C 66.81494903564453 0.00037384033203125 122.39599609375 48.89397811889648 132.917236328125 112.7745361328125 L 101.6708908081055 112.7743530273438 C 91.554931640625 65.91899871826172 49.832763671875 30.68451690673828 0 30.68426513671875 L 0 0 Z" stroke="none" fill="#ff3833"/>
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);
