import api from "./api";

const route = "/patrocinadores";

export default {
  getPatrocinadores: () => api.get(route),
  getPatrocinador: (idPatrocinador) => api.get(`${route}/${idPatrocinador}`),
  getSegmentosDisponibles: (idPatrocinador) =>
    api.get(
      `${route}/disponible?idPatrocinador=${
        idPatrocinador ? idPatrocinador : ""
      }`
    ),
  postPatrocinador: (patrocinador) => api.post(route, { ...patrocinador }),
  putLogos: (idPatrocinador, nuevos, editar, eliminar, idAdjunto) =>
    api.put(`${route}/${idPatrocinador}/logos`, {
      nuevos,
      editar,
      eliminar,
      idAdjunto,
    }),
  putPatrocinador: (idPatrocinador, patrocinador) =>
    api.put(`${route}/${idPatrocinador}`, { ...patrocinador }),
  putSegmentos: (segmentos) => api.put(`${route}/segmentos/all`, { segmentos }),
  deletePatrocinador: (idPatrocinador) =>
    api.delete(`${route}/${idPatrocinador}`),
};
