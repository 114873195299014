import React from "react";
import Table from "react-bootstrap/Table";

class TablaJuegos extends React.Component {

  render() {
    return (
      <Table className="background-w tablaJuegos shadow">
        <tbody className="tablaJuegos">
          {this.props.children}
        </tbody>        
      </Table>
    );
  }
}

export default TablaJuegos;