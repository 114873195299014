import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Input from "./Input";
import Checkbox from "./Checkbox";

export default function(props){

  const {
    idOpcion,
    modifier,
    texto,
    correcta,
    index,
    color
  } = props;
  
  const [opacity, setOpacity] = useState(index > 1 && texto === "" ? 0.5 : 1);

  return (
    <Container
      fluid={true}
      className={`mt-2 mb-2 py-3 align-items-center border-show border-${color} ${correcta ? 'outline-fill-' + color : ""}`}
      style={{
        backgroundColor: correcta ? color : "transparent",
        border: (texto === "" && index > 1) ? "dashed" : "solid",
        opacity
      }}
    >
      <Row>
        <Col xs={2} className="align-items-center py-2">
          {props.icon}
        </Col>
        <Col xs={7} className="align-items-center">
          <Input
            type="text"
            as="textarea"
            value={texto}
            modifier={modifier}
            placeholder="Escribe tu respuesta aquí"
            args={[idOpcion, "texto"]}
            className="bg-transparent pl-0 opcion-form"
            style={{color: correcta ? "white" : "black", height: 70, resize: "none"}}
            onFocus={() => setOpacity(1)}
            onBlur={() => {
              if(texto === "" && index > 1) setOpacity(0.5);
            }}
            maxLength={140}
          />
        </Col>
        <Col xs={3} className="text-center pt-3">
          <p style={{ fontSize: "10px", fontWeight: "bold" }} className="mb-0">
            ¿Correcta?
          </p>
          <Checkbox
            checked={correcta}
            modifier={modifier}
            args={[idOpcion, "correcta"]}
          />
        </Col>
      </Row>
    </Container>
  );
};
