import React, { Component } from "react";
import { Container, Row, Image } from "react-bootstrap";
import cara from "../assets/5.svg";
import Menu from "../components/Menu";

class Error404 extends Component {
    render(){
        return(
            <>
            {(this.props.menu) ? (<p></p>) : (<Menu {...this.props}/>)}
            <Container fluid className="justify-content-center vh-100 vw-100 overflow-hidden" style={{position: "fixed"}}> 
                <Row className="align-items-center justify-content-center h-100" >
                    <Container className="text-center mb-5">
                    <Image src={cara} style={{height: "20vh"}}/>
                    <p className="pt-5 h5">Lo sentimos. No encontramos lo que buscas.</p>
                    </Container>
                </Row>
            </Container>
            </>
        )
    }
}

export default Error404;