import React, { Component } from "react";
import ReactGA from "react-ga";
import { Row, Col, Image, Container } from "react-bootstrap";
import { BASE_URL } from "../utils";
import { navigate } from "@reach/router";
import LoadingFace from "../components/LoadingFace";
import moment from "moment";

class Espera extends Component {

  componentDidUpdate(prevProps) {
    if (!prevProps.next && this.props.next) {
      navigate(`/jugar/${this.props.pin}/respuesta`);
    }
  }

  componentDidMount() {
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
    const time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      label: "Tiempo en pantalla de Espera",
      variable: "seconds",
      category: "Espera",
      value: time,
    });
  }

  renderPatrocinador() {
    let src = '';
    if (
      this.props.patrocinador &&
      this.props.patrocinador !== null &&
      this.props.patrocinador.nombre
    ) {
      let lengthLogos = this.props.patrocinador.logos.length;
      if (lengthLogos > 0) {
        let number = 0;
        if(this.props.counter >= lengthLogos) number = this.props.counter % lengthLogos;
        else number = this.props.counter;
        src = `${BASE_URL}/adjuntos/${this.props.patrocinador?.logos[number]?.idAdjunto}`;
      } else if (this.props.patrocinador.idAdjunto !== null) {
        src = `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`;
      }
    }
    return (
      <Container className="text-center px-4">
        {(this.props.patrocinador &&
          this.props.patrocinador !== null &&
          this.props.patrocinador.nombre) ? (
            <>
              <p>Impulsado por</p>
              <h3 className="mb-3">{this.props.patrocinador.nombre}</h3>
              <Image
                src={src}
                style={{ maxWidth: 300 }}
              />
            </>
          ) : 
          <LoadingFace 
            background={this.props.background}
            faceColor={this.props.faceColor}
          />
        }
      </Container>
    );
  }

  render() {
    return (
      <Container
        fluid
        className={this.props.background === 'main' ? (this.props.user ? 'fondo-rojo-completo-user vertical-center' : 'fondo-rojo-completo vertical-center') : 'fondo-gris vertical-center'}
      >
        <Row className="justify-content-center">
          <Col sm={12} md={4}>
            <Row>{this.renderPatrocinador()}</Row>
            <Row>
              <Col sm={12}  md={12} className="text-center">
                <h5 className="mt-3">
                  {this.props.mensaje ? this.props.mensaje : ""}
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Espera;
