import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import SwitchULU from "../global/SwitchULU";

class FraseForm extends React.Component {
  render() {
    return (
      <Modal.Body>
        <Container>
          <Row>
            <Col className="px-0 pb-3">
              <div style={{ width: "100%" }}>
                <TextField
                  outlined
                  className="w-100"
                  label="Frase"
                  characterCounter={<CharacterCounter />}
                  maxLength={255}
                >
                  <Input
                    value={this.props.frase.texto}
                    maxLength={255}
                    onChange={(event) =>
                      this.props.modifier("texto", event.target.value)
                    }
                  />
                </TextField>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <SwitchULU
                label="¿Activa?"
                value={this.props.frase.activo}
                modifier={this.props.modifier}
                checkClassName="custom-control-lg"
                args="activo"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    );
  }
}

export default FraseForm;
