import {
  CREATE_NUEVA_PREGUNTA,
  SET_PROPIEDAD_PREGUNTA,
  ELIMINAR_PREGUNTA,
  ELIMINAR_OPCION,
  REESTABLECER_PREGUNTA,
  REESTABLECER_OPCION,
  DISABLE_BUTTON,
  ENABLE_BUTTON,
  EDIT_PREGUNTA,
  SAVE_CURRENT_PREGUNTA,
  SET_PROPIEDAD_PREGUNTA_CRUCIGRAMA,
  DUPLICAR_PREGUNTA,
  JUEGO_RECIBIDO,
  REORDENAR_PREGUNTAS,
  LOG_TIME,
} from "./types";
import AdjuntosService from "../services/AdjuntosService";
import PreguntasService from "../services/PreguntasService";
import MisJuegosService from "../services/MisJuegosService";
import {
  procesarPreguntasOpciones,
  eliminarOpcionesVacias,
  displayError,
  displaySuccess,
  eliminarPreguntasNuevas,
} from "../utils";
import { createCrossword } from 'ulu-crossword';

export const createNuevaPregunta = (num) => (dispatch) => {
  dispatch({ type: CREATE_NUEVA_PREGUNTA, payload: num });
};

export const duplicarPregunta = (pregunta) => (dispatch) => {
  dispatch({ type: DUPLICAR_PREGUNTA, payload: pregunta });
};

export const setPropiedadPregunta = (key, value) => (dispatch) => {
  if (key === "idAdjunto") {
    dispatch({
      type: SET_PROPIEDAD_PREGUNTA,
      payload: { key: "file", value },
    });
    if (!value)
      dispatch({ type: SET_PROPIEDAD_PREGUNTA, payload: { key, value: null } });
  } else {
    dispatch({
      type: SET_PROPIEDAD_PREGUNTA,
      payload: { key, value },
    });
  }
};

export const reordenarPreguntas = ({ oldIndex, newIndex }) => (dispatch) => {
  dispatch({ type: REORDENAR_PREGUNTAS, payload: { oldIndex, newIndex } });
};

export const postPreguntas = (preguntas, juego, idJuego, callback) => (
  dispatch
) => {
  dispatch({ type: DISABLE_BUTTON });
  if (juego.tiempo === null) delete juego.tiempo;
  let opciones = [];
  if (!validarPreguntas(preguntas, juego.tipo.toLowerCase())) {
    dispatch({ type: ENABLE_BUTTON });
    return displayError(
      dispatch,
      "Todas las preguntas deben tener un texto, respuesta o imagen."
    );
  }
  if(juego.tipo === 'crucigrama'){
    const isValid = createCrossword({
      words: preguntas,
      gridSize: 9,
      tries: 100,
      print: false
    });
    if(!isValid){
      return displayError(
        dispatch,
        "Las palabras son muy largas o hay demasiadas para poder crear un crucigrama"
      );
    }
  }
  if (juego.tipo.toLowerCase() !== "trivia") {
    opciones = preguntas.map((pregunta) => ({
      texto: pregunta.respuesta,
      idOpcion: pregunta.idOpcion,
      idPregunta: pregunta.idPregunta,
    }));
    preguntas = procesarPreguntasOpciones(preguntas, opciones);
  } else {
    preguntas = preguntas.map((pregunta) => ({
      ...pregunta,
      opciones: eliminarOpcionesVacias(pregunta.opciones),
    }));
  }
  preguntas = eliminarPreguntasNuevas(preguntas);
  if (juego.titulo === "") {
    dispatch({ type: ENABLE_BUTTON });
    return displayError(dispatch, "Debes agregar un título a tu juego.");
  }

  dispatch({ type: LOG_TIME });

  const promises = [];
  preguntas.forEach((pregunta) => {
    if (
      pregunta.file &&
      typeof pregunta.file !== "string" &&
      pregunta.file.name
    ) {
      let formData = new FormData();
      formData.append("adjunto", pregunta.file);
      const promise = AdjuntosService.postAdjunto(formData);
      promise.then((res) => {
        pregunta.idAdjunto = res.data.idAdjunto;
      });
      promises.push(promise);
    }
  });
  Promise.all(promises).then(() => {
    if (!idJuego || idJuego === "nuevo") {
      let imagePromise;
      if(juego.selectedImage){//si hay una imagen seleccionada de unsplash
        imagePromise = MisJuegosService.uploadUnsplashImage({
          downloadLocation: juego.unsplashImage.downloadLocation,
          downloadURL: juego.unsplashImage.downloadURL,
          id: juego.selectedImage
        });
      }
      else if(juego.dropzonePreview){
        const formData = new FormData();
        formData.append("adjunto", juego.dropzonePreview);
        imagePromise = AdjuntosService.postAdjuntoBucket(formData);
      }
      else{
        imagePromise = Promise.resolve();
      }
      imagePromise.then((response) => {
        const newJuego = {...juego};
        if(response && response.data && response.data.key){
          newJuego.keyAdjunto = response.data.key;
        }
        return MisJuegosService.postJuego(newJuego);
      })
      .then((res) => {
          const { idJuego } = res.data.juego;
          juego.idJuego = idJuego;
          juego.originalImage = res.data.juego.originalImage;
          juego.selectedImage = undefined;
          PreguntasService.postPreguntas(idJuego, preguntas)
            .then((res) => {
              preguntas = res.data.preguntas;
              dispatch({ type: JUEGO_RECIBIDO, payload: { preguntas, juego } });
              displaySuccess(dispatch, "Juego subido con éxito.");
              dispatch({ type: ENABLE_BUTTON });
              setTimeout(() => {
                if (callback) callback(idJuego);
              }, 500);
            })
            .catch((error) => {
              dispatch({ type: ENABLE_BUTTON });
              displayError(
                dispatch,
                "Hubo un error al guardar las preguntas. Inténtalo más tarde."
              );
            });
        })
        .catch((error) => {
          dispatch({ type: ENABLE_BUTTON });
          displayError(
            dispatch,
            "Hubo un error al guardar el juego. Inténtalo más tarde."
          );
        });
    } else {
      let imagePromise;
      if(juego.selectedImage){//si hay una imagen seleccionada de unsplash
        imagePromise = MisJuegosService.uploadUnsplashImage({
          downloadLocation: juego.unsplashImage.downloadLocation,
          downloadURL: juego.unsplashImage.downloadURL,
          id: juego.selectedImage
        });
      }
      else if(juego.dropzonePreview){
        const formData = new FormData();
        formData.append("adjunto", juego.dropzonePreview);
        imagePromise = AdjuntosService.postAdjuntoBucket(formData);
      }
      else{
        imagePromise = Promise.resolve();
      }
      imagePromise.then((response) => {
        const newJuego = {...juego};
        if(response && response.data && response.data.key){
          newJuego.keyAdjunto = response.data.key;
        }
        
        return MisJuegosService.putJuego(idJuego, newJuego);
      }).then((res) => {
          preguntas.forEach((pregunta) => {
            if (pregunta.file) delete pregunta.file;
          });
          juego.originalImage = res.data.juego.originalImage;
          juego.selectedImage = undefined;
          PreguntasService.postPreguntas(idJuego, preguntas)
            .then((res) => {
              preguntas = res.data.preguntas;
              dispatch({ type: JUEGO_RECIBIDO, payload: { preguntas, juego } });
              displaySuccess(dispatch, "Cambios guardados con éxito.");
              dispatch({ type: ENABLE_BUTTON });
              if (callback) callback(idJuego);
            })
            .catch((error) => {
              dispatch({ type: ENABLE_BUTTON });
              displayError(
                dispatch,
                "Hubo un error al guardar las preguntas. Inténtalo más tarde."
              );
            });
        })
        .catch((error) => {
          console.error(error);
          dispatch({ type: ENABLE_BUTTON });
          displayError(
            dispatch,
            "Hubo un error al guardar el juego. Inténtalo más tarde."
          );
        });
    }
  });
};

export const eliminarPregunta = (idPregunta) => (dispatch) => {
  dispatch({ type: ELIMINAR_PREGUNTA, payload: idPregunta });
};

export const eliminarOpcion = (idOpcion) => (dispatch) => {
  dispatch({ type: ELIMINAR_OPCION, payload: idOpcion });
};

export const reestablecerPregunta = (idPregunta) => (dispatch) => {
  dispatch({ type: REESTABLECER_PREGUNTA, payload: idPregunta });
};

export const reestablecerOpcion = (idOpcion) => (dispatch) => {
  dispatch({ type: REESTABLECER_OPCION, payload: idOpcion });
};

export const setPropiedadPreguntaCrucigrama = (idPregunta, key, value) => (
  dispatch
) => {
  dispatch({
    type: SET_PROPIEDAD_PREGUNTA_CRUCIGRAMA,
    payload: { idPregunta, key, value },
  });
};

const validarPreguntas = (preguntas, tipo) => {
  let valid = true;
  if (preguntas.length < 1) return false;
  preguntas.forEach((pregunta) => {
    if (String(pregunta.idPregunta)[0] !== "d") {
      if (
        (tipo === "ahorcado" || tipo === "crucigrama") &&
        !pregunta.respuesta
      ) {
        valid = false;
      }
      if (
        tipo === "ahorcado" &&
        pregunta.respuesta !== null &&
        pregunta.respuesta === ""
      ) {
        valid = false;
      }
      if (
        tipo !== "ahorcado" &&
        tipo !== "crucigrama" &&
        (pregunta.texto === "" || pregunta.texto === null) &&
        (pregunta.idAdjunto === "" || pregunta.idAdjunto === null)
      )
        valid = false;
      if (tipo !== "ahorcado" && tipo !== "crucigrama") {
        pregunta.opciones.forEach((opcion, index) => {
          if (
            (opcion.texto === "" || opcion.texto === null) &&
            index < 2 &&
            (opcion.idAdjunto === "" || opcion.idAdjunto === null) &&
            index < 2
          )
            valid = false;
        });
      }
      if (tipo === "crucigrama") {
        if (
          (pregunta.texto !== undefined && pregunta.texto.length < 1) ||
          (pregunta.respuesta !== undefined && pregunta.respuesta.length < 1)
        )
          valid = false;
      }
    }
  });
  return valid;
};

export const editarPregunta = (idPregunta) => (dispatch) => {
  dispatch({ type: EDIT_PREGUNTA, payload: idPregunta });
};

export const saveCurrentPregunta = () => (dispatch) => {
  dispatch({ type: SAVE_CURRENT_PREGUNTA });
};
