import React from "react";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";

export default function ({
  value,
  maxLength,
  max,
  min,
  onChange,
  label,
  divClassName,
  dateTime,
  type,
  disabled,
  name,
  trailingIcon,
  onTrailingIconSelect,
}) {
  if (type === "datetime-local") {
    if (dateTime === null) {
      value = "";
    }
  }
  return (
    <div style={{ width: "100%" }} className={divClassName}>
      <TextField
        outlined
        className="w-100"
        label={label}
        characterCounter={<CharacterCounter />}
        maxLength={maxLength}
        onTrailingIconSelect={onTrailingIconSelect}
        trailingIcon={trailingIcon}
      >
        <Input
          id={label}
          value={value}
          name={name}
          maxLength={maxLength}
          onChange={onChange}
          dateTime={dateTime}
          disabled={disabled}
          type={type}
          max={max}
          min={min}
        />
      </TextField>
    </div>
  );
}
