import React, { Component } from "react";
import { Container, Row, Button, Col } from "react-bootstrap";
import logoCara from "../../assets/logo-cara.png";
import {
    Asterisco,
    Rayas,
    Rectangulo,
  } from "../../assets/icons.js";
import IconsLoading from "../../components/IconsLoading";
import { BASE_URL } from "../../utils";
import { navigate }  from '@reach/router';

class Lobby extends Component {
    constructor(props) {
      super(props);
      this.state = {
        nickname: ''
      };

      this.handleNickname = this.handleNickname.bind(this);
      this.submitNickname = this.submitNickname.bind(this);
    }
    
    handleNickname(e) {
        let nickname = e.target.value;
        this.setState({nickname});
    }

    submitNickname(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.setApodo(this.state.nickname);
    }
  
    render() {
        if(this.props.loadingLobby){
            return (
                <Container className='fondo-gris-full vertical-center'>
                    <Row>
                        <Col>
                            <IconsLoading /> 
                        </Col>
                    </Row>
                </Container>
            );
        }
        let gameType = this.props.tipo.toLowerCase();
        let icon;
        
        switch(gameType) {
            case 'trivia':
                icon = <Rayas 
                    className='header-icon'
                    color='var(--trivia)' 
                    width='20px'
                    height='20px'
                />
                break;
            case 'ahorcado':
                icon = <Asterisco 
                    className='header-icon'
                    color='var(--hangman)' 
                    width='20px'
                    height='20px'
                />
                break;
            case 'crucigrama':
                icon = <Rectangulo 
                    className='header-icon'
                    color='var(--crossword)' 
                    width='20px'
                    height='20px'
                />
                break;
            default:
                break;
        }

        return (
            <Container fluid className="mx-0" onKeyPress={this.toggleFunction}>
                <Row className="align-items-center justify-content-md-center">
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-rojo-mitad order-2 order-lg-1'></Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-gris order-1 order-lg-2 py-5 py-sm-0'>
                        <Row className="justify-content-md-center mb-2">
                            <Col xs={12} className="text-center">
                                <img
                                    alt={'logo ulu'}
                                    src={logoCara}
                                    className="d-block mx-auto"
                                    style={{ height: 60 }}
                                    onClick={() => navigate('/explorar')}
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mb-4">
                            <Col xs={12} md={8} className="text-center">
                                <h2 className='host-game-title'>{icon} {this.props.nombre}</h2>
                                <p className='text-small'>Creado por <a className='link-gray' href=''><b>{this.props.creador}</b></a></p>
                            </Col>
                        </Row>
                        <form onSubmit={this.submitNickname}>
                            <Row className="justify-content-md-center mb-4">
                                <Col xs={12} md={6} className='text-center'>
                                    {/* CAMBIAR A COMPONENTE LLAMADO INPUT */}
                                    <div className='input-wrapper'>
                                        <input type="text" className='input' value={this.state.nickname} onChange={this.handleNickname}/>
                                        <div className={`input-placeholder ${this.state.nickname.length > 0 && 'focused'}`}>
                                            Ingresa tu apodo
                                        </div>
                                    </div>
                                    {/*<p>{this.state.nickname.length} / 255</p>*/}
                                    {/* CAMBIAR A COMPONENTE LLAMADO INPUT */}
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center mb-3">
                                <Col xs={12} md='auto'>
                                    <Button
                                        variant="link"
                                        className="link-gray"
                                        size='lg'
                                        onClick={() => navigate('/explorar')}
                                    >
                                        <h6 className='mb-0'>Regresar a Explorar</h6>
                                    </Button>
                                    
                                    <Button
                                        variant="danger"
                                        className="new-red-button"
                                        size='lg'
                                        disabled={this.state.nickname.length < 1}
                                        type='submit'
                                    >
                                        <h6 className='m-0'>Comenzar ULU</h6>
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        {this.props.patrocinador && 
                            <Row className="justify-content-center">
                                <Col xs={12} md={6} className='text-center'>
                                    <hr className='mb-4'></hr>
                                    {/* CAMBIAR A COMPONENTE LLAMADO SPONSOREDBY */}
                                    <p className='text-small'>ULU impulsado por</p>
                                    <img alt='logo impulsor' src={`${BASE_URL}/adjuntos/${this.props.patrocinador.logoPrincipal}`} className='logo-width-host-pin my-2' />
                                    <p className='text-small'>{this.props.patrocinador.nombre}</p>
                                    {/* CAMBIAR A COMPONENTE LLAMADO SPONSOREDBY */}
                                </Col>
                            </Row>
                        }
                        {/* CAMBIAR A COMPONENTE LLAMADO LANDINGLINK */}
                        <Row className='text-white mt-3 mt-sm-0' style={{display: 'inline-block'}}>
                            <Col xs={12} className='text-center landing-link'>
                                <a href='https://edu.playulu.com' rel="noopener noreferrer" target='_blank' className='link-gray'>Conoce más sobre <b>PlayULU</b></a>
                            </Col>
                        </Row>
                        {/* CAMBIAR A COMPONENTE LLAMADO LANDINGLINK */}
                    </Col>
                </Row>
            </Container>
        );
    }
}


export default Lobby;