import React, { Component } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import Dropzone from "./Dropzone";

class PatrocinadorForm extends Component {
  render() {
    return (
      <Modal.Body className="background-w pt-1">
        <Container>
          <Row>
            <Col className="px-0 pb-3">
              <div style={{ width: "100%" }}>
                <TextField
                  outlined
                  className="w-100"
                  label="Nombre"
                  characterCounter={<CharacterCounter />}
                  maxLength={100}
                >
                  <Input
                    value={this.props.patrocinador.nombre}
                    maxLength={100}
                    onChange={(event) =>
                      this.props.modifier("nombre", event.target.value)
                    }
                  />
                </TextField>
              </div>
            </Col>
          </Row>
          <Row>
            <Dropzone
              modifier={this.props.modifier}
              base_url={this.props.base_url}
              idAdjunto={this.props.patrocinador.idAdjunto}
              removeImage={() => this.props.modifier("idAdjunto")}
              args={"idAdjunto"}
              file={this.props.patrocinador.file}
              height="auto"
              message=". Recomendado: 225x150px"
              alert={this.props.alert}
            />
          </Row>
          <Row>
            <Col className="px-0">
              <p className="h4 pt-4">
                <i className="fas fa-graduation-cap"></i>
                <span className="pl-1 pl-sm-3">Segmentos</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="pl-0">
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>Máximo: {this.props.disponibles.primaria}</Tooltip>
                }
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    outlined
                    className="w-100"
                    label="Primaria"
                    characterCounter={<CharacterCounter />}
                    maxLength={3}
                  >
                    <Input
                      value={this.props.patrocinador.primaria}
                      maxLength={3}
                      onChange={(event) =>
                        this.props.modifier("primaria", event.target.value)
                      }
                      max={this.props.disponibles["primaria"]}
                      type="number"
                    />
                  </TextField>
                </div>
              </OverlayTrigger>
            </Col>
            <Col>
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>Máximo: {this.props.disponibles.secundaria}</Tooltip>
                }
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    outlined
                    className="w-100"
                    label="Secundaria"
                    characterCounter={<CharacterCounter />}
                    maxLength={3}
                  >
                    <Input
                      value={this.props.patrocinador.secundaria}
                      maxLength={3}
                      onChange={(event) =>
                        this.props.modifier("secundaria", event.target.value)
                      }
                      max={this.props.disponibles["secundaria"]}
                      type="number"
                    />
                  </TextField>
                </div>
              </OverlayTrigger>
            </Col>
            <Col>
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>
                    Máximo: {this.props.disponibles.preparatoria}
                  </Tooltip>
                }
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    outlined
                    className="w-100"
                    label="Preparatoria"
                    characterCounter={<CharacterCounter />}
                    maxLength={3}
                  >
                    <Input
                      value={this.props.patrocinador.preparatoria}
                      maxLength={3}
                      onChange={(event) =>
                        this.props.modifier("preparatoria", event.target.value)
                      }
                      max={this.props.disponibles["peparatoria"]}
                      type="number"
                    />
                  </TextField>
                </div>
              </OverlayTrigger>
            </Col>
            <Col>
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>
                    Máximo: {this.props.disponibles.universidad}
                  </Tooltip>
                }
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    outlined
                    className="w-100"
                    label="Universidad"
                    characterCounter={<CharacterCounter />}
                    maxLength={3}
                  >
                    <Input
                      value={this.props.patrocinador.universidad}
                      maxLength={3}
                      onChange={(event) =>
                        this.props.modifier("universidad", event.target.value)
                      }
                      max={this.props.disponibles["universidad"]}
                      type="number"
                    />
                  </TextField>
                </div>
              </OverlayTrigger>
            </Col>
            <Col className="pr-0">
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>
                    Máximo: {this.props.disponibles.profesional}
                  </Tooltip>
                }
              >
                <div style={{ width: "100%" }}>
                  <TextField
                    outlined
                    className="w-100"
                    label="Profesional"
                    characterCounter={<CharacterCounter />}
                    maxLength={3}
                  >
                    <Input
                      value={this.props.patrocinador.profesional}
                      maxLength={3}
                      onChange={(event) =>
                        this.props.modifier("profesional", event.target.value)
                      }
                      max={this.props.disponibles["profesional"]}
                      type="number"
                    />
                  </TextField>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    );
  }
}

export default PatrocinadorForm;
