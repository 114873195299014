import api from "./api";

const route = "/explorar";

export default {
  getPatrocinadores: () => api.get(`${route}/bubbles`),
  getJuegosPatrocinadores: ({gameType}) => api.get(`${route}/cardsPatrocinadores`,{
    params: {
      gameType
    }
  }),
  getJuegosDestacados: ({gameType,limit=20}) => api.get(`${route}/cardsDestacados`, {
    params: {
      gameType,
      limit
    }
  }),
  getAllJuegos: ({currentPage, limit, gameType}) => api.get(`${route}/cards`, {
    params: {
      page: currentPage,
      limit,
      gameType
    }
  }),
  searchJuegos: ({page, limit, search, gameType}) => api.get(`${route}/search`, {
    params: {
      page,
      limit,
      search,
      gameType
    }
  }),
  getGamesProfile: ({idPerfil, gameType, currentPage, limit}) => api.get(`${route}/cardsProfile`, {
    params: {
      idPerfil,
      gameType,
      page: currentPage,
      limit,
    }
  }),
  getHighlightsProfile: ({idPerfil, gameType}) => api.get(`${route}/cardsProfileFeatured`, {
    params: {
      idPerfil,
      gameType,
    }
  }),
  getUserProfile: (idPerfil) => api.get(`${route}/statisticsProfile?idPerfil=${idPerfil}`),
  profileSearch: ({ page, limit, idPerfil, search, gameType }) => api.get(`${route}/profileSearch`, {
    params: {
      page,
      limit,
      idPerfil,
      search,
      gameType
    }
  }),
};
