import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { BASE_URL } from '../../utils';
import { getColor, getIcon, getColorName } from '../../utils';
import { connect } from "react-redux";
import { selectQuestionCrucigrama } from '../../actions/jugarSoloActions';

class QuestionList extends Component {
    constructor(props) {
        super(props);
        this.renderIcon = this.renderIcon.bind(this);
    }

    renderIcon(idPregunta) {
        let color = getColor(idPregunta % 5);
        let colorName = getColorName(idPregunta % 5);
        let icon = getIcon(idPregunta % 18, {color, newclass: `mb-0 crossword-icon-${colorName}`, width: '25px', height: '25px'});
        return icon;
    }

    selectQuestion(pregunta) {
        if(!pregunta.contestada) {
            this.props.selectQuestionCrucigrama(pregunta.idPregunta);
            this.props.toggleQuestionList && this.props.toggleQuestionList();
        }
        else
            return
    }

    render() {
        return(
            <>
                <Row className='mt-3 mt-md-0'>
                    <Col xs={12}>
                        <h4>Verticales:</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ul className='crossword-hint-list'>
                            {Array.from(this.props.preguntas).map(([key, pregunta]) =>  (
                                <div key={`question-list-crucigrama-solo-${pregunta.idPregunta}`}>
                                    {pregunta.orientacion === 'vertical' &&
                                        <li key={`li-question-list-crucigrama-solo-${pregunta.idPregunta}`}  className={`crossword-hint ${(this.props.selectedQuestion === pregunta.idPregunta || pregunta.contestada) && 'crossword-selected-hint'}`} onClick={() => this.selectQuestion(pregunta)}>
                                            <Row className='no-gutters'>
                                                <Col xs='auto' className='vertical-center mr-3'>
                                                    {this.renderIcon(pregunta.idPregunta)}
                                                </Col>
                                                <Col className='vertical-center'>
                                                    <p className={pregunta.contestada ? `crossword-correct-hint-${getColorName(pregunta.idPregunta % 5)}` : ''}>{pregunta.pregunta}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    }
                                </div>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row className='mt-3 mt-md-auto'>
                    <Col xs={12}>
                        <h4>Horizontales:</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ul className='crossword-hint-list'>
                            {Array.from(this.props.preguntas).map(([key, pregunta]) =>  (
                                <div key={`question-list-crucigrama-solo-${pregunta.idPregunta}`}>
                                    {pregunta.orientacion === 'horizontal' &&
                                        <li key={`li-question-list-crucigrama-solo-${pregunta.idPregunta}`} className={`crossword-hint ${(this.props.selectedQuestion === pregunta.idPregunta || pregunta.contestada) && 'crossword-selected-hint'}`} onClick={() => this.selectQuestion(pregunta)}>
                                            <Row className='no-gutters'> 
                                                <Col xs='auto' className='vertical-center mr-3'>
                                                    {this.renderIcon(pregunta.idPregunta)}
                                                </Col>
                                                <Col className='vertical-center'>
                                                    <p className={pregunta.contestada ? `crossword-correct-hint-${getColorName(pregunta.idPregunta % 5)}` : ''}>{pregunta.pregunta}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    }
                                </div>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row className='justify-content-between mt-md-auto my-4 mb-md-0 align-items-center'>
                    {this.props.patrocinador &&
                    <Col xs='auto' sm={4} md='auto'>
                        <div className='text-center'>
                            <p className='text-small'>ULU impulsado por</p>
                            <img className='logo-width-host-pin my-2' src={`${BASE_URL}/adjuntos/${this.props.patrocinador.logoPrincipal}`} alt='logo impulsor' />
                            <p className='text-small'>{this.props.patrocinador.nombre}</p>
                        </div>
                    </Col>
                    }
                    <Col xs='auto' sm={4} md='auto' className='justify-self-end text-right'>
                        <Button
                            variant="link"
                            className="link-gray"
                            size={this.props.isMobile ? 'sm' : 'lg'}
                            onClick={this.props.terminarJuego}
                        >
                            <h6 className='mb-0'>Terminar ULU</h6>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    preguntas: state.crucigramaSolo.preguntas,
    selectedQuestion: state.crucigramaSolo.selectedQuestion
});

export default connect(mapStateToProps, { selectQuestionCrucigrama })(QuestionList);