import {
  CREATE_FRASE,
  EDIT_FRASE,
  FRASES_RECIBIDAS,
  SET_PROPIEDAD_FRASE,
  SAVE_FRASE,
  DELETE_FRASE,
} from "../actions/types";

const schema = (length) => ({
  idFrase: `nueva-${length}`,
  texto: "",
  activo: false,
});

const initialState = {
  frases: null,
  frase: null,
  editadoFrase: null,
  errorFrase: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FRASES_RECIBIDAS:
      return { ...state, frases: payload };
    case CREATE_FRASE:
      return { ...state, editadoFrase: schema(state.frases.length) };
    case EDIT_FRASE:
      return { ...state, frase: payload };
    case SET_PROPIEDAD_FRASE: {
      const { key, value } = payload;
      let editadoFrase = state.editadoFrase;
      let frase = state.frase;
      if (editadoFrase !== null) {
        editadoFrase = { ...state.editadoFrase };
        editadoFrase[key] = value;
      }
      if (frase !== null) {
        frase = { ...state.frase };
        frase[key] = value;
      }
      return { ...state, editadoFrase, frase };
    }
    case SAVE_FRASE:
      const frases = [...state.frases];
      const index = frases.findIndex(
        (frase) => String(frase.idFrase) === String(payload.idFrase)
      );
      if (index === -1) frases.push(payload);
      else frases[index] = payload;
      return { ...state, frases, editadoFrase: null, frase: null };
    case DELETE_FRASE: {
      const frases = [...state.frases];
      const index = frases.findIndex(
        (frase) => String(frase.idFrase) === String(payload)
      );
      if (index !== -1)
        frases[index].idFrase = `delete-${frases[index].idFrase}`;
      return { ...state, frases };
    }
    default:
      return state;
  }
};
