import {
  SET_PROPIEDAD_JUEGO,
  CREATE_NUEVO_JUEGO,
  JUEGO_RECIBIDO,
  SELECCIONAR_JUEGO,
  IMAGES_SEARCH_JUEGO_RECEIVED,
  LOADING_IMAGES_SEARCH
} from "./types";
import MisJuegosService from "../services/MisJuegosService";
import { displayError, convert } from "../utils";
import AdjuntosService from "../services/AdjuntosService";

export const createNuevoJuego = tipo => dispatch => {
  dispatch({ type: CREATE_NUEVO_JUEGO, payload: tipo });
};

export const setPropiedadJuego = (key, value) => dispatch => {
  dispatch({ type: SET_PROPIEDAD_JUEGO, payload: { key, value } });
};

export const getJuego = idJuego => dispatch => {
  MisJuegosService.getJuego(idJuego)
    .then(res => {
      let { juego, preguntas } = res.data;  
      if(juego.tiempo) {
        juego.minutos = Math.floor(juego.tiempo / 60);
        juego.segundos = juego.tiempo % 60;
      }
      if(juego.puntos === null)
        juego.puntos = juego.puntosDefault;
      const promises = [];
      preguntas.forEach(pregunta => {
        if(pregunta.idAdjunto && pregunta.idAdjunto !== null){
          const promise = AdjuntosService.getAdjunto(pregunta.idAdjunto);
          promise.then(res => {
            pregunta.file = convert(res.data, res.data.type);
            promises.push(promise);
          }).catch(error => displayError(dispatch, error));
        }
      }); 
      Promise.all(promises).then(() => {
        dispatch({
          type: JUEGO_RECIBIDO,
          payload: { juego, preguntas }
        });
      })           
    })
    .catch(error => {
      displayError(dispatch, error);
    });  
};

export const seleccionarJuego = tipo => dispatch => {
  dispatch({ type: SELECCIONAR_JUEGO, payload: tipo });
};

export const searchImages = ({tipoJuego, keyword, page}) => (dispatch) => {
  dispatch({ type: LOADING_IMAGES_SEARCH });
  MisJuegosService.searchImages({tipoJuego, keyword, page}).then(({data}) => {
    dispatch({ type: IMAGES_SEARCH_JUEGO_RECEIVED, payload: { results: data.results, pages: data.total_pages } });
  });
};