import {
  LOGIN,
  LOGOUT,
  SHOW_ALERT,
  CLEAR_ALERT,
  CLEAR_SUCCESS,
  SHOW_SPINNER,
  HIDE_SPINNER,
  USER_CREATED,
  SHOW_SUCCESS,
  SET_PROPIEDAD_USER,
  SET_PROPIEDAD_LOGIN,
  PERFIL_RECIBIDO,
} from "./types";
import AuthService from "../services/AuthService";
import UsuarioService from "../services/UsuarioService";
import AdjuntosService from "../services/AdjuntosService";
import {
  displaySuccess,
  displayError,
  validarUsuario,
  procesarUsuario,
} from "../utils";
import ReactGA from "react-ga";

const handleError = (error, dispatch) => {
  dispatch({ type: HIDE_SPINNER });
  if (error) {
    if (error.response) {
      let status = error.response.status;
      if (status === 404)
        dispatch({
          type: SHOW_ALERT,
          payload: "No tenemos una cuenta con ese correo. ¡Regístrate!",
        });
      else if (status === 401)
        dispatch({
          type: SHOW_ALERT,
          payload: "Credenciales incorrectas. Inténtalo de Nuevo.",
        });
    } else {
      let message = error.toString();
      dispatch({ type: SHOW_ALERT, payload: message });
    }
    window.setTimeout(() => dispatch({ type: CLEAR_ALERT }), 5000);
  }
};

export const signIn = (email, password) => (dispatch) => {
  dispatch({ type: SHOW_SPINNER });
  AuthService.signIn(email, password)
    .then(() => {
      UsuarioService.getUsuario()
        .then((res) => {
          const { usuario } = res.data;
          localStorage.setItem('user', JSON.stringify(res.data));
          dispatch({
            type: LOGIN,
            payload: usuario,
          });
          dispatch({ type: HIDE_SPINNER });
        })
        .catch((error) => {
          handleError(error, dispatch);
        });
    })
    .catch((error) => {
      handleError(error, dispatch);
    });
};

export const userLoggedIn = () => (dispatch) => {
  dispatch({ type: SHOW_SPINNER });
  const user = AuthService.userLoggedIn();
  if(user){
    UsuarioService.getUsuario()
      .then((res) => {
        let { usuario } = res.data;
        dispatch({
          type: LOGIN,
          payload: usuario,
        });
        dispatch({ type: HIDE_SPINNER });
      })
  }
  else{
    dispatch({ type: HIDE_SPINNER });
    console.log('user not found in local storage')
    AuthService.signOut().then().catch(() => {
      ReactGA.event({
        category: "Cerrar Sesión",
        action: "Cerrar Sesión",
        label: "Cerrar Sesión",
      });
      localStorage.removeItem('user');
      dispatch({ type: LOGOUT });
    });
  }
};

export const signOut = (idUsuario) => (dispatch) => {
  console.log('Signing out')
  AuthService.signOut()
    .then(() => {
      ReactGA.event({
        category: "Cerrar Sesión",
        action: "Cerrar Sesión",
        label: "Cerrar Sesión",
      });
      localStorage.removeItem('user');
      dispatch({ type: LOGOUT });
    }).catch(e => {
      localStorage.setItem('user', null)
      dispatch({ type: LOGOUT });
      ReactGA.event({
        category: "Cerrar Sesión",
        action: "Cerrar Sesión",
        label: "Cerrar Sesión",
      });
      localStorage.removeItem('user');
    });
};

export const signUp = ({ nombre, correo, password, idEscuela, rol, profesional, escuela, usuario }) => (
  dispatch
) => {
  dispatch({ type: SHOW_SPINNER });
  AuthService.signUp({ nombre, correo, password, idEscuela, rol, profesional, escuela, usuario })
    .then((res) => {
      ReactGA.event({
        category: "User",
        action: "Sign up",
        label: "Sign Up Complete",
      });
      dispatch({
        type: SET_PROPIEDAD_LOGIN,
        payload: { key: "correo", value: correo },
      });
      dispatch({
        type: LOGIN,
        payload: res.data,
      });
      dispatch({ type: HIDE_SPINNER });
      dispatch({ type: USER_CREATED });
      localStorage.setItem('user', JSON.stringify(res.data));
      dispatch({ type: SHOW_SUCCESS, payload: "¡Registrado con éxito!" });
    })
    .catch((error) => {
      dispatch({ type: HIDE_SPINNER });
      if (error.response) {
        if (error.response.status === 409)
          dispatch({
            type: SHOW_ALERT,
            payload: "Ya existe un usuario con este correo electrónico.",
          });
        if (error.response.status === 400)
          dispatch({
            type: SHOW_ALERT,
            payload: "El correo electrónico no es válido.",
          });
      }
    });
};

export const setPropiedadUser = (key, value) => (dispatch) => {
  if (key === "file") {
    dispatch({
      type: SET_PROPIEDAD_USER,
      payload: { key: "idAdjunto", value: null },
    });
  }
  if (key === "alias") value = value.trim();
  dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
};

export const recoverPassword = (email) => (dispatch) => {
  AuthService.recoverPassword(email)
    .then(() => {
      dispatch({
        type: SHOW_SUCCESS,
        payload: "Te hemos enviado un correo para reestablecer tu contraseña. ",
      });
      setTimeout(() => dispatch({ type: CLEAR_SUCCESS }), 3000);
    })
    .catch((error) => {
      if (error.code === "auth/user-not-found")
        dispatch({
          type: SHOW_ALERT,
          payload: "No hay un usuario con ese correo.",
        });
      else if (error.code === "auth/invalid-email")
        dispatch({ type: SHOW_ALERT, payload: "Ese correo no es válido." });
      else
        dispatch({
          type: SHOW_ALERT,
          payload: "Hubo un error al enviar el correo. Inténtalo más tarde.",
        });
      setTimeout(() => dispatch({ type: CLEAR_ALERT }), 3000);
    });
};

export const updatePassword = (correo, password, newPassword) => (dispatch) => {
  AuthService.updatePassword({ password, newPassword})
    .then(() => {
      displaySuccess(dispatch, "Contraseña actualizada con éxito.");
    })
    .catch((error) => {
      if (error.response.data.error.code === "auth/incorrect_password") {
        displayError(dispatch, "La contraseña es incorrecta.");
      } else {
        displayError(dispatch, error);
      }
    });
};

export const putUsuario = (user, callback) => (dispatch) => {
  const error = validarUsuario(user);
  if (error) return displayError(dispatch, error);
  user = procesarUsuario(user);
  const promises = [];
  if (user.file) {
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("adjunto", user.file);
      AdjuntosService.postAdjunto(formData).then((res) => {
        const { idAdjunto } = res.data;
        user.idAdjunto = idAdjunto;
        delete user.file;
        resolve();
      });
    });
    promises.push(promise);
  }
  Promise.all(promises).then(() => {
    UsuarioService.putUsuario(user)
      .then(() => {
        if (callback) callback();
        displaySuccess(dispatch, "Perfil actualizado con éxito.");
      })
      .catch((error) => {
        displayError(dispatch, error);
      });
  });
};

export const deleteUsuario = () => (dispatch) => {
  UsuarioService.deleteUsuario()
    .then((res) => {
      displaySuccess(dispatch, "Perfil eliminado con éxito");
      AuthService.signOut();
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const getUsuario = () => (dispatch) => {
  UsuarioService.getUsuario()
    .then((res) => {
      const { usuario } = res.data;
      dispatch({ type: PERFIL_RECIBIDO, payload: usuario });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};
