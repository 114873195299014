import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Accordion,
  Card,
} from "react-bootstrap";
import CardPatrocinador from "../components/CardPatrocinador";
import SearchBox from "../components/SearchBox";
import Sidebar from "../components/Sidebar";
import AdminTable from "../components/AdminTable";
import PatrocinadorForm from "../components/PatrocinadorForm";
import { Billete, Graduado, Boton } from "../assets/icons";
import { signOut } from "../actions/userActions";
import {
  getPatrocinadores,
  setPropiedadPatrocinador,
  editPatrocinador,
  postPatrocinador,
  createPatrocinador,
  getSegmentosDisponibles,
  setPropiedadPatrocinadorTabla,
  postSegmentos,
} from "../actions/patrocinadoresActions";
import {
  isAdmin
} from '../actions/adminActions'
import { modalComponent, alert } from "../actions/modalActions";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { BASE_URL } from "../utils";

class Patrocinadores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: undefined,
      query: "",
      showModal: false,
      editMode: false,
      opcionesSidebar: [
        {
          title: "Vista general",
          filter: null,
          filterSidebar: 'Vista general',
          icon: (props) => <Billete color="#2f2f2f" {...props} />,
        },
        {
          title: "Estado de patrocinadores",
          filter: null,
          filterSidebar: 'Estado de patrocinadores',
          icon: (props) => <Boton color="#2f2f2f" {...props} />,
        },
        {
          title: "Segmentos",
          filter: null,
          filterSidebar: 'Segmentos',
          icon: (props) => <Graduado color="#2f2f2f" {...props} />,
        },
      ],
      component: "Vista general",
    };
    this.searchPatrocinadores = this.searchPatrocinadores.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.getPatrocinadores();
    this.props.getSegmentosDisponibles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patrocinadores !== null) {
      if (prevProps.patrocinadores.length !== this.props.patrocinadores.length)
        this.props.getSegmentosDisponibles();
    }
    if (this.props.editado !== null && this.props.disponibles !== null) {
      this.props.modalComponent(
        "Agregar Patrocinador",
        <PatrocinadorForm
          modifier={this.props.setPropiedadPatrocinador}
          patrocinador={this.props.editado}
          disponibles={this.props.disponibles}
          alert={this.props.alert}
        />,
        () => this.props.editPatrocinador(null),
        () =>
          this.props.postPatrocinador(
            this.props.editado,
            this.props.disponibles
          ),
        () => {
          if (this.props.editado.nombre === "") return false;
          let valid = true;
          Object.keys(this.props.disponibles).forEach((segmento) => {
            if (this.props.editado[segmento] > this.props.disponibles[segmento])
              valid = false;
            if (this.props.editado[segmento] === "")
              this.props.setPropiedadPatrocinador(segmento, 0);
            if (isNaN(this.props.editado[segmento])) valid = false;
          });
          return valid;
        },
        "Verifica el nombre del patrocinador o los porcentajes de segmentos."
      );
    }
  }

  searchPatrocinadores(query) {
    this.setState({ query });
  }

  handleShow() {
    this.setState({ showModal: true });
    this.props.createPatrocinador();
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleChange(event, input) {
    let { value } = event.target;
    this.props.setPropiedadPatrocinador(input, value);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    this.setState({ validated: true });
    this.props.postPatrocinador(this.props.editado);
  }

  renderTablaPatrocinadores() {
    if (this.props.patrocinadores && this.props.patrocinadores !== null) {
      const rows = this.props.patrocinadores
        .map((patrocinador) => ({
          ...patrocinador,
          primaria: patrocinador.segmentos[0].porcentaje,
          secundaria: patrocinador.segmentos[1].porcentaje,
          preparatoria: patrocinador.segmentos[2].porcentaje,
          universidad: patrocinador.segmentos[3].porcentaje,
          profesional: patrocinador.segmentos[4].porcentaje,
        }))
        .filter((patrocinador) => patrocinador.activo);
      return (
        <AdminTable
          idRow="idPatrocinador"
          headers={[
            "Patrocinador",
            "Primaria",
            "Secundaria",
            "Preparatoria",
            "Universidad",
            "Profesional",
          ]}
          rows={rows}
          exclude={[
            "premiosActivos",
            "premiosTotales",
            "activo",
            "segmentos",
            "file",
            "idAdjunto",
            "fechaVencimiento",
            "saldo",
          ]}
          sumRows
          tableClass="mb-0"
          suffix="%"
          editMode={this.state.editMode}
          excludeEditMode={["nombre"]}
          inputModifier={this.props.setPropiedadPatrocinadorTabla}
        />
      );
    }
  }

  async handleClickCard(idPatrocinador) {
    await navigate(`/admin/patrocinadores/${idPatrocinador}/configuracion`);
    window.scrollTo(0, 0);
  }

  renderPatrocinadoresActivos() {
    if (this.props.patrocinadores === null)
      return <Spinner animation="border" variant="dark" />;
    if (this.props.patrocinadores.length === 0)
      return (
        <Container fluid>
          <p>Aún no hay patrocinadores.</p>
        </Container>
      );
    return this.props.patrocinadores.map((patrocinador) => {
      if (
        patrocinador.activo &&
        (this.state.query === "" ||
          (this.state.query !== "" &&
            patrocinador.nombre
              .toLowerCase()
              .includes(this.state.query.toLowerCase())))
      )
        return (
          <Col
            xs={12}
            sm={4}
            md={3}
            lg={3}
            xl={3}
            className="pb-3"
            key={patrocinador.idPatrocinador}
          >
            <CardPatrocinador
              id={patrocinador.idPatrocinador}
              nombre={patrocinador.nombre}
              premiosActivos={patrocinador.premiosActivos}
              premiosTotales={patrocinador.premiosTotales}
              src={
                patrocinador.idAdjunto !== null
                  ? `${BASE_URL}/adjuntos/${patrocinador.idAdjunto}`
                  : undefined
              }
              segmentos={patrocinador.segmentos}
              handleClick={this.handleClickCard}
            />
          </Col>
        );
      return null;
    });
  }

  renderPatrocinadoresInactivos() {
    if (this.props.patrocinadores !== null)
      return this.props.patrocinadores.map((patrocinador) => {
        if (
          !patrocinador.activo &&
          (this.state.query === "" ||
            (this.state.query !== "" &&
              patrocinador.nombre
                .toLowerCase()
                .includes(this.state.query.toLowerCase())))
        )
          return (
            <Col
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              className="pb-3"
              key={patrocinador.idPatrocinador}
            >
              <CardPatrocinador
                id={patrocinador.idPatrocinador}
                nombre={patrocinador.nombre}
                premiosActivos={patrocinador.premiosActivos}
                premiosTotales={patrocinador.premiosTotales}
                src={
                  patrocinador.idAdjunto !== null
                    ? `${BASE_URL}/adjuntos/${patrocinador.idAdjunto}`
                    : undefined
                }
                segmentos={patrocinador.segmentos}
                handleClick={this.handleClickCard}
              />
            </Col>
          );
        return null;
      });
  }

  renderAccordionPatrocinadores() {
    return (
      <Accordion
        onSelect={(activeKey) => this.setState({ activeKey })}
        className="pl-1"
      >
        <Card className="mb-2 accordion-premios">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <Row>
              <Col xs={10} sm={11}>
                Activos
              </Col>
              <Col xs={1}>
                <i
                  className={`fas fa-chevron-${
                    this.state.activeKey === "0" ? "up" : "down"
                  }`}
                ></i>
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row>{this.renderPatrocinadoresActivos()}</Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordion-premios">
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <Row>
              <Col xs={10} sm={11}>
                Inactivos
              </Col>
              <Col xs={1}>
                <i
                  className={`fas fa-chevron-${
                    this.state.activeKey === "1" ? "up" : "down"
                  }`}
                ></i>
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Row>{this.renderPatrocinadoresInactivos()}</Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  renderEstado() {
    if (this.state.component === "Vista general" || this.state.component === "Estado de patrocinadores")
      return (
        <>
          <Row id="estado">
            <Col>
              <h1 className="pl-1 pl-sm-2 pt-4">
                <i className="fas fa-power-off"></i>
                <span className="pl-1 pl-sm-3">Estado de patrocinadores</span>
              </h1>
            </Col>
          </Row>
          <Row className="my-3 justify-content-between">
            <Col xs={12} sm={6} md={6} lg={4}>
              <SearchBox
                searchModifier={this.searchPatrocinadores}
                placeholder="  &#61442;   Buscar"
              />
            </Col>
          </Row>
          {this.renderAccordionPatrocinadores()}
        </>
      );
  }

  renderSegmentos() {
    if (
      this.state.component === "Vista general" ||
      this.state.component === "Segmentos"
    )
      return (
        <>
          <Row id="segmentos">
            <Col>
              <h1 className="pl-1 pl-sm-2 pt-4">
                <i className="fas fa-graduation-cap"></i>
                <span className="pl-1 pl-sm-3">Segmentos</span>
              </h1>
            </Col>
          </Row>
          <Row className="pl-1">
            <Col xs={11} sm={9} lg={8} className="admin-table px-0 ml-3 mb-3">
              {this.renderTablaPatrocinadores()}
            </Col>
          </Row>
          <Row className="mb-5">
            <Col xs={11} sm={9} lg={8} className="px-0 ml-3">
              <Row className="justify-content-sm-end px-3">
                {this.state.editMode ? (
                  <Button
                    variant="danger"
                    className="new-red-button px-3 py-2"
                    onClick={() => {
                      this.setState({ editMode: false });
                      this.props.postSegmentos(this.props.patrocinadores);
                    }}
                  >
                    Guardar segmentos
                  </Button>
                ) : (
                  <Button
                    variant="outline-danger"
                    className="border-red-new px-4 py-3"
                    size='lg'
                    onClick={() => this.setState({ editMode: true })}
                  >
                    <h6>Editar segmentos</h6>
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        </>
      );
  }

  render() {
    if(this.props.loadingAdmin){
      return <></>
    }
    return (
      <Container fluid className="no-bg-img h-100">
        <Row>
          <Col
            sm={4}
            md={3}
            xl={2}
            className="bg-white px-0 hide-mobile"
            style={{ minHeight: "100vh" }}
          >
            <Sidebar
              admin={true}
              usuario={this.props.usuario}
              signOut={this.props.signOut}
              opciones={this.state.opcionesSidebar}
              handleFilter={(filterSidebar) => this.setState({ component: filterSidebar })}
            />
          </Col>
          <Col>
            {this.renderEstado()}
            {this.renderSegmentos()}
            <div className='fixed-bottom-right'>
              <Button
                variant="danger"
                className="new-red-button px-3 py-3"
                onClick={this.handleShow}
                size='lg'
              >
                <h6 className='mb-0'>Agregar patrocinador</h6>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  patrocinadores: state.patrocinadores.patrocinadores,
  editado: state.patrocinadores.editado,
  disponibles: state.patrocinadores.disponibles,
  usuario: state.user.user,
  loadingAdmin: state.admin.loadingAdmin
});

export default connect(mapStateToProps, {
  alert,
  signOut,
  postSegmentos,
  modalComponent,
  postPatrocinador,
  editPatrocinador,
  getPatrocinadores,
  createPatrocinador,
  getSegmentosDisponibles,
  setPropiedadPatrocinador,
  setPropiedadPatrocinadorTabla,
  isAdmin
})(Patrocinadores);
