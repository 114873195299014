import React, { Component } from 'react';
import LobbyQuestion from './LobbyQuestion';
import Crossword from './Crossword';
import Switcher from './Switcher';
import GameEnding from './GameEnding';
import Keyboard from './Keyboard';
import { Container, Row, Col } from 'react-bootstrap';
import Div100vh from 'react-div-100vh';
import { use100vh } from 'react-div-100vh';

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const realHeight = use100vh();
        return <Component {...props} realHeight={realHeight} />;
    }
}

class CrosswordContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            letterPressed: null,
        }
    }


    render() {
        const component = this.props.component.toLowerCase();

        switch(component) {
            case 'lobbyquestion':
                return(
                    <Container className='vertical-center fondo-gris-full'>
                        <LobbyQuestion 
                            juego={this.props.juego}
                            isMobile = {this.props.isMobile}
                        />
                    </Container>
                );
            case 'question':
                if(this.props.isMobile) {
                    return(
                        <Container>
                            <Div100vh className='vertical-space-between'>
                                <div>
                                    <Row>
                                        <Col xs={12} className='px-0'>
                                            <Crossword 
                                                juego={this.props.juego}
                                                isMobile = {this.props.isMobile}
                                                component = {this.props.component}
                                                responder={this.props.responder}//callback to send answer to back
                                                selectQuestion={this.props.selectQuestion}
                                                selectedQuestion={this.props.selectedQuestion}
                                                setQuestionStatus={this.props.setQuestionStatus}
                                                letterPressed = {this.state.letterPressed}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className='px-0'>
                                            <Switcher 
                                                juego={this.props.juego}
                                                isMobile = {this.props.isMobile}
                                                patrocinador={this.props.patrocinador}
                                                component = {this.props.component}
                                                responder={this.props.responder}//callback to send answer to back
                                                selectQuestion={this.props.selectQuestion}
                                                selectedQuestion={this.props.selectedQuestion}
                                                terminarJuego={this.props.terminarJuego}
                                                tiempoPregunta={this.props.tiempoPregunta}

                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col xs={12}>
                                        <Keyboard onClick={(e) => this.setState({letterPressed: e})} gameType={this.props.urlQuery.tipo.toLowerCase()}/>
                                    </Col>
                                </Row>
                            </Div100vh>
                        </Container>
                    );
                } else {
                    return(
                        <Container className='vertical-center fondo-gris-full'>
                            <Row>
                                <Col md={6}>
                                    <Crossword
                                        juego={this.props.juego}
                                        isMobile = {this.props.isMobile}
                                        component = {this.props.component}
                                        responder={this.props.responder}//callback to send answer to back
                                        selectQuestion={this.props.selectQuestion}
                                        selectedQuestion={this.props.selectedQuestion}
                                        setQuestionStatus={this.props.setQuestionStatus}
                                    />
                                </Col>
                                <Col md={6} className='switcher-col'>
                                    <Switcher 
                                        juego={this.props.juego}
                                        isMobile = {this.props.isMobile}
                                        patrocinador={this.props.patrocinador}
                                        component = {this.props.component}
                                        responder={this.props.responder}//callback to send answer to back
                                        selectQuestion={this.props.selectQuestion}
                                        selectedQuestion={this.props.selectedQuestion}
                                        terminarJuego={this.props.terminarJuego}
                                        tiempoPregunta={this.props.tiempoPregunta}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    );
                }
            case 'gameending':
                if(this.props.isMobile) {
                    return(
                        <Container
                            className='vertical-center'
                            style={{minHeight: `calc(${this.props.realHeight}px - 100px - 40px)`}}
                        >
                            <GameEnding 
                                patrocinador={this.props.patrocinador}
                                apodo={this.props.apodo}
                                porcentajeCorrectas={this.props.juego.porcentajeCorrectas}
                                setSugerenciaProperty={this.props.setSugerenciaProperty}
                                showThankYouModal={this.props.showThankYouModal}
                                user={this.props.user}
                            />
                        </Container>
                    );
                } else {
                    return(
                        <Container className='vertical-center fondo-gris-full'>
                            <GameEnding 
                                patrocinador={this.props.patrocinador}
                                apodo={this.props.apodo}
                                porcentajeCorrectas={this.props.juego.porcentajeCorrectas}
                                setSugerenciaProperty={this.props.setSugerenciaProperty}
                                showThankYouModal={this.props.showThankYouModal}
                                user={this.props.user}
                            />
                        </Container>
                    );
                }
            default:
                break;
        }
    }
}

export default withMyHook(CrosswordContent);