import {
    EXPLORE_PATROCINADORES_RECIBIDOS,
    EXPLORE_JUEGOS_PATROCINADORES_RECIBIDOS,
    EXPLORE_JUEGOS_DESTACADOS_RECIBIDOS,
    EXPLORE_ALL_JUEGOS_RECIBIDOS,
    EXPLORE_SEARCH_JUEGOS_RECIBIDOS,
    EXPLORE_SEARCH_NEXT_PAGE_JUEGOS_RECIBIDOS,
    EXPLORE_RESET_JUEGOS
} from "./types";
import ExploreService from "../services/ExploreService";
import { displayError } from "../utils";
import { toast } from 'react-toastify';

export const getPatrocinadores = () => (dispatch) => {
    ExploreService.getPatrocinadores()
        .then((res) => {
            const patrocinadores = res.data;
            dispatch({ type: EXPLORE_PATROCINADORES_RECIBIDOS, payload: patrocinadores });
        })
        .catch((error) => {
            displayError(dispatch, error);
        });
};

export const getJuegosPatrocinadores = ({gameType}) => (dispatch) => {
    ExploreService.getJuegosPatrocinadores({gameType})
        .then((res) => {
            const patrocinadores = res.data;
            dispatch({ type: EXPLORE_JUEGOS_PATROCINADORES_RECIBIDOS, payload: patrocinadores });
        })
        .catch((error) => {
            displayError(dispatch, error);
        });
};

export const getJuegosDestacados = ({gameType}) => (dispatch) => {
    ExploreService.getJuegosDestacados({gameType})
        .then((res) => {
            const juegosDestacados = res.data;
            dispatch({ type: EXPLORE_JUEGOS_DESTACADOS_RECIBIDOS, payload: juegosDestacados });
        })
        .catch((error) => {
            displayError(dispatch, error);
        });
};

export const getAllJuegos = ({currentPage, limit, gameType}) => (dispatch) => {
    ExploreService.getAllJuegos({currentPage, limit, gameType})
        .then((res) => {
            const {cards, n} = res.data;
            dispatch({ type: EXPLORE_ALL_JUEGOS_RECIBIDOS, payload: {cards, n} });
        })
        .catch((error) => {
            displayError(dispatch, error);
        });
};

export const searchJuegos = ({page, limit, search, gameType}) => (dispatch) => {
 
    ExploreService.searchJuegos({page, limit, search, gameType})
        .then((res) => {
            const {cards, n} = res.data;
            dispatch({ type: EXPLORE_SEARCH_JUEGOS_RECIBIDOS, payload: {cards, n} });
        })
        .catch((error) => {
            displayError(dispatch, error);
        });
};

export const searchJuegosNextPage = ({page, limit, search, gameType}) => (dispatch) => {
 
    ExploreService.searchJuegos({page, limit, search, gameType})
        .then((res) => {
            const {cards, n} = res.data;
            dispatch({ type: EXPLORE_SEARCH_NEXT_PAGE_JUEGOS_RECIBIDOS, payload: {cards, n} });
        })
        .catch((error) => {
            displayError(dispatch, error);
        });
};

export const resetJuegos = () => (dispatch) => {
    dispatch({ type: EXPLORE_RESET_JUEGOS });
};

export const getGamesProfile = async ({idPerfil, gameType, currentPage, limit}) => {
    try {
        const response = await ExploreService.getGamesProfile({idPerfil, gameType, currentPage, limit});
        let res = {
            cards: response.data.cards,
            n: response.data.n,
        }
        return res;
    } catch (e) {
        toast.error('Lo sentimos, estamos teniendo problemas obteniendo los juegos de este usuario.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const getHighlightsProfile = async ({idPerfil, gameType}) => {
    try {
        const response = await ExploreService.getHighlightsProfile({idPerfil, gameType});
        return response.data;
    } catch (e) {
        toast.error('Lo sentimos, estamos teniendo problemas obteniendo los juegos destacados de este usuario.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const getUserProfile = async (idPerfil) => {
    try {
        const response = await ExploreService.getUserProfile(idPerfil);
        return response.data;
    } catch (e) {
        toast.error('Lo sentimos, estamos teniendo problemas obteniendo los datos de este usuario.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const profileSearch = async ({ page, limit, idPerfil, search, gameType }) => {
    try {
        const response = await ExploreService.profileSearch({ page, limit, idPerfil, search, gameType });
        let res = {
            cards: response.data.cards,
            n: response.data.n,
        }
        return res;
    } catch (e) {
        toast.error('Lo sentimos, estamos teniendo problemas buscando juegos con esta búsqueda.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}
