import React, { Component } from 'react';

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return(
           <>
            {this.props.isMobile ? 
                <h6>{this.props.question}</h6>
                :
                <h1>{this.props.question}</h1>
            }
            </>
        );
    }
}

export default Question;