import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class EndULUModal extends Component {
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4 className='mb-2'>¿Estás seguro que deseas terminar este ULU?</h4>
                    <p>Perderás todos los puntos que hayas ganado.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='link'
                        className='link-gray'
                        onClick={this.props.onHide}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='outline-danger'
                        className='border-red-new'
                        onClick={this.props.terminarJuego}
                    >
                        Si, terminar ULU
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default EndULUModal;