import React from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import SidebarForm from "../components/SidebarForm";
import PreguntaCrucigrama from "../components/crucigrama/PreguntaCrucigrama";
import rectangulo from "../assets/Rectangulo.svg";
import {
  getJuego,
  createNuevoJuego,
  seleccionarJuego,
} from "../actions/juegoActions";
import {
  createNuevaPregunta,
  postPreguntas,
  setPropiedadPreguntaCrucigrama,
  eliminarPregunta,
  reestablecerPregunta,
} from "../actions/preguntasActions";
import { confirm } from "../actions/modalActions";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import { getColor, getColorName } from "../utils";

class CreacionCrucigrama extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      movil: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!isNaN(this.props.idJuego)) this.props.getJuego(this.props.idJuego);
    if (this.props.tipo === undefined) {
      this.props.createNuevoJuego("Crucigrama");
      this.props.seleccionarJuego("Crucigrama");
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    window.scrollTo(0, 0);
  }

  resize() {
    let currentNav = window.innerWidth <= 760;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  handleChange(evt, idPregunta, key) {
    const { value } = evt.target;
    this.props.setPropiedadPreguntaCrucigrama(idPregunta, key, value);
  }

  postPreguntas() {
    this.props.postPreguntas(
      this.props.preguntas,
      this.props.juego,
      this.props.idJuego,
      () => {
        navigate(`/editar/${this.props.juego.idJuego}`);
      }
    );
  }

  eliminarPregunta(pregunta) {
    this.props.confirm(
      `¿Estás seguro que deseas eliminar la pregunta ${pregunta.texto}?`,
      () => {
        this.props.eliminarPregunta(pregunta.idPregunta);
      }
    );
  }

  renderPalabras() {
    if (this.props.preguntas) {
      return this.props.preguntas.map((pregunta, index) => {
        if (String(pregunta.idPregunta)[0] === "d")
          return (
            <Button
              key={pregunta.idPregunta}
              variant="link"
              className="text-danger"
              onClick={() =>
                this.props.reestablecerPregunta(pregunta.idPregunta)
              }
            >
              Pregunta {index + 1} eliminada. Haz click para deshacer.
            </Button>
          );
        return (
          <PreguntaCrucigrama
            key={pregunta.idPregunta}
            index={index}
            pregunta={pregunta}
            handleChange={this.handleChange}
            eliminarPregunta={(pregunta) => this.eliminarPregunta(pregunta)}
          />
        );
      });
    }
  }

  renderVistaPrevia() {
    return (
      <>
        <Row>
          <p className="h6 mt-4 pt-3">Vista previa</p>
        </Row>
        <Row className="justify-content-start"></Row>
        <Row style={{ fontWeight: "bold" }} className="mr-5">
          <p>
            *El orden de las palabras puede variar una vez que des click en
            guardar
          </p>
        </Row>
      </>
    );
  }

  handleClick() {
    this.props.createNuevaPregunta(this.props.preguntas.length + 1);
  }

  renderAgregarPreguntas() {
    if (this.props.preguntas) {
      if (this.props.preguntas.length < 9) {
        return (
          <Row className="mt-4 pl-4 mr-2 hide-mobile">
            <Button
              variant="outline-danger"
              className="border-red-new height-lg px-3 py-2 mr-4"
              onClick={() =>
                this.props.createNuevaPregunta(this.props.preguntas.length + 1)
              }
            >
              <h6 className="py-0 px-0 my-0 mx-0">+ Agregar</h6>
            </Button>
          </Row>
        );
      }
    }
  }

  render() {
    return (
      <Container fluid className="px-0 overflow-hidden">
        <Row>
          <Col
            sm={12}
            md={3}
            xl={2}
            className="pl-1 pr-0 hide-mobile col-xl-2 col-md-3 col-12"
          >
            <SidebarForm
              idJuego={this.props.idJuego}
              preguntas={this.props.preguntas}
              juego={this.props.juego}
              createNuevaPregunta={this.props.createNuevaPregunta}
              tipo={this.props.tipo}
              movil={this.state.movil}
            />
          </Col>
          <Container
            className={`show-mobile px-4 py-3 pastel-${getColorName(1)}`}
            fluid
            style={{ color: getColor(1) }}
          >
            <Row>
              <Col xs={10}>
                <span className="h5">Creación de Crucigrama</span>
              </Col>
              <Col xs={2}>
                <Button
                  variant="link"
                  className="text-dark py-0"
                  onClick={() =>
                    this.setState({ showMenu: !this.state.showMenu })
                  }
                >
                  <i
                    className={`fa fa-chevron-${
                      this.state.showMenu ? "up" : "down"
                    }`}
                  ></i>
                </Button>
              </Col>
            </Row>
            {this.state.showMenu && (
              <Row
                className="py-2"
                onClick={() => navigate(`/editar/${this.props.idJuego}`)}
              >
                <Container fluid>
                  <span className="h5">Información General</span>
                </Container>
              </Row>
            )}
          </Container>
          <Col sm={12} md={9} xl={10}>
            <Row className="pb-2">
              <Container className="ml-0" fluid>
                <Row className="pl-4">
                  <h1 className="my-3">
                    <Image src={rectangulo} style={{ width: "3vw" }} />
                    <span className="ml-2">Preguntas de crucigrama</span>
                  </h1>
                </Row>
                {this.renderPalabras()}
                {this.renderAgregarPreguntas()}
              </Container>
            </Row>
            <Row className="py-3" />
            <Row className="justify-content-end mt-2 show-mobile">
              <Container className="text-right">
                <Row>
                  <Col>
                    <Button
                      variant="outline-danger"
                      className="border-red-new rounded-circle circle-button show-mobile fixed-bottom py-auto mt-5 mb-2 ml-auto"
                      style={{ marginRight: "180px", height: "58px" }}
                      onClick={() => {
                        this.handleClick();
                      }}
                    >
                      <Row className="align-items-center text-center justify-content-center">
                        <p className="h3 m-0 p-0 pt-1">+</p>
                      </Row>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      disabled={this.props.disabled}
                      variant="danger"
                      className="new-red-button px-3 py-3 mx-3 mt-5 mb-2 ml-auto fixed-bottom"
                      style={{ width: "15vw" }}
                      onClick={this.props.handleSubmit}
                    >
                      {this.props.idJuego ? "Guardar" : "Crear"}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Col>
          <Col xs={5} className="mt-5 hide-mobile">
            <Row className="justify-content-end mt-2">
              <Container className="text-right">
                <Button
                  variant="danger"
                  disabled={this.props.disabled}
                  className="new-red-button px-3 py-3 mx-3 mb-2 ml-auto mt-5 fixed-bottom"
                  style={{ width: "15vw" }}
                  onClick={() => this.postPreguntas()}
                >
                  {this.props.idJuego ? "Guardar" : "Crear"}
                </Button>
              </Container>
            </Row>
          </Col>
        </Row>
        <Row className="py-5" />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  juego: state.juego.juego,
  tipo: state.juego.tipo,
  preguntas: state.juego.preguntas,
  opciones: state.juego.opciones,
  disabled: state.juego.disabled,
});

export default connect(mapStateToProps, {
  setPropiedadPreguntaCrucigrama,
  createNuevaPregunta,
  reestablecerPregunta,
  createNuevoJuego,
  seleccionarJuego,
  eliminarPregunta,
  postPreguntas,
  getJuego,
  confirm,
})(CreacionCrucigrama);
