import React, { Component } from 'react';
import { getColor, getIcon, getColorName } from '../../utils';
import { connect } from "react-redux";
import { selectQuestionCrucigrama, setCrucigramaQuestionStatus } from '../../actions/jugarSoloActions';

class Crossword extends Component {
    constructor(props) {
        super(props);
        /**
         * Puzzle chars:
         *  '*' represents non editable cell
         *  '' represents non answered cell
         *  Any other character represents entered character
         */
        const preguntasMap = new Map();//Saves preguntas by their ID
        const coordinateMap = new Map();//Maps coors to their corresponding idPregunta {`x-y`: idPregunta}
        props.preguntas.forEach((pregunta) => {
            preguntasMap.set(pregunta.idPregunta, {...pregunta, contestada: false});
            if(pregunta.orientacion === 'horizontal') {
                for (let i = 0; i < pregunta.respuesta.length; i++) {
                    const key = `${pregunta.x + i}-${pregunta.y}`;
                    if(coordinateMap.has(key)){
                        coordinateMap.set(key, [...coordinateMap.get(key), pregunta.idPregunta]);
                    }
                    else{
                        coordinateMap.set(key, [pregunta.idPregunta]);
                    }
                }
            } else {
                for (let i = 0; i < pregunta.respuesta.length; i++) {
                    const key = `${pregunta.x}-${pregunta.y + i}`;
                    if(coordinateMap.has(key)){
                        coordinateMap.set(key, [...coordinateMap.get(key), pregunta.idPregunta]);
                    }
                    else{
                        coordinateMap.set(key, [pregunta.idPregunta]);
                    }
                }
            }
        });
        const firstIndex = props.preguntas.keys().next().value;
        this.state = {
            puzzle: [],//Puzzle to store user inputs
            currentCell: {//Where simulated cursor is at
                x: props.preguntas.get(firstIndex).x,
                y: props.preguntas.get(firstIndex).y
            },
            currentWord: props.preguntas.get(firstIndex),//Current word user is typing
            preguntasMap,
            coordinateMap
        }
        props.selectQuestionCrucigrama(props.preguntas.get(firstIndex).idPregunta);
        this.registerKey = this.registerKey.bind(this);
        this.validateCoordinates = this.validateCoordinates.bind(this);
        this.handleSelectCell = this.handleSelectCell.bind(this);
        this.checkAnswer = this.checkAnswer.bind(this);

    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.registerKey, true);
    }
    componentDidMount() {
        document.addEventListener('keydown', this.registerKey);
        let puzzle = this.state.puzzle;
        for (let i = 0; i < 9; i++) {
            const row = [];
            for (let j = 0; j < 9; j++) {
                row.push('*');
            }
            puzzle.push(row);
        }

        this.props.preguntas.forEach((pregunta) => {
            if(pregunta.orientacion === 'horizontal') {
                for (let i = 0; i < pregunta.respuesta.length; i++) {
                    puzzle[pregunta.y][pregunta.x + i] = '';
                }
            } else {
                for (let i = 0; i < pregunta.respuesta.length; i++) {
                    puzzle[pregunta.y + i][pregunta.x] = '';
                }
            }
        });  
        
        this.setState({puzzle});
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.letterPressed !== this.props.letterPressed) {
            this.registerKey(this.props.letterPressed);
        }
        if(prevState.currentWord.idPregunta !== this.props.selectedQuestion) {
            this.setState({currentWord: this.state.preguntasMap.get(this.props.selectedQuestion)});
        }
    }


    checkAnswer(){
        let currentWord = this.state.currentWord;
        for (let i = 0; i < currentWord.respuesta.length; i++) {
            const letra = currentWord.respuesta.charAt(i);
            if(currentWord.orientacion === 'horizontal') {
                if(this.state.puzzle[currentWord.y][currentWord.x + i] !== letra){
                    return false;
                }
            } else {
                if(this.state.puzzle[currentWord.y + i][currentWord.x] !== letra){
                    return false;
                }
            }
        }
        return true;
    }
    validateCoordinates({x, y}){
        const { puzzle } = this.state;
        return (
            x < puzzle.length &&
            x >= 0 &&
            y < puzzle.length &&
            y >= 0 &&
            puzzle[y][x] !== '*'
        );
    }
    registerKey(e) {
        //recibes una letra del teclado.
        const key = e.key.toLowerCase();
        //Handle arrow keys
        switch (key) {
            case 'arrowup': {
                this.travel('up');
                return;
            }
            case 'arrowdown': {
                this.travel('down');
                return;
            }
            case 'arrowright': {
                this.travel('right');
                return;
            }
            case 'arrowleft': {
                this.travel('left');
                return;
            }
            default:
                break;
        }

        const currentQuestions =  this.state.coordinateMap.get(`${this.state.currentCell.x}-${this.state.currentCell.y}`);
        let answeredCount = 0;
        for (let i = 0; i < currentQuestions.length; i++) {
            if(this.state.preguntasMap.get(currentQuestions[i]).contestada)
                answeredCount++;
        }
        if(answeredCount>=currentQuestions.length){
            return;//Dont do anything if is answered in both questions
        }
        const puzzle = this.state.puzzle;
        //handle backspace
        if(key==='backspace'){
            if(puzzle[this.state.currentCell.y][this.state.currentCell.x] === ''){
                //go left or up
                if(this.state.currentWord.orientacion === 'horizontal'){
                    this.travel('left');
                }
                else{
                    this.travel('up');
                }
            }
            puzzle[this.state.currentCell.y][this.state.currentCell.x] = '';
            this.setState({puzzle});
            return;
        }
        //Handle characters
        if(!/^[a-zA-Z0-9 ]*$/g.test(key) || key.length > 1){//do nothing with invalid characters
            return;
        }
        puzzle[this.state.currentCell.y][this.state.currentCell.x] = key;
        this.setState({puzzle});
        //Check if answer is correct
        const isCorrect = this.checkAnswer();
        if(isCorrect){//Set status in map to correct
            this.props.setCrucigramaQuestionStatus(this.state.currentWord.idPregunta);
            const preguntasMap = this.state.preguntasMap;
            preguntasMap.set(this.state.currentWord.idPregunta, {
                ...preguntasMap.get(this.state.currentWord.idPregunta),
                contestada: true
            });
            this.props.responder(this.state.currentWord.idPregunta);
            //travel to next cell not answered
            let x = this.state.currentCell.x;
            let y = this.state.currentCell.y;
            let foundUnansweredWord = false;
            this.state.preguntasMap.forEach((pregunta) =>{
                if(!pregunta.contestada && !foundUnansweredWord && pregunta.idPregunta !== this.state.currentWord.idPregunta){
                    //check if first letter has been answered
                    if(puzzle[pregunta.y][pregunta.x] === ''){//not answered
                        x = pregunta.x;
                        y = pregunta.y;
                    }
                    else{//has been answered
                        if(pregunta.orientacion === 'horizontal'){
                            x = pregunta.x + 1;
                            y = pregunta.y;
                        }
                        else{
                            x = pregunta.x;
                            y = pregunta.y + 1;
                        }
                    }
                    foundUnansweredWord = true;
                }
            });
            
            this.setState({
                preguntasMap
            }, () => {
                this.handleSelectCell({x,y});
            });
        }
        else{
            //Handle where to travel
            if(this.state.currentWord.orientacion === 'horizontal'){//Try to travel right
                this.travel('right');
            }
            else{//Try to travel down
                this.travel('down');
            }
        }
    }
    handleSelectCell({x, y}){
        //find word in that cell
        const wordsAtCoor = [...this.state.coordinateMap.get(`${x}-${y}`)].filter(w => !this.state.preguntasMap.get(w).contestada);//Remove answered questions from options
        let currentWord = this.state.currentWord;
        if(wordsAtCoor.length > 0 && (!wordsAtCoor.includes(this.state.currentWord.idPregunta) || wordsAtCoor.length === 1)){//Only change currentWord if there is no intersection
            currentWord = this.state.preguntasMap.get(wordsAtCoor[0]);
        }

        this.props.selectQuestionCrucigrama(currentWord.idPregunta);

        this.setState({
            currentCell: {
                x,
                y
            },
            currentWord
        });
    }
    //Method to try to change in certain direction
    travel(direction){
        switch (direction) {
            case 'up': {
                const x = this.state.currentCell.x;
                const y = this.state.currentCell.y - 1;
                if(this.validateCoordinates({x,y})){
                    this.handleSelectCell({x, y});
                }
                return;
            }
            case 'down': {
                const x = this.state.currentCell.x;
                const y = this.state.currentCell.y + 1;
                if(this.validateCoordinates({x,y})){
                    this.handleSelectCell({x, y});
                }
                return;
            }
            case 'right': {
                const x = this.state.currentCell.x + 1;
                const y = this.state.currentCell.y;
                if(this.validateCoordinates({x,y})){
                    this.handleSelectCell({x, y});
                }
                return;
            }
            case 'left': {
                const x = this.state.currentCell.x - 1;
                const y = this.state.currentCell.y;
                if(this.validateCoordinates({x,y})){
                    this.handleSelectCell({x, y});
                }
                return;
            }
            default:
                break;
        }
    }

    cellClassname(i,j) {
        let arrId = this.state.coordinateMap.get(`${j}-${i}`);

        if(arrId === undefined)
            return;

        let style = [];

        for(let k = 0; k < arrId.length; k++) {

            let pregunta = this.state.preguntasMap.get(arrId[k]);

            let color;
            
            if(!pregunta.contestada)
                color = getColorName(pregunta.idPregunta % 5);
            else
                color = 'answered';

            if(this.state.currentWord.idPregunta === arrId[k]) {
                style.push(`cell-selected-${color} cell-selected`);
            }

            if(pregunta.orientacion === 'horizontal' && j === pregunta.x) {
                style.push(`crossword-cell-horizontal-starting-${color} horizontal-starting`)
            } else if(pregunta.orientacion === 'horizontal' && j === pregunta.x + pregunta.respuesta.length - 1) {
                style.push(`crossword-cell-horizontal-ending-${color} horizontal-ending`)
            } else if(pregunta.orientacion === 'horizontal') {
                style.push(`crossword-cell-horizontal-middle-${color}`)
            } else if(pregunta.orientacion === 'vertical' && i === pregunta.y) {
                style.push(`crossword-cell-vertical-starting-${color} vertical-starting`)
            } else if(pregunta.orientacion === 'vertical' && i === pregunta.y + pregunta.respuesta.length - 1) {
                style.push(`crossword-cell-vertical-ending-${color} vertical-ending`)
            } else if(pregunta.orientacion === 'vertical') {
                style.push(`crossword-cell-vertical-middle-${color}`)
            }
        }

        return style.join(' ');
    }

    renderIcon(i, j) {
        let arrId = this.state.coordinateMap.get(`${j}-${i}`);

        if(arrId === undefined)
            return;

        let color;
        let icon;
        let pregunta;

        for(let k = 0; k < arrId.length; k++) {
            pregunta = this.state.preguntasMap.get(arrId[k]);
            if((pregunta.orientacion === 'horizontal' && j === pregunta.x) || (pregunta.orientacion === 'vertical' && i === pregunta.y)) {
                color = getColor(pregunta.idPregunta % 5);
                icon = getIcon(pregunta.idPregunta % 18, {color, newclass: `m-0`, width: '10px', height: '10px'});
            }
        }


        return icon;

    }

    render() {
        let { currentCell} = this.state;
        return(
            <table className='crossword-table mx-auto'>
                <tbody>
                    {this.state.puzzle.map((row, i) => (
                        <tr key={`crossword-grid-row-${i}`} className='crossword-grid-row'>
                            {row.map((col, j) => (
                                <td 
                                    key={`crossword-grid-cell-${j}-${i}`}
                                    onClick={() => col !== '*' && this.handleSelectCell({x: j, y: i})}
                                    className={
                                        `${col !== '*' ? `writteable ${this.cellClassname(i, j)} crossword-grid-col` : 'non-writteable crossword-grid-col'} ${(i === currentCell.y && j === currentCell.x) && 'current-cell'}`
                                    }
                                >
                                    {col !== '*' && col}
                                    <div className='cell-icon'>
                                        {this.renderIcon(i, j)}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

const mapStateToProps = (state) => ({
    preguntas: state.crucigramaSolo.preguntas,
    selectedQuestion: state.crucigramaSolo.selectedQuestion,
});

export default connect(mapStateToProps, { selectQuestionCrucigrama, setCrucigramaQuestionStatus  })(Crossword);