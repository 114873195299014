/**
 * Mis Juegos
 */
export const MISJUEGOS_RECIBIDOS = "MISJUEGOS_RECIBIDOS";
export const MISJUEGOS_SORT = "MISJUEGOS_SORT";
export const MISJUEGOS_FILTRAR = "MISJUEGOS_FILTRAR";
export const DUPLICAR_JUEGO = "DUPLICAR_JUEGO";
export const ELIMINAR_JUEGO = "ELIMINAR_JUEGO";
export const SHOW_OPCIONES = "SHOW_OPCIONES";
export const SET_PROPIEDAD_MIJUEGO = "SET_PROPIEDAD_MIJUEGO";

/**
 * Crear Juego
 */
export const CREATE_NUEVO_JUEGO = "CREATE_NUEVO_JUEGO";
export const SET_PROPIEDAD_JUEGO = "SET_PROPIEDAD_JUEGO";
export const JUEGO_RECIBIDO = "JUEGO_RECIBIDO";
export const SELECCIONAR_JUEGO = "SELECCIONAR_JUEGO";
export const IMAGES_SEARCH_JUEGO_RECEIVED = "IMAGES_SEARCH_JUEGO_RECEIVED";
export const LOADING_IMAGES_SEARCH = "LOADING_IMAGES_SEARCH";

/**
 * Preguntas
 */
export const CREATE_NUEVA_PREGUNTA = "CREATE_NUEVA_PREGUNTA";
export const SET_PROPIEDAD_PREGUNTA = "SET_PROPIEDAD_PREGUNTA";
export const ELIMINAR_PREGUNTA = "ELIMINAR_PREGUNTA";
export const REESTABLECER_PREGUNTA = "REESTABLECER_PREGUNTA";
export const DISABLE_BUTTON = "DISABLE_BUTTON";
export const ENABLE_BUTTON = "ENABLE_BUTTON";
export const EDIT_PREGUNTA = "EDIT_PREGUNTA";
export const SAVE_CURRENT_PREGUNTA = "SAVE_CURRENT_PREGUNTA";
export const SET_PROPIEDAD_PREGUNTA_CRUCIGRAMA =
  "SET_PROPIEDAD_PREGUNTA_CRUCIGRAMA";
export const DUPLICAR_PREGUNTA = "DUPLICAR_PREGUNTA";
export const REORDENAR_PREGUNTAS = "REORDENAR_PREGUNTAS";
export const LOG_TIME = "LOG_TIME";
/**
 * Opcion
 */
export const CREATE_NUEVA_OPCION = "CREATE_NUEVA_OPCION";
export const SET_PROPIEDAD_OPCION = "SET_PROPIEDAD_OPCION";
export const ELIMINAR_OPCION = "ELIMINAR_OPCION";
export const REESTABLECER_OPCION = "REESTABLECER_OPCION";
/**
 * User
 */
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const USER_CREATED = "USER_CREATED";
export const SET_PROPIEDAD_USER = "SET_PROPIEDAD_USER";
export const SET_PROPIEDAD_LOGIN = "SET_PROPIEDAD_LOGIN";
export const PERFIL_RECIBIDO = "PERFIL_RECIBIDO";
/**
 * Escuelas
 */
export const ESCUELAS_RECIBIDAS = "ESCUELAS_RECIBIDAS";
export const SET_ARG_ESCUELA = "SET_ARG_ESCUELA";
export const ESTADOSESCUELAS_RECIBIDOS = "ESTADOSESCUELAS_RECIBIDOS";
export const MUNICIPIOS_RECIBIDOS = "MUNICIPIOS_RECIBIDOS";
/**
 * Modal
 */
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR = "CLEAR";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const MODAL_COMPONENT = "MODAL_COMPONENT";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
/**
 * Spinners
 */
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_SPINNER = "SHOW_SPINNER";
/**
 * Jugar
 */
export const PREGUNTA_RECIBIDA = "PREGUNTA_RECIBIDA";
export const OPCIONES_RECIBIDAS = "OPCIONES_RECIBIDAS";
export const NEXT = "NEXT";
export const SET_HORA_SERVER = "SET_HORA_SERVER";
export const CLEAR_JUEGO = "CLEAR_JUEGO";
export const SET_PATROCINADOR_JUEGO = "SET_PATROCINADOR_JUEGO";
export const ENABLE_REPORTE = "ENABLE_REPORTE";
export const DISABLE_REPORTE = "DISABLE_REPORTE";
export const FINISHED_JUEGO = "FINISHED_JUEGO";
/**
 * Crucigrama
 */
export const PREGUNTAS_CRUCIGRAMA_RECIBIDAS = "PREGUNTAS_CRUCIGRAMA_RECIBIDAS";
export const RESPUESTA_CRUCIGRAMA_RECIBIDA = "RESPUESTA_CRUCIGRAMA_RECIBIDA";
/**
 * Jugador
 */
export const JOIN_JUEGO = "JOIN_JUEGO";
export const SET_APODO = "SET_APODO";
export const FEEDBACK_RECIBIDO = "FEEDBACK_RECIBIDO";
export const FRASE_RECIBIDA = "FRASE_RECIBIDA";
export const SCORE_RECIBIDO = "SCORE_RECIBIDO";
export const MONGOID_RECIBIDO = "MONGOID_RECIBIDO";
export const SET_SCORE = "SET_SCORE";
export const SET_DATOS_JUEGO = "SET_DATOS_JUEGO";
export const SET_LUGAR = "SET_LUGAR";
/**
 * Host
 */
export const PIN_RECIBIDO = "PIN_RECIBIDO";
export const INICIAR_JUEGO = "INICIAR_JUEGO";
export const SCOREBOARD_RECIBIDO = "SCOREBOARD_RECIBIDO";
export const JUGADOR_RECIBIDO = "JUGADOR_RECIBIDO";
export const RESPUESTA_RECIBIDA = "RESPUESTA_RECIBIDA";
export const SET_COUNTER = "SET_COUNTER";
export const SET_ID_JUEGO = "SET_ID_JUEGO";
export const SET_ID_SESION = "SET_ID_SESION";
/**
 * Ahorcado
 */
export const LETRA_RESPONDIDA = "LETRA_RESPONDIDA";
export const CLEAR_CONTESTADAS = "CLEAR_CONTESTADAS";
export const AGREGAR_RESPUESTA_AHORCADO = "AGREGAR_RESPUESTA_AHORCADO";
export const JUGADOR_AHORCADO = "JUGADOR_AHORCADO";
export const JUGADOR_FINISHED = "JUGADOR_FINISHED";
/**
 * Estados
 */
export const ESTADOS_RECIBIDOS = "ESTADOS_RECIBIDOS";
export const SET_ESTADO = "SET_ESTADO";
export const PATROCINIOS_RECIBIDOS = "PATROCINIOS_RECIBIDOS";
export const SET_PATROCIONIOS = "SET_PATROCIONIOS";
/**
 * Patrocinadores
 */
export const PATROCINADORES_RECIBIDOS = "PATROCINADORES_RECIBIDOS";
export const EDIT_PATROCINADOR = "EDIT_PATROCINADOR";
export const CREATE_PATROCINADOR = "CREATE_PATROCINADOR";
export const SET_PROPIEDAD_PATROCINADOR = "SET_PROPIEDAD_PATROCINADOR";
export const SAVE_PATROCINADOR = "SAVE_PATROCINADOR";
export const SHOW_OPCIONES_PATROCINADOR = "SHOW_OPCIONES_PATROCINADOR";
export const PATROCINADOR_RECIBIDO = "PATROCINADOR_RECIBIDO";
export const SET_SEGMENTO_PATROCINADOR = "SET_SEGMENTO_PATROCINADOR";
/**
 * Premios
 */
export const PREMIOS_RECIBIDOS = "PREMIOS_RECIBIDOS";
export const EDIT_PREMIO = "EDIT_PREMIO";
export const SET_PROPIEDAD_PREMIO = "SET_PROPIEDAD_PREMIO";
export const SAVE_PREMIO = "SAVE_PREMIO";
export const CREATE_PREMIO = "CREATE_PREMIO";
export const DELETE_PREMIO = "DELETE_PREMIO";
export const ERROR_PREMIO = "ERROR_PREMIO";
export const SEGMENTOS_DISPONIBLES = "SEGMENTOS_DISPONIBLES";
/**
 * Frases
 */
export const FRASES_RECIBIDAS = "FRASES_RECIBIDAS";
export const CREATE_FRASE = "CREATE_FRASE";
export const EDIT_FRASE = "EDIT_FRASE";
export const SET_PROPIEDAD_FRASE = "SET_PROPIEDAD_FRASE";
export const SAVE_FRASE = "SAVE_FRASE";
export const DELETE_FRASE = "DELETE_FRASE";
/**
 * Logos
 */
export const LOGOS_RECIBIDOS = "LOGOS_RECIBIDOS";
export const SHOW_LOGO_PATROCINADOR = "SHOW_LOGO_PATROCINADOR";
export const AGREGAR_LOGO_PATROCINADOR = "AGREGAR_LOGO_PATROCINADOR";
export const EDIT_LOGO = "EDIT_LOGO";
export const SET_PROPIEDAD_LOGO = "SET_PROPIEDAD_LOGO";
export const SET_LOGO = "SET_LOGO";
export const DELETE_LOGO = "DELETE_LOGO";
export const SAVE_LOGO = "SAVE_LOGO";
export const CLEAR_EDITADO = "CLEAR_EDITADO";
/**
 * Codigos
 */
export const CREATE_CODIGO = "EDIT_CODIGO";
export const SET_PROPERTY_CODIGO = "SET_PROPERTY_CODIGO";
export const EDIT_CODIGO = "EDIT_CODIGO";
export const SAVE_CODIGO = "SAVE_CODIGO";
export const CODIGOS_RECIBIDOS = "CODIGOS_RECIBIDOS";
export const EDITAR_CODIGOS = "EDITAR_CODIGOS";
export const CLEAR_CODIGOS = "CLEAR_CODIGOS";
export const DELETE_CODIGO = "DELETE_CODIGO";
export const SET_PROPIEDAD_CODIGO = "SET_PROPIEDAD_CODIGO";
export const SET_PROPIEDAD_EXCEL = "SET_PROPIEDAD_EXCEL";
export const SET_EXCEL = "SET_EXCEL";
export const ERROR_CODIGOS = "ERROR_CODIGOS";
export const CAMBIAR_TIPO = "CAMBIAR_TIPO";
/**
 * Reportes
 */
export const REPORTES_RECIBIDOS = "REPORTES_RECIBIDOS";

/**
 * Explore
 */

export const EXPLORE_PATROCINADORES_RECIBIDOS = "EXPLORE_PATROCINADORES_RECIBIDOS";
export const EXPLORE_JUEGOS_PATROCINADORES_RECIBIDOS = "EXPLORE_JUEGOS_PATROCINADORES_RECIBIDOS";
export const EXPLORE_JUEGOS_DESTACADOS_RECIBIDOS = "EXPLORE_JUEGOS_DESTACADOS_RECIBIDOS";
export const EXPLORE_ALL_JUEGOS_RECIBIDOS = "EXPLORE_ALL_JUEGOS_RECIBIDOS";
export const EXPLORE_SEARCH_JUEGOS_RECIBIDOS = "EXPLORE_SEARCH_JUEGOS_RECIBIDOS";
export const EXPLORE_SEARCH_NEXT_PAGE_JUEGOS_RECIBIDOS = "EXPLORE_SEARCH_NEXT_PAGE_JUEGOS_RECIBIDOS";
export const EXPLORE_RESET_JUEGOS = "EXPLORE_RESET_JUEGOS";

/**
 * Admin
 */

export const IS_ADMIN = "IS_ADMIN";

/***
 *  Jugar Solo
 */

export const SOLO_JUEGO_RECIBIDO = "SOLO_JUEGO_RECIBIDO";
export const SOLO_CLEAR_STORE = "SOLO_CLEAR_STORE";
export const SOLO_SUGERENCIA_GUARDADA = "SOLO_SUGERENCIA_GUARDADA";
export const SOLO_CRUCIGRAMA_RECIBIDO = "SOLO_CRUCIGRAMA_RECIBIDO";
export const SOLO_CRUCIGRAMA_SELECT_QUESTION = "SOLO_CRUCIGRAMA_SELECT_QUESTION";
export const SOLO_CRUCIGRAMA_SET_QUESTION_STATUS = "SOLO_CRUCIGRAMA_SET_QUESTION_STATUS";
