import React, { Component } from "react";
import ReactGA from "react-ga";
import {
  Container,
  Spinner,
  Row,
  Col,
  Dropdown,
  Image
} from "react-bootstrap";
import TablaJuegos from "../components/tablaJuegos";
import FilaJuego from "../components/filaJuego";
import SearchBox from "../components/SearchBox";
import Sidebar from "../components/Sidebar";
import { signOut } from "../actions/userActions";
import { setJuego } from "../actions/jugarActions";
import {
  getMisJuegos,
  sortJuegos,
  eliminarJuego,
  duplicarJuego,
  showOpciones,
  clearJuego,
  setPropiedadMiJuego,
} from "../actions/misJuegosActions";
import { confirm, alert } from "../actions/modalActions";
import { connect } from "react-redux";
import { searchRows } from "../utils";
import { navigate } from "@reach/router";
import { options, tipos, CustomToggle } from "../utils";
import DropdownMisJuegos from "../components/DropdownMisJuegos";
import moment from "moment";
import logoTristeEmptyState from "../assets/logo-triste-empty-state.png";

class MisJuegos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      searchResult: undefined,
      movil: false,
      option: "Más reciente",
    };
    this.searchJuegos = this.searchJuegos.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.eliminarJuego = this.eliminarJuego.bind(this);
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.time = moment();
    ReactGA.pageview("/misjuegos");
    this.props.getMisJuegos();
    this.props.clearJuego();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.misjuegos && this.props.misjuegos) {
      this.handleSort(options[0]);
    }
  }

  componentWillUnmount() {
    const time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      label: "Tiempo en Pantalla Principal",
      category: "Mis Juegos",
      variable: "seconds",
      value: time,
    });
  }

  resize() {
    let currentNav = window.innerWidth <= 760;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  eliminarJuego(juego) {
    this.props.confirm(
      `¿Estás seguro que deseas eliminar el juego ${juego.titulo}? Esta acción no puede deshacerse.`,
      () => {
        this.props.eliminarJuego(juego.idJuego);
      }
    );
  }

  renderMisJuegos() {
    if (!this.props.misjuegos)
      return <Spinner animation="border" variant="dark" />;
    if (this.props.misjuegos.length === 0) return (
      <Row className="justify-content-center" style={{minHeight: '60vh'}}>
        <Col md={3} className="text-center vertical-center">
          <Image
            src={logoTristeEmptyState}
            className="d-block mx-auto mb-3"
            style={{ height: 100 }}
          />
          <h6 style={{color: "#B4B4B4"}}>
            ¡Oh no! Parece que aún no has creado ningún ULU.
            No te preocupes, solo haz click en "Crear nuevo ULU".
          </h6>
        </Col>
      </Row>
    );
    let juegos = this.props.misjuegos;
    if (this.state.searchResult) {
      juegos = this.state.searchResult;
    }
    let { filter } = this.state;
    if (filter === "Todos") filter = null;
    if (filter !== null) {
      if (filter === "Favoritos") {
        juegos = juegos.filter((juego) => juego.favorito);
      } else {
        juegos = juegos.filter((juego) => juego.tipo === this.state.filter);
      }
    }
    return (
      <>
        <TablaJuegos
          sortJuegos={this.props.sortJuegos}
          orden={this.props.orden}
          opcion={this.props.opcion}
        >
          {juegos.map((juego, index) => (
            <FilaJuego
              juego={juego}
              index={index}
              key={juego.idJuego}
              setJuego={this.props.setJuego}
              navigate={navigate}
              editarJuego={(idJuego) => navigate(`/editar/${idJuego}`)}
              showOpciones={this.props.showOpciones}
              duplicarJuego={this.props.duplicarJuego}
              eliminarJuego={this.eliminarJuego}
              opciones={this.props.opciones}
              alert={this.props.alert}
              activo={juego.activo}
              setPropiedadMiJuego={this.props.setPropiedadMiJuego}
              user={this.props.user}
            />
          ))}
        </TablaJuegos>
      </>
    );
  }

  handleFilter(opcion) {
    this.setState({ filter: opcion });
    ReactGA.event({
      category: "Filter",
      action: `Filtrar por ${opcion}`,
      label: `Filtrar por ${opcion}`,
    });
  }

  searchJuegos(query) {
    let searchResult = searchRows(query, this.props.misjuegos);
    this.setState({ searchResult });
    ReactGA.event({
      category: "Search",
      action: "Buscar Mis Juegos",
      label: "Buscar Mis Juegos",
    });
  }

  renderDisclaimer() {
    if (window.innerWidth <= 576)
      return (
        <Row>
          <Col>
            <p className="text-muted">
              *En móvil no se puede ser host de un juego
            </p>
          </Col>
        </Row>
      );
  }

  handleSort(option) {
    this.props.sortJuegos(option.value, option.name);
    this.setState({ option: option.name });
    ReactGA.event({
      category: "Sort",
      action: `Ordenar por ${option.name}`,
      label: `Ordenar por ${option.name}`,
    });
  }

  renderSort() {
    if (options)
      return options.map((option) => (
        <Dropdown.Item
          key={option.value}
          onClick={() => this.handleSort(option)}
        >
          {option.name}
        </Dropdown.Item>
      ));
  }

  renderSelected() {
    if (this.props.opcion) {
      let selected = options.find(
        (option) => option.value === this.props.opcion
      );
      if (selected) return selected.name;
    }
    return this.state.option;
  }

  render() {
    return (
      <Container fluid={true} className="mb-xs-65px">
        <Row>
          <Col
            sm={4}
            md={3}
            xl={2}
            className="bg-white px-0 hide-mobile"
            style={{ minHeight: "100vh" }}
          >
            <Sidebar
              usuario={this.props.user}
              handleFilter={this.handleFilter}
              movil={this.state.movil}
              filter={this.state.filter}
            />
          </Col>
          <DropdownMisJuegos
            tipos={tipos}
            tipo={this.state.filter}
            handleFilter={this.handleFilter}
          />
          <Col sm={12} md={9} xl={10} className="pt-2">
            <Row>
              <Col>
                <h1>Mis Juegos</h1>
              </Col>
            </Row>
            <Row className="my-3 justify-content-between">
              <Col xs={12} sm={6} md={6} lg={4}>
                <SearchBox
                  searchModifier={this.searchJuegos}
                  placeholder="  &#61442;   Buscar"
                />
              </Col>
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={5}
                className="sort-container pr-4 pt-3"
              >
                <span className="mr-1 px-0">Ordenar por:</span>

                <Dropdown
                  drop="down"
                  style={{ backgroundColor: "transparent", color: '#171717 !important' }}
                  className="d-inline custom-toggle font-weight-bold black-text"
                >
                  <Dropdown.Toggle
                    id="ordenar"
                    as={CustomToggle}
                    className="text-left black-text"
                  >
                    {this.renderSelected()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-right">
                    {this.renderSort()}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            {this.renderDisclaimer()}
            <Row className="overflow-x-auto">
              <Container fluid>{this.renderMisJuegos()}</Container>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  misjuegos: state.misjuegos.juegos,
  orden: state.misjuegos.orden,
  opcion: state.misjuegos.opcion,
  opciones: state.misjuegos.opciones,
  user: state.user.user

});

export default connect(mapStateToProps, {
  getMisJuegos,
  duplicarJuego,
  eliminarJuego,
  showOpciones,
  sortJuegos,
  setJuego,
  signOut,
  confirm,
  alert,
  clearJuego,
  setPropiedadMiJuego,
})(MisJuegos);
