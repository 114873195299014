import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../../../assets/logo-cara.png";
import asterisco from "../../../assets/Asterisco.svg";
import LetraAhorcado from "../../../components/ahorcado/LetraAhorcado";
import LoadingFace from "../../../components/LoadingFace";
import { navigate } from "@reach/router";

class HostPreguntaAhorcado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ahorcado: null,
      respuestas: 0,
      idPregunta: 0
    };
  }

  componentDidMount() {
    this.setState({ ahorcado: this.renderAhorcado() });
    let { tiempo } = this.props.pregunta;
    if(!tiempo) tiempo = this.props.juego.tiempo;
    this.props.setCounter(tiempo, this.props.displayScore);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if(this.state.respuestas < this.props.respuestas.length) {      
      this.setState({ respuestas: this.state.respuestas + 1, ahorcado: this.renderAhorcado() });
    }
    if(prevProps.respuestas.length > 0 && this.props.respuestas.length === 0) {
      this.setState({ ahorcado: this.renderAhorcado() });
    }
    if(this.state.idPregunta !== this.props.pregunta.idPregunta) {
      this.setState({ idPregunta: this.props.pregunta.idPregunta, respuestas: 0 });
      this.props.clearContestadas();
    }
  }

  renderAhorcado() {
    let palabra = this.props.pregunta.respuesta.toLowerCase();        
    let palabras = palabra.split(" ");
    let contestadas = new Set();
    let respuestas = {};
    if(this.props.respuestas) {
      this.props.respuestas.forEach(respuesta => {
        respuesta.letra = respuesta.letra.toLowerCase();
        let { letra } = respuesta;        
        if(!respuestas[letra]) respuestas[letra] = 1;
        else respuestas[letra]++;
        if(this.props.jugadores.length === respuestas[letra]) contestadas.add(letra.toLowerCase());
      });
    }
    let letrasPalabra = new Set();
    let arreglo = Array.from(palabra);
    arreglo.forEach(letra => {
      letrasPalabra.add(letra);
    });
    if (palabras != null) {
      let j = 0;
      let parent = this.refs.parent;
      let maxWidth = parent.getBoundingClientRect().width;
      return palabras.map((palabra, indexPalabra) => {
        let letras = Array.from(palabra);
        let children = <span className="ml-5"></span>;
        if (indexPalabra === 0) j += letras.length * 65;
        else if (j + letras.length * 65 > maxWidth) {
          j = letras.length * 65;
          children = <br />;
        } else j += letras.length * 65;
        return letras.map((letra, index) => {
          return (
            <LetraAhorcado
              key={index}
              letra={letra}
              jugadores={this.props.jugadores}
              contestadas={contestadas}
              respuestas={respuestas[letra]}
            >
              {index === 0 && indexPalabra > 0 ? children : ""}
            </LetraAhorcado>
          );
        });
      });
    }
  }

  renderImage() {
    let src;
    if (
      this.props.pregunta.idAdjunto &&
      this.props.pregunta.idAdjunto != null
    ) {
      src = `${this.props.base_url}/adjuntos/${this.props.pregunta.idAdjunto}`;
      return (
        <Row className="mt-4">
          <Col md={4}>
            <Image src={src} className="question-img"/>
          </Col>
          <Col md={8} ref="parent" className="align-content-around">
            <p className="h2 mb-4">{this.props.pregunta.hint}</p>
            <Container className="text-center">{this.state.ahorcado}</Container>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className="mt-4">
          <Col md={4}>
            <LoadingFace time={this.props.pregunta.tiempo} />
          </Col>
          <Col md={8} ref="parent" className="align-content-around">
            <p className="h2 mb-4">{this.props.pregunta.hint}</p>
            <Container className="text-center">{this.state.ahorcado}</Container>
          </Col>
        </Row>
      );
    }
  }

  render() {
    let { segundos } = this.props;
    const minutos = parseInt(segundos / 60);
    segundos = segundos % 60;
    return (
      <Container fluid>
        <Row className="pt-3 justify-content-center pb-4">
          <Col xs={3}>
            <Image src={logo} style={{ width: "80px" }} onClick={() => navigate('/explorar')} />
          </Col>
          <Col xs={6}>
            <Row className="justify-content-center align-items-center text-center">
              <Col>
                <h1>
                  <Image src={asterisco} style={{ width: 30 }} className="pb-2 mr-2" />
                  {this.props.juego ? this.props.juego.titulo : "Ahorcado"}
                </h1>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center text-center">
              <Col>
                <h6 className="text-muted">
                  <i className="far fa-clock mr-1"></i>{minutos}m {segundos}s
                </h6>
              </Col>
            </Row>
          </Col>
          <Col xs={3}></Col>
        </Row>
        {this.renderImage()}
      </Container>
    );
  }
}

export default HostPreguntaAhorcado;
