import React, { Component } from "react";
import ReactGA from "react-ga";
import { Row, Col } from "react-bootstrap";
import CardScoreboard from "../../components/CardScoreboard";
import HeaderHost from "../../components/HeaderHost";
import OpcionButton from "../../components/OpcionButton";
import moment from "moment";

class Scoreboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movil: false,
    };
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.time = moment();
    this.resize();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      label: "Tiempo en Pantalla de Respuestas",
      variable: "seconds",
      category: "Respuestas",
      value: time,
    });
  }

  resize() {
    let currentNav = window.innerWidth <= 576;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  renderOpciones(width) {
    if (this.props.opciones)
      return this.props.opciones.map((opcion, index) => (
        <OpcionButton
          key={opcion.idOpcion}
          index={index}
          idOpcion={opcion.idOpcion}
          texto={opcion.texto}
          correcta={opcion.correcta}
          width={width}
          scoreboard
        />
      ));
  }

  renderPregunta() {
    if (this.props.pregunta !== null) {
      if (this.props.juego.tipo !== "Trivia") return this.props.pregunta.hint;
      return this.props.pregunta.texto;
    }
  }

  renderPreguntaRespondida() {
    return (
      <Col xs={12} sm={8} className={this.state.movil && "pt-3"}>
        <Row
          className={`no-gutters ${this.state.movil && "text-center pt-3"}`}
        >
          <Col xs={12}>
            <h2>
              P: {this.renderPregunta()}
            </h2>
          </Col>
        </Row>
        <Row className='no-gutters'>
          {this.renderOpciones(this.state.movil && "full")}
          {this.renderPalabra()}
        </Row>
      </Col>
    );
  }

  renderScoreboard() {
    return (
      <Col xs={12} sm={4} className={this.state.movil ? "ml-n1 pr-3" : "px-3"}>
        <CardScoreboard scoreboard={this.props.scoreboard} />
      </Col>
    );
  }

  renderPage() {
    if (this.state.movil) {
      return (
        <>
          {this.renderScoreboard()}
          {this.renderPreguntaRespondida()}
        </>
      );
    } else {
      return (
        <>
          {this.renderPreguntaRespondida()}
          {this.renderScoreboard()}
        </>
      );
    }
  }
  renderPalabra() {
    if (this.props.pregunta && this.props.pregunta.respuesta) {
      return (
        <Col xs={12}>
          <p
            className="h2 pl-3"
            style={{
              textDecoration: "underline",
              textDecorationColor: "var(--hangman)",
              textDecorationThickness: "5px",
            }}
          >
            R: {this.props.pregunta.respuesta}
          </p>
        </Col>
      );
    }
  }
  render() {
    return (
      <>
        <HeaderHost
          tipo={this.props.juego.tipo}
          titulo={this.props.juego.titulo}
          respuestas={this.props.respuestas}
          tiempo={this.props.tiempo}
          hideClock={this.props.hideClock}
        />
        <div className='fondo-gris-full vertical-center'>
          <Row className='justify-content-between question-height'>
            {this.renderPage()}
          </Row>
        </div>
      </>
    );
  }
}

export default Scoreboard;
