import React from "react";
import { Row, Col, Image } from "react-bootstrap";

class MedallaJugador extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col className="text-center">
            <Image
              src={this.props.image}
              className="w-100 d-block m-auto"
              style={{ maxWidth: this.props.size }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p className="h1 m-0" style={{ color: this.props.color }}>
              <span style={{ borderBottom: "2px solid" }}>
                <b>{this.props.alias}</b>
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p className="h3 pt-2" style={{ color: this.props.color }}>
              <span><b>{this.props.puntos} pts</b></span>
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

export default MedallaJugador;
