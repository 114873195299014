import React, { Component } from "react";
import { Container, Row, Button, Col, Image } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import { Link, navigate } from "@reach/router";
import { alert } from "../actions/modalActions";
import { signIn } from "../actions/userActions";
import logoCara from "../assets/logo-cara.png";

import { connect } from "react-redux";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      pasword: undefined,
      validated: false,
      visible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleFunction = this.toggleFunction.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    if (this.state.email === "" || this.state.email === undefined) {
      this.setState({ validated: false });
      return this.props.alert("Por favor, ingresa un correo válido");
    }
    if (this.state.pasword === "" || this.state.password === undefined) {
      this.setState({ validated: false });
      return this.props.alert("Por favor, ingresa una contraseña válida");
    }
    let email = this.state.email;
    this.setState({ validated: true });
    await this.props.signIn(email.toLowerCase().trim(), this.state.password);
    if (this.props.location.pathname.includes("admin")) navigate("/admin");
    else if (this.props.location.pathname.includes("perfil"))
      navigate("/perfil");
    else navigate("/explorar");
  };

  toggleFunction(event) {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  }

  render() {
    return (
      <Container fluid className="mx-0" onKeyPress={this.toggleFunction}>
        <Row className="align-items-center justify-content-md-center">
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-rojo-mitad order-2 order-lg-1 py-5 py-sm-0'>
            <Row className="justify-content-md-center">
              <Col md={12} className="text-center text-white pl-4">
                <h2 className='pb-2'>¿Buscas jugar un ULU?</h2>
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  className='white-button'
                  href="/jugar"
                >
                  <h6 className="py-2 my-0">¡Juega aquí!</h6>
                </Button>
              </Col>
            </Row>
            <Row className='text-white mt-3 mt-sm-0' style={{display: 'inline-block'}}>
              <Col xs={12} className='text-center landing-link'>
                <a href='https://edu.playulu.com' rel="noopener noreferrer" target='_blank' className='white-link'>Conoce más sobre <b>PlayULU</b></a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-gris order-1 order-lg-2 py-5 py-sm-0'>
            <Row className="justify-content-md-center">
              <Col md={12} className="text-center pl-4">
                <Image
                  src={logoCara}
                  className="d-block mx-auto mb-3"
                  style={{ height: 60 }}
                  onClick={() => navigate('/explorar')}
                />
                <h2>Inicia Sesión</h2>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-1 pb-3">
              <Col md={6}>
                <TextField
                  outlined
                  className="w-100"
                  label="Correo electrónico"
                  characterCounter={<CharacterCounter />}
                  maxLength={255}
                >
                  <Input
                    value={this.state.email}
                    type="email"
                    maxLength={255}
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    onSubmit={this.handleSubmit}
                    id="email"
                  />
                </TextField>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-1">
              <Col md={6}>
                <TextField
                  outlined
                  className="w-100"
                  label="Contraseña"
                  onTrailingIconSelect={() =>
                    this.setState((prevState) => ({
                      visible: !prevState.visible,
                    }))
                  }
                  trailingIcon={
                    <i
                      className={
                        this.state.visible
                          ? "far fa-eye"
                          : "far fa-eye-slash"
                      }
                    />
                  }
                  characterCounter={<CharacterCounter />}
                  maxLength={255}
                >
                  <Input
                    type={this.state.visible ? "" : "password"}
                    value={this.state.password}
                    maxLength={255}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    onSubmit={this.handleSubmit}
                    id="password"
                  />
                  
                </TextField>
                  <Link
                  to="/recovery"
                  variant="link"
                  className="text-muted"
                  >
                    Olvidé mi contraseña
                  </Link>
              </Col>
            </Row>
            <Row className="justify-content-md-center pb-3">
              <Col md={6} className="text-right pt-2">
                <Button
                  variant="danger"
                  size="lg"
                  className="new-red-button"
                  onClick={this.handleSubmit}
                  type="submit"
                >
                  <h6 className="py-2 my-0">Iniciar Sesión</h6>
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className='text-center'>
                <hr></hr>
                <p className="pr-2">
                  ¿No tienes cuenta? 
                  <Link to="/signup" variant="link" style={{display: 'inline-block'}} className="ml-1">
                    Regístrate aquí
                  </Link>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.user.email,
  password: state.user.password,
});

export default connect(mapStateToProps, { signIn, alert })(Login);
