import { CREATE_NUEVA_OPCION, SET_PROPIEDAD_OPCION } from "./types";
import AdjuntosService from "../services/AdjuntosService";
import { displayError } from "../utils";

export const createNuevaOpcion = (idPregunta, index) => dispatch => {
  dispatch({ type: CREATE_NUEVA_OPCION, payload: { idPregunta, index } });
};

export const setPropiedadOpcion = (idOpcion, key, value) => dispatch => {
  if(key === "idAdjunto") {
    let formData = new FormData();
    formData.append("adjunto", value);
    AdjuntosService.postAdjunto(formData).then(res => {
      const { idAdjunto } = res.data;
      dispatch({ type: SET_PROPIEDAD_OPCION, payload: { idOpcion, key, value: idAdjunto }});
    }).catch(error => {
      displayError(dispatch, "Hubo un error al subir la imagen. Inténtalo más tarde.");
    });
  } else dispatch({ type: SET_PROPIEDAD_OPCION, payload: { idOpcion, key, value } });
};
