import {
  CREATE_FRASE,
  EDIT_FRASE,
  SET_PROPIEDAD_FRASE,
  SAVE_FRASE,
  DELETE_FRASE,
} from "./types";

export const createFrase = () => (dispatch) => {
  dispatch({ type: CREATE_FRASE });
};

export const editFrase = (frase) => (dispatch) => {
  dispatch({ type: EDIT_FRASE, payload: frase });
};

export const setPropiedadFrase = (key, value, alter) => (dispatch) => {
  if (!value && alter) value = alter;
  dispatch({ type: SET_PROPIEDAD_FRASE, payload: { key, value } });
};

export const saveFrase = (frase) => (dispatch) => {
  dispatch({ type: SAVE_FRASE, payload: frase });
};

export const deleteFrase = (idFrase) => (dispatch) => {
  dispatch({ type: DELETE_FRASE, payload: idFrase });
};
