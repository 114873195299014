import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import Menu from "../../components/Menu";
import logoCaraBlanca from "../../assets/logo-cara-blanca.png";

class IngresarPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: undefined,
      apodo: undefined,
      showAlert: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let pin = event.target.elements.pin.value;
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    this.setState({ validated: true });

    if (pin.includes(" ")) {
      this.props.alert("Este PIN no es válido. Intenta de nuevo.");
    } else this.props.joinJuego(pin);
  };

  render() {
    const { validated } = this.state;
    return (
      <>
        <Menu user={this.props.user} />
        <Container fluid className={this.props.user ? 'fondo-rojo-completo-user vertical-center' : 'fondo-rojo-completo vertical-center'}>
          <Row className="justify-content-center">
            <Col md={12} className="mb-3">
              <Image
                src={logoCaraBlanca}
                className="d-block mx-auto"
                style={{ height: 100 }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mx-auto">
              <h1 className="my-4 text-center text-white">
                ¡Ingresa el PIN del juego!
              </h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xs={12} md={2}>
              <Form
                noValidate
                validated={validated}
                onSubmit={this.handleSubmit}
              >
                <Form.Group>
                  <Form.Control
                    required
                    type="text"
                    size="lg"
                    name="pin"
                    placeholder="Ejemplo: jugarulu"
                    className="text-lowercase"
                    maxLength={40}
                    id="ingresar-pin"
                    autoCapitalize="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    Porfavor ingresa un pin.
                  </Form.Control.Feedback>
                </Form.Group>
                {/*<Input label='Pin del juego' />*/}
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  block
                  className='white-button'
                >
                  <h6 className="py-2 my-0">Continuar</h6>
                </Button>
              </Form>
              {!this.props.user && (
                <>
                  <hr className="mt-4 mb-0" style={{borderTopColor: 'lightgray'}} />
                  <Row className="mt-4 mb-3 justify-content-center text-center">
                    <Col xs={6} className="mx-auto border-right">
                      <Button
                        href={
                          this.props.user === null ? "/login" : "/misjuegos"
                        }
                        variant='link'
                        className="inline py-0 white-link"
                      >
                        Inicia sesión
                      </Button>
                    </Col>
                    <Col xs={6} className="mx-auto">
                      <Button
                        href="/signup"
                        variant='link'
                        className="inline py-0 white-link"
                      >
                        Regístrate
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row className='text-white' style={{display: 'inline-block'}}>
            <Col xs={12} className='text-center landing-link'>
              <a href='https://edu.playulu.com' rel="noopener noreferrer" target='_blank' className='white-link'>Conoce más sobre <b>PlayULU</b></a>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IngresarPin;
