import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Radio, { NativeRadioControl } from "@material/react-radio";
import "@material/react-radio/dist/radio.css";

export default function ({ property, label, options, modifier, selected }) {
  function renderOptions() {

    if (options && options !== null)
      return (
        <Form>
          {options.map(({ name, value }) => (
            <Form.Check key={value} inline className='mr-4'>
              <Radio value={value} label={name}>
                <NativeRadioControl
                  name={name}
                  value={value}
                  id={value}
                  checked={selected === value}
                  onChange={(e) => modifier(property, e.target.value)}
                />
              </Radio>
            </Form.Check>
          ))}
        </Form>
     );
  }

  return (
    <>
      <Row className="pt-3">
        <Col>
          <p className="m-0 h5">{label}</p>
        </Col>
      </Row>
      <Row>
      {renderOptions()}
      </Row>
    </>
  );
}
