import {
  EDIT_PREMIO,
  CREATE_PREMIO,
  SET_PROPIEDAD_PREMIO,
  SAVE_PREMIO,
  DELETE_PREMIO,
  ERROR_PREMIO,
} from "./types";
import PremiosService from "../services/PremiosService";
import { displayError, displaySuccess } from "../utils";
import AdjuntosService from "../services/AdjuntosService";
import moment from "moment";

export const editPremio = (premio) => (dispatch) => {
  dispatch({ type: EDIT_PREMIO, payload: premio });
};

export const setPropiedadPremio = (key, value) => (dispatch) => {
  if (key === "adjuntoPremio") {
    dispatch({ type: SET_PROPIEDAD_PREMIO, payload: { key: "file", value } });
    if (!value) dispatch({ type: SET_PROPIEDAD_PREMIO, payload: { key } });
  } else {
    dispatch({ type: SET_PROPIEDAD_PREMIO, payload: { key, value } });
  }
};

export const createPremio = () => (dispatch) => {
  dispatch({ type: CREATE_PREMIO });
};

export const postPremio = (idPatrocinador, premio) => (dispatch) => {
  premio.idPatrocinador = idPatrocinador;
  premio.fechaHoraVencimiento = moment(premio.fechaHoraVencimiento)
    .utc()
    .format();
  if (premio.idPremio === "nuevo") {
    if (premio.file) {
      if (premio.file.name) {
        const formData = new FormData();
        formData.append("adjunto", premio.file);
        return AdjuntosService.postAdjunto(formData)
          .then((res) => {
            premio.idAdjunto = res.data.idAdjunto;
            PremiosService.postPremio(premio)
              .then((res) => {
                const { idPremio } = res.data;
                premio.idPremio = idPremio;
                premio.adjuntoPremio = premio.idAdjunto;
                premio.adjuntoPatrocinador = null;
                dispatch({ type: SAVE_PREMIO, payload: premio });
                displaySuccess(dispatch, "Premio subido con éxito.");
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 409) {
                    dispatch({ type: ERROR_PREMIO });
                    return displayError(
                      dispatch,
                      "El nombre de premio ya está en uso. Escoge uno diferente."
                    );
                  }
                }
                displayError(dispatch, error);
              });
          })
          .catch((error) => {
            displayError(dispatch, error);
          });
      }
    }
    PremiosService.postPremio(premio)
      .then((res) => {
        const { idPremio } = res.data;
        premio.idPremio = idPremio;
        premio.adjuntoPremio = null;
        premio.adjuntoPatrocinador = null;
        dispatch({ type: SAVE_PREMIO, payload: premio });
        displaySuccess(dispatch, "Premio subido con éxito.");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            dispatch({ type: ERROR_PREMIO });
            return displayError(
              dispatch,
              "El nombre de premio ya está en uso. Escoge uno diferente."
            );
          }
        }
        displayError(dispatch, error);
      });
  } else {
    if (premio.file) {
      if (premio.file.name) {
        const formData = new FormData();
        formData.append("adjunto", premio.file);
        return AdjuntosService.postAdjunto(formData)
          .then((res) => {
            premio.idAdjunto = res.data.idAdjunto;
            PremiosService.putPremio(premio.idPremio, premio)
              .then((res) => {
                premio.adjuntoPremio = premio.idAdjunto;
                dispatch({ type: SAVE_PREMIO, payload: premio });
                displaySuccess(dispatch, "Premio actualizado con éxito.");
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 409) {
                    dispatch({ type: ERROR_PREMIO });
                    return displayError(
                      dispatch,
                      "El nombre de premio ya está en uso. Escoge uno diferente."
                    );
                  }
                }
                displayError(dispatch, error);
              });
          })
          .catch((error) => {
            displayError(dispatch, error);
          });
      }
    }
    premio.idAdjunto = premio.adjuntoPremio;
    PremiosService.putPremio(premio.idPremio, premio)
      .then((res) => {
        dispatch({ type: SAVE_PREMIO, payload: premio });
        displaySuccess(dispatch, "Premio actualizado con éxito.");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 409) {
            dispatch({ type: ERROR_PREMIO });
            return displayError(
              dispatch,
              "El nombre de premio ya está en uso. Escoge uno diferente."
            );
          }
        }
        displayError(dispatch, error);
      });
  }
};

export const togglePremio = (idPremio, premio) => (dispatch) => {
  PremiosService.togglePremio(idPremio, !premio.activo)
    .then((res) => {
      premio.activo = !premio.activo;
      dispatch({ type: SAVE_PREMIO, payload: premio });
      displaySuccess(dispatch, "Estado del premio cambiado con éxito.");
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const eliminarPremio = (idPremio) => (dispatch) => {
  PremiosService.deletePremio(idPremio)
    .then((res) => {
      displaySuccess(dispatch, "Premio eliminado con éxito.");
      dispatch({ type: DELETE_PREMIO, payload: idPremio });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};
