import React, { Component } from 'react';
import { connect } from "react-redux";
import { createJugarSoloSesion, clearStore, postSugerencia } from "../../actions/jugarSoloActions";
import Header from './Header';
import TriviaContent from './TriviaContent';
import { Container, Row, Col } from 'react-bootstrap';
import IconsLoading from "../../components/IconsLoading";
import Footer from './Footer';
import { Rayas } from "../../assets/icons.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lobby from './Lobby';
import io from "socket.io-client";
import { BASE_URL, errorMessageSoloTrivia } from '../../utils';
import EndULUModal from './EndULUModal';

class AloneTrivia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: 'lobbyquestion',//ENUM defining the current status so <TriviaContent> and <Footer> can choose what to render component to render [lobbyquestion | question | gameending]
            socket: null,
            currentView: 'lobby', //ENUM defining the current component to render [lobby | main_content
            lobbyPreguntaTimer: null,//timer for window before pregunta with options
            currentTimeLobbyPregunta: 0,//time counter for window before pregunta with options
            questionPreguntaTimer: null,//timer for window with question and options
            currentTimePregunta: 0,//time counter for window with question and options
            loading: false,//if we are in the state of loading something from the back
            apodo: null,
            idSesion: null,
            sugerencia: {
                recomendacionJuego: null,
                showThankYouModal: false
            },
            juego: {
                puntos: 0,//total ponints
                pregunta: null,//current question text
                opciones: null,//current options
                idPregunta: null,//questionId
                idAdjunto: null,//Question idAdjunto
                preguntaActual: 0,//counter of current question
                tiempoInicialPregunta: null,//Time since options have been shown to user
                streak: false,//if the user is on streak
                contestada: false,//if the user has answered the current question
                correctas: [],//array of correct idOpcion
                incorrectas: [],//array of incorrect idOpcion
                porcentajeCorrectas: null,//percentage of correct answers
                porcentajeIncorrectas: null,//percentage of incorrect answers
                selectedAnswer: null, //id of the selected answer
            },//se guardan todos los dastos del juego

            showEndULUModal: false,
        }
        this.responder = this.responder.bind(this);
        this.siguientePregunta = this.siguientePregunta.bind(this);
        this.setSugerenciaProperty = this.setSugerenciaProperty.bind(this);
        this.postSugerencia = this.postSugerencia.bind(this);
    }
    componentDidMount(){
        this.props.clearStore();//reset redux to empty
        this.props.createJugarSoloSesion({//create sesion in back with HTTP
            idJuego: this.props.urlQuery.juego,
            tipo: this.props.urlQuery.tipo,
            segmento: this.props.urlQuery.segmento
        }).then(() => {//when back returns response connect to websockets
            const juego = {...this.state.juego, preguntasTotales: this.props.preguntasTotales};//save preguntasTotales from redux to state for consistency
            this.setState({
                juego
            })
            this.connectSockets();
        })
    }
    startTimerQuestion(){//Handle start timer question
        const timeInterval = setInterval(() => {//set timer
            //start question timer
            this.setState((prevState) => {
                if(prevState.currentTimePregunta - 1 <= 0){//if timer reaches 0s
                    //stop interval
                    clearInterval(this.state.questionPreguntaTimer);
                }
                return {//if not reduce by 1
                    ...prevState,
                    currentTimePregunta: prevState.currentTimePregunta - 1 
                }
            }, () => {
                if(this.state.currentTimePregunta <= 0){//if timer reaches 0s send null to back as answer
                    this.responder(null);                
                }
            });
        }, 1000);//run every second      
        this.setState({ questionPreguntaTimer: timeInterval });//save time interval in state
    }
    connectSockets(){
        const base_url = BASE_URL.replace("/api", "");
        let socket = io(`${base_url}/${this.props.namespace}`);
        this.setState({ socket });//save socket in state
        socket.on('apodo', ({ apodo }) => {//When back saves apodo
            this.setState({ apodo });
            socket.emit('nextPregunta');//Ask for first question 
        });
        socket.on('respuesta', ({ correcta, correctas, incorrectas, puntos, streak }) => {//When back returns answer results
            //save game data to state
            const juego = {...this.state.juego};
            juego.puntos = puntos;
            juego.streak = streak;
            juego.contestada = true;
            juego.correctas = correctas;
            juego.incorrectas = incorrectas;
            clearInterval(this.state.questionPreguntaTimer);
            this.setState({ juego, loading: false, questionPreguntaTimer: null });//hide loading icons and stop timer
        });
        socket.on('nextPregunta', ({ 
            pregunta,
            opciones,
            idPregunta,
            idAdjunto 
        }) => {//When back sends next question
            this.setState((prevState) => {
                const juego = {//Save preguntas, opciones, idPregunta and idAdjunto to State
                    ...prevState.juego,
                    pregunta,//current question text
                    opciones,//current options
                    idPregunta,//questionId
                    idAdjunto,//Question idAdjunto
                    preguntaActual: prevState.juego.preguntaActual + 1,//counter of current question
                    tiempoInicialPregunta: Date.now(),//Time since options have been shown to user
                    contestada: false,//if the user has answered the current question
                    correctas: [],//array of correct idOpcion
                    incorrectas: []//array of incorrect idOpcion
                }
                return {
                    ...prevState,
                    currentTimeLobbyPregunta: this.props.tiempoEsperaLobby,//Set initial counter
                    currentTimePregunta: this.props.tiempoEsperaPregunta,//Set initial counter
                    juego,
                    loading: false
                }
            });
            const timeInterval = setInterval(() => {//set timer
                //start lobby question timer
                this.setState((prevState) => {
                    if(prevState.currentTimeLobbyPregunta - 1 <= 0){//if timer reaches 0s
                        //start question and stop interval
                        clearInterval(this.state.lobbyPreguntaTimer);
                        return {
                            ...prevState,
                            component: 'question'
                        }
                    }
                    return {//if not reduce by 1
                        ...prevState,
                        currentTimeLobbyPregunta: prevState.currentTimeLobbyPregunta - 1 
                    }
                }, () => {
                    //after state changes, startTimerQuestion, only if lobby timer reaches 0s AND questionPreguntaTimer hasnt been created yet
                    if(this.state.currentTimeLobbyPregunta - 1 <= 0 && this.state.questionPreguntaTimer === null){
                        this.startTimerQuestion();
                    }
                })
            }, 1000);//run every second      
            this.setState({ currentView: 'main_content', lobbyPreguntaTimer: timeInterval });//save time interval in state and show lobby question
        });
        socket.on('juegoTerminado', ({//Back sends message that game has finished
            puntos,
            idSesion,
            porcentajeCorrectas, 
            porcentajeIncorrectas
        }) => {
            clearInterval(this.state.questionPreguntaTimer);
            clearInterval(this.state.lobbyPreguntaTimer);
            socket = null;//Close socket
            const juego = {...this.state.juego};
            juego.puntos = puntos;
            juego.porcentajeCorrectas = porcentajeCorrectas;
            juego.porcentajeIncorrectas = porcentajeIncorrectas;
            this.setState({ 
                //puntos,
                juego,
                idSesion,
                component: 'gameending',
                loading: false,
                //porcentajeCorrectas, 
                //porcentajeIncorrectas
            });
        });
        socket.on('handle_error', ({ code, status }) => {
            let message = errorMessageSoloTrivia[code];
            if(!errorMessageSoloTrivia[code]){//Set default message if not found in utils
                message = "¡Ups! Parece que hubo un problema en la partida";
            }
            toast.error(message, {
                position: "bottom-right",
                autoClose: 12000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
        socket.on("disconnect", () => {
            if(this.state.component !== 'gameending'){//If server disconnects before ending game, show error
                toast.error("¡Ups! Parece que hubo un problema con la conexión", {
                    position: "bottom-right",
                    autoClose: 12000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }
    responder(opcion){
        if(this.state.juego.contestada)
            return;//if the user has already answered dont send the answer to back
        this.setState({ loading: true });
        let juego = {...this.state.juego};
        let idOpcion;
        if(opcion){//If the user answered something
            idOpcion = opcion.idOpcion;
            juego.selectedAnswer = idOpcion;
            this.setState({juego});
        }
        else{//if user didnt answer anything send null to back
            idOpcion = null;
        }
        this.state.socket.emit('respuesta', {
            idPregunta: this.state.juego.idPregunta,
            idOpcion,
            tiempo: Date.now() - this.state.juego.tiempoInicialPregunta
        });
    }
    setSugerenciaProperty({ key, value }){
        const sugerencia = {...this.state.sugerencia};
        sugerencia[key] = value;
        this.setState({sugerencia});
    }
    siguientePregunta(){
        if(!this.state.juego.contestada)//If user has not answered the question, answer it as null
            return this.responder(null);

        if(this.state.juego.preguntaActual !== this.state.juego.preguntasTotales){//Only set lobbyQuestion if there are any questions left
            this.setState({ loading: true, component: 'lobbyquestion'});
        }
        else{//If there are no questions left, show loading
            this.setState({ loading: true });
        }   
        this.state.socket.emit('nextPregunta');//Ask for next question
    }
    postSugerencia(){
        this.props.postSugerencia({
            idSesion: this.state.idSesion,
            recomendacionJuego: this.state.sugerencia.recomendacionJuego,
        });
        const sugerencia = {...this.state.sugerencia, showThankYouModal: true };
        this.setState({ sugerencia });
    }
    render() {
        if(this.state.currentView === 'lobby'){//Render lobby
            return (
                <Lobby 
                    tipo={this.props.tipo}
                    loadingLobby={this.props.loadingLobby}
                    nombre={this.props.nombre}
                    patrocinador={this.props.patrocinador}
                    user={this.props.user}
                    creador={this.props.creador}
                    setApodo={(apodo) => {
                        this.state.socket.emit('apodo', { apodo })
                    }}//send apodo to back
                />
            );
        }//if not render main content

        return(
            <>
                <Header
                    component={this.state.component}
                    juego={this.state.juego}
                    isMobile = {this.props.isMobile}
                    titulo={this.props.nombre}
                    tiempoLobby={this.state.currentTimeLobbyPregunta}
                    tiempoPregunta={this.state.currentTimePregunta}
                    loading={this.state.loading}
                    icon = 
                    {
                        <Rayas 
                            className='header-icon'
                            color='#7e2cff' 
                            width='22px'
                            height='22px'
                        />
                    }
                />
                {this.state.loading ? 
                    <Container className='fondo-gris-full vertical-center'>
                        <Row>
                            <Col>
                                <IconsLoading /> 
                            </Col>
                        </Row>
                    </Container>
                    : 
                    <TriviaContent
                        apodo={this.state.apodo}
                        juego={this.state.juego}
                        patrocinador={this.props.patrocinador}
                        component={this.state.component}
                        responder={this.responder}//callback to send answer to back
                        setSugerenciaProperty={this.setSugerenciaProperty}
                        showThankYouModal={this.state.sugerencia.showThankYouModal}
                        isMobile={this.props.isMobile}
                        user={this.props.user}
                        tiempoPregunta={this.state.currentTimePregunta}
                    />
                }
                <Footer
                    juego={this.state.juego}
                    idJuego={this.props.urlQuery.juego}
                    type={this.props.urlQuery.tipo}
                    patrocinador={this.props.patrocinador}
                    isMobile={this.props.isMobile}
                    component={this.state.component}
                    siguientePregunta={this.siguientePregunta}
                    postSugerencia={this.postSugerencia}
                    loading={this.state.loading}
                    user={this.props.user}
                    terminarJuego={() => {
                        this.setState({
                            showEndULUModal: true,
                        })
                    }}
                />
                <EndULUModal
                    show={this.state.showEndULUModal}
                    onHide={() => this.setState({showEndULUModal: false})}
                    terminarJuego={() => { 
                        this.state.socket.emit('terminarJuego');
                        this.setState({
                            showEndULUModal: false,
                            loading: true,
                        })
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    tipo: state.jugarSolo.tipo,
    nombre: state.jugarSolo.nombre,
    patrocinador: state.jugarSolo.patrocinador,
    loadingLobby: state.jugarSolo.loadingLobby,
    namespace: state.jugarSolo.namespace,
    tiempoEsperaLobby: state.jugarSolo.tiempoEsperaLobby,
    preguntasTotales: state.jugarSolo.cantidadPreguntas,
    tiempoEsperaPregunta: state.jugarSolo.tiempoEsperaPregunta,
    user: state.user.user,
    creador: state.jugarSolo.creador
});

export default connect(mapStateToProps, {
    createJugarSoloSesion,
    clearStore,
    postSugerencia
  })(AloneTrivia);