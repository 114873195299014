import React from "react";
import { Card, Table } from "react-bootstrap";

class CardScoreboard extends React.Component {
  /* 
  //ESTE METODO SE PUEDE LLEGAR A UTILIZAR CUANDO YA SE PONGAN LOS ICONOS
  //DE LAS POSICIONES DE LOS JUGADORES

  estadoJugador(lugar) {
    //return <i className="fas fa-fire-alt fa-2x px-2"></i>;
    return <h4>#{lugar}</h4>
  }

  renderLeaders() {
    if (this.props.scoreboard !== null) {
      return this.props.scoreboard.slice(0, 5).map((jugador, i) => (
        <Row className="text-dark my-3 mx-0" key={jugador.alias}>
          <Col xs={8} sm={8} md={8}>
            <Row className='no-gutters'>
              <Col xs={3}>{this.estadoJugador(i)}</Col>
              <Col xs={9}>
                <h4>{jugador.alias}</h4>
              </Col>
            </Row>
          </Col>
          <Col xs={4} sm={4} md={4} className="text-right">
            <p className="h4 mt-1">{jugador.puntos}</p>
          </Col>
        </Row>
      ));
    }
  }
  */

  renderLeadersTable() {
    if(this.props.scoreboard !== null) {
      return this.props.scoreboard.slice(0, 5).map((jugador, i) => (
        <tr>
          <td>#{i+1}</td>
          <td id='overflow-alias-card-scoreboard'>{jugador.alias}</td>
          <td className='text-right'>{jugador.puntos}</td>
        </tr>
      ));
    }
  }

  render() {
    return (
      <Card className="scoreboard-card p-2 shadow">
        {/*this.renderLeaders()*/}
        <Table size='sm' borderless>
          <thead>
            <tr className='h6'>
              <th>Lugar</th>
              <th>Jugador</th>
              <th className='text-right'>Puntos</th>
            </tr>
          </thead>
          <tbody class='h4'>
            {this.renderLeadersTable()}
          </tbody>
        </Table>
      </Card>
    );
  }
}

export default CardScoreboard;
