import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class Recommend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recommendation: '',
        }

        this.handleRecommendation = this.handleRecommendation.bind(this);
    }

    handleRecommendation(e){
        const enumRecomendacion = {
            'nada': 0,
            'poco': 1,
            'algo': 2,
            'mucho': 3
        }
        const recommendation = e.currentTarget.value;
        this.setState({recommendation});
        this.props.setSugerenciaProperty({
            key: 'recomendacionPlataforma',
            value: enumRecomendacion[recommendation]//send number from enum
        });
    }

    render() {
        return(
            <>
                <Row className='justify-content-center mt-3'>
                    <Col xs={12} md={6} className='text-center'>
                        <h6>¿Qué tanto recomendarías ULU a otra persona?</h6>
                    </Col>
                </Row>
                <Row className='justify-content-center mb-3'>
                    <Col xs={12} md={6} className='text-center'>
                        <form className='recommendation'>
                            <label htmlFor='nada'>Nada<br />
                                <input className='recommendation-radio' type="radio" id='nada' value='nada' checked={this.state.recommendation === 'nada'} onChange={this.handleRecommendation} />
                            </label>
                            <label htmlFor='poco'>Poco<br />
                                <input className='recommendation-radio' type="radio" id='poco' value='poco' checked={this.state.recommendation === 'poco'} onChange={this.handleRecommendation} />
                            </label>
                            <label htmlFor='algo'>Algo<br />
                                <input className='recommendation-radio' type="radio" id='algo' value='algo' checked={this.state.recommendation === 'algo'} onChange={this.handleRecommendation} />
                            </label>
                            <label htmlFor='mucho'>Mucho<br />
                                <input className='recommendation-radio' type="radio" id='mucho' value='mucho' checked={this.state.recommendation === 'mucho'} onChange={this.handleRecommendation} />
                            </label>
                        </form>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Recommend;