import {
  CREATE_NUEVO_JUEGO,
  CREATE_NUEVA_PREGUNTA,
  SET_PROPIEDAD_PREGUNTA,
  CREATE_NUEVA_OPCION,
  SET_PROPIEDAD_OPCION,
  SET_PROPIEDAD_JUEGO,
  JUEGO_RECIBIDO,
  ELIMINAR_PREGUNTA,
  ELIMINAR_OPCION,
  REESTABLECER_PREGUNTA,
  REESTABLECER_OPCION,
  DISABLE_BUTTON,
  ENABLE_BUTTON,
  EDIT_PREGUNTA,
  SAVE_CURRENT_PREGUNTA,
  SET_PROPIEDAD_PREGUNTA_CRUCIGRAMA,
  SELECCIONAR_JUEGO,
  DUPLICAR_PREGUNTA,
  CLEAR_JUEGO,
  REORDENAR_PREGUNTAS,
  LOG_TIME,
  IMAGES_SEARCH_JUEGO_RECEIVED,
  LOADING_IMAGES_SEARCH
} from "../actions/types";
import arrayMove from "array-move";
import moment from "moment";
import ReactGA from "react-ga";

const savePregunta = (pregunta, preguntas) => {
  let pregunta3 = { ...pregunta };
  let preguntas3 = [...preguntas];
  let index3 = preguntas3.findIndex(
    (pregunta) => String(pregunta.idPregunta) === String(pregunta3.idPregunta)
  );
  if (index3 !== -1)
    preguntas3[index3] = { ...pregunta3, orden: preguntas3[index3].orden };
  return preguntas3;
};

const createPregunta = (num) => ({
  idPregunta: `nueva-${parseInt(num)}`,
  texto: "",
  idAdjunto: "",
  opciones: new Array(4)
    .fill(1)
    .map((x, index) => createOpcion(`nueva-${parseInt(num)}`, index)),
  orden: num - 1,
});

const createOpcion = (idPregunta, num) => ({
  idPregunta,
  idOpcion: `nueva-${idPregunta}-${parseInt(num)}`,
  texto: "",
  idAdjunto: "",
  correcta: false,
});

const schema = {
  idJuego: "nuevo",
  titulo: "",
  descripcion: "",
  publico: true,
};

const INITIAL_STATE = {
  juego: undefined,
  tipo: undefined,
  preguntas: [],
  cambios: false,
  initialPreguntas: undefined,
  initialOpciones: undefined,
  initialJuego: undefined,
  disabled: false,
  imagesSearchResults: [],
  imagesSearchPages: undefined
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_JUEGO:
      return { ...INITIAL_STATE };
    case SELECCIONAR_JUEGO:
      return { ...state, tipo: action.payload };
    case JUEGO_RECIBIDO:
      let { juego } = action.payload;
      let preguntasJuego = action.payload.preguntas;
      return {
        ...state,
        juego,
        preguntas: preguntasJuego,
        initialJuego: juego,
        initialPreguntas: preguntasJuego,
        disabled: false,
        tipo: juego.tipo,
        imagesSearchResults: [],
        imagesSearchPages: undefined,
        loadingImages: false
      };
    case CREATE_NUEVO_JUEGO:
      const tipo = action.payload;
      return {
        ...state,
        juego: { ...schema, tipo },
        cambios: true,
        time: moment(),
      };
    case CREATE_NUEVA_PREGUNTA: {
      let preguntas = [...state.preguntas];
      if (state.juego.tipo.toLowerCase() !== "crucigrama") {
        preguntas = savePregunta(state.pregunta, state.preguntas);
      }
      const nuevaPregunta = createPregunta(action.payload);
      return {
        ...state,
        preguntas: [...preguntas, nuevaPregunta],
        cambios: true,
        pregunta: nuevaPregunta,
      };
    }
    case DUPLICAR_PREGUNTA:
      let preguntas6 = [...state.preguntas];
      let num = preguntas6.length;
      let opcionesPreguntas = action.payload.opciones;
      if (!opcionesPreguntas) opcionesPreguntas = [];
      let pregunta6 = {
        ...action.payload,
        idPregunta: `nueva-${num + 1}`,
        opciones: opcionesPreguntas.map((opcion, index) => ({
          ...opcion,
          idOpcion: `nueva-${num + 1}-${index}`,
          idPregunta: `nueva-${num + 1}`,
        })),
      };
      return { ...state, preguntas: [...preguntas6, pregunta6] };
    case CREATE_NUEVA_OPCION:
      const { idPregunta, index } = action.payload;
      let options = [...state.pregunta.opciones];
      options.push(createOpcion(idPregunta, index));
      return {
        ...state,
        pregunta: { ...state.pregunta, opciones: options },
      };
    case SET_PROPIEDAD_OPCION:
      const { idOpcion, key, value } = action.payload;
      let opciones = [...state.pregunta.opciones];
      let opcion = opciones.find(
        (opcion) => String(opcion.idOpcion) === String(idOpcion)
      );
      if (opcion) {
        opcion[key] = value;
      }
      let cambiada = { ...state.pregunta, opciones };
      let questions6 = [...state.preguntas];
      let indice = questions6.findIndex(
        (pregunta) =>
          String(pregunta.idPregunta) === String(cambiada.idPregunta)
      );
      if (indice !== -1) questions6[indice] = cambiada;
      return {
        ...state,
        cambios: opciones !== state.initialOpciones,
        pregunta: cambiada,
        preguntas: questions6,
      };
    case SET_PROPIEDAD_PREGUNTA:
      let pregunta = { ...state.pregunta };
      let preguntas5 = [...state.preguntas];
      let pregunta5 = preguntas5.find(
        (question) => question.idPregunta === pregunta.idPregunta
      );
      pregunta[action.payload.key] = action.payload.value;
      pregunta5[action.payload.key] = action.payload.value;
      return {
        ...state,
        pregunta,
        preguntas: preguntas5,
      };
    case SET_PROPIEDAD_JUEGO:
      let game = { ...state.juego };
      game[action.payload.key] = action.payload.value;
      return { ...state, juego: game, cambios: game !== state.initialJuego };
    case IMAGES_SEARCH_JUEGO_RECEIVED: {
      return { ...state, imagesSearchResults: action.payload.results, imagesSearchPages: action.payload.pages, loadingImages: false };
    }
    case LOADING_IMAGES_SEARCH: {
      return { ...state, loadingImages: true };
    }
    case ELIMINAR_PREGUNTA:
      let questions = [...state.preguntas];
      let question = questions.find(
        (pregunta) => String(pregunta.idPregunta) === String(action.payload)
      );
      if (question) question.idPregunta = `delete-${question.idPregunta}`;
      return {
        ...state,
        preguntas: questions,
        cambios: questions !== state.initialPreguntas,
      };
    case ELIMINAR_OPCION:
      let options2 = [...state.opciones];
      let option2 = options2.find(
        (opcion) => String(opcion.idOpcion) === String(action.payload)
      );
      if (option2) option2.idOpcion = `delete-${option2.idOpcion}`;
      return {
        ...state,
        opciones: options2,
        cambios: options2 !== state.initialOpciones,
      };
    case REESTABLECER_PREGUNTA:
      let preguntas2 = [...state.preguntas];
      let pregunta2 = preguntas2.find(
        (pregunta) => String(pregunta.idPregunta) === String(action.payload)
      );
      if (pregunta2) pregunta2.idPregunta = pregunta2.idPregunta.substring(7);
      return { ...state, preguntas: preguntas2 };
    case REESTABLECER_OPCION:
      let opciones2 = [...state.opciones];
      let opcion2 = opciones2.find(
        (opcion) => String(opcion.idOpcion) === String(action.payload)
      );
      if (opcion2) opcion2.idOpcion = opcion2.idOpcion.substring(7);
      return { ...state, opciones: opciones2 };
    case DISABLE_BUTTON:
      return { ...state, disabled: true };
    case ENABLE_BUTTON:
      return { ...state, disabled: false };
    case EDIT_PREGUNTA:
      let questions2 = [...state.preguntas];
      let index2 = questions2.findIndex(
        (question2) => String(question2.idPregunta) === String(action.payload)
      );
      let question2 = questions2[index2];
      return {
        ...state,
        pregunta: question2,
        index: index2,
      };
    case SAVE_CURRENT_PREGUNTA:
      let preguntas3 = savePregunta(state.pregunta, state.preguntas);
      return {
        ...state,
        preguntas: preguntas3,
      };
    case SET_PROPIEDAD_PREGUNTA_CRUCIGRAMA:
      const preguntas4 = [...state.preguntas];
      const pregunta4 = preguntas4.find(
        (pregunta) =>
          String(pregunta.idPregunta) === String(action.payload.idPregunta)
      );
      if (pregunta4) pregunta4[action.payload.key] = action.payload.value;
      return { ...state, preguntas: preguntas4 };
    case REORDENAR_PREGUNTAS:
      const preguntas8 = [...state.preguntas];
      const { oldIndex, newIndex } = action.payload;
      let preguntasMoved = [...arrayMove(preguntas8, oldIndex, newIndex)];
      preguntasMoved = preguntasMoved.map((pregunta, index) => ({
        ...pregunta,
        orden: index,
      }));
      return { ...state, preguntas: preguntasMoved };
    case LOG_TIME:
      const time = moment().diff(state.time, "seconds");
      ReactGA.timing({
        label: "Tiempo de crear juego",
        variable: "seconds",
        category: "User Time",
        value: time,
      });
      return { ...state, time: null };
    default:
      return { ...state };
  }
};
