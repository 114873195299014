import React from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";
import asterisco from "../../assets/Asterisco.svg";
import Dropzone from "../Dropzone";
import { BASE_URL, getIcon } from "../../utils";
import { navigate } from "@reach/router";
import rectangulo from "../../assets/Rectangulo.svg";
import fondo from "../../assets/fondo-nuevo.png";
import logo from "../../assets/logo.png";
import placeholderImage from "../../assets/jugar-sin-imagen.png";

class PreguntaAhorcadoCrear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: placeholderImage,
    };
  }

  onUnload = e => { // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
 }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pregunta.idAdjunto !== null &&
      this.state.src === placeholderImage
    ) {
      this.setState({
        src: `${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}`,
      });
    }
    if (this.props.pregunta !== prevProps.pregunta) {
      if (this.props.pregunta.idAdjunto !== null) {
        this.setState({
          src: `${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}`,
        });
      } else {
        this.setState({
          src: placeholderImage,
        });
      }
    }
    if (this.props.pregunta.file && this.state.src === placeholderImage) {
      if (this.props.pregunta.file.name !== undefined) {
        this.readFile();
      }
    }
    if (
      prevProps.pregunta.file !== this.props.pregunta.file &&
      this.state.src !== placeholderImage
    ) {
      if (this.props.pregunta.file) {
        if (this.props.pregunta.file.name) {
          this.readFile();
        }
      }
    }
  }

  readFile() {
    const reader = new FileReader();
    reader.onload = (e) => {
      const src = e.target.result;
      this.setState({ src });
    };
    reader.readAsDataURL(this.props.pregunta.file);
  }

  renderPregunta() {
    if (this.props.pregunta) {
      let maxPregunta;
      let maxRespuesta;
      if (this.props.juego.tipo.toLowerCase() === "crucigrama") {
        maxPregunta = 70;
        maxRespuesta = 9;
      } else {
        maxPregunta = 140;
        maxRespuesta = 35;
      }
      return (
        <>
          <Row className="px-2">
            <div className="w-100">
              <TextField
                outlined
                className="w-100"
                label="Pregunta"
                characterCounter={<CharacterCounter />}
                maxLength={maxPregunta}
                textarea
              >
                <Input
                  value={this.props.pregunta.texto}
                  maxLength={maxPregunta}
                  onChange={(event) => this.props.handleChange(event, "texto")}
                  style={{ resize: "none" }}
                />
              </TextField>
            </div>
          </Row>
          {this.renderDropzone()}
          <Row className="px-2 mb-2 mt-3">
            <p className="h6">Respuesta</p>
          </Row>
          <Row className="px-2">
            <div className="w-100">
              <TextField
                outlined
                className="w-100"
                label="Respuesta"
                characterCounter={<CharacterCounter />}
                maxLength={maxRespuesta}
              >
                <Input
                  value={this.props.pregunta.respuesta}
                  maxLength={maxRespuesta}
                  onChange={(event) =>
                    this.props.handleChange(event, "respuesta")
                  }
                />
              </TextField>
            </div>
          </Row>
        </>
      );
    }
  }

  renderDropzone() {
    if (this.props.juego.tipo.toLowerCase() !== "crucigrama") {
      let images;
      if (
        this.props.pregunta.idAdjunto &&
        this.props.pregunta.idAdjunto !== null
      ) {
        images = [
          { src: `${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}` },
        ];
      }
      return (
        <Row className="px-2 py-3">
          <Dropzone
            modifier={this.props.setPropiedadPregunta}
            base_url={`${BASE_URL}/adjuntos/`}
            idAdjunto={this.props.pregunta.idAdjunto}
            args="idAdjunto"
            images={images}
            removeImage={() => this.props.setPropiedadPregunta("idAdjunto")}
            file={this.props.pregunta.file}
            alert={this.props.alert}
          />
        </Row>
      );
    }
  }

  renderLetra(letra) {
    return (
      <p className="pr-2 py-0 my-0" style={{ textDecoration: "underline" }}>
        {letra}
      </p>
    );
  }

  renderPalabraVistaPrevia() {
    let letras;
    if (this.props.pregunta.respuesta) {
      letras = this.props.pregunta.respuesta.split("");
      return letras.map((letra, index) => {
        if (letra !== " ")
          return (
            <span
              key={index}
              className="py-0 my-0"
              style={{ textDecoration: "underline", paddingRight: "2px" }}
            >
              {letra}
            </span>
          );
        else return <span style={{ paddingRight: "4px" }} />;
      });
    }
    /* if (this.props.pregunta.respuesta) {
      let palabras = this.props.pregunta.respuesta.split(" ");
      palabras.forEach((palabra) => {
        return (
          <p
            style={{ textDecoration: "underline", letterSpacing: "2px" }}
            className="pr-3"
          >
            {palabra}
          </p>
        );
      });
    } */
    /* let palabras = this.props.pregunta.respuesta.split(" ");
    let contestadas = new Set();
    if (palabras != null) {
      contestadas.add(letra.toLowerCase());
      let j = 0;
      let parent = this.refs.parent;
      let maxWidth = parent.getBoundingClientRect().width;
      return palabras.map((palabra, indexPalabra) => {
        let letras = Array.from(palabra);
        let children = <span className="ml-5"></span>;
        if (indexPalabra === 0) j += letras.length * 65;
        else if (j + letras.length * 65 > maxWidth) {
          j = letras.length * 65;
          children = <br />;
        } else j += letras.length * 65;
        return letras.map((letra, index) => {
          return (
            <LetraAhorcado
              key={index}
              letra={letra}
              jugadores={this.props.jugadores}
              contestadas={contestadas}
              respuestas={respuestas[letra]}
            >
              {index === 0 && indexPalabra > 0 ? children : ""}
            </LetraAhorcado>
          );
        });
      });
    } */
  }

  renderContenidoVistaPreviaAhorcado() {
    let minutos;
    let segundos;
    if (this.props.juego) {
      minutos = Math.floor(this.props.juego.tiempo / 60);
      segundos = this.props.juego.tiempo % 60;
    }
    return (
      <Container className="box-border mx-0 px-0 vista-previa" fluid>
        <Image src={fondo} className="h-100 w-100 vista-previa-bg" />
        <Col className="vista-previa-elements d-flex flex-column">
          <Row className="justify-content-center pt-1">
            <Col xs={3} className="">
              <Image src={logo} style={{ width: "30%" }} />
            </Col>
            <Col xs={6}>
              <Row className="justify-content-center align-items-center text-center">
                <Col>
                  <p
                    className="px-0 py-0 mx-0 my-0"
                    style={{ fontSize: "12px" }}
                  >
                    <Image src={asterisco} style={{ width: "5%" }} />
                    <span className="font-weight-bold pl-1">
                      {this.props.juego.titulo
                        ? this.props.juego.titulo
                        : "Ahorcado"}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center text-center">
                <Col>
                  <p
                    className="text-muted px-0 py-0 mx-0 my-0"
                    style={{ fontSize: "10px" }}
                  >
                    <i className="far fa-clock"></i>{" "}
                    <span>
                      {this.props.juego.tiempo ? minutos : "0"}m :{" "}
                      {this.props.juego.tiempo
                        ? segundos.toString().length === 2
                          ? segundos
                          : "0" + segundos
                        : "15"}
                      s
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={3}></Col>
          </Row>
          <Row className="flex-grow-1 py-3">
            <Col md={4}>
              <Image
                src={this.state.src}
                className="d-block w-100 mw-100 m-auto image-150"
              />
            </Col>
            <Col md={8} ref="parent" className="align-content-around">
              <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "12px" }}>
                {this.props.pregunta.texto}
              </p>
              <Container
                className="text-center pt-3 px-0"
                //style={{ overflowWrap: "break-word" }}
              >
                <p>{this.renderPalabraVistaPrevia()}</p>
                {/* <p className="px-0 mx-0 py-0 my-0" style={{textDecoration: "underline", letterSpacing: "4px"}}>{this.props.pregunta.respuesta}</p> */}
              </Container>
            </Col>
          </Row>
          <Row className="pb-2 align-items-center">
            <Col xs={4} className="">
              <Row className="pl-3">
                <p
                  className="px-0 py-0 mx-0 my-0"
                  style={{ fontWeight: "bold", fontSize: "10px" }}
                >
                  PIN del juego:
                </p>
              </Row>
              <Row className="pl-3">
                <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "10px" }}>
                  xxxx
                </p>
              </Row>
            </Col>
            <Col xs={4} className="text-center">
              <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "12px" }}>
                Pregunta {this.props.index + 1}/{this.props.preguntas.length} -{" "}
                {this.props.juego.puntos ? this.props.juego.puntos : 1000}pts
              </p>
            </Col>
            <Col xs={4} className="text-right pl-0 pr-3">
              <Button
                variant="link"
                className="link-gray pl-0"
                style={{ fontSize: "10px" }}
              >
                Salir del juego
              </Button>
              <Button
                variant="outline-danger"
                className="rounded-button border-red-new py-1"
                style={{ fontSize: "10px" }}
              >
                Continuar
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }

  renderVerticalesVistaPrevia() {
    return this.props.preguntas
      .map((pregunta, index) => {
        if (index < 4 && pregunta.texto)
          return (
            <Row key={index}>
              <Col xs={2}>{getIcon(index, { style: { width: "15px" } })}</Col>
              <Col xs={10} className="px-0">
                <p className="px-0 mx-0 py-0 my-0" style={{ fontSize: "10px" }}>
                  {pregunta.texto}
                </p>
              </Col>
            </Row>
          );
        return null;
      })
      .filter((pregunta) => pregunta !== null);
  }

  renderHorizontalesVistaPrevia() {
    return this.props.preguntas
      .map((pregunta, index) => {
        if (index >= 4 && pregunta.texto)
          return (
            <Row>
              <Col xs={2}>{getIcon(index, { style: { width: "15px" } })}</Col>
              <Col xs={10} className="px-0">
                <p className="px-0 mx-0 py-0 my-0" style={{ fontSize: "10px" }}>
                  {pregunta.texto}
                </p>
              </Col>
            </Row>
          );
        return null;
      })
      .filter((pregunta) => pregunta !== null);
  }

  renderContenidoVistaPreviaCrucigrama() {
    let minutos;
    let segundos;
    if (this.props.juego) {
      minutos = Math.floor(this.props.juego.tiempo / 60);
      segundos = this.props.juego.tiempo % 60;
    }
    return (
      <Container className="box-border mx-0 px-0 vista-previa" fluid>
        <Image src={fondo} className="h-100 w-100 vista-previa-bg" />
        <Col className="vista-previa-elements d-flex flex-column">
          <Row className="justify-content-center pt-1">
            <Col xs={3} className="">
              <Image src={logo} style={{ width: "30%" }} />
            </Col>
            <Col xs={6}>
              <Row className="justify-content-center align-items-center text-center">
                <Col>
                  <p
                    className="px-0 py-0 mx-0 my-0"
                    style={{ fontSize: "12px" }}
                  >
                    <Image src={rectangulo} style={{ width: "5%" }} />
                    <span className="font-weight-bold pl-1">
                      {this.props.juego.titulo
                        ? this.props.juego.titulo
                        : "Crucigrama"}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center text-center">
                <Col>
                  <p
                    className="text-muted px-0 py-0 mx-0 my-0"
                    style={{ fontSize: "10px" }}
                  >
                    <i className="far fa-clock"></i>{" "}
                    <span>
                      {this.props.juego.tiempo ? minutos : "1"}m :{" "}
                      {this.props.juego.tiempo
                        ? segundos.toString().length === 2
                          ? segundos
                          : "0" + segundos
                        : "00"}
                      s
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={3}></Col>
          </Row>
          <Row className="flex-grow-1 py-3">
            <Col xs={6}>
              <Row className="align-items-center">
                <Col xs={10}>
                  <p
                    className="pr-0 py-0 mx-0 my-0"
                    style={{ fontSize: "10px" }}
                  >
                    Verticales:
                  </p>
                </Col>
                <Col xs={2}>
                  <i className="far fa-check-circle fa-sm"></i>
                </Col>
              </Row>
              {this.renderVerticalesVistaPrevia()}
            </Col>
            <Col xs={6}>
              <Row className="align-items-center">
                <Col xs={10}>
                  <p
                    className="pr-0 py-0 mx-0 my-0"
                    style={{ fontSize: "10px" }}
                  >
                    Horizontales:
                  </p>
                </Col>
                <Col xs={2}>
                  <i className="far fa-check-circle p"></i>
                </Col>
              </Row>
              {this.renderHorizontalesVistaPrevia()}
            </Col>
          </Row>
          <Row className="pb-2 align-items-center">
            <Col xs={6} className="">
              <Row className="pl-3">
                <p
                  className="px-0 py-0 mx-0 my-0"
                  style={{ fontWeight: "bold", fontSize: "10px" }}
                >
                  PIN del juego:
                </p>
              </Row>
              <Row className="pl-3">
                <p className="px-0 py-0 mx-0 my-0" style={{ fontSize: "10px" }}>
                  xxxx
                </p>
              </Row>
            </Col>
            <Col xs={6} className="text-right pl-0 pr-3">
              <Button
                variant="link"
                className="link-gray pl-0"
                style={{ fontSize: "10px" }}
              >
                Salir del juego
              </Button>
              <Button
                variant="outline-danger"
                className="rounded-button border-red-new py-1"
                style={{ fontSize: "10px" }}
              >
                Continuar
              </Button>
            </Col>
          </Row>
        </Col>
      </Container>
    );
  }

  renderDisclaimer() {
    if (this.props.juego.tipo.toLowerCase() === "crucigrama")
      return (
        <Row style={{ fontWeight: "bold" }} className="mr-5">
          <p>
            *El orden de las palabras puede variar una vez que des click en
            guardar
          </p>
        </Row>
      );
  }

  renderVistaPrevia() {
    let minutos;
    let segundos;
    if (this.props.juego) {
      minutos = Math.floor(this.props.juego.tiempo / 60);
      segundos = this.props.juego.tiempo % 60;
    }
    return (
      <>
        <Row>
          <p className="h6 mt-4 pt-3">Vista previa</p>
        </Row>
        <Row className="justify-content-start">
          {this.props.juego.tipo.toLowerCase() === "crucigrama"
            ? this.renderContenidoVistaPreviaCrucigrama()
            : this.renderContenidoVistaPreviaAhorcado()}
        </Row>
        <Row className="align-items-center pt-2">
          <p>
            <i
              className={
                this.props.juego.publico
                  ? "fas fa-users pr-1"
                  : "fas fa-user color-000 pr-1"
              }
            />
            <span className="pr-3">
              {this.props.juego.publico ? " Todos" : " Solo yo"}
            </span>
            <i className="far fa-clock pr-1" />
            <span className="pr-3">
              {this.props.juego.tiempo
                ? minutos
                : this.props.tipo === "crucigrama"
                ? "1"
                : "0"}
              m :{" "}
              {this.props.juego.tiempo
                ? segundos.toString().length === 2
                  ? segundos
                  : "0" + segundos
                : this.props.tipo === "crucigrama"
                ? "00"
                : "15"}
              s
            </span>
          </p>
        </Row>
        {this.renderDisclaimer()}
      </>
    );
  }

  async handleClick() {
    this.props.createNuevaPregunta(this.props.preguntas.length + 1);
    await navigate(
      this.props.idJuego
        ? `/editar/${this.props.idJuego}/pregunta/nueva-${
            this.props.preguntas.length + 1
          }`
        : `/crear/pregunta/nueva-${this.props.preguntas.length + 1}`
    );
    window.scrollTo(0, 0);
  }

  renderIcon() {
    if (this.props.juego.tipo.toLowerCase() === "crucigrama") {
      return <Image src={rectangulo} style={{ width: "3vw" }} />;
    } else return <Image src={asterisco} style={{ width: "3vw" }} />;
  }

  renderButton() {
    if (
      (this.props.tipo &&
        this.props.tipo.toLowerCase() === "crucigrama" &&
        this.props.preguntas.length < 9) ||
      !this.props.tipo
    )
      return (
        <Button
          variant="outline-danger"
          className="border-red-new show-mobile-button px-3 py-3 mr-3"
          style={{ width: 60, height: 60, color: "#FF3833", borderRadius: '70px' }}
          onClick={() => {
            this.handleClick();
          }}
          size='lg'
        >
          <h6><i className="fas fa-plus"></i></h6>
        </Button>
      );
  }

  render() {
    return (
      <Row>
        <Col xs={12} md={6} className="px-4">
          <Row className="pb-2">
            <Container className="ml-1 pl-2">
              <h1 className="my-3">
                {this.renderIcon()}
                <span className="ml-2">Pregunta {this.props.index + 1}</span>
              </h1>
            </Container>
          </Row>
          {this.renderPregunta()}
        </Col>
        <Col xs={12} md={6} className="mt-4 hide-mobile">
          {this.renderVistaPrevia()}
          {/*
          <Row className='fixed-bottom mb-4 mr-3' style={{zIndex: 9}}>
            <Col md={12} className='text-right'>
              {this.renderButton()}
              <Button
                size='lg'
                variant="danger"
                className="new-red-button px-4 py-4"
                onClick={this.props.handleSubmit}
                disabled={this.props.disabled}
              >
                <h6 className='mb-0'>{this.props.button}</h6>
              </Button>
            </Col>
          </Row>
          */}
        </Col>
      </Row>
    );
  }
}

export default PreguntaAhorcadoCrear;
