import React from "react";
import { Modal, Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import Dropzone from "./Dropzone";
import AdminTable from "./AdminTable";
import InputULU from "./global/InputULU";
import Input from "./Input";
class CodigosModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro: null,
    };
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    this.props.getCodigos(this.props.idPremio);
  }

  handleFilter(filtro) {
    this.setState({ filtro });
  }

  getTipo(tipos, key, excel) {
    let message;
    if (this.props.codigos !== null && this.props.codigos.length > 0) {
      const codigo = this.props.codigos[0];
      if (codigo) {
        if (key === 1) {
          if (codigo.tipoCodigo !== "") {
            return codigo.tipoCodigo;
          }
        } else if (key === 2) {
          if (codigo.tipoCodigoBarras !== "") {
            return codigo.tipoCodigoBarras;
          }
        } else if (key === 3) {
          if (codigo.codificacion !== "") {
            return codigo.codificacion;
          }
        }
      } else if (excel) {
        if (key === 1) {
          if (this.props.excel.tipoCodigo) {
            return this.props.excel.tipoCodigo;
          }
        } else if (key === 2) {
          if (this.props.excel.tipoCodigoBarras) {
            return this.props.excel.tipoCodigoBarras;
          }
        } else if (key === 3) {
          if (this.props.excel.codificacion) {
            return this.props.excel.codificacion;
          }
        }
      }
    } else if (excel) {
      if (key === 1) {
        if (this.props.excel.tipoCodigo) {
          return this.props.excel.tipoCodigo;
        }
      } else if (key === 2) {
        if (this.props.excel.tipoCodigoBarras) {
          return this.props.excel.tipoCodigoBarras;
        }
      } else if (key === 3) {
        if (this.props.excel.codificacion) {
          return this.props.excel.codificacion;
        }
      }
    } else if (this.props.tipo) {
      return this.props.tipo;
    }
    if (key === 1) {
      message = "Tipo de Código";
    } else if (key === 2) {
      message = "Tipo de Código de Barras";
    } else if (key === 3) {
      message = "Codificación";
    }
    return message;
  }

  renderTipos(tipos, key) {
    if (tipos && tipos !== null) {
      let handler = this.props.cambiarTipo;
      return tipos.map((tipo, index) => (
        <Dropdown.Item key={index} onClick={() => handler(key, tipo)}>
          {tipo}
        </Dropdown.Item>
      ));
    }
  }

  renderDropdownBarras(excel) {
    let tipoCodigo;
    let tipoCodigoBarras;
    if (excel) {
      tipoCodigo = this.props.excel.tipoCodigo;
      tipoCodigoBarras = this.props.excel.tipoCodigoBarras;
    } else {
      if (this.props.codigos && this.props.codigos !== null) {
        if (this.props.codigos[0]) {
          tipoCodigo = this.props.codigos[0].tipoCodigo;
          tipoCodigoBarras = this.props.codigos[0].tipoCodigoBarras;
        } else {
          tipoCodigo = this.props.nuevo.tipoCodigo;
          tipoCodigoBarras = this.props.nuevo.tipoCodigoBarras;
        }
      } else {
        tipoCodigo = this.props.nuevo.tipoCodigo;
        tipoCodigoBarras = this.props.nuevo.tipoCodigoBarras;
      }
    }
    if (String(tipoCodigo).includes("arras")) {
      return (
        <Col
          xs={tipoCodigoBarras === "EAN" || tipoCodigoBarras === "MSI" ? 7 : 12}
          className="p-0"
        >
          <Dropdown drop="down">
            <Dropdown.Toggle
              id="tipoCodigoBarras"
              bsPrefix="outline-no-fill"
              className="text-left"
            >
              {this.getTipo(this.props.tiposBarras, 2, excel)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.renderTipos(
                this.props.tiposBarras,
                "tipoCodigoBarras",
                excel
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      );
    }
  }

  renderDropdownCodificacion(excel) {
    let tipoCodigoBarras;
    if (excel) tipoCodigoBarras = this.props.excel.tipoCodigoBarras;
    else {
      if (this.props.codigos && this.props.codigos !== null) {
        if (this.props.codigos[0]) {
          tipoCodigoBarras = this.props.codigos[0].tipoCodigoBarras;
        } else {
          tipoCodigoBarras = this.props.nuevo.tipoCodigoBarras;
        }
      } else {
        tipoCodigoBarras = this.props.nuevo.tipoCodigoBarras;
      }
    }
    if (tipoCodigoBarras === "EAN" || tipoCodigoBarras === "MSI") {
      return (
        <Col xs={5} className="p-0 pl-2">
          <Dropdown drop="down">
            <Dropdown.Toggle
              id="tipoCodigoBarras"
              bsPrefix="outline-no-fill"
              className="text-left"
            >
              {this.getTipo(
                tipoCodigoBarras === "EAN"
                  ? this.props.codificacionEAN
                  : this.props.codificacionMSI,
                3,
                excel
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.renderTipos(
                tipoCodigoBarras === "EAN"
                  ? this.props.codificacionEAN
                  : this.props.codificacionMSI,
                "codificacion",
                excel
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      );
    }
  }

  renderDropdowns(excel) {
    return (
      <>
        <Row className="pt-3">
          <Col className="p-0">
            <Dropdown drop="down">
              <Dropdown.Toggle
                id="tipoCodigo"
                bsPrefix="outline-no-fill"
                className="text-left"
              >
                {this.getTipo(this.props.tipos, 1, excel)}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.renderTipos(this.props.tipos, "tipoCodigo", excel)}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="pt-3">
          {this.renderDropdownBarras(excel)}
          {this.renderDropdownCodificacion(excel)}
        </Row>
      </>
    );
  }

  renderOpcionesExcel() {
    if (this.props.file && this.props.file !== null) {
      return this.renderDropdowns(true);
    }
  }

  renderTable() {
    if (this.props.codigos !== null) {
      let { codigos } = this.props;
      codigos = codigos.filter((codigo) => String(codigo.idCodigo)[0] !== "d");
      codigos = codigos.map((codigo) => ({
        ...codigo,
        ganado: codigo.ganado === 1 ? "Otorgado" : "Sin otorgar",
      }));
      if (this.state.filtro !== null) {
        if (this.state.filtro === "activos") {
          codigos = codigos.filter(
            (codigo) =>
              codigo.cantidad === 0 || codigo.canjeados < codigo.cantidad
          );
        } else if (this.state.filtro === "expirados") {
          codigos = codigos.filter(
            (codigo) =>
              codigo.cantidad > 0 && codigo.canjeados === codigo.cantidad
          );
        }
      }
      return (
        <AdminTable
          idRow="idCodigo"
          reducer="CODIGO"
          headers={["Código", "# usos", "# otorgados", "Tipo de código"]}
          editRow={this.props.editRow}
          editedRow={this.props.codigo}
          deleteRow={this.props.deleteRow}
          saveRow={this.props.saveRow}
          rows={codigos}
          editables={["codigo", "cantidad"]}
          isEditable={(row) => row.ganado === "Sin otorgar"}
          inputModifier={this.props.modifier}
          exclude={[
            "idCodigo",
            "tipoCodigoBarras",
            "codificacion",
            "idPremio",
            "ganado",
          ]}
          tableClass="mb-0 border-radius-10"
          editColor="rgb(63,63,63)"
        />
      );
    }
  }

  render() {
    return (
      <Modal.Body className="py-0">
        <Container className="py-0">
          <Row className="mb-3">
            <Col className="p-0">
              Tipo de Codigo
              <Container fluid>
                {this.renderDropdowns(this.props.file !== null)}
              </Container>
            </Col>
          </Row>
          <Row>
            <Col className="p-0">Carga automática</Col>
          </Row>
          <Row>
            <Dropzone
              file={this.props.file}
              modifier={this.props.setExcel}
              accept="spreadsheet"
              excel
              alert={this.props.alert}
            />
          </Row>
          {/* this.renderOpcionesExcel() */}
          <Row>
            <Col className="p-0">
              <hr style={{ borderWidth: 2, marginTop: 25 }} />
            </Col>
          </Row>
          <Row className="mb-3 align-items-center">
            <Col xs={6} className="p-0">
              Carga manual
            </Col>
            <Col xs={6} className="p-0">
              <Input
                as="select"
                type="select"
                options={[
                  { name: "Todos", value: null },
                  { name: "Activos", value: "activos" },
                  { name: "Expirados", value: "expirados" },
                ]}
                modifier={this.handleFilter}
              />
            </Col>
          </Row>
          <Row>
            <Col
              className="p-0 admin-table tabla-codigos mb-3"
              style={{ fontSize: 16 }}
            >
              {this.renderTable()}
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <InputULU
                label="Código"
                maxLength={255}
                value={this.props.nuevo.codigo}
                onChange={(event) =>
                  this.props.setPropiedadCodigo("codigo", event.target.value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className="p-0" xs={6} md={4}>
              <InputULU
                label="Cantidad"
                value={this.props.nuevo.cantidad}
                onChange={(event) =>
                  this.props.setPropiedadCodigo("cantidad", event.target.value)
                }
                type="number"
                maxLength={255}
                max={255}
                min={0}
              />
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <p className="h6 text-muted">
                * Si deseas códigos ilimitados escribe 0
              </p>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col className="p-0">
              <Button
                variant="outline-danger"
                className="border-red-new px-3 py-2 mx-auto my-0"
                onClick={() => this.props.agregarCodigo(this.props.nuevo)}
                disabled={this.props.nuevo.codigo === ""}
              >
                {this.props.nuevo.idCodigo === "nuevo" ? "Agregar" : "Guardar"}{" "}
                código
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    );
  }
}

export default CodigosModal;
