import React, { Component } from 'react';
import Sidebar from "../components/Sidebar";
import SearchBox from "../components/SearchBox";
import ExploreGameList from '../components/ExploreGameList';
import SponsorBubbleList from "../components/SponsorBubbleList";
import DropdownMisJuegos from "../components/DropdownMisJuegos";
import InfiniteScroll from 'react-infinite-scroll-component';
import IconsLoading from '../components/IconsLoading';
import { throttle } from "throttle-debounce";
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { tipos, isMobile } from "../utils";
import { connect } from "react-redux";
import { 
    getPatrocinadores,
    getJuegosPatrocinadores,
    getJuegosDestacados,
    getAllJuegos,
    searchJuegos,
    searchJuegosNextPage,
    resetJuegos
} from '../actions/exploreActions';
import { setJuego } from "../actions/jugarActions";
import { navigate } from "@reach/router";

class Explorar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pages: null,
          currentPage: 1,
          currentPageSearch: 1,
          search: "",
          gameType: "all",
          filter: null,
          highlightsGameForwardCount: 0,
          partnersGameForwardCount: 0,
          partnersBubbleForwardCount: 0,
          partnersBubbleGrid: false,
          partnersGameGrid: false,
          mobile: false
        };
        this.partnersBubbleList = React.createRef();
        this.highlightsGameList = React.createRef();
        this.partnersGameList = React.createRef();

        this.changePartnersGameList = this.changePartnersGameList.bind(this);
        this.changePartnersBubbleList = this.changePartnersBubbleList.bind(this);
        this.scrollForward = this.scrollForward.bind(this);
        this.scrollBackwards = this.scrollBackwards.bind(this);
        this.fetchGames = this.fetchGames.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.fetchSearchGamesThrottled = throttle(500, this.fetchSearchGames.bind(this));
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.props.getPatrocinadores();
        if(isMobile())//Fetch on start if its on mobile
          this.fetchGames();
        
        this.props.getJuegosPatrocinadores({gameType: this.state.gameType});
        this.props.getJuegosDestacados({gameType: this.state.gameType});
        //window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();

    }

    componentWillUnmount() {
      //window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      let mobile = isMobile();
      this.setState({mobile});
    }

    fetchGames() {
        if (Math.ceil(this.props.lengthData / 10) - this.state.currentPage !== 0) {
            this.setState({currentPage: this.state.currentPage + 1 }, () => {
                setTimeout(() => {//para que se vea la animacion de cargando
                    this.props.getAllJuegos({currentPage: this.state.currentPage, limit: 10, gameType: this.state.gameType});
                }, 300);
            });
        }
    }

    fetchSearchGames() {
        if (Math.ceil(this.props.searchResultsLength / 10) - this.state.currentPageSearch !== 0) {
            this.setState({currentPageSearch: this.state.currentPageSearch + 1 }, () => {
                setTimeout(() => {//para que se vea la animacion de cargando
                    this.props.searchJuegosNextPage({page: this.state.currentPageSearch, limit: 10, search: this.state.search, gameType: this.state.gameType});
                    this.props.getAllJuegos({currentPageSearch: this.state.currentPageSearch, limit: 10, gameType: this.state.gameType});
                }, 300);
            });
        }
    }

    handleFilter(filter){
      this.setState(
        {
          gameType: filter.toLowerCase(),
          currentPage: 0,
          currentPageSearch: 1,
          filter,
        },
        () => {
          this.props.resetJuegos();
          this.props.getJuegosPatrocinadores({
            gameType: this.state.gameType,
          });
          this.props.getJuegosDestacados({ gameType: this.state.gameType });
          if (this.state.search === "") {
            this.fetchGames();
          } else {
            this.fetchSearchGamesThrottled();
          }
        }
      );
    }

    scrollForward(row,all=false) {
      let speed = 1000
      let tracker = 1
      if(all){
        speed = 6000;
        tracker = 3
      }

      switch(row) {
        case 'partnersGameList':
          this.partnersGameList.current.scrollBy({left: speed, top: 0, behavior: 'smooth'});
          this.setState((prevState) => ({partnersGameForwardCount: prevState.partnersGameForwardCount + tracker }));
          break;
        case 'highlightsGameList':
          this.highlightsGameList.current.scrollBy({left: speed, top: 0, behavior: 'smooth'});
          this.setState((prevState) => ({highlightsGameForwardCount: prevState.highlightsGameForwardCount + tracker }));
          break;
        case 'partnersBubbleList':
          this.partnersBubbleList.current.scrollBy({left: speed, top: 0, behavior: 'smooth'});
          this.setState((prevState) => ({partnersBubbleForwardCount: prevState.partnersBubbleForwardCount + tracker }));  
          break;
        default:
          break;
      }
    }

    scrollBackwards(row,all=false) {
      let speed = -1000;
      let tracker = -1;
      if(all){
        speed = -6000;
        tracker = 0;
      }

      switch(row) {
        case 'partnersGameList':
            this.partnersGameList.current.scrollBy({left: speed, top: 0, behavior: 'smooth'});
            this.setState((prevState) => ({partnersGameForwardCount: tracker==0 ? 0 : prevState.partnersGameForwardCount - tracker }));
          break;
        case 'highlightsGameList':
            this.highlightsGameList.current.scrollBy({left: speed, top: 0, behavior: 'smooth'});
            this.setState((prevState) =>({highlightsGameForwardCount: tracker==0 ? 0 :  prevState.highlightsGameForwardCount - tracker }));
          break;
        case 'partnersBubbleList':
            this.partnersBubbleList.current.scrollBy({left: speed, top: 0, behavior: 'smooth'});
            this.setState((prevState) => ({partnersBubbleForwardCount: tracker==0 ? 0 :  prevState.partnersBubbleForwardCount - tracker}));
          break;
        default:
          break;
      }
    }

    changePartnersGameList() {
      this.setState((prevState) => ({partnersGameGrid: !prevState.partnersGameGrid}));
    }

    changePartnersBubbleList() {
      this.setState((prevState) => ({partnersBubbleGrid: !prevState.partnersBubbleGrid}));
    }

    renderContent() {
      if (this.state.search === "") {
        return (
          <>
            <Row className="mt-3 mb-4">
                <Col md={12}>
                    <h5>Destacados de la semana</h5>
                    <div className='one-sponsor-game-list'>
                      {/* Left arrow */}
                      {this.state.highlightsGameForwardCount > 0 &&
                        <div className='scrollable-bar-backwards vertical-center'>
                          <button 
                            className='scrollable-button' 
                            onClick={() => this.scrollBackwards('highlightsGameList')}
                            onDoubleClick={()=>{this.scrollBackwards('highlightsGameList',true);console.log('dblclick')}}
                            >
                            <h5 className='m-0 p-0'>
                              <i className="fas fa-chevron-left"></i>
                            </h5>
                          </button>
                        </div>
                      }

                      {/*List of games*/}
                      <div className="two" role='presentation' ref={this.highlightsGameList}>
                          <div className="three">
                              <ExploreGameList 
                                  games={this.props.juegosDestacados}
                                  setJuego={this.props.setJuego}
                                  navigate={navigate}
                                  user={this.props.user}
                                  isMobile={this.state.mobile}
                              />
                          </div>
                      </div>

                      {/* Right arrow */}
                      {(this.props.juegosDestacados && this.props.juegosDestacados.length >= 6 && window.innerWidth > 576) && 
                        <div className='scrollable-bar-forward vertical-center'>
                          <button 
                          className='scrollable-button' 
                          onClick={() => this.scrollForward('highlightsGameList')}
                          onDoubleClick={()=>{this.scrollForward('highlightsGameList',true); console.log('dblclick')}}
                          >
                            <h5 className='m-0 p-0'><i className="fas fa-chevron-right"></i></h5>
                          </button>
                        </div>
                      }

                    </div>
                </Col>
            </Row>
            {this.props.juegosPatrocinadores.length !== 0 &&
            <Row className="mt-3 mb-4">
                <Col md={12}>
                    <h5>Creados por impulsores</h5>
                    <div className='one-sponsor-game-list'>
                      {/* Left Arrow */}
                      {(this.state.partnersGameForwardCount > 0 && !this.state.partnersGameGrid) &&
                        <div className='scrollable-bar-backwards vertical-center'>
                          <button 
                            className='scrollable-button' 
                            onClick={() => this.scrollBackwards('partnersGameList')}
                            onDoubleClick={()=>this.scrollBackwards('partnersGameList',true)}
                          >
                            <h5 className='m-0 p-0'>
                              <i className="fas fa-chevron-left"></i>
                            </h5>
                          </button>
                        </div>
                      }
                      {/* List of games */}
                      <div className="two" role='presentation' ref={this.partnersGameList}>
                          <div className="three">
                              <ExploreGameList 
                                grid={this.state.partnersGameGrid}
                                changeRender={this.changePartnersGameList}
                                sponsor 
                                games={this.props.juegosPatrocinadores}
                                setJuego={this.props.setJuego}
                                navigate={navigate}  
                                user={this.props.user}
                                isMobile={this.state.mobile}
                              />
                          </div>
                      </div>

                      {/* Right Arrow */}
                      {(this.props.juegosPatrocinadores && this.props.juegosPatrocinadores.length >= 6 && window.innerWidth > 576 && !this.state.partnersGameGrid) && 
                        <div className='scrollable-bar-forward vertical-center'>
                          <button 
                            className='scrollable-button' 
                            onClick={() => this.scrollForward('partnersGameList')}
                            onDoubleClick={()=>this.scrollForward('partnersGameList',true)}
                          >
                            <h5 className='m-0 p-0'>
                              <i className="fas fa-chevron-right"></i>
                            </h5>
                          </button>
                        </div>
                      }
                    </div>
                </Col>
            </Row>
            }
            <Row className="mt-3 mb-4">
                <Col md={12}>
                    <h5>Todos los juegos</h5>
                    {this.props.allJuegos &&
                        <InfiniteScroll
                            dataLength={this.props.allJuegos.length}
                            next={this.fetchGames}
                            hasMore={Math.ceil(this.props.lengthData / 10) - this.state.currentPage !== 0}
                            loader={<IconsLoading />}
                        >
                            <ExploreGameList 
                                grid
                                games={this.props.allJuegos}
                                setJuego={this.props.setJuego}
                                navigate={navigate}
                                user={this.props.user}
                                isMobile={this.state.mobile}
                            />
                        </InfiniteScroll>
                    }
                </Col>
            </Row>
          </>
        );
      }
      else{
        return (
          <Row className="mt-3 mb-4">
            <Col md={12}>
              <h5>Resultados de: {this.state.search}</h5>
              {this.props.searchResults &&
                  <InfiniteScroll
                      dataLength={this.props.searchResults.length}
                      next={this.fetchSearchGamesThrottled}
                      hasMore={Math.ceil(this.props.searchResultsLength / 10) - this.state.currentPageSearch !== 0}
                      loader={<IconsLoading />}
                  >
                      <ExploreGameList  
                        navigate={navigate} 
                        setJuego={this.props.setJuego}
                        grid
                        games={this.props.searchResults}
                        user={this.props.user}
                      />
                  </InfiniteScroll>
              }
            </Col>
          </Row>
        )
      }
    }
    
    render() {
        return (
          <Container fluid className="mb-xs-65px">
            <Row>
              <Col
                sm={4}
                md={3}
                xl={2}
                className="bg-white px-0 hide-mobile"
                style={{ minHeight: "100vh" }}
              >
                <Sidebar
                  handleFilter={this.handleFilter}
                  movil={this.state.movil}
                  filter={this.state.filter}
                  page="Explore"
                  user={this.props.user}
                />
              </Col>
              <DropdownMisJuegos
                tipos={tipos}
                tipo={this.state.filter}
                handleFilter={this.handleFilter}
                page="Explore"
              />
              <Col sm={12} md={9} xl={10}>
                <div className="explore-fixed-top">
                  <Row className='justify-content-between py-2'>
                    <Col xs={12} md={4}>
                      <SearchBox
                        searchModifier={(search) => {
                          this.props.searchJuegos({
                            page: 1,
                            limit: 10,
                            search,
                          });
                          this.setState({ search: search });
                        }}
                        placeholder="  &#61442;   Buscar"
                      />
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col md={12}>
                    <h5>Impulsores destacados</h5>
                    <div className="one">
                      {(this.state.partnersBubbleForwardCount > 0 && !this.state.partnersBubbleGrid) &&
                        <div className='scrollable-bar-backwards vertical-center'>
                          <button className='scrollable-button' onClick={() => this.scrollBackwards('partnersBubbleList')}>
                            <h5 className='m-0 p-0'><i className="fas fa-chevron-left"></i></h5>
                          </button>
                        </div>
                      }
                      <div className="two" role="presentation" ref={this.partnersBubbleList}>
                        <div className="three">
                          <SponsorBubbleList
                            grid={this.state.partnersBubbleGrid}
                            changeRender={this.changePartnersBubbleList}
                            sponsors={this.props.patrocinadores}
                          />
                        </div>
                      </div>
                      {(this.props.patrocinadores && this.props.patrocinadores.length >= 16 && window.innerWidth > 576 && !this.state.partnersBubbleGrid) && 
                      <div className='scrollable-bar-forward vertical-center'>
                        <button className='scrollable-button' onClick={() => this.scrollForward('partnersBubbleList')}>
                          <h5 className='m-0 p-0'><i className="fas fa-chevron-right"></i></h5>
                        </button>
                      </div>
                    }
                    </div>
                  </Col>
                </Row>
                {this.renderContent()}
              </Col>
            </Row>
          </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    patrocinadores: state.explore.patrocinadores,
    juegosPatrocinadores: state.explore.juegosPatrocinadores,
    juegosDestacados: state.explore.juegosDestacados,
    allJuegos: state.explore.allJuegos,
    lengthData: state.explore.lengthData,
    search: state.explore.search,
    searchResults: state.explore.searchResults,
    searchResultsLength: state.explore.searchResultsLength,
    user: state.user.user
});

export default connect(mapStateToProps, {
    getPatrocinadores,
    getJuegosPatrocinadores,
    getJuegosDestacados,
    getAllJuegos,
    searchJuegos,
    searchJuegosNextPage,
    resetJuegos,
    setJuego
})(Explorar);