import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import SidebarForm from "../components/SidebarForm";
import DetalleForm from "../components/DetalleForm";
import { navigate } from "@reach/router";
import {
  getJuego,
  createNuevoJuego,
  setPropiedadJuego,
  searchImages
} from "../actions/juegoActions";
import {
  createNuevaPregunta,
  setPropiedadPregunta,
  postPreguntas,
  eliminarPregunta,
  eliminarOpcion,
  reestablecerPregunta,
  reestablecerOpcion,
  setPropiedadPreguntaCrucigrama,
  duplicarPregunta,
  reordenarPreguntas,
} from "../actions/preguntasActions";
import { confirm } from "../actions/modalActions";
import { getJuegos } from "../actions/configuracionJuegosActions";
import { connect } from "react-redux";
import Dropdown from "../components/Dropdown";
import { getTipo, getColor, getColorName } from "../utils";

class InformacionGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulo: undefined,
      descripcion: undefined,
      visible: true,
      //button: "Crear",
      idJuego: "nuevo",
      validMinutos: true,
      validSegundos: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillMount() {
    onbeforeunload = (e) => "Don't leave :(";
  }

  componentWillUnmount() {
    onbeforeunload = null;
  }
  
  componentDidMount() {
    this.props.getJuegos();
    if (this.props.idJuego && !this.props.juego) {
      this.props.getJuego(this.props.idJuego);
      this.setState({ idJuego: this.props.idJuego });
    } else if (this.props.tipo && !this.props.juego) {
      this.props.createNuevoJuego(this.props.tipo);
    } else if (!this.props.juego) {
      return navigate("/seleccionar");
    }
    /* if (this.props.path.includes("editar"))
      this.setState({ button: "Guardar" }); */
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    window.scrollTo(0, 0);
    ReactGA.pageview("/informaciongeneral");
  }

  componentDidUpdate() {
    if (this.props.juegos !== null) {
      if (this.props.juego || this.props.tipo) {
        const tipo = this.props.juego ? this.props.juego.tipo : this.props.tipo;
        const juego = this.props.juegos.find((juego) => juego.nombre === tipo);
        if (!juego.activo) {
          return navigate("/503");
        }
      }
    }
  }

  resize() {
    let currentNav = window.innerWidth <= 760;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    this.setState({ validated: true });
    this.props.postPreguntas(
      this.props.preguntas,
      this.props.juego,
      this.props.idJuego,
      (idJuego) => {
        navigate("/editar/" + this.props.juego.idJuego);
      }
    );
    ReactGA.event({
      category: "Guardar",
      action: "Guardar Juego",
      label: "Guardar Juego",
    });
  }

  handleChange(event, input) {
    let { value } = event.target;
    if (input === "publico") {
      value = value === "publico";
    }
    if (input === "minutos") {
      if (isNaN(value) || value > 9 || value < 0) {
        this.setState({ validMinutos: false });
      } else {
        this.setState({ validMinutos: true });
      }
    }
    if (input === "segundos") {
      if (isNaN(value) || value > 59 || value < 0) {
        this.setState({ validSegundos: false });
      } else {
        this.setState({ validSegundos: true });
      }
    }
    this.props.setPropiedadJuego(input, value);
  }

  renderCambios() {
    if (this.props.cambios)
      return <p className="text-warning mb-3">Cambios sin guardar</p>;
  }

  async handleClick() {
    this.props.createNuevaPregunta(this.props.preguntas.length + 1);
    await navigate(
      this.props.idJuego
        ? `/editar/${this.props.idJuego}/pregunta/nueva-${
            this.props.preguntas.length + 1
          }`
        : `/crear/pregunta/nueva-${this.props.preguntas.length + 1}`
    );

    window.scrollTo(0, 0);
  }

  renderButton() {
    // Este solo es el + de movil
    if (
      (this.props.tipo.toLowerCase() === "crucigrama" &&
        this.props.preguntas.length < 9) ||
      this.props.tipo.toLowerCase() !== "crucigrama"
    )
      return (
        <Button
          variant="outline-danger"
          className="border-red-new show-mobile-button px-2 py-2 mr-2"
          style={{
            width: 50,
            height: 50,
            color: "#FF3833",
            borderRadius: "70px",
          }}
          onClick={() => this.handleClick()}
          size="lg"
        >
          <h6>
            <i className="fas fa-plus"></i>
          </h6>
        </Button>
      );
  }

  renderJuego() {
    if (!this.props.juego || this.props.juego === null)
      return (
        <Container className="text-center pt-3">
          <Spinner animation="border" variant="dark" />
        </Container>
      );
    return (
      <>
        <DetalleForm
          juego={this.props.juego}
          tipo={this.props.tipo}
          handleChange={this.handleChange}
          disabled={this.props.disabled}
          validMinutos={this.state.validMinutos}
          validSegundos={this.state.validSegundos}
          searchImages={this.props.searchImages}
          imagesSearchResults={this.props.imagesSearchResults}
          imagesSearchPages={this.props.imagesSearchPages}
          loadingImages={this.props.loadingImages}
        />
        {/*
          <Button
            disabled={this.props.disabled}
            variant="danger"
            className="new-red-button px-3 py-3 mx-3 mt-5 mb-2 ml-auto fixed-bottom"
            style={{ width: "15vw" }}
            onClick={this.handleSubmit}
          >
            Guardar
          </Button>
          */}
        {/*
          <Row className='fixed-bottom mb-4 mr-3' style={{zIndex: 9}}>
            <Col md={12} className='text-right'>
          */}
        <div className='fixed-bottom-right'>
          {this.renderButton()}
          <Button
            size="lg"
            variant="danger"
            className="new-red-button px-3 py-3"
            onClick={this.handleSubmit}
            disabled={this.props.disabled}
          >
            <h6>Guardar</h6>
          </Button>
        </div>
        {/*
            </Col>
          </Row>
          */}
      </>
    );
  }

  render() {
    let tipo = getTipo(this.props.juego);

    return (
      <Container className="no-bg-img h-100" fluid>
        <Row>
          <Col xs={12} md={3} xl={2} className="bg-white hide-mobile" style={{ minHeight: "100vh" }}>
            <SidebarForm
              idJuego={this.props.idJuego}
              tipo={this.props.tipo}
              preguntas={this.props.preguntas}
              createNuevaPregunta={this.props.createNuevaPregunta}
              duplicarPregunta={this.props.duplicarPregunta}
              eliminarPregunta={this.props.eliminarPregunta}
              reestablecerPregunta={this.props.reestablecerPregunta}
              movil={this.state.movil}
              reordenarPreguntas={this.props.reordenarPreguntas}
            />
          </Col>
          <Container
            className={`show-mobile px-4 pb-3 pastel-${getColorName(
              tipo === "ahorcado"
                ? 4
                : tipo === "trivia"
                ? 3
                : tipo === "crucigrama"
                ? 1
                : 2
            )}`}
            fluid
            style={{
              color: getColor(
                tipo === "ahorcado"
                  ? 4
                  : tipo === "trivia"
                  ? 3
                  : tipo === "crucigrama"
                  ? 1
                  : 2
              ),
            }}
          >
            <Dropdown
              tipo={tipo}
              idJuego={this.props.idJuego}
              preguntas={this.props.preguntas}
              createNuevaPregunta={this.props.createNuevaPregunta}
              duplicarPregunta={this.props.duplicarPregunta}
              eliminarPregunta={this.props.eliminarPregunta}
              reestablecerPregunta={this.props.reestablecerPregunta}
            />
          </Container>
          <Col sm={12} md={9} xl={10}>
            {this.renderJuego()}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  juego: state.juego.juego,
  tipo: state.juego.tipo,
  preguntas: state.juego.preguntas,
  cambios: state.juego.cambios,
  disabled: state.juego.disabled,
  juegos: state.configuracion.juegos,
  imagesSearchResults: state.juego.imagesSearchResults,
  imagesSearchPages: state.juego.imagesSearchPages,
  loadingImages: state.juego.loadingImages
});

export default connect(mapStateToProps, {
  getJuego,
  createNuevoJuego,
  createNuevaPregunta,
  setPropiedadPregunta,
  setPropiedadJuego,
  eliminarPregunta,
  reestablecerPregunta,
  setPropiedadPreguntaCrucigrama,
  reestablecerOpcion,
  eliminarOpcion,
  postPreguntas,
  duplicarPregunta,
  confirm,
  getJuegos,
  reordenarPreguntas,
  searchImages
})(InformacionGeneral);
