import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Image } from "react-bootstrap";
import Div100vh from 'react-div-100vh'
import OpcionButton from "../../../components/OpcionButton";
import rayas from "../../../assets/Rayas.svg";
import rectangulo from "../../../assets/Rectangulo.svg";
import asterisco from "../../../assets/Asterisco.svg";
import moment from "moment";

class SeleccionarOpcion extends Component {
  componentDidMount() {
    this.time = moment();
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
    if (this.time !== null) {
      ReactGA.event({
        action: "No clicks Opciones Trivia",
        category: "No clicks",
        label: "No clicks Opciones Trivia",
      });
    }
  }

  responder(idOpcion) {
    this.time = null;
    this.props.responder(idOpcion);
  }

  renderOpciones() {
    if (this.props.opciones)
      return this.props.opciones.map((opcion, index) => (
        <OpcionButton
          key={opcion.idOpcion}
          index={index}
          idOpcion={opcion.idOpcion}
          responder={() => this.responder(opcion.idOpcion)}
          hideTexto
        />
      ));
  }

  render() {
    let icon;
    if (this.props.juego.tipo.toLowerCase() === "crucigrama") icon = rectangulo;
    else if (this.props.juego.tipo.toLowerCase() === "ahorcado")
      icon = asterisco;
    else icon = rayas;

    return (
      <Container fluid>
        <Div100vh className='trivia-player-screen py-3'>
          <Row>
            <Col xs={12} className='text-center'>
              <h1>
                <Image src={icon} style={{ width: 30 }} className="pb-2 mr-2" />
                {this.props.juego.titulo !== null && this.props.juego.titulo}
              </h1>
              <h2>
                Pregunta {this.props.numPregunta} / {this.props.total}
              </h2>
            </Col>
          </Row>
          <Row className='no-gutters'>
            {this.renderOpciones()}
          </Row>
        </Div100vh>
      </Container>
    );
  }
}

export default SeleccionarOpcion;
