import api from "./api";

const route = "/escuelas";

export default {
  getEscuelas: (args) =>
    api.get(
      `${route}?${Object.keys(args)
        .map((key) =>
          args[key] && args[key] !== "" ? `${key}=${args[key]}` : null
        )
        .filter((element) => element !== null)
        .join("&")}`
    ),
  getEstados: () => api.get(`${route}/estados`),
  getMunicipios: (estado) => api.get(`${route}/municipios/${estado}`),
};
