import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  Button,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import FraseForm from "../components/patrocinador/FraseForm";
import SearchBox from "../components/SearchBox";
import LogoModal from "../components/LogoModal";
import PremioForm from "../components/PremioForm";
import CodigosModal from "../components/CodigosModal";
import CardPatrocinador from "../components/CardPatrocinador";
import FrasesPatrocinador from "../components/FrasesPatrocinador";
import {
  Billete,
  Boton,
  Rueda,
  Graduado,
  Comentario,
  Trofeo,
  Basurero,
} from "../assets/icons";
import {
  getPatrocinador,
  showOpcionesPatrocinador,
  setPropiedadPatrocinador,
  showLogoPatrocinador,
  deletePatrocinador,
  agregarLogo,
  setLogo,
  deleteLogo,
  editLogo,
  saveLogo,
  setPropiedadLogo,
  putPatrocinador,
  clearEditado,
  getSegmentosDisponibles,
} from "../actions/patrocinadoresActions";
import {
  editPremio,
  createPremio,
  postPremio,
  setPropiedadPremio,
  togglePremio,
  eliminarPremio,
} from "../actions/premiosActions";
import {
  createFrase,
  setPropiedadFrase,
  saveFrase,
  editFrase,
  deleteFrase,
} from "../actions/frasesActions";
import {
  editRow,
  saveRow,
  setPropertyRow,
  createRow,
} from "../actions/tableActions";
import {
  createCodigo,
  editarCodigos,
  getCodigos,
  clearCodigos,
  deleteCodigo,
  postCodigos,
  setPropiedadCodigo,
  setExcel,
  setPropiedadExcel,
  cambiarTipo,
} from "../actions/codigosActions";
import {
  isAdmin
} from '../actions/adminActions'
import { confirm, alert, modalComponent } from "../actions/modalActions";
import { connect } from "react-redux";
import { BASE_URL } from "../utils";
import placeholderImage from "../assets/jugar-sin-imagen.png";
import InputULU from "../components/global/InputULU";
import SwitchULU from "../components/global/SwitchULU";
import SegmentosPatrocinador from "../components/patrocinador/SegmentosPatrocinador";
import DeletePatrocinador from "../components/patrocinador/DeletePatrocinador";
import LogosPatrocinador from "../components/patrocinador/LogosPatrocinador";
import moment from "moment";

class ConfiguracionPatrocinador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      opcionesSidebar: [
        {
          title: "Vista general",
          filterSidebar: "todos",
          icon: (props) => <Billete color="#000000" {...props} />,
        },
        {
          title: "Estado",
          filterSidebar: "estado",
          icon: (props) => <Boton color="#000000" {...props} />,
        },
        {
          title: "General",
          filterSidebar: "general",
          icon: (props) => <Rueda color="#000000" {...props} />,
        },
        {
          title: "Segmentos",
          filterSidebar: "segmentos",
          icon: (props) => <Graduado color="#000000" {...props} />,
        },
        {
          title: "Frases",
          filterSidebar: "frases",
          icon: (props) => <Comentario color="#000000" {...props} />,
        },
        {
          title: "Premios",
          filterSidebar: "premios",
          icon: (props) => <Trofeo color="#000000" {...props} />,
        },
        {
          title: "Borrar",
          filterSidebar: "borrar",
          icon: (props) => <Basurero color="#000000" {...props} />,
        },
      ],
      tiposCodigo: ["Código de barras", "Texto", "QR"],
      tiposCodigoBarras: [
        "CODE128",
        "EAN",
        "CODE39",
        "ITF14",
        "MSI",
        "pharmacode",
        "codabar",
      ],
      codificacionEAN: ["EAN13", "UPC", "EAN8", "EAN5", "EAN2"],
      codificacionMSI: ["MSI", "MSI10", "MSI11", "MSI1010", "MSI1110"],
      component: "todos",
    };
    this.deleteLogo = this.deleteLogo.bind(this);
    this.deleteFrase = this.deleteFrase.bind(this);
    this.searchPremios = this.searchPremios.bind(this);
    this.editarCodigos = this.editarCodigos.bind(this);
  }

  componentDidMount() {
    this.props.isAdmin();
    this.props.getPatrocinador(this.props.idPatrocinador);
    this.props.getSegmentosDisponibles(this.props.idPatrocinador);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.clearEditado();
  }

  componentDidUpdate() {
    if (this.props.editado !== null || this.props.error) {
      if (this.props.editado !== null) {
        const title =
          this.props.editado.idPremio === "nuevo" ? "Agregar" : "Editar";
        this.props.modalComponent(
          `${title} Premio`,
          <PremioForm
            premio={this.props.editado}
            modifier={this.props.setPropiedadPremio}
            base_url={`${BASE_URL}/adjuntos/`}
            alert={this.props.alert}
          />,
          () => this.props.editPremio(null),
          () =>
            this.props.postPremio(
              this.props.idPatrocinador,
              this.props.editado
            ),
          () =>
            this.props.editado.descripcion !== "" &&
            this.props.editado.fechaHoraVencimiento !== "" &&
            this.props.editado.fechaHoraVencimiento !== null &&
            this.props.editado.nombre !== "",
          "Los campos nombre, descripción y fecha de vencimiento son obligatorios."
        );
      }
    }
    if (this.props.editadoFrase !== null || this.props.errorFrase) {
      if (this.props.editadoFrase !== null) {
        this.props.modalComponent(
          "Agregar frase",
          <FraseForm
            frase={this.props.editadoFrase}
            modifier={this.props.setPropiedadFrase}
            alert={this.props.alert}
          />,
          () => this.props.editFrase(null),
          () => this.props.saveFrase(this.props.editadoFrase),

          () => this.props.editadoFrase.texto !== "",
          "La frase no puede estar vacía."
        );
      }
    }
    if (
      (this.props.editadoCodigos || this.props.errorCodigos) &&
      this.props.premio !== null
    ) {
      if (this.props.editadoCodigos !== null) {
        this.props.modalComponent(
          <>
            <p className="h4 m-0">
              Códigos de
              {this.props.premio ? " " + this.props.premio.nombre : "l premio"}
            </p>
            <p className="h6">
              *Los códigos que ya hayan sido otorgados no podrán ser modificados
            </p>
          </>,
          <CodigosModal
            idPremio={this.props.premio.idPremio}
            premio={this.props.premio}
            codigo={this.props.codigo}
            nuevo={this.props.nuevoCodigo}
            codigos={this.props.codigos}
            tipos={this.state.tiposCodigo}
            saveRow={this.props.saveRow}
            agregarCodigo={(codigo) => {
              if (codigo.cantidad > -1) this.props.saveRow(codigo, "CODIGO");
              else
                this.props.alert(
                  "No puedes agregar códigos con una cantidad negativa de usos"
                );
            }}
            getCodigos={this.props.getCodigos}
            tiposBarras={this.state.tiposCodigoBarras}
            codificacionEAN={this.state.codificacionEAN}
            codificacionMSI={this.state.codificacionMSI}
            modifier={this.props.setPropertyRow}
            setPropiedadCodigo={this.props.setPropiedadCodigo}
            setPropiedadExcel={this.props.setPropiedadExcel}
            deleteRow={this.props.deleteCodigo}
            saveCodigo={this.props.saveCodigo}
            confirm={this.props.confirm}
            editRow={this.props.editRow}
            file={this.props.file}
            setExcel={this.props.setExcel}
            postCodigos={this.props.postCodigos}
            excel={this.props.excel}
            alert={this.props.alert}
            cambiarTipo={this.props.cambiarTipo}
            tipo={this.props.tipo}
          />,
          this.props.clearCodigos,
          () =>
            this.props.postCodigos(
              this.props.premio.idPremio,
              this.props.codigos,
              this.props.file,
              this.props.excel,
              this.props.clearCodigos
            ),
          () => {
            if (this.props.file) {
              if (
                !this.props.excel.tipoCodigo ||
                this.props.excel.tipoCodigo === ""
              )
                return false;
              else if (this.props.excel.tipoCodigo.includes("arras")) {
                if (
                  !this.props.excel.tipoCodigoBarras ||
                  this.props.excel.tipoCodigoBarras === ""
                )
                  return false;
                else if (
                  this.props.excel.tipoCodigoBarras.includes("EAN") ||
                  this.props.excel.tipoCodigoBarras.includes("MSI")
                ) {
                  if (
                    !this.props.excel.codificacion ||
                    this.props.excel.codificacion === ""
                  )
                    return false;
                  else return true;
                } else return true;
              } else return true;
            }
            if (this.props.codigos.length === 0) {
              return false;
            }
            return true;
          },
          "Debes ingresar al menos un código o un archivo de Excel con el tipo de código"
        );
      }
    }
  }

  editarCodigos(idPremio) {
    const premio = this.props.premios.find(
      (premio) => parseInt(premio.idPremio) === parseInt(idPremio)
    );
    this.props.editarCodigos(premio);
  }

  deleteFrase(frase) {
    this.props.confirm(
      `¿Estás seguro que deseas eliminar la frase ${frase.frase}?`,
      () => this.props.deleteFrase(frase.idFrase)
    );
  }

  deleteLogo(logo) {
    this.props.confirm(
      `¿Estás seguro que deseas eliminar el logo ${logo.nombre}?`,
      () => this.props.deleteLogo(logo.idAdjunto)
    );
  }

  renderInputFecha() {
    if (this.props.patrocinador !== null) {
      let { fechaVencimiento } = this.props.patrocinador;
      fechaVencimiento = moment(fechaVencimiento);
      fechaVencimiento = fechaVencimiento.format("YYYY-MM-DDTHH:mm");
      return (
        <Col xs={12} md={6} className="px-0">
          <p className="m-0 pl-sm-2 h5">Fecha</p>
          <InputULU
            divClassName="pl-sm-2"
            value={fechaVencimiento}
            dateTime={fechaVencimiento}
            disabled={!this.props.patrocinador.desactivar}
            onChange={(event) =>
              this.props.setPropiedadPatrocinador(
                "fechaVencimiento",
                event.target.value
              )
            }
            type="datetime-local"
          />
        </Col>
      );
    }
  }

  renderEstado() {
    if (
      this.props.patrocinador !== null &&
      (this.state.component === "todos" || this.state.component === "estado")
    ) {
      return (
        <>
          <p className="pl-sm-2 h3">
            <i className="fas fa-power-off"></i>
            <span className="pl-3">Estado</span>
          </p>
          <SwitchULU
            formClassName="pl-sm-2"
            checkClassName="custom-control-lg pb-3"
            value={this.props.patrocinador.activo}
            label="¿Activo?"
            args="activo"
            modifier={this.props.setPropiedadPatrocinador}
          />
          <SwitchULU
            formClassName="pl-sm-2"
            checkClassName="custom-control-lg pb-3"
            label="Desactivar automáticamente"
            args="desactivar"
            value={
              this.props.patrocinador.desactivar !== undefined
                ? this.props.patrocinador.desactivar
                : this.props.patrocinador.fechaVencimiento === null
            }
            modifier={(key, value) => {
              this.props.setPropiedadPatrocinador(key, value);
              if (!value)
                this.props.setPropiedadPatrocinador("fechaVencimiento", null);
            }}
          />
          {this.renderInputFecha()}
          <hr className="my-5" />
        </>
      );
    }
  }

  renderGeneral() {
    if (this.props.patrocinador === null)
      return <Spinner animation="border" variant="dark" />;
    const logoProps = this.props.logo !== null ? this.props.logo : {};
    if (this.state.component === "todos" || this.state.component === "general")
      return (
        <>
          <LogoModal
            show={this.props.logo !== null}
            handleClose={() => this.props.showLogoPatrocinador(null)}
            base_url={BASE_URL}
            {...logoProps}
          />
          <Row>
            <Col>
              <p className="pl-sm-2 h3">
                <i className="fas fa-cog"></i>
                <span className="pl-3">General</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="px-0">
              <InputULU
                divClassName="pl-3 pl-sm-4"
                label="Nombre"
                maxLength={100}
                value={this.props.patrocinador.nombre}
                type="text"
                onChange={(event) =>
                  this.props.setPropiedadPatrocinador(
                    "nombre",
                    event.target.value
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="px-0">
              <InputULU
                divClassName="pl-3 pl-sm-4 pt-3"
                label="Correo"
                maxLength={100}
                type="email"
                value={this.props.patrocinador.correo ? this.props.patrocinador.correo : ""}
                onChange={(event) =>
                  this.props.setPropiedadPatrocinador(
                    "correo",
                    event.target.value
                  )
                }
              />
            </Col>
          </Row>
          <LogosPatrocinador
            logos={this.props.logos}
            file={this.props.fileLogo}
            deleteLogo={this.deleteLogo}
            setLogo={this.props.setLogo}
            logo={this.props.editadoLogo}
            saveLogo={this.props.saveLogo}
            editLogo={this.props.editLogo}
            agregarLogo={this.props.agregarLogo}
            patrocinador={this.props.patrocinador}
            setPropiedadLogo={this.props.setPropiedadLogo}
            showLogoPatrocinador={this.props.showLogoPatrocinador}
            setPropiedadPatrocinador={this.props.setPropiedadPatrocinador}
          />
          <hr className="my-5" />
        </>
      );
  }

  renderInputsSegmentos() {
    if (this.props.patrocinador !== null && this.props.disponibles !== null)
      return (
        <SegmentosPatrocinador
          patrocinador={this.props.patrocinador}
          modifier={this.props.setPropiedadPatrocinador}
          disponibles={this.props.disponibles}
        />
      );
  }

  renderSegmentos() {
    if (
      this.state.component === "todos" ||
      this.state.component === "segmentos"
    )
      return (
        <>
          <Row>
            <Col>
              <p className="pl-sm-2 h3">
                <i className="fas fa-graduation-cap"></i>
                <span className="pl-2">Segmentos</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={11} sm={10} lg={9}>
              <h5>Nivel educativo</h5>
              {this.renderInputsSegmentos()}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h5>Regiones</h5>
              <h6>Estados</h6>
              {this.props.patrocinador && this.props.patrocinador.regiones && this.props.patrocinador.regiones.estados && this.props.patrocinador.regiones.estados.length > 0 ?
                <ul>
                  {this.props.patrocinador.regiones.estados.map(({nombre}) => {
                    return (
                      <li key={nombre}>{nombre}</li>
                    );
                  })}
                </ul>
                :
                <p>No se están patrocinando estados completos.</p>
              }
              <h6>Municipios</h6>
              {this.props.patrocinador && this.props.patrocinador.regiones && this.props.patrocinador.regiones.ciudades && this.props.patrocinador.regiones.ciudades.length > 0 ?
                <ul>
                  {this.props.patrocinador.regiones.ciudades.map(({nombre}) => {
                    return (
                      <li key={nombre}>{nombre}</li>
                    );
                  })}
                </ul>
                :
                <p>No se están patrocinando ciudades.</p>
              }
            </Col>
          </Row>
          <hr className="my-5" />
        </>
      );
  }

  renderFrases() {
    if (this.state.component === "todos" || this.state.component === "frases")
      return (
        <>
          <FrasesPatrocinador
            idPatrocinador={this.props.idPatrocinador}
            frases={this.props.frases}
            createFrase={this.props.createFrase}
            frase={this.props.frase}
            alertar={this.props.alert}
            editFrase={this.props.editFrase}
            saveFrase={this.props.saveFrase}
            deleteFrase={this.deleteFrase}
            setPropiedadFrase={this.props.setPropiedadFrase}
            iconsEstado
          />
          <hr className="my-5" />
        </>
      );
  }

  renderPremios() {
    if (this.state.component === "todos" || this.state.component === "premios")
      return (
        <>
          <Row>
            <Col>
              <p className="pl-sm-2 h3">
                <i className="fas fa-trophy"></i>
                <span className="pl-3">Premios</span>
              </p>
            </Col>
          </Row>
          <Row className="my-3 justify-content-between">
            <Col xs={12} sm={6} md={6} lg={4}>
              <SearchBox
                searchModifier={this.searchPremios}
                placeholder="  &#61442;   Buscar"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={11}>
              <div className="pl-sm-2">{this.renderAccordionPremios()}</div>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={6} sm={5} md={4} lg={3} xl={2}>
              <div className="pl-sm-2">
                <Button
                  variant="outline-danger"
                  className="border-red-new py-2 mx-auto my-0"
                  onClick={this.props.createPremio}
                  block
                >
                  Agregar premio
                </Button>
              </div>
            </Col>
          </Row>
          <hr className="my-5" />
        </>
      );
  }

  searchPremios(query) {
    if (this.props.patrocinador !== null) {
      this.setState({ query });
    }
  }

  renderAccordionPremios() {
    return (
      <Accordion onSelect={(activeKey) => this.setState({ activeKey })}>
        <Card className="mb-2 accordion-premios">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <Row>
              <Col xs={10} sm={11}>
                Activos
              </Col>
              <Col xs={1}>
                <i
                  className={`fas fa-chevron-${
                    this.state.activeKey === "0" ? "up" : "down"
                  }`}
                ></i>
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row>{this.renderPremiosActivos()}</Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className="accordion-premios">
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <Row>
              <Col xs={10} sm={11}>
                Inactivos
              </Col>
              <Col xs={1}>
                <i
                  className={`fas fa-chevron-${
                    this.state.activeKey === "1" ? "up" : "down"
                  }`}
                ></i>
              </Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Row>{this.renderPremiosInactivos()}</Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  getImagen(premio) {
    let idAdjunto =
      premio.adjuntoPremio !== null
        ? premio.adjuntoPremio
        : premio.adjuntoPatrocinador;
    if (idAdjunto === null) return placeholderImage;
    return `${BASE_URL}/adjuntos/${idAdjunto}`;
  }

  renderPremiosActivos() {
    if (this.props.premios !== null && this.props.premios !== undefined) {
      let premios = this.props.premios;
      premios = premios.filter((premio) => premio.activo);
      return this.renderCards(premios);
    }
  }

  renderCards(premios) {
    if (premios.length === 0)
      return <p className="px-3">Aún no hay premios.</p>;
    return premios.map((premio) => {
      if (
        this.state.query === "" ||
        (this.state.query !== "" &&
          premio.nombre.toLowerCase().includes(this.state.query.toLowerCase()))
      )
        return (
          <Col
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={3}
            className="pb-3"
            key={premio.idPremio}
          >
            <CardPatrocinador
              id={premio.idPremio}
              premio={premio}
              idPremio={premio.idPremio}
              nombre={premio.nombre}
              src={this.getImagen(premio)}
              handleClick={this.editarCodigos}
              premios={true}
              opciones={this.props.opciones}
              showOpcionesPatrocinador={this.props.showOpcionesPatrocinador}
              confirm={this.props.confirm}
              desactivarPremio={(idPremio) =>
                this.props.togglePremio(idPremio, premio)
              }
              activarPremio={(idPremio) =>
                this.props.togglePremio(idPremio, premio)
              }
              editPremio={this.props.editPremio}
              eliminarPremio={(idPremio) => this.props.eliminarPremio(idPremio)}
              activo={premio.activo}
            />
          </Col>
        );
      return <></>;
    });
  }

  renderPremiosInactivos() {
    if (this.props.premios !== null && this.props.premios !== undefined) {
      let premios = this.props.premios;
      premios = premios.filter((premio) => !premio.activo);
      return this.renderCards(premios);
    }
  }

  renderHeader() {
    if (this.props.patrocinador === null)
      return <Spinner animation="border" variant="dark" />;
    return (
      <p className="pl-3 pl-sm-4 pt-4 h1">
        <i className="fas fa-money-bill-wave"></i>
        <span className="pl-3">
          Configuracion de {this.props.patrocinador.nombre}
        </span>
      </p>
    );
  }

  renderBreadcrumbs() {
    return (
      <Row className="mt-n4">
        <Col>
          <Breadcrumb
            className="bg-transparent"
            bsPrefix="breadcrumb pl-0 pl-sm-2 mb-0 pb-4"
          >
            <Breadcrumb.Item
              href="/admin/patrocinadores"
              style={{ color: "#212529" }}
            >
              Patrocinadores
            </Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: "#212529" }}>
              {this.props.patrocinador ? this.props.patrocinador.nombre : ""}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    );
  }

  renderBorrar() {
    if (this.state.component === "todos" || this.state.component === "borrar")
      return (
        <>
          <Row>
            <Col>
              <p className="pl-sm-2 h3">
                <i className="far fa-trash-alt"></i>
                <span className="pl-3">Borrar</span>
              </p>
            </Col>
          </Row>
          <DeletePatrocinador
            deletePatrocinador={this.props.deletePatrocinador}
            confirm={this.props.confirm}
            patrocinador={this.props.patrocinador}
          />
          <Row className="py-5" />
        </>
      );
  }

  render() {
    if(this.props.loadingAdmin){
      return <></>
    }
    return (
      <Container fluid className="no-bg-img h-100">
        <Row>
          <Col
            sm={4}
            md={3}
            xl={2}
            className="bg-white px-0 hide-mobile"
            style={{ minHeight: "100vh" }}
          >
            <Sidebar
              usuario={this.props.usuario}
              admin={true}
              signOut={this.props.signOut}
              opciones={this.state.opcionesSidebar}
              handleFilter={(filter) => this.setState({ component: filter })}
            />
          </Col>
          <Col>
            <Row>{this.renderHeader()}</Row>
            {this.renderBreadcrumbs()}
            {this.renderEstado()}

            {this.renderGeneral()}

            {this.renderSegmentos()}

            {this.renderFrases()}

            {this.renderPremios()}

            {this.renderBorrar()}
          </Col>
        </Row>
        <div className='fixed-bottom-right'>
          {/*<Button
            variant="link"
            className="link-gray px-4 py-4"
            onClick={async () => {
              await navigate("/admin/patrocinadores");
              window.scrollTo(0, 0);
            }}
            size='lg'
          >
            <h6 className='mb-0'>Salir</h6>
          </Button>*/}
          <Button
            variant="danger"
            className="new-red-button px-4 py-4"
            size='lg'
            onClick={() =>
              this.props.putPatrocinador(
                this.props.patrocinador,
                this.props.frases,
                this.props.logos,
                this.props.disponibles,
                this.props.setPropiedadPatrocinador
              )
            }
          >
            <h6 className='mb-0'>Guardar cambios</h6>
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  opciones: state.patrocinadores.opciones,
  editado: state.premios.editado,
  editadoCodigos: state.codigos.editadoCodigos,
  codigos: state.codigos.codigos,
  codigo: state.codigos.codigo,
  nuevoCodigo: state.codigos.nuevo,
  premios: state.premios.premios,
  premio: state.premios.premio,
  patrocinador: state.patrocinadores.patrocinador,
  error: state.premios.error,
  editadoFrase: state.frases.editadoFrase,
  errorFrase: state.frases.errorFrase,
  frases: state.frases.frases,
  frase: state.frases.frase,
  logo: state.patrocinadores.logo,
  editadoLogo: state.patrocinadores.editadoLogo,
  logos: state.patrocinadores.logos,
  disponibles: state.patrocinadores.disponibles,
  errorCodigos: state.codigos.errorCodigos,
  file: state.codigos.file,
  excel: state.codigos.excel,
  fileLogo: state.patrocinadores.file,
  tipo: state.codigos.tipo,
  usuario: state.user.user,
  loadingAdmin: state.admin.loadingAdmin
});

export default connect(mapStateToProps, {
  alert,
  setLogo,
  editLogo,
  saveLogo,
  editFrase,
  saveFrase,
  postPremio,
  editPremio,
  deleteLogo,
  agregarLogo,
  deleteFrase,
  createFrase,
  clearEditado,
  editRow,
  confirm,
  saveRow,
  setExcel,
  createRow,
  getCodigos,
  postCodigos,
  cambiarTipo,
  deleteCodigo,
  clearCodigos,
  createCodigo,
  togglePremio,
  createPremio,
  editarCodigos,
  modalComponent,
  eliminarPremio,
  setPropertyRow,
  getPatrocinador,
  putPatrocinador,
  setPropiedadLogo,
  setPropiedadFrase,
  deletePatrocinador,
  setPropiedadPremio,
  showLogoPatrocinador,
  getSegmentosDisponibles,
  setPropiedadPatrocinador,
  setPropiedadCodigo,
  setPropiedadExcel,
  showOpcionesPatrocinador,
  isAdmin
})(ConfiguracionPatrocinador);
