import {
  LETRA_RESPONDIDA,
  CLEAR_CONTESTADAS,
  AGREGAR_RESPUESTA_AHORCADO,
  CLEAR_JUEGO,
  JUGADOR_AHORCADO,
  JUGADOR_FINISHED,
} from "../actions/types";

const INITIAL_STATE = {
  contestadas: new Set(),
  respuestas: [],
  ahorcados: [],
  finished: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LETRA_RESPONDIDA:
      let letra = payload;
      letra = letra.toLowerCase();
      const { contestadas } = state;
      contestadas.add(letra);
      return { ...state, contestadas };
    case CLEAR_CONTESTADAS:
      return {
        ...state,
        contestadas: new Set(),
        respuestas: [],
        ahorcados: [],
        finished: [],
      };
    case AGREGAR_RESPUESTA_AHORCADO:
      let { respuestas } = state;
      respuestas.push(payload);
      return { ...state, respuestas };
    case CLEAR_JUEGO:
      return { ...INITIAL_STATE };
    case JUGADOR_AHORCADO:
      let { ahorcados } = state;
      if (!Array.isArray(ahorcados)) ahorcados = [];
      ahorcados.push(payload);
      return { ...state, ahorcados };
    case JUGADOR_FINISHED:
      let { finished } = state;
      if (!Array.isArray(finished)) finished = [];
      finished.push(payload);
      return { ...state, finished };
    default:
      return { ...state };
  }
};
