import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { navigate } from "@reach/router";

export default function ({
  preguntas,
  pregunta,
  tipo,
  idJuego,
  createNuevaPregunta,
  duplicarPregunta,
  eliminarPregunta,
  reestablecerPregunta,
}) {
  const [show, setShow] = useState(false);

  function renderPreguntas() {
    if (show && preguntas !== null) {
      let components = [];
      components.push(
        <Row
          key="info-general"
          className="mx-0 py-2 align-items-center"
          onClick={() => {navigate(`/editar/${idJuego}`); setShow(!show);}}
        >
          <Container fluid className="px-0">
            <span className="h5">Información General</span>
          </Container>
        </Row>
      );
      preguntas = preguntas.sort((a, b) => (a.orden > b.orden ? 1 : -1));
      components.push(
        ...preguntas.map((pregunta) => {
          if (String(pregunta.idPregunta)[0] === "d") {
            return (
              <Row
                key={pregunta.idPregunta}
                className="mx-0 py-2 align-items-center"
              >
                <Col xs={8} className="px-0">
                  <span className="h5">
                    Pregunta #{pregunta.orden + 1} eliminada
                  </span>
                </Col>
                <Col xs={4} className="text-right">
                  <Button
                    variant="link"
                    className="text-danger px-0"
                    onClick={() => reestablecerPregunta(pregunta.idPregunta)}
                  >
                    <i className="fa fa-undo px-0"></i>
                  </Button>
                </Col>
              </Row>
            );
          }
          return (
            <Row
              key={pregunta.idPregunta}
              className="mx-0 py-2 align-items-center"
            >
              <Col xs={8} className="px-0">
                <span
                  className="h5"
                  onClick={() => {
                      navigate(
                        `/editar/${idJuego}/pregunta/${pregunta.idPregunta}`
                      );
                      setShow(!show);
                    }
                  }
                >
                  Pregunta #{pregunta.orden + 1}
                </span>
              </Col>
              <Col xs={4} className="text-right">
                <Button
                  variant="link"
                  className="text-dark px-0"
                  onClick={() => duplicarPregunta(pregunta)}
                >
                  <i className="far fa-clone pr-2"></i>
                </Button>
                <Button
                  variant="link"
                  className="text-danger px-0"
                  onClick={() => eliminarPregunta(pregunta.idPregunta)}
                >
                  <i className="far fa-trash-alt px-0"></i>
                </Button>
              </Col>
            </Row>
          );
        })
      );
      return components;
    }
  }

  function renderTab() {
    if (pregunta && pregunta !== null) {
      return `Pregunta #${pregunta.orden + 1}`;
    }
    return "Información General";
  }

  function handleClick() {
    createNuevaPregunta(preguntas.length + 1);
    navigate(
      idJuego
        ? `/editar/${idJuego}/pregunta/nueva-${preguntas.length + 1}`
        : `/crear/pregunta/nueva-${preguntas.length + 1}`
    );
  }

  function renderButton() {
    return (
      <Row className="align-self-end justify-content-center text-center px-0">
        <Container fluid style={{ minWidth: "100%" }}>
          <Button
            variant="outline-danger"
            className="border-red-new px-3 py-3 mx-1 my-0"
            onClick={() => {handleClick(); setShow(!show);}}
            block
            style={{ color: "#FF3833" }}
          >
            <span className="py-0 px-0 my-0 mx-0">
              <h5 className="py-2 my-0">
                <i className="fas fa-plus"></i> Agregar Pregunta
              </h5>
            </span>
          </Button>
        </Container>
      </Row>
    );
  }

  return (
    <Container fluid className="px-2 mt-3">
      <Row className="mx-0 align-items-center">
        <Col xs={10} className="px-0">
          {!show && <span className="h5">{renderTab()}</span>}
        </Col>
        <Col xs={2}>
          <Button
            variant="link"
            className="text-dark py-0"
            onClick={() => setShow(!show)}
          >
            <i className={`fa fa-chevron-${show ? "up" : "down"}`}></i>
          </Button>
        </Col>
        <Container className="px-0" fluid>
          {renderPreguntas()}
        </Container>
      </Row>
      {show && tipo !== "crucigrama" ? renderButton() : ""}
    </Container>
  );
}
