import React, { Component } from 'react';
import Rating from './Rating';
//import Comments from './Comments';
import Recommend from './Recommend';
import ThankyouModal from './ThankyouModal';
import { Row, Col } from 'react-bootstrap';
import { BASE_URL } from '../../utils';

class ReviewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return(
            <>
                <Row className='justify-content-center'>
                    <Col xs={12} md={6} className='text-center'>
                        <h5 className='non-bold'>Felicidades</h5>
                    </Col>
                </Row>
                <Row className='justify-content-center mb-3'>
                    <Col xs={12} md={4} className='text-center winner-gold'>
                        <h1 className='winner-nickname'>{this.props.apodo}</h1>
                        <hr />
                        <h5 className='non-bold'>{this.props.porcentajeCorrectas}% correcto</h5>
                    </Col>
                </Row>
                {this.props.patrocinador &&
                    <Row className='justify-content-center my-3'>
                        <Col xs='auto' className='text-center'>
                            <p className='text-small'>ULU impulsado por</p>
                            <img alt='logo impulsor' src={`${BASE_URL}/adjuntos/${this.props.patrocinador.logoPrincipal}`} className='logo-width-host-pin my-2' />
                            <p className='text-small'>{this.props.patrocinador.nombre}</p>
                        </Col>
                    </Row>
                }
                <Rating setSugerenciaProperty={this.props.setSugerenciaProperty}/>
                {/*<Recommend setSugerenciaProperty={this.props.setSugerenciaProperty}/>/*}
                {/*<Comments setSugerenciaProperty={this.props.setSugerenciaProperty}/>*/}
                <ThankyouModal
                    show={this.props.showThankYouModal}
                    user={this.props.user}
                    onHide={() => this.props.setSugerenciaProperty({key: 'showThankYouModal', value: false })}
                />
            </>
        );
    }
}

export default ReviewForm;