import React, { Component } from 'react';
import QuestionList from './QuestionList';
import {Container, Row, Col} from 'react-bootstrap';
import { getColor, getIcon, getColorName } from '../../utils';
import { connect } from 'react-redux';
import { selectQuestionCrucigrama } from '../../actions/jugarSoloActions';

class FooterMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showQuestionList: false,
        }

        this.toggleQuestionList = this.toggleQuestionList.bind(this);
    }

    renderIcon(idPregunta) {
        let color = getColor(idPregunta % 5);
        let icon = getIcon(idPregunta % 18, {color, newclass: `mb-0 ml-1 mr-2`, width: '12px', height: '12px'});
        return icon;
    }

    toggleQuestionList() {
        this.setState({showQuestionList: !this.state.showQuestionList})
    }

    render() {
        return(
            <>
                {this.state.showQuestionList && 
                    <div className={'question-list-absolute container'}>
                        <QuestionList 
                            juego={this.props.juego}
                            patrocinador={this.props.patrocinador}
                            selectQuestion={this.props.selectQuestion}
                            selectedQuestion={this.props.selectedQuestion}
                            isMobile = {this.props.isMobile}
                            component = {this.props.component}
                            allFinished = {false}
                            toggleQuestionList={this.toggleQuestionList}
                            terminarJuego={this.props.terminarJuego}
                        />
                    </div>
                }
                <div className='bg-white p-3'>
                        <Row className='justify-content-between'>
                            <Col xs='auto' className='vertical-center'>
                                <p onClick={this.toggleQuestionList}>
                                    <b>
                                        {/*Array.from(this.props.preguntas).map(([key, pregunta]) => (
                                            pregunta.idPregunta === this.props.selectedQuestion &&
                                                pregunta.orientacion
                                        ))*/}
                                        Ver preguntas
                                    </b>
                                    {this.renderIcon(this.props.selectedQuestion)}
                                    <i class="fas fa-sync-alt fa-xs"></i>
                                </p>
                            </Col>
                            <Col xs={4} className='text-center footer-mobile-trophy mx-auto'>
                                <p className='text-small'><i className={`fas ${this.props.juego.streak ? 'fa-fire-alt' : 'fa-trophy'}`} /> {this.props.juego.puntos} pts</p>
                            </Col>
                            <Col xs='auto' className='vertical-center text-right'>
                                <p className='text-small'><i className="far fa-clock" /> {this.props.component === 'lobbyquestion' ? this.props.tiempoLobby : this.props.tiempoPregunta} s</p>
                            </Col>
                        </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    preguntas: state.crucigramaSolo.preguntas,
    selectedQuestion: state.crucigramaSolo.selectedQuestion
});

export default connect(mapStateToProps, { selectQuestionCrucigrama })(FooterMobile);