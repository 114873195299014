import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import IconsLoading from '../../components/IconsLoading';
import { BASE_URL } from '../../utils';

class LobbyQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return(
            <>
                {
                this.props.juego.idAdjunto && this.props.juego.idAdjunto !== null &&
                    <Row className='justify-content-center mb-2'>
                        <Col xs={4} className='text-center'>
                            {this.props.juego.idAdjunto !== 'icons-loading' ? 
                            <img loading='lazy' alt='pregunta lobby' src={`${BASE_URL}/adjuntos/${this.props.juego.idAdjunto}`} className='lobby-question-img' />
                            :
                            <IconsLoading />
                            }
                        </Col>
                    </Row>
                }
                <Row className='justify-content-center'>
                    <Col xs={12} md={7} className='text-center'>
                        <h2 className='lobby-question-text'>{this.props.juego.pregunta}</h2>
                    </Col>
                </Row>
            </>
        );
    }
}

export default LobbyQuestion;