import React from "react";
import { Row, Container, Col, Button } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";

export default (props) => {
  const { pregunta, handleChange, eliminarPregunta, index } = props;
  const { idPregunta, texto, respuesta} = pregunta;  
  return (
    <Row key={idPregunta} className="pl-4 pr-5 py-3">
      <Container className="px-0 py-0 mx-0 my-0">
        <h4>Pregunta {index + 1}</h4>
        <Row className="px-0 py-2 mx-0 my-0">
          <div className="w-100">
            <TextField
              outlined
              className="w-100"
              label="Pista o pregunta"
              characterCounter={<CharacterCounter />}
              maxLength={70}
              textarea
            >
              <Input
                value={texto}
                maxLength={70}
                onChange={(event) => handleChange(event, idPregunta, "texto")}
              />
            </TextField>
          </div>
        </Row>
        <Row className="px-0 py-2 my-0">
          <Col xs={6}>
            <div className="w-100">
            <TextField
              outlined
              label="Palabra"
              className="w-100 mw-100"
              characterCounter={<CharacterCounter />}
              maxLength={9}
            >
              <Input
                value={respuesta}
                maxLength={9}
                onChange={(event) =>
                  handleChange(event, idPregunta, "respuesta")
                }
              />
            </TextField>
            </div>
          </Col>
          <Col xs={6} className="text-right">
            <Button variant="link" className="text-danger" onClick={() => eliminarPregunta(pregunta)}>
                <i className="fa fa-trash-alt"></i>
            </Button>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
