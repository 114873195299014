import { navigate } from "@reach/router";
import React, { Component } from "react";
import { BASE_URL } from "../utils";
class SponsorBubble extends Component {
    render() {
        return (
            <li className='sponsor-bubble' onClick={() => navigate(`/user/${this.props.sponsor.idPerfil}`)}>
                {console.log('push')}
                <img src={`${BASE_URL}/adjuntos/${this.props.sponsor.img}`} alt={this.props.sponsor.alt} className='sponsor-bubble-img' /> 
                <p className='sponsor-bubble-title text-smaller'>{this.props.sponsor.name}</p>
            </li>
        );
    }
}

export default SponsorBubble;