import React, { Component } from 'react';
import {
    Asterisco,
    Circulo,
    Estrella,
    Hexagono,
    Pin,
    Rayas,
    Rectangulo,
} from "../assets/icons.js";

class IconsLoading extends Component {
    render() {
        return(
            <div style={{textAlign: 'center', marginTop: 40, marginBottom: 40}}>
                <Asterisco color='var(--hangman)' className='icons-loading hangman-icon-loading'/>
                <Circulo color='#FF3833' className='icons-loading circle-icon-loading'/>
                <Estrella color='#FFD833' className='icons-loading star-icon-loading'/>
                <Hexagono color='#FF9B27' className='icons-loading hexagon-icon-loading'/>
                <Pin color='#FF3833' className='icons-loading pin-icon-loading'/>
                <Rayas color='#7E2CFF' className='icons-loading trivia-icon-loading'/>
                <Rectangulo color='#26DD8E' className='icons-loading crossword-icon-loading'/>
            </div>
        );
    }
}

export default IconsLoading;