import React, { Component } from "react";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import logo from "../../../assets/logo-cara.png";
import asterisco from "../../../assets/Asterisco.svg";
import LetraAhorcado from "../../../components/ahorcado/LetraAhorcado";
import cara1 from "../../../assets/1.svg";
import cara2 from "../../../assets/2.svg";
import cara3 from "../../../assets/3.svg";
import cara4 from "../../../assets/4.svg";
import { navigate } from "@reach/router";

class PreguntaAhorcado extends Component {
  constructor(props) {
    super(props);
    let size = window.innerHeight; // size es height
    let width = window.innerWidth;
    if (width < 992 || size > width) size /= 7;
    else size /= 3;
    this.state = {
      ahorcado: null,
      value: "",
      contestadas: 0,
      idPregunta: 0,
      ended: false,
      size,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    this.setState({ ahorcado: this.renderAhorcado() });
    this.handleBlur();
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contestadas.size > 0 && this.props.contestadas.size === 0) {
      this.setState({ ahorcado: this.renderAhorcado() });
    }
    if (this.state.contestadas < this.props.contestadas.size) {
      this.setState({
        contestadas: this.state.contestadas + 1,
        ahorcado: this.renderAhorcado(),
      });
    }
    if (this.state.idPregunta !== this.props.pregunta.idPregunta) {
      this.props.clearContestadas();
      this.setState({
        idPregunta: this.props.pregunta.idPregunta,
        contestadas: 0,
      });
    }
    if (!prevState.ended && this.state.ended) {
      this.props.jugadorAhorcado(this.props.apodo);
    }
    if (prevProps.contestadas && this.props.contestadas) {
      if (prevProps.contestadas !== this.props.contestadas) {
        this.setState({
          contestadas: this.props.contestadas.size,
          ahorcado: this.renderAhorcado(),
        });
      }
    }
  }

  handleBlur() {
    const input = document.getElementById("ahorcado");
    input.focus();
  }

  handleChange(evt) {
    let letra = evt.target.value;
    letra = letra[letra.length - 1];
    this.props.responder(letra);
    //window.scrollTo(0, document.body.scrollHeight / 2);
  }

  renderAhorcado() {
    let { respuesta } = this.props.pregunta;
    let palabras = respuesta.split(" ");
    if (palabras != null) {
      let j = 0;
      let parent = this.refs.parent;
      let maxWidth = parent.getBoundingClientRect().width;
      return palabras.map((palabra, indexPalabra) => {
        let letras = Array.from(palabra);
        let children = <span className="ml-5"></span>;
        if (indexPalabra === 0) j += letras.length * 35;
        else if (j + letras.length * 35 > maxWidth) {
          j = letras.length * 35;
          children = <br />;
        } else j += letras.length * 35;
        return letras.map((letra, index) => {
          return (
            <LetraAhorcado
              key={index}
              letra={letra}
              jugadores={this.props.jugadores}
              contestadas={this.props.contestadas}
            >
              {index === 0 && indexPalabra > 0 ? (
                children
              ) : (
                <span className="ml-0"></span>
              )}
            </LetraAhorcado>
          );
        });
      });
    }
  }
  getUniqueChars(string) {
    return string
      .toLowerCase()
      .split("")
      .filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      })
      .join("");
  }
  calcularVidas() {
    let errores = 0;
    let correctas = 0;
    if (this.props.contestadas.size > 0) {
      this.props.respuestas.forEach((respuesta) => {
        if (respuesta.puntos === 0) errores++;
        else correctas++;
      });
    }
    const palabraUniqueChars = this.getUniqueChars(
      this.props.pregunta.respuesta.split(" ").join("")
    );
    if (correctas === palabraUniqueChars.length) {
      this.props.jugadorFinished(this.props.apodo);
    }
    if (errores > 5) errores = 5;
    return 5 - errores;
  }

  renderCara() {
    const vidas = this.calcularVidas();
    switch (vidas) {
      case 5:
        return <Image src={cara1} className="cara-ahorcado" />;
      case 4:
        return <Image src={cara2} className="cara-ahorcado" />;
      case 3:
        return <Image src={cara3} className="cara-ahorcado" />;
      case 2:
        return <Image src={cara4} className="cara-ahorcado" />;
      default:
        if (!this.state.ended) this.setState({ ended: true });
        return "";
    }
  }

  renderPoints() {
    return (
      <>
        <i className="fas fa-arrow-circle-up pr-3 pb-4 text-success fa-lg"></i>
        <span>{this.props.pregunta.puntos} pts</span>
      </>
    );
  }

  render() {
    return (
      <Container style={{ position: "absolute", top: 0, left: 0 }} fluid>
        <Row>
          <Col className="d-none d-sm-block">
            <Row className="pt-5 justify-content-center pb-4">
              <Col md={4} className="pl-5">
                <Image src={logo} style={{ width: "80px" }} className="ml-3" onClick={() => navigate('/explorar')} />
              </Col>
              <Col md={4}>
                <Row className="justify-content-center align-items-center text-center">
                  <Container fluid>
                    <h3>
                      <Image src={asterisco} style={{ width: "2vw" }} />
                      <span className="ml-3 font-weight-bold">
                        {this.props.juego
                          ? this.props.juego.titulo
                          : "Ahorcado"}
                      </span>
                    </h3>
                  </Container>
                </Row>
              </Col>
              <Col md={4} />
            </Row>
          </Col>
          {/* <Col md={4} className="text-center mt-5 d-block d-sm-none">{this.renderPoints()}</Col> */}
        </Row>
        <Row className="vertical-center-mobile fondo-gris-full-mobile justify-content-center">
          <Col xs={5} md={3} className='align-self-center'>
            <div
              className="background-red-muted text-center mb-3 aspect-ratio-11"
              style={{ width: '100%', paddingTop: '100%', position: 'relative' }}
            >
              {this.renderCara()}
            </div>
          </Col>
          <Col xs={12} md={8}>
            {/* <Row className="px-4">
              <p className="h4 font-weight-light">{this.props.pregunta.hint}</p>
            </Row> */}
            <Container>
              <Form.Control
                id="ahorcado"
                value={this.state.value}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                className="input-ahorcado"
                autoFocus
                //onFocus={() => window.scrollTo(0,0)}
              />
            </Container>
            <Container className="text-center px-0" ref="parent">
              {this.state.ahorcado}
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PreguntaAhorcado;
