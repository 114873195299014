import React from "react";
import { Row, Col } from "react-bootstrap";
import { getIcon } from "../../utils";

class HintPalabra extends React.Component {
  renderIcon() {
    return getIcon(this.props.index, { width: 20, height: 20});
  }

  render() {    
    return (
      <div className='py-3'>
        <Row className='align-items-center text-left mb-1'>
          <Col xs={10}>
            <h6>{this.renderIcon(this.props.index)} {this.props.texto}</h6>
          </Col>
          <Col xs={2} className="text-right">
            <h6>{this.props.respuestas}</h6>
          </Col>
        </Row>
        {this.props.showRespuesta && (
          <Row className="align-items-center text-left">
            <Col xs={12}>
              <h6 className='non-bold' style={{textDecoration: "underline", textDecorationColor: "#26dd8e", textDecorationThickness: '5px'}}>R: {this.props.respuesta}</h6>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default HintPalabra;
