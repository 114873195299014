import React from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import placeholder from "../assets/jugar-sin-imagen.png";
import moment from "moment-timezone";

class CardPatrocinador extends React.Component {
  renderSegmentos() {
    if (this.props.segmentos && this.props.segmentos !== null) {
      return this.props.segmentos.map((segmento) => (
        <Col className="px-4" key={segmento.nombre}>
          <p className="mb-0 text-gray text-small">{segmento.nombre}</p>
          <p className="mb-0 text-gray text-small">
            {segmento.porcentaje}
            {"%"}
          </p>
        </Col>
      ));
    }
  }

  renderBodyText() {
    if (this.props.premio && this.props.premio !== null) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const currentTime = moment(this.props.premio.fechaHoraVencimiento).tz(timezone).format('DD/MM/YYYY hh:mm a');
      return (
        <ul className='text-small px-4'>
          <li className="text-gray">
            <b>Descripción:</b> {this.props.premio.descripcion}
          </li>
          <li className="text-gray">
            <b>Fecha de vencimiento:</b> {currentTime}
          </li>
          <li className="text-gray">
            <b># de códigos:</b> {this.props.premio.cantidad}
          </li>
          <li className="text-gray">
            <b>Lugares asignados:</b> {this.props.premio.cantidad}
          </li>
        </ul>
      );
    } else {
      return (
        <>
          <p className="text-gray px-2 m-0 text-small">
            Premios activos: {this.props.premiosActivos}
          </p>
          <p className="text-gray px-2 m-0 text-small">
            Premios totales: {this.props.premiosTotales}
          </p>
        </>
      );
    }
  }

  toggleActivo() {
    if (this.props.activo)
      this.props.confirm(
        `¿Deseas desactivar el premio: ${this.props.nombre}?`,
        () => this.props.desactivarPremio(this.props.idPremio)
      );
    else
      this.props.confirm(
        `¿Deseas activar el premio: ${this.props.nombre}?`,
        () => this.props.activarPremio(this.props.idPremio)
      );
  }

  renderOpciones() {
    if (this.props.premios)
      if (this.props.opciones === this.props.idPremio) {
        return (
          <div
            className="card border-0 shadow px-3 py-1"
            style={{
              position: "absolute",
              top: "170px",
              right: "5px",
              borderRadius: 10,
              zIndex: 1050,
              width: 130,
            }}
          >
            <Button
              variant="link"
              className="text-dark text-left px-0"
              onClick={() => this.props.editPremio(this.props.premio)}
            >
              <i className="fa fa-edit"></i> Editar
            </Button>
            <Button
              variant="link"
              className="text-dark text-left px-0"
              onClick={() => this.toggleActivo()}
            >
              <i className="fa fa-power-off"></i>{" "}
              {this.props.activo ? "Desactivar" : "Activar"}
            </Button>
            <hr style={{ marginTop: 5, marginBottom: 5 }} />
            <Button
              variant="link"
              className="text-danger text-left px-0"
              onClick={() =>
                this.props.confirm(
                  `¿Deseas eliminar el premio: ${this.props.nombre}?`,
                  () => this.props.eliminarPremio(this.props.idPremio)
                )
              }
            >
              <i className="fa fa-trash"></i> Eliminar
            </Button>
          </div>
        );
      }
  }

  renderKebab() {
    if (this.props.premios) {
      return (
        <Col xs={2} className="pl-4 pl-sm-2 pl-md-0 pl-lg-1 pr-0">
          <Button
            variant="link"
            className="text-dark pl-0 pr-2"
            onClick={(e) => {
              e.stopPropagation();
              this.props.showOpcionesPatrocinador(
                this.props.opciones === this.props.idPremio
                  ? undefined
                  : this.props.idPremio
              );
            }}
          >
            <i className="fas fa-ellipsis-v py-2"></i>
          </Button>
        </Col>
      );
    }
  }

  handleClick() {
    if(this.props.args) {
      if(Array.isArray(this.props.args)) return this.props.handleClick(this.props.id, ...this.props.args);
      return this.props.handleClick(this.props.id, this.props.args);
    }
    this.props.handleClick(this.props.id);
  }

  render() {
    return (
      <React.Fragment>
        <Card
          style={{
            width: "100%",
            borderRadius: 10,
            borderColor: "#a1a1a1",
            position: "relative",
          }}
        >
          <Card.Img
            variant="top"
            src={this.props.src ? this.props.src : placeholder}
            style={{ height: 150, objectFit: "cover" }}
            className="rounded-top"
          />
          <Row>
            <Col>
              <h5 className="px-2 pt-2 m-0">{this.props.nombre}</h5>
            </Col>
            {this.renderKebab()}
            {this.renderOpciones()}
          </Row>
          <Row>
            <Col>{this.renderBodyText()}</Col>
          </Row>
          <Row>
            <Col className="px-4 py-2">
              <Button
                variant="outline-danger"
                className="border-red-new px-3 py-2 mx-auto my-0"
                onClick={() => this.handleClick()}
                block
              >
                {this.props.premios ? "Ver códigos" : "Configuración"}
              </Button>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export default CardPatrocinador;
