import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { navigate } from '@reach/router'

class ThankyouModal extends Component {
    render() {
        return(
            <Modal
                {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4 className='text-center'>¡Muchas gracias por tus comentarios!</h4>
                    <br />
                    <br />
                    <p>
                        ¡Eres parte importante de lo que hacemos!
                        <br />
                        <br />
                        Gracias a tus comentarios podemos seguir mejorando <b className='color-red'>ULU®</b> para que más personas
                        tengan oportunidad de seguir divirtiendose mientras aprenden.
                        <br />
                        <br />
                        Agradecemos de todo corazón la confianza y el entusiasmo que has tenido al jugar con nosotros.
                        <br />
                        <br />
                        ¡Te esperamos de nuevo!
                        <br />
                        <br />
                        <b className='color-red'>- Familia ULU®</b>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='link'
                        className='link-gray' 
                        onClick={this.props.onHide}
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant='outline-danger'
                        className='border-red-new' 
                        onClick={() => this.props.user ? navigate('/explorar') : navigate('/jugar')}
                    >
                        Jugar nuevo ULU
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ThankyouModal;