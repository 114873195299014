import React from "react";
import { Row, Col, Dropdown, Container } from "react-bootstrap";
import InputULU from "../global/InputULU";
import SearchableInput from "../common/SearchableInput";
import Input from "../Input";
import { niveles } from "../../utils";
import InfoCard from "../../components/InfoCard";
export default function ({
  escuela,
  escuelas,
  modifier,
  changeQuery,
  queryEstado,
  queryMunicipio,
  queryNivel,
  queryEscuela,
  showEscuelas,
  estados,
  municipios,
  user,
  nivel,
  escuelaManual,
  nombreEscuelaManual,
  nombreEmpresa,
  setPropiedadUser,
  handleChangeEmpresa
}) {
  function renderEscuelas() {
    if (escuelas && escuelas !== null)
      return (
        <>
        {
          escuelas.map((escuela) => (
            <Dropdown.Item
              key={escuela.idEscuela}
              onClick={() => {
                modifier("idEscuela", escuela.idEscuela);
                modifier("nombre_escuela", escuela.nombre);
              }}
            >
              {escuela.nombre}
            </Dropdown.Item>
          ))
        }
          <Dropdown.Item
            className="text-left border"
            onClick={() => {
                modifier("escuelaManual", true);
              }
            }
          >
            <p>Mi escuela no aparece en la lista</p>
          </Dropdown.Item>
        </>
      );
  }

  let entidad = user.entidad ? user.entidad.toUpperCase() : queryEstado;

  return (
    <>
      {(user.rol === "alumno" || user.rol === "maestro") && (
        <>
          <Row className="my-1">
            <Col md={6}>
              <p className="h6 m-0"><b>Datos de tu escuela</b></p>
            </Col>
          </Row>
          <Row className="py-2">
          <Col md={6}>
              <p className="m-0">Nivel educativo</p>
              <Input
                as="select"
                type="select"
                options={niveles}
                value={nivel ? nivel : queryNivel}
                modifier={(value) =>
                  changeQuery("nivel", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="py-2">
            <Col md={6}>
              <p className="m-0">Estado</p>
              <Input
                as="select"
                type="select"
                placeholder="Estado"
                value={entidad}
                options={estados}
                modifier={(value) => changeQuery("estado", { target: { value } })}
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="py-2">
          <Col md={6}>
              <p className="m-0">Municipio</p>
              <Input
                as="select"
                type="select"
                placeholder="Municipio"
                value={
                  user.municipio ? user.municipio.toUpperCase() : queryMunicipio
                }
                options={municipios}
                modifier={(value) =>
                  changeQuery("municipio", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="py-2">
          <Col md={6}>
              <p className="m-0">Nombre</p>
              <SearchableInput
                placeholder="Escuela"
                id="escuela"
                name="escuela"
                value={queryEscuela ? queryEscuela : ''}
                onChange={(event) => {
                  changeQuery("escuela", event);
                  setPropiedadUser("escuelaManual", false);
                }}
              />
            </Col>
          </Row>
          {showEscuelas && (
            <Row className="mx-0">
              <Container
                className="dropdown-container px-0"
                style={{ maxHeight: 120, overflowY: "scroll" }}
                fluid
              >
                {renderEscuelas()}
              </Container>
              <Col>
              <InfoCard
                texts={[
                  {
                    title: "Escuela:",
                    message:
                      "Este campo nos permite identificarte de mejor manera para poder ofrecerte una mejor experiencia.",
                  },
                  {
                    title: "Nivel educativo",
                    message:
                      "Tu nivel educativo nos permite mostrarte ULUs que creemos que te pueden ayudar a mejorar tus estudios.",
                  },
                  {
                    title: "Rol en la escuela",
                    message:
                      "Este campo nos permite identificarte de mejor manera para poder ofrecerte una mejor experiencia.",
                  },
                ]}
              />
            </Col>
            </Row>
          )}
          {escuelaManual &&  (
            <Row className="my-2">
              <Col md={6}>
                <InputULU
                  label="Nombre de tu escuela"
                  maxLength={255}
                  value={nombreEscuelaManual}
                  onChange={(event) => { 
                    changeQuery("nombreEscuelaManual", event, false);
                    setPropiedadUser("nombre_escuela", event.target.value);
                    setPropiedadUser("escuelaManual", true);
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      {user.rol === "profesional" && (
        <>

        <Row className="my-1">
          <Col md={6}>
            <p className="h6 m-0"><b>Datos de tu empresa</b></p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <p className="m-0">Estado</p>
            <Input
              as="select"
              type="select"
              placeholder="Estado"
              value={entidad}
              options={estados}
              modifier={(value) => { 
                changeQuery("estado", { target: { value } });
                setPropiedadUser("entidad", value);
              }}
              style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
              className='border-morado'
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <p className="m-0">Municipio</p>
            <Input
              as="select"
              type="select"
              placeholder="Municipio"
              value={
                user.municipio ? user.municipio.toUpperCase() : queryMunicipio
              }
              options={municipios}
              modifier={(value) =>{
                changeQuery("municipio", { target: { value } });
                setPropiedadUser("municipio", value);
              }}
              style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
              className='border-morado'
            />
          </Col>
        </Row>
        {/** 
        <Row className="mb-2">
          <Col md={6}>
            <InputULU
                  label="Nombre de tu empresa"
                  maxLength={255}
                  value={nombreEmpresa}
                  onChange={(event) => { 
                    setPropiedadUser("nombreEmpresa", event.target.value);
                    handleChangeEmpresa(event.target.value);

                  }}
              />
          </Col>
        </Row>
        */}
        </>
      )}
    </>
  );
}
