import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from "@reach/router";
import { BASE_URL } from '../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import base64url from 'base64url';
import copy from 'clipboard-copy';

class Footer extends Component {
    constructor(props) {
        super(props);
        const getUrl = window.location;
        const baseUrl = getUrl.protocol + "//" + getUrl.host;
        let queryURL = `juego=${this.props.idJuego}&tipo=${this.props.type}&segmento=${this.props.user ? this.props.user.nivel : ''}`;
        queryURL = base64url(queryURL);
        this.state = {
            invitationUrl: `${baseUrl}/play/alone/${queryURL}`
        }

        this.handleKeepPlaying = this.handleKeepPlaying.bind(this);
    }

    handleKeepPlaying() {
        if(this.props.user) {
            navigate('/explorar');
        } else {
            navigate('/signup');
        }
    }

    render() {
        let component = this.props.component.toLowerCase();
        if(this.props.isMobile) {
            if(component !== 'gameending') {
                return(
                    <div className={`footer ${component !== 'question' || this.props.juego.contestada ? 'animate-footer-in' : 'animate-footer-out' }`}>
                        <Container className='mb-2'>
                            <Row className='justify-content-between align-items-end no-gutters'>
                                <Col xs='auto' className='justify-self-start'>
                                    <Button
                                        variant="link"
                                        className="link-gray px-0 pb-3"
                                        disabled={this.props.loading}
                                        onClick={this.props.terminarJuego}
                                    >
                                        <p className='mb-0 text-small'>Terminar ULU</p>
                                    </Button>
                                </Col>
                                {this.props.patrocinador &&
                                <Col xs='auto' className='text-center'>
                                    <p className='text-small'>ULU impulsado por</p>
                                    <img alt='logo impulsor' src={`${BASE_URL}/adjuntos/${this.props.patrocinador.logoPrincipal}`} className='logo-width-host-pin my-2' />
                                    <p className='text-small'>{this.props.patrocinador.nombre}</p>
                                </Col>
                                }
                                <Col xs='auto' className='justify-self-end'>
                                    <Button
                                        variant="outline-danger"
                                        className="border-red-new px-0 mb-3"
                                        disabled={component === 'lobbyquestion' || this.props.loading}
                                        onClick={this.props.siguientePregunta}
                                    >
                                        <p className='m-0 mx-1 text-small'>Siguiente {/*<i class="fas fa-xs fa-arrow-right"></i>*/}</p>
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                );
            } else {
                return(
                    <Container>
                        <Row className='justify-content-between no-gutters my-2'>
                            <Col xs={6} className='text-center'>
                                <Button
                                    variant="link"
                                    className="text-dark text-left"
                                    onClick={() => {
                                        copy(this.state.invitationUrl);
                                        toast.dark("Link copiado!", {
                                            position: "bottom-right",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                        this.setState({cardOptions: false})
                                    }}
                                >
                                    <i className="fas fa-share-alt mr-1"></i> Compartir ULU
                                </Button>
                            </Col>
                            <Col xs={6} className='text-center'>
                                <Button
                                    variant="danger"
                                    className="new-red-button py-2"
                                    onClick={()=> {
                                        this.props.postSugerencia();
                                        navigate("/explorar");
                                    }}
                                >
                                    <h6 className='m-0'>Seguir jugando</h6>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                );
            }
        } else {
            return(
                <div className='footer'>
                    <Container className='mb-2'>
                        <Row className='justify-content-between align-items-end footer-question-number-relative'>
                            {this.props.patrocinador &&
                            <Col xs={3} sm={4} md='auto'>
                                <div className='text-center'>
                                    <p className='text-small'>ULU impulsado por</p>
                                    <img className='logo-width-host-pin my-2' src={`${BASE_URL}/adjuntos/${this.props.patrocinador.logoPrincipal}`} alt='logo impulsor' />
                                    <p className='text-small'>{this.props.patrocinador.nombre}</p>
                                </div>
                            </Col>
                            }
                            <Col xs='auto' className='text-center footer-question-number-absolute'>
                                {this.props.component !== 'gameending' &&
                                    <h6 className='non-bold'>Pregunta {this.props.juego.preguntaActual} / {this.props.juego.preguntasTotales}</h6>
                                }
                            </Col>
                            <Col xs={3} sm={4} md='auto' className='ml-auto'>
                                {this.props.component !== 'gameending' ?
                                    <>
                                        <Button
                                            variant="link"
                                            className="link-gray"
                                            size='lg'
                                            disabled={this.props.loading}
                                            onClick={this.props.terminarJuego}
                                        >
                                            <h6 className='mb-0'>Terminar ULU</h6>
                                        </Button>
                                        <Button
                                            variant="outline-danger"
                                            className="border-red-new"
                                            size='lg'
                                            disabled={component === 'lobbyquestion' || this.props.loading}
                                            onClick={this.props.siguientePregunta}
                                        >
                                            <h6 className='m-0'>Siguiente</h6>
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            variant="link"
                                            className="text-dark text-left"
                                            onClick={() => {
                                                copy(this.state.invitationUrl);
                                                toast.dark("Link copiado!", {
                                                    position: "bottom-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                this.setState({cardOptions: false})
                                            }}
                                        >
                                            <i className="fas fa-share-alt mr-1"></i> Compartir ULU
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="new-red-button ml-2"
                                            size='lg'
                                            onClick={()=> {
                                                this.props.postSugerencia();
                                                navigate("/explorar");
                                            }}
                                        >
                                            <h6 className='m-0'>Seguir jugando</h6>
                                        </Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }
}

export default Footer;