import api from "./api";

const route = "/misJuegos";

export default {
  getMisJuegos: () => api.get(`${route}`),
  getJuego: (idJuego) => api.get(`${route}/${idJuego}`),
  postJuego: (juego) => api.post(route, { ...juego }),
  putFavorito: (idJuego, favorito) =>
    api.put(`${route}/${idJuego}/favorito`, { favorito }),
  putJuego: (idJuego, juego) => api.put(`${route}/${idJuego}`, { ...juego }),
  deleteJuego: (idJuego) => api.delete(`${route}/${idJuego}`),
  searchImages: ({tipoJuego, keyword, page}) => api.get(`imageSearch`, {
    params: {
      page,
      keyword,
      tipoJuego
    }
  }),
  uploadUnsplashImage: ({ downloadLocation, downloadURL, id }) => api.post(`${route}/unsplashImage`, {
    downloadLocation, downloadURL, id
  }),
};
