import React, { Component } from "react";
import ReactGA from "react-ga";
import { Row, Col, Image } from "react-bootstrap";
import logo from "../../../assets/logo-cara.png";
import HintPalabra from "../../../components/crucigrama/HintPalabra";
import rectangulo from "../../../assets/Rectangulo.svg";
import moment from "moment";
import { navigate } from "@reach/router";

class RespuestasCrucigrama extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.time = moment();
  }

  componentWillUnmount() {
    let time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      label: "Tiempo en página de respuestas de crucigrama",
      variable: "seconds",
      category: "Respuestas Crucigrama",
      value: time,
    });
  }

  renderVerticales() {
    if (this.props.preguntas !== null) {
      return this.props.preguntas
        .filter((pregunta) => pregunta.direccion === "vertical")
        .map((pregunta) => (
          <HintPalabra
            key={pregunta.idPregunta}
            idPregunta={pregunta.idPregunta}
            texto={pregunta.hint}
            respuestas={pregunta.respuestas}
            respuesta={pregunta.respuesta}
            showRespuesta={true}
            index={pregunta.index}
          />
        ));
    }
  }

  renderHorizontales() {
    if (this.props.preguntas !== null) {
      return this.props.preguntas
        .filter((pregunta) => pregunta.direccion === "across")
        .map((pregunta) => (
          <HintPalabra
            key={pregunta.idPregunta}
            idPregunta={pregunta.idPregunta}
            texto={pregunta.hint}
            respuestas={pregunta.respuestas}
            respuesta={pregunta.respuesta}
            showRespuesta={true}
            index={pregunta.index}
          />
        ));
    }
  }

  render() {
    return (
      <>
        <Row className="pt-3 justify-content-center">
          <Col xs='auto'>
            <Image src={logo} style={{ width: "80px" }} onClick={() => navigate('/explorar')} />
          </Col>
          <Col>
            <Row className="justify-content-center align-items-center text-center">
              <Col>
                <h3>
                  <Image src={rectangulo} style={{ width: "2vw" }} />
                  <span className="ml-3">
                    {this.props.juego ? this.props.juego.titulo : "Crucigrama"}
                  </span>
                </h3>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center text-center">
              <Col>
                <p className="h4 text-muted">
                  <i className="far fa-clock"></i> <span>0m 0s</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-3 justify-content-between">
          <Col xs={5} className="max-h-60">
            <Row>
              <Col xs={10}>
                <h4>
                  Verticales:
                </h4>
              </Col>
              <Col xs={2} className="text-right align-items-center">
                <i className="far fa-check-circle"></i>
              </Col>
            </Row>
            {this.renderVerticales()}
          </Col>
          <Col xs={5} className="max-h-60">
            <Row>
              <Col xs={10}>
                <h4>
                  Horizontales:
                </h4>
              </Col>
              <Col xs={2} className="text-right align-items-center">
                <i className="far fa-check-circle"></i>
              </Col>
            </Row>
            {this.renderHorizontales()}
          </Col>
        </Row>
      </>
    );
  }
}

export default RespuestasCrucigrama;
