import React, { useState } from 'react';
import { Asterisco, Circulo, Estrella, Hexagono, Rayas, Rectangulo } from '../../assets/icons';
import { isMobile } from '../../utils';
import Button from '../common/Button';
import { navigate } from "@reach/router";

const GameTypeSidebar = (props) => {
    const [showGameTypes, setShowGameTypes] = useState(false);

    const width = '16px';
    const height = '16px';
    const gameTypes = [
        {
            id: 0,
            label: 'Todos',
            selector: 'all',
            icon: <Circulo width={width} height={height} color='var(--ulu-red)' />,
            inUse: true,
        },
        {
            id: 1,
            label: 'Favoritos',
            selector: 'favourites',
            icon: <Estrella width={width} height={height} color='var(--ulu-yellow)' />,
            inUse: false,
        },
        {
            id: 2,
            label: 'Trivia',
            selector: 'trivia',
            icon: <Rayas width={width} height={height} color='var(--trivia)'/>,
            inUse: true,
        },
        {
            id: 3,
            label: 'Ahorcado',
            selector: 'hangman',
            icon: <Asterisco width={width} height={height} color='var(--hangman)' />,
            inUse: true,
        },
        {
            id: 4,
            label: 'Crucigrama',
            selector: 'crossword',
            icon: <Rectangulo width={width} height={height} color='var(--crossword)' />,
            inUse: true,
        },
        {
            id: 5,
            label: 'Encuestas',
            selector: 'survey',
            icon: <Hexagono width={width} height={height} color='var(--ulu-yellow)' />,
            inUse: false,
        },
    ]

    function handleClick() {
        navigate("/seleccionar");
      }

    if(isMobile()) {
        return (
            <div className='sidebar-flex'>
                {gameTypes.map(gameType => (
                    (gameType.inUse && props.selected === gameType.selector) &&
                    <div 
                        key={gameType.id}
                        className={`selected-game-type ${gameType.selector} ${props.selected === gameType.selector && `active-${gameType.selector}`}`}
                        onClick={() => setShowGameTypes(!showGameTypes)}
                    >
                        {gameType.icon}
                        <p className='text-smaller bold my-auto ml-3'>{gameType.label}</p>
                        <span className={`ml-auto ${showGameTypes}`}><ion-icon name="chevron-down-outline"></ion-icon></span>
                    </div>
                ))}
                {showGameTypes &&
                    <ul className='game-types-list'>
                        {gameTypes.map(gameType => (
                            (gameType.inUse && props.selected !== gameType.selector) &&
                            <li 
                                key={gameType.id}
                                className={`${gameType.selector} ${props.selected === gameType.selector && `active-${gameType.selector}`}`}
                                onClick={() => {
                                    props.select(gameType.selector);
                                    setShowGameTypes(false);
                                }}
                            >
                                {gameType.icon}
                                <p className='text-smaller bold my-auto ml-3'>{gameType.label}</p>
                            </li>
                        ))}
                        <li>
                            <hr />
                            <Button
                                size='lg'
                                label='Crear ULU'
                                variant='secondary'
                                block
                            />
                        </li>
                    </ul>
                }
            </div>
        );
    } else {
        return (
            <div className='sidebar-flex'>
                <ul className='game-types-list'>
                    {gameTypes.map(gameType => (
                        gameType.inUse &&
                        <li 
                            key={gameType.id}
                            className={`${gameType.selector} ${props.selected === gameType.selector && `active-${gameType.selector}`}`}
                            onClick={() => props.select(gameType.selector)}
                        >
                            {gameType.icon}
                            <p className='text-smaller bold my-auto ml-3'>{gameType.label}</p>
                        </li>
                    ))}
                </ul>
                <div className='mt-auto mx-3 mb-3'>
                    <hr />
                    <Button
                        onClick={() => handleClick()}
                        size='lg'
                        label='Crear ULU'
                        variant='secondary'
                        block
                    />
                </div>
            </div>
        );
    }
}

export default GameTypeSidebar;