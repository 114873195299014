import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Image, Button, Col, Spinner } from "react-bootstrap";
import logo from "../../assets/logo.png";
import { navigate } from "@reach/router";
import { BASE_URL } from "../../utils";
import MedallaJugador from "../../components/MedallaJugador";
import medalla1 from "../../assets/Medalla_dorada.svg";
import medalla2 from "../../assets/Medalla_plateada.svg";
import medalla3 from "../../assets/Medalla_cobre.svg";
import rayas from "../../assets/Rayas.svg";
import asterisco from "../../assets/Asterisco.svg";
import rectangulo from "../../assets/Rectangulo.svg";
import { getTipo } from "../../utils";

class FinalScoreboard extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderPatrocinador() {
    if (this.props.patrocinador !== null && this.props.patrocinador.nombre) {
      let src = logo;
      if (this.props.patrocinador.idAdjunto !== null) {
        src = `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`;
      }
      return (
        <Row className="align-items-end p-0">
          <Col md={6} className="text-center p-0">
            <h6 className='mb-2'>ULU impulsado por</h6>
            <Image src={src} className="logo-width" />
            <h6 className='mt-2'>{this.props.patrocinador.nombre}</h6>
          </Col>
        </Row>
      );
    }
  }

  async handleClick() {
    await navigate("/explorar");
    document.documentElement.scrollTop = 0;
    ReactGA.event({
      category: "Fin de Juego",
      action: "Ir a Mis Juegos",
      label: "Ir a Mis Juegos",
    });
  }

  render() {
    let icon;
    if (this.props.juego.tipo.toLowerCase() === "crucigrama") icon = rectangulo;
    else if (this.props.juego.tipo.toLowerCase() === "ahorcado")
      icon = asterisco;
    else icon = rayas;
    return (
      <Container fluid>
        <Row className="my-4">
          <Col className="text-center">
            <h1>
              <Image src={icon} style={{ width: 30 }} className="pb-2 mr-2" />
              {this.props.juego !== null ? this.props.juego.titulo : ""}
            </h1>
          </Col>
        </Row>
        <Row className="text-center my-2">
          <Col>
            <p className="m-0 display-4">Felicidades</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col xs={6}>
            <Row>
              <Col xs={4} className="mt-2 pt-5 text-center">
                {this.props.segundo && (
                  <MedallaJugador
                    image={medalla2}
                    color="gray"
                    alias={this.props.segundo.alias}
                    size={135}
                    puntos={this.props.segundo.puntos}
                  />
                )}
              </Col>
              <Col xs={4} className="text-center">
                {this.props.primero && (
                  <MedallaJugador
                    image={medalla1}
                    color="goldenrod"
                    alias={this.props.primero.alias}
                    size={150}
                    puntos={this.props.primero.puntos}
                  />
                )}
              </Col>
              <Col xs={4} className="mt-5 pt-5 text-center">
                {this.props.tercero && (
                  <MedallaJugador
                    image={medalla3}
                    color="peru"
                    alias={this.props.tercero.alias}
                    size={120}
                    puntos={this.props.tercero.puntos}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="fixed-bottom justify-content-between pb-5 px-5">
          <Col xs={6}>
            {this.renderPatrocinador()}
          </Col>
          <Col md={6} className="text-right pt-3 align-self-end">
            <Button
              variant="outline-danger"
              size="lg"
              className="rounded-button border-red-new px-4 py-4 mt-5"
              onClick={() => {
                ReactGA.event({
                  category: "Fin de Juego",
                  action: "Descargar Reporte",
                  label: "Descargar Reporte",
                });
                this.props.downloadReporte(
                  this.props.idJuego,
                  this.props.idSesion,
                  getTipo(this.props.juego)
                );
              }}
              disabled={!this.props.reporte}
            >
              {this.props.reporte ? (
                <h6 className="mb-0">Descargar reporte</h6>
              ) : (
                <Spinner animation="border" variant="dark" />
              )}
            </Button>
            <Button
              variant="danger"
              size='lg'
              className="rounded-button new-red-button ml-2 px-4 py-4 mt-5"
              onClick={() => this.handleClick()}
            >
              <h6 className="mb-0">Regresar a explorar</h6>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FinalScoreboard;
