import React, { Component } from 'react';
import AloneTrivia from './AloneTrivia';
import AloneHangman from './AloneHangman';
import AloneCrossword from './AloneCrossword';
import { isMobile } from '../../utils/index';
import base64url from "base64url";
import querystring from 'querystring';

class PlayAlone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameType: 'crucigrama',
            mobile: false
        };
    }

    componentDidMount() {
        let mobile = isMobile();
        this.setState({mobile});
    }

    render() {
        let urlQuery = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/')+1);//Remove first '/' character
        urlQuery = base64url.decode(urlQuery);
        urlQuery = querystring.decode(urlQuery);
        switch(urlQuery.tipo.toLowerCase()) {
            case 'trivia':
                return(
                    <AloneTrivia
                        isMobile={this.state.mobile}
                        urlQuery={urlQuery}
                    />
                );
            case 'ahorcado':
                return(
                    <AloneHangman
                        isMobile={this.state.mobile}
                        urlQuery={urlQuery}
                    />
                );
            case 'crucigrama':
                return(
                    <AloneCrossword
                        isMobile={this.state.mobile}
                        urlQuery={urlQuery}
                    />
                );
            default:
                break;
        }
    }

}

export default PlayAlone;