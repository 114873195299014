import api from "./api";

const route = "/patrocinadores/premios";

export default {
  postPremio: (premio) => api.post(route, { ...premio }),
  putPremio: (idPremio, premio) =>
    api.put(`${route}/${idPremio}`, { ...premio }),
  togglePremio: (idPremio, activo) =>
    api.put(`${route}/activo/${idPremio}`, { activo }),
  deletePremio: (idPremio) => api.delete(`${route}/${idPremio}`),
  enviarPremio: ({
    mail,
    apodo,
    idPatrocinador,
    idSesion
  }) => 
    api.post("jugar/premio", {
      mail,
      apodo,
      idPatrocinador,
      idSesion
    }),
};
