import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image
} from "react-bootstrap";
import Menu from "../../components/Menu";
import logoCaraBlanca from "../../assets/logo-cara-blanca.png";

class IngresarApodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apodo: null
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    onbeforeunload = null;
  }

  handleSubmit = event => {
    event.preventDefault();
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    this.setState({ validated: true });
    let apodo = event.target.elements.apodo.value;
    this.props.setApodo(apodo);
  };

  render() {
    const { validated } = this.state;
    return (
        <>
        {this.props.user && <Menu user={this.props.user} />}
        <Container fluid className={this.props.user ? 'fondo-rojo-completo-user vertical-center' : 'fondo-rojo-completo vertical-center'}>
          <Row className="justify-content-center">
            <Col md={12} className="mb-3">
              <Image
                src={logoCaraBlanca}
                className="d-block mx-auto"
                style={{ height: 100 }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mx-auto">
              <h1 className="mb-2 mt-4 pb-4 text-center text-white">
                Ingresa tu apodo
              </h1>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xs={12} md={2}>
              <Form
                noValidate
                validated={validated}
                onSubmit={this.handleSubmit}
              >
                <Form.Group>
                  <Form.Control
                    required
                    type="text"
                    size="lg"
                    name="apodo"
                    placeholder="Ejemplo: Andrés"
                    maxLength={40}
                    id="ingresar-apodo"
                  />
                  <Form.Control.Feedback type="invalid">
                    Porfavor ingresa un apodo.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  block
                  className="white-button"
                >
                  <h6 className="py-2 my-0">Ingresar</h6>
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IngresarApodo;
