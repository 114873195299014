import React, { Component, Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Input from './Input';

class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			property: ''
		};
		this.search = this.search.bind(this);
	}

	search(query) {
		if (this.props.searchModifier)
			this.props.searchModifier(query, this.state.property);
	}

	componentDidMount() {
		if (this.props.filters) this.setProperty();
	}

	setProperty(property) {
		this.setState({ property });
	}

	renderFilters() {
		if (this.props.filters) {
			return (
				<Fragment>
					<Col xs={12} sm={8} md={9} className="pr-0">
						<Input
							className="rounded-button alt-icon-fa"
							type="text"
							placeholder={this.props.placeholder}
							modifier={this.search}
						/>
					</Col>
					<Col xs={12} sm={4} md={3}>
						<Input
							className="rounded-button alt-icon-fa"
							as="select"
							modifier={this.setProperty}
							options={this.props.options}
						/>
					</Col>
				</Fragment>
			);
		}
		return (
			<Input
				className="rounded-button alt-icon-fa border-morado searchbox"
				type="text"
				placeholder={this.props.placeholder}
				modifier={this.search}
			/>
		);
	}

	render() {
		return this.renderFilters();
	}
}

export default SearchBox;
