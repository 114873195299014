import api from "./api";

const route = "/jugarSolo";

export default {
  crearSesion: ({ idJuego, tipo, segmento }) => api.post(route, {
        idJuego,
    },
    {
        params: {
            segmento
        }
    }
  ),
  postSugerencia: ({ idSesion, recomendacionJuego, recomendacionPlataforma, sugerencias }) => api.post(`${route}/sugerencia/${idSesion}`, {
    recomendacionJuego,
    recomendacionPlataforma,
    sugerencias
  }
  )
};
