import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { setResponse, clearModal, alert } from "../../actions/modalActions";
import { connect } from "react-redux";

class ModalConfirm extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleClose(response, e) {
    if(response && this.props.validation) {
      const valid = this.props.validation();
      if(!valid) return this.props.alert(this.props.message);
    }
    this.props.setResponse(response);
    if (response === true) this.props.callback();
    else if (this.props.onClose) this.props.onClose();
    this.props.clearModal();
  }

  render() {
    return (
      <Modal
        id="modal-confirm"
        show={this.props.show}
        size={this.props.size ? this.props.size : ""}
        onHide={() => this.handleClose(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title className="pl-3 pr-3">
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#fff" }}>
          {this.props.component ? (
            this.props.component
          ) : (
            <p className='texto-modal'>{this.props.content}</p>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{ backgroundColor: "#fff" }}
          className="text-right"
        >
          <Button
            variant="link"
            className="link-gray px-4 py-4"
            size='lg'
            onClick={() => this.handleClose(false)}
          >
            <h6 className='mb-0'>No, cancelar</h6>
          </Button>
          <Button
            variant="outline-danger"
            className="border-red-new px-4 mr-4 pt-3"
            size='lg'
            onClick={(e) => this.handleClose(true, e)}
          >
            <h6>Si, continuar</h6>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  show: state.modal.show,
  content: state.modal.content,
  callback: state.modal.callback,
  title: state.modal.title,
  onClose: state.modal.onClose,
  component: state.modal.component,
  validation: state.modal.validation,
  message: state.modal.message
});

export default connect(mapStateToProps, { setResponse, clearModal, alert })(
  ModalConfirm
);
