import React, { Component } from "react";
import ReactGA from "react-ga";
import { Container, Row, Button, Image, Col, Dropdown, Form } from "react-bootstrap";
import SearchableInput from "../components/common/SearchableInput";
import "@material/react-text-field/dist/text-field.css";
import "@material/react-material-icon/dist/material-icon.css";
import { Link, navigate } from "@reach/router";
import {
  getEscuelas,
  setArgEscuela,
  getEstados,
  getMunicipios,
} from "../actions/escuelasActions";
import { signUp, signIn } from "../actions/userActions";
import { alert } from "../actions/modalActions";
import { connect } from "react-redux";
import { throttle } from "throttle-debounce";
import { niveles } from "../utils";
import InputULU from "../components/global/InputULU";
import Input from "../components/Input";
import moment from "moment";
import PasswordStrengthBar from "react-password-strength-bar";
import logoCara from "../assets/logo-cara.png";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      email: "",
      password: "",
      usuario: "",
      nombre: "",
      rol: 1,
      school: null,
      queryEstado: "",
      queryMunicipio: "",
      queryNivel: "Primaria",
      queryEscuela: "",
      escuelaManual: false,
      nombreEscuelaManual: ''
    };
    this.changeQuery = this.changeQuery.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRolChange = this.handleRolChange.bind(this);
    this.autocompleteSearchThrottled = throttle(500, this.autocompleteSearch);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.escuelas !== this.props.escuelas) {
      this.setState({ showEscuelas: true });
    }
  }

  componentDidMount() {
    ReactGA.pageview("/signup");
    this.props.getEscuelas(this.props.args);
    this.props.getEstados();
    window.scrollTo(0, 0);
    this.time = moment();
  }

  changeQuery = (key, event) => {
    const { value } = event.target;
    let prop = `query${key[0].toUpperCase()}${key.slice(1)}`;
    //key=escuela //prop=queryEscuela
    this.setState({ [prop]: value }, () => {
      if (key === "nivel")
        this.autocompleteSearchThrottled(this.state.queryNivel);
      if (key === "escuela")
        this.autocompleteSearchThrottled(this.state.queryEscuela);
      if (key === "estado") this.props.getMunicipios(value);
    });
    this.props.setArgEscuela(key, value);
  };

  autocompleteSearch() {
    this.props.getEscuelas(this.props.args);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.nombre === "") {
      this.setState({ validated: false });
      return this.props.alert("El nombre no puede estar vacío");
    }
    if (this.state.usuario === "") {
      this.setState({ validated: false });
      return this.props.alert("El usuario no puede estar vacío");
    }
    if (this.state.email === "") {
      this.setState({ validated: false });
      return this.props.alert("El correo no es válido.");
    }
    if (this.state.password.length < 6) {
      this.setState({ validated: false });
      return this.props.alert(
        "La contraseña debe ser de al menos 6 caracteres."
      );
    }
    if (this.state.rol !== "profesional"){
      if((this.state.school === null && !this.state.escuelaManual) || (this.state.escuelaManual && this.state.nombreEscuelaManual === '')) {
        this.setState({ validated: false });
        return this.props.alert("Debes seleccionar una escuela");
      }
    }
    this.setState({ validated: true });
    let email = this.state.email;
    const signUpData = {
      nombre: this.state.nombre,
      correo: email.toLowerCase().trim(),
      usuario: this.state.usuario,
      password: this.state.password,
      rol: this.state.rol
    };
    if(this.state.rol === "profesional"){
      signUpData.profesional = {};
      if(this.state.queryEstado === ""){//If estado no seleccionado, escoger primero
        signUpData.profesional.entidad = this.props.estados[0];
      }
      else{
        signUpData.profesional.entidad = this.state.queryEstado;
      }
      if(this.state.queryMunicipio === ""){//If municipio no seleccionado, escoger primero
        signUpData.profesional.municipio = this.props.municipios[0];
      }
      else{
        signUpData.profesional.municipio = this.state.queryMunicipio;
      }
    }
    else if(this.state.escuelaManual){//Registrar escuela manual
      signUpData.escuela = {};
      signUpData.escuela.nombre = this.state.nombreEscuelaManual;
      signUpData.escuela.nivel = this.state.queryNivel;
      if(this.state.queryEstado === ""){//If estado no seleccionado, escoger primero
        signUpData.escuela.entidad = this.props.estados[0];
      }
      else{
        signUpData.escuela.entidad = this.state.queryEstado;
      }
      if(this.state.queryMunicipio === ""){//If municipio no seleccionado, escoger primero
        signUpData.escuela.municipio = this.props.municipios[0];
      }
      else{
        signUpData.escuela.municipio = this.state.queryMunicipio;
      }
    }
    else{//Registrar escuela con idEscuela
      signUpData.idEscuela = this.state.school;
    }
    this.props.signUp(signUpData);
    let time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      category: "Signup Complete",
      label: "Tiempo de Signup",
      variable: "seconds",
      value: time,
    });
  }

  getEscuela() {
    if (this.props.escuelas !== null && this.state.school !== null) {
      let { school } = this.state;
      let escuela = this.props.escuelas.find((uni) => uni.idEscuela === school);
      return escuela.nombre;
    }
    return "Busca tu escuela aquí";
  }

  renderEscuelas() {
    if (this.props.escuelas && this.props.escuelas !== null)
      return (
        <>
        {
          this.props.escuelas.map((escuela) => (
            <Dropdown.Item
              key={escuela.idEscuela}
              className="text-left border"
              onClick={() =>
                this.setState({
                  school: escuela.idEscuela,
                  escuela: escuela.nombre,
                  queryEscuela: escuela.nombre,
                  showEscuelas: false,
                  escuelaManual: false
                })
              }
            >
              {escuela.nombre}
            </Dropdown.Item>
          ))
        }
          <Dropdown.Item
            className="text-left border"
            onClick={() =>
              this.setState({
                escuelaManual: true
              })
            }
          >
            <p>Mi escuela no aparece en la lista</p>
          </Dropdown.Item>
        </>
      );
  }

  handleChange(event, input) {
    let { value } = event.target;
    this.props.setPropiedadUser(input, value);
  }

  handleRolChange(e) {
    this.setState({rol: e.currentTarget.value});
  }

  renderInputs(estados, municipios){
    let rol = this.state.rol;
    if(rol === 'alumno' || rol === 'maestro') {
      return (
        <>
          <Row className="justify-content-center mb-1">
            <Col md={6}>
              <p className="h6 m-0"><b>Datos de tu escuela</b></p>
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <p className="m-0">Nivel educativo</p>
              <Input
                as="select"
                type="select"
                options={niveles}
                value={this.state.queryNivel}
                modifier={(value) =>
                  this.changeQuery("nivel", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <p className="m-0">Estado</p>
              <Input
                as="select"
                type="select"
                placeholder="Estado"
                value={this.state.queryEstado}
                options={estados}
                modifier={(value) =>
                  this.changeQuery("estado", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <p className="m-0">Municipio</p>
              <Input
                as="select"
                type="select"
                placeholder="Municipio"
                value={this.state.queryMunicipio}
                options={municipios}
                modifier={(value) =>
                  this.changeQuery("municipio", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <p className="m-0">Nombre</p>
              <SearchableInput
                placeholder="Escuela"
                value={
                  this.state.queryEscuela
                }
                onChange={(event) => this.changeQuery("escuela", event)}
              />
            </Col>
          </Row>
          {this.state.showEscuelas && (
            <Row className="justify-content-center">
              <Col md={6} style={{ maxHeight: 120, overflowY: "scroll" }}>
                {this.renderEscuelas()}
              </Col>
            </Row>
          )}
          {this.state.escuelaManual &&  (
            <Row className="justify-content-center mb-2">
              <Col md={6}>
                <InputULU
                  label="Nombre de tu escuela"
                  maxLength={255}
                  value={this.state.nombreEscuelaManual}
                  onChange={(event) =>
                    this.setState({ nombreEscuelaManual: event.target.value })
                  }
                />
              </Col>
            </Row>
          )}
          <Row className="justify-content-center p-0">
            <Col md={6} className="text-right pt-2">
              <Button
                variant="danger"
                type="submit"
                size="lg"
                className="new-red-button"
                onClick={this.handleSubmit}
              >
                <h6 className="py-2 my-0">Registrarme</h6>
              </Button>
            </Col>
          </Row>
        </>
      );
    } else if (rol === 'profesional') {
      return (
        <>
          <Row className="justify-content-center mb-1">
            <Col md={6}>
              <p className="h6 m-0"><b>Datos de tu empresa</b></p>
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <p className="m-0">Estado</p>
              <Input
                as="select"
                type="select"
                placeholder="Estado"
                value={this.state.queryEstado}
                options={estados}
                modifier={(value) =>
                  this.changeQuery("estado", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col md={6}>
              <p className="m-0">Municipio</p>
              <Input
                as="select"
                type="select"
                placeholder="Municipio"
                value={this.state.queryMunicipio}
                options={municipios}
                modifier={(value) =>
                  this.changeQuery("municipio", { target: { value } })
                }
                style={{ borderColor: "rgba(0, 0, 0, 0.5)", backgroundColor: 'transparent' }}
                className='border-morado'
              />
            </Col>
          </Row>
          <Row className="justify-content-center p-0">
            <Col md={6} className="text-right pt-2">
              <Button
                variant="danger"
                type="submit"
                size="lg"
                className="new-red-button"
                onClick={this.handleSubmit}
              >
                <h6 className="py-2 my-0">Registrarme</h6>
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return;
    }
  }

  render() {
    let { estados, municipios } = this.props;
    if (estados !== null && municipios === null) {
      this.props.getMunicipios(estados[0]);
    }
    if (estados === null) estados = [];
    else
      estados = estados.map((estado) => ({
        name: estado,
        value: estado,
      }));
    if (municipios === null) municipios = [];
    else
      municipios = municipios.map((municipio) => ({
        name: municipio,
        value: municipio,
      }));
    return (
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-rojo-mitad order-2 order-lg-1 py-5 py-sm-0'>
            <Row className="justify-content-md-center">
              <Col md={12} className="text-center text-white pl-4">
                <h2 className='pb-2'>¿Buscas jugar un ULU?</h2>
                <Button
                  type="submit"
                  variant="danger"
                  size="lg"
                  className='white-button'
                  href="/jugar"
                >
                  <h6 className="py-2 my-0">¡Juega aquí!</h6>
                </Button>
              </Col>
            </Row>
            <Row className='text-white mt-3 mt-sm-0' style={{display: 'inline-block'}}>
              <Col xs={12} className='text-center landing-link'>
                <a href='https://edu.playulu.com' rel="noopener noreferrer" target='_blank' className='white-link'>Conoce más sobre <b>PlayULU</b></a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='vertical-center fondo-gris order-1 order-lg-2 py-5 py-sm-0'>
            <Row className="justify-content-md-center mt-md-5">
              <Col md={12} className="text-center pl-4">
                <Image
                  src={logoCara}
                  className="d-block mx-auto mb-1"
                  style={{ height: 60 }}
                  onClick={() => navigate('/explorar')}
                />
                <h2>Regístrate</h2>
              </Col>
            </Row>
            <Row className="justify-content-center mt-1 mb-1">
              <Col md={6}>
                <p className="h6 m-0"><b>Datos personales</b></p>
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <Col md={6}>
                <InputULU
                  label="Nombre"
                  maxLength={255}
                  value={this.state.nombre}
                  onChange={(event) =>
                    this.setState({ nombre: event.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <Col md={6}>
                <InputULU
                  label="Usuario"
                  maxLength={255}
                  value={this.state.usuario}
                  onChange={(event) =>
                    this.setState({ usuario: event.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-2">
              <Col md={6}>
                <InputULU
                  label="Correo Electrónico"
                  maxLength={255}
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center mb-2">
              <Col md={6}>
                <InputULU
                  label="Contraseña"
                  maxLength={255}
                  value={this.state.password}
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                  onTrailingIconSelect={() =>
                    this.setState((prevState) => ({
                      visible: !prevState.visible,
                    }))
                  }
                  trailingIcon={
                    <i
                      className={
                        this.state.visible ? "far fa-eye" : "far fa-eye-slash"
                      }
                    />
                  }
                  type={this.state.visible ? "" : "password"}
                />
                {this.state.password !== "" && (
                  <Col xs={6} className="px-0">
                    <PasswordStrengthBar
                      password={this.state.password}
                      minLength={6}
                      shortScoreWord={["Muy corta"]}
                      scoreWords={[
                        "Muy débil",
                        "Débil",
                        "Regular",
                        "Fuerte",
                        "Muy fuerte",
                      ]}
                    />
                  </Col>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              <Col md={6}>
                <Form className={'d-flex justify-content-between'}  >
                  <Form.Check inline label="Alumno" type='radio' id='alumno' value='alumno' checked={this.state.rol === 'alumno'} onChange={this.handleRolChange}/>
                  <Form.Check inline label="Maestro" type='radio' id='maestro' value='maestro' checked={this.state.rol === 'maestro'} onChange={this.handleRolChange} />
                  <Form.Check inline label="Profesional" type='radio' id='profesional' value='profesional' checked={this.state.rol === 'profesional'} onChange={this.handleRolChange}/>
                </Form>
              </Col>
            </Row>
            {this.renderInputs(estados, municipios)}
            <Row className="justify-content-center mb-md-5">
              <Col md={6} className='text-center'>
                <hr></hr>
                <p className='p-0 m-0'>
                  ¿Ya tienes cuenta?
                  <Link to="/login" className='ml-1'>Inicia sesión aquí</Link>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  escuelas: state.escuelas.escuelas,
  args: state.escuelas.args,
  estados: state.escuelas.estados,
  municipios: state.escuelas.municipios,
});

export default connect(mapStateToProps, {
  alert,
  signUp,
  signIn,
  getEstados,
  getEscuelas,
  getMunicipios,
  setArgEscuela,
})(SignUp);
