import {
  MISJUEGOS_RECIBIDOS,
  MISJUEGOS_SORT,
  DUPLICAR_JUEGO,
  SHOW_OPCIONES,
  ELIMINAR_JUEGO,
  SET_PROPIEDAD_MIJUEGO
} from "../actions/types";

const INITIAL_STATE = {
  juegos: undefined,
  juego: undefined,
  opcion: undefined,
  orden: true,
  opciones: undefined
};

const sortJuegos = (juegos, opcion) => {
  let sortAsc;
  let sortDesc;
  if (opcion.includes("titulo")) {
    sortAsc = (opcion) => (a, b) =>
      a[opcion].toLowerCase() > b[opcion].toLowerCase() ? 1 : -1;
    sortDesc = (opcion) => (a, b) =>
      a[opcion].toLowerCase() < b[opcion].toLowerCase() ? 1 : -1;
  } else {
    sortAsc = (opcion) => (a, b) => (a[opcion] > b[opcion] ? 1 : -1);
    sortDesc = (opcion) => (a, b) => (a[opcion] < b[opcion] ? 1 : -1);
  }
  let indice = opcion.indexOf("asc");
  if (indice === -1) indice = opcion.indexOf("desc");
  let orden = opcion.substring(indice);
  opcion = opcion.substring(0, indice - 1);
  let type;
  if (orden === "asc") type = sortAsc;
  else type = sortDesc;
  let sorted = juegos.slice().sort(type(opcion));
  return sorted;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MISJUEGOS_RECIBIDOS:
      let misjuegos = action.payload;
      if (state.opcion) {
        misjuegos = sortJuegos(misjuegos, state.opcion);
      }
      return { ...state, juegos: misjuegos };
    case MISJUEGOS_SORT:
      let opcion = action.payload.opcion;
      let sorted = sortJuegos(state.juegos, opcion);
      return {
        ...state,
        juegos: sorted,
        opcion: action.payload.opcion,
      };
    case DUPLICAR_JUEGO:
      return { ...state, juegos: [...state.juegos, action.payload] };
    case SHOW_OPCIONES:
      return { ...state, opciones: action.payload };
    case ELIMINAR_JUEGO:
      let juegos = [...state.juegos];
      let index = juegos.findIndex(
        (juego) => parseInt(juego.idJuego) === parseInt(action.payload)
      );
      if (index !== -1) juegos.splice(index, 1);
      return { ...state, juegos: juegos };
    case SET_PROPIEDAD_MIJUEGO: {
      const { idJuego, key, value } = action.payload;
      const juegos = [...state.juegos];
      const juego = juegos.find(
        (game) => String(game.idJuego) === String(idJuego)
      );
      if (juego) {
        juego[key] = value;
      }
      return { ...state, juegos };
    }
    default:
      return { ...state };
  }
};
