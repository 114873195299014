import React, { Component } from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { BASE_URL } from "../../utils";
import rayas from "../../assets/Rayas.svg";
import asterisco from "../../assets/Asterisco.svg";
import rectangulo from "../../assets/Rectangulo.svg";
import { getColor } from "../../utils/index";

class HostPin extends Component {
  componentDidMount() {
    this.props.connectSocket(this.props.pin);
    window.scrollTo(0, 0);
  }

  getRandomTopNumber() {
    return Math.random() * window.innerHeight + 1;
  }

  getRandomLeftNumber() {
    return Math.random() * window.innerWidth + 1;
  }

  getRandomColor() {
    return getColor(Math.round(Math.random() * 4));
  }

  renderJugadores() {
    if (this.props.jugadores !== null) {
      return this.props.jugadores
        .slice(0)
        .reverse()
        .map((jugador) => {
          return (
            <Col xs={2} className="pb-2" key={jugador}>
              <h3
                key={jugador}
                style={{
                  color: this.getRandomColor(),
                }}
                className="m-0 nombre-jugador"
              >
                {jugador}
              </h3>
            </Col>
          );
        });
    }
  }

  renderPatrocinador() {
    if (this.props.patrocinador !== null && this.props.patrocinador.nombre) {
      let src = null;
      if (this.props.patrocinador.idAdjunto !== null) {
        src = `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`;
      }
      return (
        <Row className="align-items-end p-0">
          <Col className="text-center p-0">
            <h6 className='mb-2'>ULU impulsado por</h6>
            {src !== null && <Image src={src} className="logo-width-host-pin" />}
            <h6 className='mt-2'>{this.props.patrocinador.nombre}</h6>
          </Col>
        </Row>
      );
    }
  }

  render() {
    let icon;
    if (this.props.juego.tipo.toLowerCase() === "crucigrama") icon = rectangulo;
    else if (this.props.juego.tipo.toLowerCase() === "ahorcado")
      icon = asterisco;
    else icon = rayas;
    return (
      <>
        <Row className="my-4">
          <Col className="text-center">
            <h1>
              <Image src={icon} style={{ width: 30 }} className="pb-2 mr-2" />
              {this.props.juego !== null ? this.props.juego.titulo : ""}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="overflow-scroll px-5" style={{ maxHeight: "42vh" }}>
            <Row>{this.renderJugadores()}</Row>
          </Col>
        </Row>
        <Row className="fixed-bottom justify-content-between pb-5 px-5">
          <Col xs={12} md={3}>
            {this.renderPatrocinador()}
          </Col>
          <Col xs={12} md={3} className="text-right pt-3 align-self-end">
            <Button
              size="lg"
              variant="danger"
              className="new-red-button px-4 py-4 mt-5"
              onClick={this.props.iniciarJuego}
            >
              <h6 className='mb-0'>¡Comenzar ULU!</h6>
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default HostPin;
