import { ESTADOS_RECIBIDOS, PATROCINIOS_RECIBIDOS, SET_ESTADO, SET_PATROCIONIOS } from "../actions/types"

const initialState = {
    juegos: null,
    patrocinios: null
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
    case ESTADOS_RECIBIDOS:
        return { ...state, juegos: payload };
    case SET_ESTADO:
        const juegos = [...state.juegos];
        const juego = juegos.find(juego => juego.nombre === payload.nombre);
        if(juego) juego.activo = payload.activo;
        return { ...state, juegos };
    case PATROCINIOS_RECIBIDOS:
        return { ...state, patrocinios: payload };
    case SET_PATROCIONIOS:
        return { ...state, patrocinios: payload };
    default:
        return state
    }
}
