import React, { Component } from "react";
import ReactGA from "react-ga";
//Crucigrama Imports
import JugadorCrucigrama from "./jugador/crucigrama/JugadorCrucigrama";
import PreguntaAhorcado from "./jugador/ahorcado/PreguntaAhorcado";
//Trivia Imports
import SeleccionarOpcion from "./jugador/trivia/SeleccionarOpcion";
import FeedbackRespuesta from "./jugador/trivia/FeedbackRespuesta";
//General Imports
import FinalScore from "./jugador/FinalScore";
import Unirse from "./jugador/Unirse";
import TituloJuego from "./jugador/TituloJuego";
import Espera from "./Espera";
import io from "socket.io-client";
import {
  joinJuego,
  setApodo,
  next,
  setOpciones,
  setPregunta,
  setFeedback,
  setHoraServer,
  setIdSesion,
  clearJuego,
  setDatosJuego,
  setLugar,
  setPatrocinador,
  enviarPremio,
  enviarSugerencias
} from "../actions/jugarActions";
import {
  setPreguntasCrucigrama,
  agregarRespuestaCrucigrama,
} from "../actions/crucigramaActions";
import {
  contestar,
  clearContestadas,
  agregarRespuestaAhorcado,
} from "../actions/ahorcadoActions";
import { getJuego } from "../actions/juegoActions";
import { alert, modalComponent } from "../actions/modalActions";
import { connect } from "react-redux";
import { BASE_URL, getTipo } from "../utils";
import moment from "moment";

class Jugador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      io: null,
      component: null,
      prevScore: 0,
      counter: 0,
    };
    this.setApodo = this.setApodo.bind(this);
    this.responder = this.responder.bind(this);
    this.displayTitulo = this.displayTitulo.bind(this);
    this.displayEspera = this.displayEspera.bind(this);
    this.displayFeedback = this.displayFeedback.bind(this);
    this.displayOpciones = this.displayOpciones.bind(this);
    this.jugadorAhorcado = this.jugadorAhorcado.bind(this);
    this.jugadorFinished = this.jugadorFinished.bind(this);
    this.disconnectSocket = this.disconnectSocket.bind(this);
    this.displayFinalScore = this.displayFinalScore.bind(this);
    this.displayCrucigrama = this.displayCrucigrama.bind(this);
    this.responderAhorcado = this.responderAhorcado.bind(this);
    this.responderCrucigrama = this.responderCrucigrama.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview("/jugar");
    this.displayUnirse();
    window.onclose = this.disconnectSocket;
    window.scrollTo(0, 0);
    //onbeforeunload = (e) => "Don't leave :(";
  }

  componentWillUnmount() {
    //onbeforeunload = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pin === null && this.props.pin) {
      this.connectSocket(this.props.pin);
      this.displayUnirse();
    }
    if (prevProps.apodo === null && this.props.apodo) {
      this.displayTitulo();
    }
    if (
      prevProps.acumulado !== this.props.acumulado &&
      this.props.tipo === "Crucigrama" &&
      this.state.component !== "espera"
    ) {
      this.displayCrucigrama();
    }
    if (
      prevProps.respuestasCrucigrama !== this.props.respuestasCrucigrama &&
      this.state.component !== "espera"
    ) {
      this.displayCrucigrama();
    }
  }

  connectSocket(pin, callback) {
    let base_url = BASE_URL;
    base_url = base_url.replace("/api", "");

    pin = pin.toLowerCase();

    let socket = io(`${base_url}/${pin}`);
    socket.on("error", function () {
      console.log("Lo sentimos, hubo un error al conectarte a la sesión.");
    });
    socket.on("connect", () => {
      if (callback) callback();
    });

    socket.on("error", () => {
      this.props.alert("Este PIN no es válido. Intenta de nuevo.");
      this.props.joinJuego(null);
    });

    socket.on("apodo_existe", () => {
      this.displayUnirse();
      this.props.setApodo();
      this.props.alert(
        "Este apodo ya está en uso por otro jugador. Intenta uno diferente."
      );
    });

    socket.on("idUsuario_existe", () => {
      this.displayUnirse();
      this.props.setApodo();
      this.props.alert("Ya hay alguien dentro de este juego con tu perfil.");
    });

    socket.on("idSesion", (idSesion) => {
      this.props.setIdSesion(idSesion);
    });

    socket.on("enviarHoraServer", (horaServer) => {
      this.displayEspera();
      this.props.setHoraServer(horaServer);
    });

    socket.on("pregunta", (pregunta) => {
      if (this.state.component === "titulo") {
        let time = moment().diff(this.time, "seconds");
        ReactGA.timing({
          category: "Tiempo en Pantalla",
          label: `Tiempo en PIN Jugador`,
          variable: "seconds",
          value: time,
        });
      }
      if (pregunta !== null) {
        this.props.setPregunta(pregunta);
        if (pregunta.opciones) this.props.setOpciones(pregunta.opciones);
        let tipo = getTipo(this.props.juego);
        if (tipo === "ahorcado") {
          this.props.clearContestadas();
          return this.displayOpciones();
        }
        if (tipo === "trivia")
          setTimeout(() => {
            this.displayOpciones();
          }, 5000);
      }
    });

    socket.on("preguntas_crucigrama", (preguntas) => {
      this.props.setPreguntasCrucigrama(preguntas);
      this.displayCrucigrama();
    });

    socket.on("datos_juego", ({ juego, patrocinador }) => {
      this.props.setPatrocinador(patrocinador);
      this.props.setDatosJuego(juego);
    });

    socket.on("feedback", (data) => {
      let { tipo } = this.props.juego;
      tipo = tipo.toLowerCase();
      if (this.state.component !== "opciones" && tipo !== "crucigrama") return;
      let { puntos, mensaje, idRespuesta, idOpcion, letra } = data;
      switch (tipo) {
        case "crucigrama":
          this.props.agregarRespuestaCrucigrama(
            this.props.apodo,
            puntos,
            idRespuesta
          );
          this.displayCrucigrama();
          break;
        case "ahorcado":
          this.props.agregarRespuestaAhorcado(this.props.apodo, letra, puntos);
          this.displayOpciones();
          break;
        default:
          this.props.setFeedback(puntos, mensaje, idOpcion);
          this.displayEspera();
      }
    });

    socket.on("closed", () => {
      this.getScore();
    });

    socket.on("score", (data) => {
      let { lugar, puntos } = data;
      if(lugar === null || lugar === undefined || puntos === null || puntos === undefined)
        return;
      puntos = puntos - this.state.prevScore;
      this.setState({ prevScore: this.state.prevScore + puntos });
      this.props.setLugar(lugar, puntos);
      if (
        this.state.component !== "final" &&
        this.state.component !== "feedback"
      ) {
        this.displayFeedback();
      }
    });

    socket.on("game_over", ({ scoreboard, premios }) => {
      this.props.setPatrocinador({...this.props.patrocinador, premios});
      let index = scoreboard.findIndex(
        (usuario) => usuario.alias === this.props.apodo
      );
      let usuario = scoreboard[index];
      if (usuario) this.props.setLugar(index + 1, usuario.puntos);
      this.displayFinalScore();
    });

    this.setState({ socket });
  }

  jugadorAhorcado(apodo) {
    let { socket } = this.state;
    socket.emit("ahorcado", apodo);
    this.displayEspera();
    this.setState({ mensaje: "Oh no! Perdiste todas tus vidas." });
  }

  jugadorFinished(apodo) {
    let { socket } = this.state;
    socket.emit("finished", apodo);
    this.displayEspera();
  }

  disconnectSocket() {
    let { socket } = this.state;
    if (socket && socket !== null) socket.disconnect();
    this.props.clearJuego();
    this.props.setApodo();
  }

  setApodo(apodo) {
    if (apodo === "") return;
    let { socket } = this.state;
    if (!socket) {
      this.connectSocket(this.props.pin);
    } else {
      let idUsuario = -1;
      if (this.props.user !== null) idUsuario = this.props.user.idUsuario;
      socket.emit("apodo", { apodo, idUsuario });
      this.props.setApodo(apodo);
      this.displayTitulo();
    }
  }

  responder(idOpcion) {
    let { socket } = this.state;
    let idUsuario = -1;
    if (this.props.user !== null) idUsuario = this.props.user.idUsuario;
    let { numPregunta } = this.getParams();
    let index = numPregunta - 1;
    socket.emit("respuesta", {
      apodo: this.props.apodo,
      idOpcion,
      horaServer: this.props.horaServer,
      idPregunta: this.props.pregunta.idPregunta,
      idSesion: this.props.idSesion,
      idUsuario,
      index,
    });
    this.displayEspera();
  }

  responderCrucigrama(idRespuesta, respuesta) {
    let { socket } = this.state;
    let idUsuario = -1;
    if (this.props.user !== null) idUsuario = this.props.user.idUsuario;
    socket.emit("respuesta", {
      respuesta,
      idRespuesta,
      apodo: this.props.apodo,
      horaServer: this.props.horaServer,
      idSesion: this.props.idSesion,
      idUsuario,
    });
  }

  responderAhorcado(letra) {
    if (!this.props.contestadas.has(letra.toLowerCase())) {
      let { socket } = this.state;
      this.props.contestar(letra);
      let idUsuario = -1;
      if (this.props.user !== null) idUsuario = this.props.user.idUsuario;
      let { numPregunta } = this.getParams();
      let index = numPregunta - 1;
      socket.emit("respuesta", {
        letra,
        apodo: this.props.apodo,
        horaServer: this.props.horaServer,
        idPregunta: this.props.pregunta.idPregunta,
        idSesion: this.props.idSesion,
        idUsuario,
        index,
      });
    }
  }

  getScore() {
    let { socket } = this.state;
    socket.emit("get_score", this.props.apodo);
  }

  displayUnirse() {
    this.setState({
      component: "unirse",
    });
  }

  displayCrucigrama() {
    this.setState({ component: "crucigrama" });
  }

  displayTitulo() {
    this.time = moment();
    this.setState({
      component: "titulo",
    });
  }

  displayEspera(mensaje) {
    if (this.state.component !== "final") {
      this.setState({
        component: "espera",
        mensaje,
      });
    }
  }

  displayOpciones() {
    this.setState({
      component: "opciones", counter: this.state.counter + 1
    });
  }

  displayFeedback() {
    this.setState({
      component: "feedback",
      mensaje: undefined,
      counter: this.state.counter + 1,
    });
    let tipo = getTipo(this.props.juego);
    if (tipo !== "ahorcado" && tipo !== "trivia" && tipo !== "crucigrama") {
      this.props.clearContestadas();
    }
  }

  displayFinalScore() {
    this.getScore();
    this.setState({ component: "final" });
  }

  getParams() {
    const numPregunta = this.props.numPregunta;
    const puntosPregunta =
      this.props.pregunta !== null ? this.props.pregunta.puntos : 1000;
    const respuestas = this.props.respuestas.length;
    const total = this.props.juego !== null ? this.props.juego.preguntas : 0;
    return { numPregunta, puntosPregunta, respuestas, total };
  }

  renderComponent() {
    let { total, numPregunta } = this.getParams();
    switch (this.state.component) {
      case "unirse":
        return (
          <Unirse
            pin={this.props.pin}
            setApodo={this.setApodo}
            joinJuego={this.props.joinJuego}
            apodo={this.props.apodo}
            alert={this.props.alert}
            user={this.props.user}
            spinner={this.props.spinner}
          />
        );
      case "titulo":
        return (
          <TituloJuego
            juego={this.props.juego}
            apodo={this.props.apodo}
            patrocinador={this.props.patrocinador}
            pin={this.props.pin}
          />
        );
      case "crucigrama":
        return (
          <JugadorCrucigrama
            pin={this.props.pin}
            juego={this.props.juego}
            preguntas={this.props.preguntas}
            respuestas={this.props.respuestasCrucigrama}
            responder={this.responderCrucigrama}
            displayEspera={this.displayEspera}
            puntos={this.props.acumulado}
          />
        );
      case "opciones":
        let tipo = this.props.tipo;
        if (this.props.juego) tipo = this.props.juego.tipo;
        if (tipo) tipo = tipo.toLowerCase();
        if (tipo === "ahorcado")
          return (
            <PreguntaAhorcado
              pin={this.props.pin}
              juego={this.props.juego}
              apodo={this.props.apodo}
              total={this.props.juego.preguntas}
              numPregunta={this.props.numPregunta}
              pregunta={this.props.pregunta}
              contestadas={this.props.contestadas}
              clearContestadas={this.props.clearContestadas}
              respuestas={this.props.respuestasAhorcado}
              displayEspera={this.displayEspera}
              displayFeedback={this.displayFeedback}
              responder={this.responderAhorcado}
              jugadorAhorcado={this.jugadorAhorcado}
              jugadorFinished={this.jugadorFinished}
              puntos={this.props.acumulado}
            />
          );
        return (
          <SeleccionarOpcion
            juego={this.props.juego}
            pin={this.props.pin}
            opciones={this.props.opciones}
            apodo={this.props.apodo}
            responder={this.responder}
            total={total}
            numPregunta={numPregunta}
          />
        );
      case "feedback":
        return (
          <FeedbackRespuesta
            juego={this.props.juego}
            base_url={BASE_URL}
            puntos={this.props.puntos}
            patrocinador={this.props.patrocinador}
            apodo={this.props.apodo}
            mensaje={
              this.props.pregunta
                ? this.props.pregunta.respuesta
                  ? this.props.pregunta.respuesta
                  : this.props.mensaje
                : this.props.mensaje
            }
            next={this.props.next}
            pin={this.props.pin}
            lugar={this.props.lugar}
          />
        );
      case "final":
        return (
          <FinalScore
            idSesion={this.props.idSesion}
            base_url={BASE_URL}
            lugar={this.props.lugar}
            apodo={this.props.apodo}
            puntos={this.props.puntos}
            patrocinador={this.props.patrocinador}
            user={this.props.user}
            modalComponent={this.props.modalComponent}
            enviarPremio={this.props.enviarPremio}
            enviarSugerencias={this.props.enviarSugerencias}
            juego={this.props.juego}
          />
        );
      default:
        let mensaje = this.state.mensaje
          ? this.state.mensaje
          : this.props.mensaje;
        if (this.props.patrocinador && this.props.patrocinador !== null) {
          if (this.props.patrocinador.frases.length > 0) {
            const random = Math.floor(
              Math.random() * this.props.patrocinador.frases.length
            );
            mensaje = this.props.patrocinador.frases[random].frase;
          }
        }
        return (
          <Espera
            patrocinador={this.props.patrocinador}
            mensaje={mensaje}
            feedback={this.props.puntos !== undefined}
            next={this.props.move}
            pin={this.props.pin}
            counter={this.state.counter}
          />
        );
    }
  }

  render() {
    return this.renderComponent();
  }
}

const mapStateToProps = (state) => ({
  pin: state.jugar.pin,
  apodo: state.jugar.apodo,
  juego: state.jugar.juego,
  pregunta: state.jugar.pregunta,
  opciones: state.jugar.opciones,
  patrocinador: state.jugar.patrocinador,
  mensaje: state.jugar.mensaje,
  puntos: state.jugar.puntos,
  move: state.jugar.next,
  horaServer: state.jugar.horaServer,
  idSesion: state.jugar.idSesion,
  user: state.user.user,
  numPregunta: state.jugar.numPregunta,
  lugar: state.jugar.lugar,
  preguntas: state.crucigrama.preguntas,
  acumulado: state.jugar.acumulado,
  respuestas: state.jugar.respuestas,
  respuestasAhorcado: state.ahorcado.respuestas,
  contestadas: state.ahorcado.contestadas,
  respuestasCrucigrama: state.crucigrama.respuestas,
});

export default connect(mapStateToProps, {
  joinJuego,
  setApodo,
  next,
  alert,
  enviarPremio,
  setOpciones,
  setFeedback,
  setPregunta,
  setHoraServer,
  setIdSesion,
  clearJuego,
  setDatosJuego,
  setLugar,
  setPreguntasCrucigrama,
  contestar,
  getJuego,
  setPatrocinador,
  clearContestadas,
  modalComponent,
  agregarRespuestaAhorcado,
  agregarRespuestaCrucigrama,
  enviarSugerencias
})(Jugador);
