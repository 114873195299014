import React, { useState } from "react";
import { Card, Row, Col, Accordion } from "react-bootstrap";

export default function ({ pregunta }) {
  const [activo] = useState(0);

  return (
    <Card key={pregunta.idFAQ} className="mb-2 accordion-premios">
      <Accordion.Toggle
        as={Card.Header}
        eventKey={pregunta.idFAQ}
        style={{ backgroundColor: "rgb(242,242,242)" }}
      >
        <Row>
          <Col xs={10} sm={11}>
            <p className="m-0">{pregunta.pregunta}</p>
          </Col>
          <Col xs={1}>
            <i
              className={`fas fa-chevron-${
                activo === pregunta.idFAQ ? "up" : "down"
              }`}
            ></i>
          </Col>
        </Row>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={pregunta.idFAQ}>
        <Card.Body>
          <Row>
            <Col>
              <p className="m-0">{pregunta.respuesta}</p>
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
