import {
    SOLO_CLEAR_STORE,
    SOLO_JUEGO_RECIBIDO,
    SOLO_SUGERENCIA_GUARDADA,
    SOLO_CRUCIGRAMA_RECIBIDO,
    SOLO_CRUCIGRAMA_SELECT_QUESTION,
    SOLO_CRUCIGRAMA_SET_QUESTION_STATUS
} from "./types";
import JugarSoloService from "../services/JugarSoloService";
  
export const createJugarSoloSesion = ({ idJuego, tipo, segmento }) => async (dispatch) => {
    const response = await JugarSoloService.crearSesion({ idJuego, tipo, segmento });
    const {
        patrocinador,
        tiempoEsperaLobby,
        tiempoEsperaPregunta,
        nombre,
        cantidadPreguntas,
        namespace,
        imagenJuego,
        layout,
        creador
    } = response.data.data;
    dispatch({ type: SOLO_JUEGO_RECIBIDO, payload: {
        patrocinador,
        tiempoEsperaLobby,
        tiempoEsperaPregunta,
        nombre,
        tipo,
        cantidadPreguntas,
        namespace,
        imagenJuego,
        creador
    }});
    if(tipo.toLowerCase() === 'crucigrama'){
        const preguntasMap = new Map();//Saves preguntas by their ID
        layout.forEach((pregunta) => {
            preguntasMap.set(pregunta.idPregunta, {...pregunta, contestada: false, respuesta: pregunta.respuesta.toLowerCase()});
        });
        dispatch({ type: SOLO_CRUCIGRAMA_RECIBIDO, payload: {
            preguntas: preguntasMap
        }});
    }
    return Promise.resolve();
};

export const postSugerencia = ({ idSesion, recomendacionJuego, recomendacionPlataforma, sugerencias }) => async (dispatch) => {
    await JugarSoloService.postSugerencia({ idSesion, recomendacionJuego, recomendacionPlataforma, sugerencias });
    dispatch({ type: SOLO_SUGERENCIA_GUARDADA });
};

export const selectQuestionCrucigrama = (idQuestion) => (dispatch) => {
    dispatch({ type: SOLO_CRUCIGRAMA_SELECT_QUESTION, payload: idQuestion });
};

export const setCrucigramaQuestionStatus = (idQuestion) => (dispatch) => {
    dispatch({ type: SOLO_CRUCIGRAMA_SET_QUESTION_STATUS, payload: idQuestion });
};

export const clearStore = () => (dispatch) => {
    dispatch({ type: SOLO_CLEAR_STORE });
};