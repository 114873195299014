import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import { getIcon, getColor } from "../../../utils";
import moment from "moment";

class JugadorCrucigrama extends Component {
  constructor(props) {
    super(props);
    let rows = 12;
    let height = window.innerHeight / rows / 2;
    this.state = {
      inputs: [],
      focused: null,
      icons: [],
      respuestas: 0,
      height,
    };
  }

  componentDidMount() {
    this.time = moment();
    this.renderPalabras();
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    //onbeforeunload = (e) => "Don't leave :("
  }

  componentWillUnmount() {
    //onbeforeunload = null;
    const time = moment().diff(this.time, "seconds");
    ReactGA.timing({
      label: "Tiempo en Completar Crucigrama",
      value: time,
      variable: "seconds",
      category: "Completar Crucigrama",
    });
  }

  componentDidUpdate() {
    if (this.state.respuestas < this.props.respuestas.length) {
      let respuesta = this.props.respuestas[this.props.respuestas.length - 1];
      if (respuesta.puntos === 0) {
        this.enableInputs(respuesta.idRespuesta);
        this.props.respuestas.forEach((respuesta) => {
          if (respuesta.puntos > 0) this.disableInputs(respuesta.idRespuesta);
        });
      }
      let inputs = Array.from(document.getElementsByTagName("input"));
      let enabled = inputs.filter((input) => !input.disabled);
      if (enabled.length === 0) {
        setTimeout(() => {
          this.props.displayEspera("¡Wow! Espera a tus compañeros.");
        }, 100);
      }
      this.setState({ respuestas: this.state.respuestas + 1 });
    }
  }

  handleChange(idRespuesta, index, value) {
    if (value !== "") {
      let inputs = Array.from(document.getElementsByTagName("input"));
      let respuestas = idRespuesta.split("_");
      if (respuestas.length > 1) {
        idRespuesta =
          respuestas[0] === this.state.focused ? respuestas[0] : respuestas[1];
        let indices = index.split("_");
        index = respuestas[0] === this.state.focused ? indices[0] : indices[1];
        index = parseInt(index);
      }
      inputs = inputs.filter((input) => input.name.includes(idRespuesta));
      inputs = inputs.filter((input, idx) => idx > index);
      if (inputs.length > 0) {
        if (inputs[0].disabled) inputs.shift();
        if (inputs[0]) inputs[0].focus();
      }
      this.setState({ inputs });
      if (String(idRespuesta).length > 1) {
        let idRespuestas = String(idRespuesta).split("_");
        idRespuestas.forEach((idRespuesta) => {
          let respuesta = Array.from(document.getElementsByTagName("input"))
            .filter((input) => input.name.includes(idRespuesta))
            .map((input) => input.value)
            .join("");
          let pregunta = this.props.preguntas.find(
            (pregunta) =>
              parseInt(pregunta.idRespuesta) === parseInt(idRespuesta)
          );
          if (respuesta.length === pregunta.respuesta.length) {
            this.props.responder(idRespuesta, respuesta);
            this.disableInputs(idRespuesta);
          }
        });
      } else {
        let respuesta = Array.from(document.getElementsByTagName("input"))
          .filter((input) => input.name.includes(idRespuesta))
          .map((input) => input.value)
          .join("");
        let pregunta = this.props.preguntas.find(
          (pregunta) => parseInt(pregunta.idRespuesta) === parseInt(idRespuesta)
        );
        if (respuesta.length === pregunta.respuesta.length) {
          this.props.responder(idRespuesta, respuesta);
          this.disableInputs(idRespuesta);
        }
      }
    }
  }

  handleDelete(evt, idRespuesta, index) {
    let key = evt.keyCode || evt.charCode;
    if (key === 8 || key === 46) {
      let inputs = Array.from(document.getElementsByTagName("input"));
      let respuestas = idRespuesta.split("_");
      if (respuestas.length > 1) {
        idRespuesta =
          respuestas[0] === this.state.focused ? respuestas[0] : respuestas[1];
        let indices = index.split("_");
        index = respuestas[0] === this.state.focused ? indices[0] : indices[1];
        index = parseInt(index);
      }
      inputs = inputs.filter((input) => input.name.includes(idRespuesta));
      inputs = inputs.filter((input, idx) => idx <= index);
      inputs.reverse();
      if (inputs[0]) inputs[0].value = "";
      inputs.shift();
      this.setState({ inputs });
      if (inputs.length > 0) {
        if (inputs[0].disabled) inputs.shift();
        if (inputs[0]) inputs[0].focus();
      }
    }
  }

  handleFocus(evt) {
    const { focused } = this.state;
    let idRespuesta = evt.target.name;
    if (focused !== idRespuesta && !idRespuesta.includes(focused)) {
      this.setState({ focused: idRespuesta });
      this.resetInputColor();
      let inputs = Array.from(document.getElementsByTagName("input"));
      inputs = inputs.filter((input) => input.name.includes(idRespuesta));
      this.setState({ inputs });
    }
    let palabra;
    if (this.props.preguntas) {
      palabra = this.props.preguntas.find(
        (palabra) => parseInt(palabra.idRespuesta) === parseInt(idRespuesta)
      );
      let direccion = palabra.direccion;
      let icon = getIcon(palabra.index, { style: { width: "5vw" } });
      this.renderDescription(direccion, icon);
      this.highlightPregunta(palabra);
      this.highlightContestadas();
    }
  }

  resetInputColor() {
    let inputs = Array.from(document.getElementsByClassName("form-control"));
    inputs.forEach((input) => {
      input.style.borderLeftColor = null;
      input.style.borderRightColor = null;
      input.style.borderTopColor = null;
      input.style.borderBottomColor = null;
      input.style.borderWidth = "0.5px";
    });
  }

  highlightContestadas() {
    if (this.props.respuestas) {
      this.props.respuestas.forEach((respuesta) => {
        if (respuesta.puntos > 0) {
          let pregunta = this.props.preguntas.find(
            (pregunta) =>
              parseInt(pregunta.idRespuesta) === parseInt(respuesta.idRespuesta)
          );
          this.highlightPregunta(pregunta);
        }
      });
    }
  }

  highlightPregunta(pregunta) {
    let { idRespuesta, direccion, respuesta, index } = pregunta;
    let inputs = Array.from(document.getElementsByTagName("input"));
    inputs = inputs.filter((input) => input.name.includes(idRespuesta));
    let color = getColor(index);
    inputs.forEach((input, index) => {
      if (direccion === "across") {
        if (index === 0) {
          input.style.borderLeftColor = color;
          input.style.borderLeftWidth = "4px";
        }
        if (index === respuesta.length - 1) {
          input.style.borderRightWidth = "4px";
          input.style.borderRightColor = color;
        }
        input.style.borderTopWidth = "4px";
        input.style.borderTopColor = color;
        input.style.borderBottomWidth = "4px";
        input.style.borderBottomColor = color;
      } else {
        if (index === 0) {
          input.style.borderTopWidth = "4px";
          input.style.borderTopColor = color;
        }
        if (index === respuesta.length - 1) {
          input.style.borderBottomColor = color;
          input.style.borderBottomWidth = "4px";
        }
        input.style.borderLeftColor = color;
        input.style.borderLeftWidth = "4px";
        input.style.borderRightColor = color;
        input.style.borderRightWidth = "4px";
      }
    });
  }

  disableInputs(idRespuesta) {
    let inputs = Array.from(document.getElementsByTagName("input"));
    inputs = inputs.filter((input) => input.name.includes(idRespuesta));
    inputs.forEach((input) => {
      input.disabled = true;
    });
  }

  enableInputs(idRespuesta) {
    let inputs = Array.from(document.getElementsByTagName("input"));
    inputs = inputs.filter((input) => input.name.includes(idRespuesta));
    inputs.forEach((input) => {
      input.disabled = false;
    });
  }

  renderPalabras() {
    let icons = [];
    if (this.props.preguntas) {
      this.props.preguntas.forEach((palabra) => {
        let { idRespuesta, x0, y0, respuesta, direccion } = palabra;
        if (direccion === "across") {
          for (let i = 0; i < respuesta.length; i++) {
            let input = this.refs[`input_${y0}_${i + x0}`];
            input.disabled = false;
            if (input.name && input.name !== null) {
              let { name } = input;
              name += `_${idRespuesta}`;
              input.name = name;
            } else {
              input.name = idRespuesta;
            }
            if (input.index !== undefined && input.index !== null) {
              input.index = `${input.index}_${i}`;
            } else {
              input.index = i;
            }
            if (i === 0) {
              let { top, left } = input.getBoundingClientRect();
              icons.push(
                getIcon(palabra.index, {
                  style: {
                    position: "absolute",
                    left: left + 5,
                    top: top + 5,
                    width: this.state.height / 3,
                  },
                  key: palabra.index,
                })
              );
            }
          }
        } else {
          for (let i = 0; i < respuesta.length; i++) {
            let input = this.refs[`input_${i + y0}_${x0}`];
            input.disabled = false;
            if (input.name && input.name !== null) {
              let { name } = input;
              name += `_${idRespuesta}`;
              input.name = name;
            } else {
              input.name = idRespuesta;
            }
            if (input.index !== undefined && input.index !== null) {
              input.index = `${input.index}_${i}`;
            } else {
              input.index = i;
            }
            if (i === 0) {
              let { top, left } = input.getBoundingClientRect();
              icons.push(
                getIcon(palabra.index, {
                  style: {
                    position: "absolute",
                    left: left + 5,
                    top: top + 5,
                    width: this.state.height / 3,
                  },
                  key: palabra.index,
                })
              );
            }
          }
        }
      });
    }
    this.setState({ icons });
  }

  renderGrid() {
    let cols = 12;
    let width = 100 / cols;
    return (
      <Row className="mx-0">
        {new Array(12).fill(1).map((one, rowIndex) =>
          new Array(12).fill(1).map((one, colIndex) => (
            <FormControl
              ref={`input_${rowIndex}_${colIndex}`}
              key={`key_${rowIndex}_${colIndex}`}
              className={"pl-0 pr-0 text-center"}
              style={{
                width: `${width}%`,
                height: `${this.state.height}px`,
                borderRadius: 0,
              }}
              disabled={true}
              onChange={(evt) =>
                this.handleChange(
                  evt.target.name,
                  evt.target.index,
                  evt.target.value
                )
              }
              onKeyDown={(evt) =>
                this.handleDelete(evt, evt.target.name, evt.target.index)
              }
              maxLength={1}
              onFocus={(evt) => this.handleFocus(evt)}
              autoCapitalize="none"
              autoCorrect="off"
              autoComplete="off"
            />
          ))
        )}
      </Row>
    );
  }

  renderDescription(direccion, icon) {
    this.setState({ direccion, icon });
  }

  renderPoints(puntos) {
    if (puntos !== undefined)
      return (
        <Fragment>
          <i
            className="far fa-arrow-alt-circle-up mr-2"
            style={{ color: "green" }}
          ></i>
          <span className="mr-1">{puntos}</span>
          <span>pts</span>
        </Fragment>
      );
  }

  render() {
    return (
      <Container fluid>
        <Row>
          {this.renderGrid()}
          {this.state.icons}
        </Row>
        <Row className="align-items-center py-2">
          <Col xs={8} className="align-items-center">
            <span className="h4 mr-1">
              {this.state.direccion
                ? this.state.direccion === "across"
                  ? "Horizontal"
                  : "Vertical"
                : ""}
            </span>
            {this.state.icon}
          </Col>
          <Col xs={4}>
            <h4>{`${this.props.puntos} pts`}</h4>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default JugadorCrucigrama;
