import React, { useState, useRef, useEffect } from 'react';
import { Estrella } from '../assets/icons';
import { BASE_URL, isMobile, playerCount, selectCreatorImg } from "../utils";
import { setJuego } from "../actions/jugarActions";
import {
    getGamesProfile,
    getHighlightsProfile,
    getUserProfile,
    profileSearch,
} from '../actions/exploreActions';
import { navigate, useParams } from "@reach/router";
import { connect } from "react-redux";
import ExploreGameList from '../components/ExploreGameList';
import SearchBox from '../components/SearchBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconsLoading from '../components/IconsLoading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import copy from 'clipboard-copy';
import GameTypeSidebar from '../components/GameTypeSidebar';
import { throttle } from "throttle-debounce";


const GameProfile = (props) => {
    const { idPerfil } = useParams();

    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;

    const highlightGamesList = useRef();
    const [highlightGrid, setHighlightGrid] = useState(false);
    const [highlightGamesForwardCount, setHighlightGamesForwardCount] = useState(0);
    
    const [search, setSearch] = useState('');
    const [gt, setGameType] = useState('all');
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSearch, setCurrentPageSearch] = useState(0);

    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({});

    const [highlightGames, setHighlightGames] = useState([]);

    const [allGames, setAllGames] = useState([]);
    const [nGames, setNGames] = useState(0);

    const [searchGames, setSearchGames] = useState([]);

    let limit = 10;

    useEffect(() => {
        setLoading(true);

        let promises = [];
        let gameType = gt.toLowerCase();
        //fetchGames();
        getUserGames(1);
        promises.push(getHighlightsProfile({idPerfil, gameType}));
        promises.push(getUserProfile(idPerfil));

        Promise.all(promises).then(([highlights, userData]) => {
            setUser(userData);
            setHighlightGames(highlights.cards);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });

    }, []);

    useEffect(() => {
        setLoading(true);

        let gameType = gt.toLowerCase();

        if(gameType === 'hangman') {
            gameType = 'ahorcado';
        } else if (gameType === 'crossword') {
            gameType = 'crucigrama'
        }

        if(search === '') {
            getUserGames(0);
        } else {
            getSearchGames(0);
        }

        let promises = [];
        promises.push(getHighlightsProfile({idPerfil, gameType}));

        Promise.all(promises).then(([highlights]) => {
            setHighlightGames(highlights.cards);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [gt]);

    useEffect(() => {
        setLoading(true);

        let gameType = gt.toLowerCase();

        if(gameType === 'hangman') {
            gameType = 'ahorcado';
        } else if (gameType === 'crossword') {
            gameType = 'crucigrama'
        }

        let promises = [];
        promises.push(profileSearch({
            page: 0,
            limit,
            idPerfil,
            search,
            gameType,
        }));

        Promise.all(promises).then(([all]) => {
            setSearchGames(all.cards);
            setNGames(all.n);
            setLoading(false);
        }).catch((e) =>  {
            setLoading(false);
        });

    }, [search]);

    const fetchGames = () => {
        if (Math.ceil(nGames / limit) - currentPage !== 0) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getUserGames = (page) => {
        setTimeout(() => {
            let gameType = gt.toLowerCase();

            if(gameType === 'hangman') {
                gameType = 'ahorcado';
            } else if (gameType === 'crossword') {
                gameType = 'crucigrama'
            }

            let promises = [];
            promises.push(getGamesProfile({idPerfil, gameType, currentPage: page, limit}));

            Promise.all(promises).then(([all]) => {
                let uniqueGames = [];
                if(page === 0) {
                    uniqueGames = [...all.cards];
                } else {
                    let gameSet = new Set();
                    let allG = [...allGames];
                    allG = all.cards.concat(allG);

                    for(let i = 0; i < allG.length; i++) {
                        if(!gameSet.has(allG[i].idJuego)) {
                            uniqueGames.push(allG[i]);
                            gameSet.add(allG[i].idJuego);
                        }
                    }
                }

                setAllGames(uniqueGames);
                setNGames(all.n);
                setLoading(false);
            }).catch((e) =>  {
                setLoading(false);
            });
        }, 100); //antes 300, DR
    }

    useEffect(() => {
        getUserGames(currentPage);
    }, [currentPage]);

    const fetchSearchGames = () => {
        if (Math.ceil(nGames / limit) - currentPageSearch !== 0) {
            setCurrentPageSearch(currentPageSearch + 1);
            /*this.setState({currentPageSearch: this.state.currentPageSearch + 1 }, () => {
                setTimeout(() => {//para que se vea la animacion de cargando
                    this.props.searchJuegosNextPage({page: this.state.currentPageSearch, limit: 10, search: this.state.search, gameType: this.state.gameType});
                    this.props.getAllJuegos({currentPageSearch: this.state.currentPageSearch, limit: 10, gameType: this.state.gameType});
                }, 300);
            });*/
        }
    }

    const getSearchGames = (page) => {
        setTimeout(() => {
            let gameType = gt.toLowerCase();

            if(gameType === 'hangman') {
                gameType = 'ahorcado';
            } else if (gameType === 'crossword') {
                gameType = 'crucigrama'
            }

            let promises = [];
            promises.push(profileSearch({page, limit, idPerfil, search, gameType}));

            Promise.all(promises).then(([sGames]) => {
                let uniqueGames = [];
                if(page === 0) {
                    uniqueGames = [...sGames.cards];
                } else {
                    let gameSet = new Set();
                    let searchG = [...searchGames];
                    searchG = sGames.cards.concat(searchG);

                    for(let i = 0; i < searchG.length; i++) {
                        if(!gameSet.has(searchG[i].idJuego)) {
                            uniqueGames.push(searchG[i]);
                            gameSet.add(searchG[i].idJuego);
                        }
                    }
                }

                setSearchGames(uniqueGames);
                setNGames(sGames.n);
                setLoading(false);
            }).catch((e) =>  {
                setLoading(false);
            });
        }, 100); //antes 300
    }

    useEffect(() => {
        getSearchGames(currentPageSearch);
    }, [currentPageSearch]);

    const scrollForward = async() => {
        await highlightGamesList.current.scrollBy({left: 800, top: 0, behavior: 'smooth'});
        await setHighlightGamesForwardCount(highlightGamesForwardCount + 1);
    }
  
    const scrollBackwards = async() => {
        await highlightGamesList.current.scrollBy({left: -800, top: 0, behavior: 'smooth'});
        await setHighlightGamesForwardCount(highlightGamesForwardCount - 1);
    }


    /*TODO filter games when selecting a gameType*/

    let playersAmountString = playerCount(user.playersAmount)
    return (    
        <div className='app-flex'>
            <GameTypeSidebar
                selected={gt}
                select={(e) => setGameType(e)}
            />
            <div className='main-content px-3 pt-3'>
                <div className='ulu-row-auto' id='user-data'>
                    <div className='ulu-col'>
                        <img src={user?.logo  ? `${BASE_URL}/adjuntos/${user.logo}` : selectCreatorImg(user)} alt='' className='game-profile-picture' />
                    </div>
                    <div className='ulu-col-6 vertical-center'>
                        <div className='ulu-row-auto'>
                            <div className='ulu-col-24'>
                                <h1>{user.name}</h1>
                                <h5
                                    className='regular text-gray'
                                    id='game-profile-username'
                                    onClick={() => {
                                        copy(`${baseUrl}/user/${user.idPerfil}`);
                                        toast.dark("Link copiado!", {
                                            position: "bottom-right",
                                            autoClose: 3000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                    }}
                                >
                                    @{user.username} <ion-icon name="share-social-outline" />
                                </h5>
                                {user.isSponsor && 
                                    <p className='text-small text-gray'>Impulsor</p>
                                }
                            </div>
                        </div>
                        <div className='ulu-row-auto mt-3'>
                            <div className='ulu-col-auto text-center'>
                                <p className='bold mb-2'><Estrella color='var(--ulu-yellow)' width='12px' height='12px' className='mr-1' /> {user.rating ? user.rating : '0.0'}</p>
                                <p className='text-small text-gray'>Calificación</p>
                            </div>
                            <div className='ulu-col-auto text-center'>
                                <p className='bold mb-2'>{user.gamesNumber ? user.gamesNumber : 0}</p>
                                <p className='text-small text-gray'>Juegos</p>
                            </div>
                            <div className='ulu-col-auto text-center'>
                                <p className='bold mb-2'>{user.playersAmount ? playersAmountString : 0}</p>
                                <p className='text-small text-gray'>Jugadores</p>
                            </div>
                        </div>
                    </div>
                </div>
                {(!user.gamesNumber || user.gamesNumber === 0) ? 
                    <div className='ulu-row-auto my-auto'>
                        <div className='ulu-col-24 text-center'>
                            <h2>¡Un segundo! Estamos cargando los juegos.</h2>
                            <a href='/explorar'>Regresa a explorar para ver más juegos</a>
                        </div>
                    </div>
                    :
                    <>
                        <div className='ulu-row-auto mt-4'>
                            <div className='ulu-col-8'>
                                <SearchBox
                                    searchModifier={(s) => {
                                        setSearch(s);
                                    }}
                                    placeholder={`Buscar juegos de ${user.name}`}
                                />
                            </div>
                        </div>
                        {search === '' ?
                            <>
                                {console.log(highlightGames)}
                                {(highlightGames && highlightGames.length > 0) &&
                                <>
                                    <div className='ulu-row-auto mt-2'>
                                        <div className='ulu-col-24'>
                                            <h2>Juegos destacados</h2>
                                        </div>
                                    </div>
                                
                                    <div className='ulu-row-auto mt-2'>
                                        <div className='ulu-col-24'>
                                            <div className='one-sponsor-game-list'>
                                                {(highlightGamesForwardCount > 0 && !highlightGrid) &&
                                                    <div className='scrollable-bar-backwards vertical-center'>
                                                        <button className='scrollable-button' onClick={scrollBackwards}>
                                                            <h5 className='m-0 p-0'><i class="fas fa-chevron-left"></i></h5>
                                                        </button>
                                                    </div>
                                                }
                                                <div className="two" role='presentation' ref={highlightGamesList}>
                                                    <div className="three">
                                                        <ExploreGameList
                                                            grid={highlightGrid}
                                                            changeRender={() => setHighlightGrid(!highlightGrid)}
                                                            games={highlightGames}
                                                            setJuego={props.setJuego}
                                                            navigate={navigate}
                                                            user={props.user}
                                                            isMobile={isMobile()}
                                                            sponsor
                                                        />
                                                    </div>
                                                </div>
                                                {(highlightGames.length >= 6 && window.innerWidth > 576 && !highlightGrid) && 
                                                    <div className='scrollable-bar-forward vertical-center'>
                                                    <button className='scrollable-button' onClick={scrollForward}>
                                                        <h5 className='m-0 p-0'><i class="fas fa-chevron-right"></i></h5>
                                                    </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                {(allGames && allGames.length > 0) &&
                                <>
                                    <div className='ulu-row-auto mt-4'>
                                        <div className='ulu-col-24'>
                                            <h2>Todos los juegos</h2>
                                        </div>
                                    </div>
                                    <div className='ulu-row-auto mt-2'>
                                        <div className='ulu-col-24'>
                                            
                                                <InfiniteScroll
                                                    dataLength={allGames.length}
                                                    next={fetchGames}
                                                    hasMore={Math.ceil(nGames / limit) - currentPage !== 0}
                                                    loader={<IconsLoading />}
                                                >
                                                    <ExploreGameList
                                                        grid
                                                        games={allGames}
                                                        setJuego={props.setJuego}
                                                        navigate={navigate}
                                                        user={props.user}
                                                        isMobile={isMobile()}
                                                    />
                                                </InfiniteScroll>
                                        </div>
                                    </div>
                                </>
                                }
                            </>
                        :
                            <>
                                <div className='ulu-row-auto mt-2'>
                                    <div className='ulu-col-24'>
                                        <h2>Resultados de: {search}</h2>
                                    </div>
                                </div>
                                {searchGames &&
                                    <div className='ulu-row-auto mt-2'>
                                        <div className='ulu-col-24'>
                                            
                                                <InfiniteScroll
                                                    dataLength={searchGames.length}
                                                    next={throttle(500, fetchSearchGames)}
                                                    hasMore={Math.ceil(nGames / limit) - currentPageSearch !== 0}
                                                    loader={<IconsLoading />}
                                                >
                                                    <ExploreGameList
                                                        grid
                                                        games={searchGames}
                                                        setJuego={props.setJuego}
                                                        navigate={navigate}
                                                        user={props.user}
                                                        isMobile={isMobile()}
                                                    />
                                                </InfiniteScroll>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps, {
    setJuego
})(GameProfile);