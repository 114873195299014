import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Container, Spinner, Row, Col, Button } from "react-bootstrap";
import PreguntaTrivia from "../components/PreguntaTrivia";
import PreguntaAhorcadoCrear from "../components/ahorcado/PreguntaAhorcadoCrear";
import SidebarForm from "../components/SidebarForm";
import Dropdown from "../components/Dropdown";
import { getJuego } from "../actions/juegoActions";
import {
  createNuevaPregunta,
  setPropiedadPregunta,
  postPreguntas,
  eliminarPregunta,
  eliminarOpcion,
  reestablecerPregunta,
  reestablecerOpcion,
  editarPregunta,
  saveCurrentPregunta,
  duplicarPregunta,
  reordenarPreguntas,
} from "../actions/preguntasActions";
import {
  createNuevaOpcion,
  setPropiedadOpcion,
} from "../actions/opcionesActions";
import { createNuevoJuego, setPropiedadJuego } from "../actions/juegoActions";
import { confirm, alert } from "../actions/modalActions";
import { getJuegos } from "../actions/configuracionJuegosActions";
import { navigate } from "@reach/router";
import { getTipo, getColor, getColorName } from "../utils";

class Pregunta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      texto: undefined,
      //button: "Crear",
      respuesta: undefined,
      pregunta: undefined,
      showMenu: false,
      idJuego: "nuevo",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //this.renderVistaPrevia = this.renderVistaPrevia.bind(this);
  }

  componentDidMount() {
    onbeforeunload = (e) => "Don't leave :(";
  }

  componentWillUnmount() {
    onbeforeunload = null;
  }

  componentDidMount() {
    ReactGA.pageview("/preguntaform");
    this.props.getJuegos();
    if (this.props.idPregunta === undefined) this.props.createNuevaPregunta();
    else this.props.editarPregunta(this.props.idPregunta);
    /* if (this.props.path.includes("editar"))
      this.setState({ button: "Guardar" }); */
    if (this.props.idJuego) this.setState({ idJuego: this.props.idJuego });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.juegos !== null) {
      if (this.props.juego || this.props.tipo) {
        const tipo = this.props.juego ? this.props.juego.tipo : this.props.tipo;
        const juego = this.props.juegos.find((juego) => juego.nombre === tipo);
        if (!juego.activo) {
          return navigate("/503");
        }
      }
    }
    if (this.props.index === -1) {
      if (this.props.idJuego !== undefined) {
        return navigate(`/editar/${this.props.idJuego}`);
      } else {
        return navigate("/crear");
      }
    }
    if (prevProps.idPregunta !== this.props.idPregunta) {
      this.props.saveCurrentPregunta();
      this.props.editarPregunta(this.props.idPregunta);
      this.setState({ showMenu: false });
    }
    if (this.props.juego) {
      if (this.props.juego.tipo === "Trivia") {
        if (prevProps.pregunta !== this.props.pregunta) {
          let length = this.props.pregunta.opciones.length;
          if (length < 4) {
            let missing = 4 - length;
            new Array(missing).fill(1).forEach((one, index) => {
              this.props.createNuevaOpcion(
                this.props.idPregunta,
                length + index
              );
            });
          }
        }
      }
    }
  }

  resize() {
    let currentNav = window.innerWidth <= 760;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (event.currentTarget.checkValidity === false) {
      return event.stopPropagation();
    }
    this.setState({ validated: true });
    this.props.postPreguntas(
      this.props.preguntas,
      this.props.juego,
      this.props.idJuego,
      (idJuego) => {
        navigate(`/editar/` + this.props.juego.idJuego);
      }
    );
  }

  handleChange(event, input) {
    let { value } = event.target;
    this.props.setPropiedadPregunta(input, value);
  }

  renderCambios() {
    if (this.props.cambios)
      return <p className="text-warning mb-3">Cambios sin guardar</p>;
  }

  renderTrivia() {
    return (
      <PreguntaTrivia
        idJuego={this.props.idJuego}
        preguntas={this.props.preguntas}
        disabled={this.props.disabled}
        pregunta={this.props.pregunta}
        juego={this.props.juego}
        createNuevaPregunta={this.props.createNuevaPregunta}
        createNuevaOpcion={this.props.createNuevaOpcion}
        duplicarPregunta={this.props.duplicarPregunta}
        eliminarPregunta={this.eliminarPregunta}
        setPropiedadPregunta={this.props.setPropiedadPregunta}
        postAdjunto={this.postAdjunto}
        setPropiedadOpcion={this.props.setPropiedadOpcion}
        index={this.props.index}
        puntos={this.props.puntos}
        button={"Guardar ULU"}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        alert={this.props.alert}
        //renderVistaPrevia={this.renderVistaPrevia}
      />
    );
  }

  renderAhorcado() {
    return (
      <PreguntaAhorcadoCrear
        disabled={this.props.disabled}
        idJuego={this.props.idJuego}
        preguntas={this.props.preguntas}
        pregunta={this.props.pregunta}
        juego={this.props.juego}
        createNuevaPregunta={this.props.createNuevaPregunta}
        duplicarPregunta={this.props.duplicarPregunta}
        eliminarPregunta={this.eliminarPregunta}
        setPropiedadPregunta={this.props.setPropiedadPregunta}
        index={this.props.index}
        puntos={this.props.puntos}
        button={"Guardar"}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        alert={this.props.alert}
        //renderVistaPrevia={this.renderVistaPrevia}
      />
    );
  }

  renderCrucigrama() {
    return (
      <PreguntaAhorcadoCrear // Es el mismo componente para no repetir otra vez lo mismo
        tipo="crucigrama"
        disabled={this.props.disabled}
        idJuego={this.props.idJuego}
        preguntas={this.props.preguntas}
        pregunta={this.props.pregunta}
        juego={this.props.juego}
        createNuevaPregunta={this.props.createNuevaPregunta}
        duplicarPregunta={this.props.duplicarPregunta}
        eliminarPregunta={this.eliminarPregunta}
        setPropiedadPregunta={this.props.setPropiedadPregunta}
        index={this.props.index}
        puntos={this.props.puntos}
        button={"Guardar"}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        alert={this.props.alert}
        //renderVistaPrevia={this.renderVistaPrevia}
      />
    );
  }

  renderForm() {
    let { tipo } = this.props;
    if (tipo) tipo = tipo.toLowerCase();
    switch (tipo) {
      case "crucigrama":
        return this.renderCrucigrama();
      case "ahorcado":
        return this.renderAhorcado();
      default:
        return this.renderTrivia();
    }
  }

  renderButton() {
    // Este solo es el + de movil
    if (
      (this.props.tipo.toLowerCase() === "crucigrama" &&
        this.props.preguntas.length < 9) ||
      this.props.tipo.toLowerCase() !== "crucigrama"
    )
      return (
        <Button
          variant="outline-danger"
          className="border-red-new show-mobile-button px-2 py-2 mr-2"
          style={{
            width: 50,
            height: 50,
            color: "#FF3833",
            borderRadius: "70px",
          }}
          onClick={async () => {
            this.props.createNuevaPregunta(this.props.preguntas.length + 1);
            await navigate(
              this.props.idJuego
                ? `/editar/${this.props.idJuego}/pregunta/nueva-${
                    this.props.preguntas.length + 1
                  }`
                : `/crear/pregunta/nueva-${this.props.preguntas.length + 1}`
            );
            window.scrollTo(0, 0);
          }}
          size="lg"
        >
          <h6>
            <i className="fas fa-plus"></i>
          </h6>
        </Button>
      );
  }

  render() {
    let tipo = getTipo(this.props.juego);
    if (this.props.pregunta === undefined)
      return <Spinner animation="border" />;
    return (
      <Container className="no-bg-img h-100" fluid>
        <Row>
          <Col xs={0} md={3} xl={2} className="bg-white hide-mobile" style={{ minHeight: "100vh" }}>
            <SidebarForm
              idJuego={this.props.idJuego}
              preguntas={this.props.preguntas}
              reordenarPreguntas={this.props.reordenarPreguntas}
              juego={this.props.juego}
              createNuevaPregunta={this.props.createNuevaPregunta}
              duplicarPregunta={this.props.duplicarPregunta}
              eliminarPregunta={this.props.eliminarPregunta}
              reestablecerPregunta={this.props.reestablecerPregunta}
              tipo={this.props.tipo}
              movil={this.state.movil}
            />
          </Col>
          <Container
            className={`show-mobile px-4 py-3 pastel-${getColorName(
              tipo === "ahorcado"
                ? 4
                : tipo === "trivia"
                ? 3
                : tipo === "crucigrama"
                ? 1
                : 2
            )}`}
            fluid
            style={{
              color: getColor(
                tipo === "ahorcado"
                  ? 4
                  : tipo === "trivia"
                  ? 3
                  : tipo === "crucigrama"
                  ? 1
                  : 2
              ),
            }}
          >
            <Dropdown
              pregunta={this.props.pregunta}
              tipo={tipo}
              idJuego={this.props.idJuego}
              preguntas={this.props.preguntas}
              createNuevaPregunta={this.props.createNuevaPregunta}
              duplicarPregunta={this.props.duplicarPregunta}
              eliminarPregunta={this.props.eliminarPregunta}
              reestablecerPregunta={this.props.reestablecerPregunta}
            />
          </Container>
          <Col sm={12} md={9} xl={10} className="pl-1">
            <Container fluid id="pregunta-container">
              {this.renderForm()}
            </Container>
          </Col>
        </Row>
        <div className='fixed-bottom-right'>
          {this.renderButton()}
          <Button
            size="lg"
            variant="danger"
            className="new-red-button px-3 py-3"
            onClick={this.handleSubmit}
            disabled={this.props.disabled}
          >
            <h6>Guardar</h6>
          </Button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  juego: state.juego.juego,
  tipo: state.juego.tipo,
  preguntas: state.juego.preguntas,
  edited: state.juego.pregunta,
  cambios: state.juego.cambios,
  pregunta: state.juego.pregunta,
  index: state.juego.index,
  respuesta: state.juego.respuesta,
  disabled: state.juego.disabled,
  juegos: state.configuracion.juegos,
});

export default connect(mapStateToProps, {
  setPropiedadPregunta,
  reestablecerPregunta,
  createNuevaPregunta,
  saveCurrentPregunta,
  setPropiedadOpcion,
  reestablecerOpcion,
  setPropiedadJuego,
  createNuevaOpcion,
  eliminarPregunta,
  createNuevoJuego,
  duplicarPregunta,
  eliminarOpcion,
  editarPregunta,
  postPreguntas,
  getJuego,
  getJuegos,
  confirm,
  alert,
  reordenarPreguntas,
})(Pregunta);
