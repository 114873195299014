import ReportesService from "../services/ReportesService";
import { REPORTES_RECIBIDOS } from "./types";
import { displayError } from "../utils";

export const getReportes = (idJuego) => (dispatch) => {
  ReportesService.getReportes(idJuego)
    .then((res) => {
      const reportes = res.data;
      dispatch({ type: REPORTES_RECIBIDOS, payload: reportes });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          return dispatch({ type: REPORTES_RECIBIDOS, payload: [] });
        }
      }
      displayError(dispatch, error);
    });
};

export const downloadReporte = (idJuego, idSesion, tipo) => (dispatch) => {
  ReportesService.downloadReporte(idJuego, idSesion, tipo)
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PlayULU-reporte-${tipo}-${idSesion}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};
