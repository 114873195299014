import React, { Component } from "react";
import SponsorBubble from './SponsorBubble';

class SponsorBubbleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grid: false,
        }
    }

    render() {
        return(
            <ul className={this.props.grid ? 'sponsor-bubble-grid' : 'sponsor-bubble-list'} >
                {this.props.sponsors.map((sponsor, i) => (
                    <SponsorBubble sponsor={sponsor} key={i} />
                ))}
                {((this.props.sponsors.length > 5 && window.innerWidth <= 576) || this.props.sponsors.length >= 16)  && 
                    <li 
                        className='sponsor-bubble'
                        onClick={this.props.changeRender}
                    >
                        <div className='sponsor-bubble-img more-information-explore vertical-center'>
                            <h5 className='mb-0'>{this.props.grid ? '-' : '+'}</h5>   
                        </div> 
                        <p className='sponsor-bubble-title text-smaller'>{this.props.grid ? 'Ver menos' : 'Ver más'}</p>
                    </li>
                }
            </ul>
        );
    }
}

export default SponsorBubbleList;
