import api from "./api";

const route = "/reportes";

export default {
  getReportes: (idJuego) => api.get(`${route}/${idJuego}`),
  downloadReporte: (idJuego, idSesion, tipo) =>
    api.get(`${route}/${idJuego}/sesion/${idSesion}?tipo=${tipo}`, {
      responseType: "blob",
    }),
};
