import React, { Component } from 'react';
import ExploreGameCard from './ExploreGameCard';

class ExploreGameList extends Component {

    render() {  
        return (
            <ul className={this.props.grid ? 'sponsor-game-grid' : 'sponsor-game-list'}>
                {console.log(this.props.games)}
                {this.props.games?.map((game, i) => (
                    <ExploreGameCard
                        game={game}
                        key={`exploreGameCard-${game.idJuego}`}
                        setJuego={this.props.setJuego}
                        navigate={this.props.navigate}
                        user={this.props.user}
                        isMobile={this.props.isMobile}
                    />
                ))}
                {(this.props.games.length > 5 && this.props.sponsor) && 
                    <li 
                        className={`sponsor-game-card more-information-explore vertical-center text-center`}
                        onClick={this.props.changeRender}
                    >
                        <h1>{this.props.grid ? 'Ver menos' : 'Ver más'}</h1>
                    </li>
                }
            </ul> 
        );
    }
}

export default ExploreGameList;