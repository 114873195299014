import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import EscuelasForm from "./EscuelasForm";
import InputULU from "../global/InputULU";
import RadioULU from "../global/RadioULU";
import SwitchULU from "../global/SwitchULU";
import Dropzone from "../Dropzone";
import { sexos, roles } from "../../utils";
import InfoCard from "../../components/InfoCard";
import moment from "moment";

export default function ({
  user,
  alert,
  setPropiedadUser,
  escuelas,
  niveles,
  deleteUser,
  base_url,
  queryEstado,
  queryMunicipio,
  queryNivel,
  queryEscuela,
  showEscuelas,
  handleChangeEscuela,
  estados,
  municipios,
  nivel,
  toggleEscuelas,
  handleChangeEmpresa,
  escuelaManual,
  nombreEscuelaManual,
  enableEscuelaManual
}) {
  let {
    idAdjunto,
    nombre,
    correo,
    alias,
    sexo,
    rol,
    file,
    nombre_escuela,
    fechaNacimiento,
    busquedaActivado,
    nombreEmpresa
  } = user;

  fechaNacimiento = moment.utc(fechaNacimiento).format("YYYY-MM-DD");

  if (nombre === null) nombre = "";
  if (alias === null) alias = "";
  return (
    <>
      <Row>
        <Col md={12}>
          <Row>
            <Col>
              <p className="m-0 h3">Información personal</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row className="pt-3">
                <Col>
                  <InputULU
                    label="Nombre y Apellido"
                    maxLength={70}
                    value={nombre}
                    onChange={(event) =>
                      setPropiedadUser("nombre", event.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <InputULU
                    label="Correo Electrónico"
                    maxLength={70}
                    value={correo}
                    onChange={(event) =>
                      setPropiedadUser("correo", event.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="pt-3">
                <Col>
                  <InputULU
                    label="Usuario"
                    type="text"
                    name="apodo"
                    maxLength={70}
                    value={alias}
                    onChange={(event) =>
                      setPropiedadUser("alias", event.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <InfoCard
                texts={[
                  {
                    title: "Nombre:",
                    message:
                      "Este es el nombre que aparecerá cuando el creador de un ULU descargue el reporte del juego.",
                  },
                  {
                    title: "Correo electrónico",
                    message:
                      "¡Aquí te enviaremos todos los premios y puntos que vayas acumulando gracias a los patrocinadores!",
                  },
                  {
                    title: "Usuario",
                    message: `Con este nombre te identificarán los demás usuarios en el lobby del juego.`, //Nota: Mensaje original tenía también: Por default tomamos el texto antes del "@" de tu correo. \n Antes de cada juego te preguntaremos si deseas usar tu usuario o mostrarte con otro apodo (el apodo solo existe para ese juego, una vez termines ese apodo desaparece).
                  },
                ]}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <p className="m-0 h5">Foto de perfil</p>
            </Col>
          </Row>
          <Row
            className={idAdjunto !== null || file !== undefined ? "mb-5" : ""}
          >
            <Col md={6}>
              <Dropzone
                idAdjunto={idAdjunto}
                base_url={base_url}
                file={file}
                modifier={setPropiedadUser}
                removeImage={() => setPropiedadUser("file")}
                alert={alert}
                args="file"
                noImgHeight
              />
            </Col>
          </Row>
          <RadioULU
            property="sexo"
            label="Género"
            selected={sexo}
            options={sexos}
            modifier={setPropiedadUser}
          />
          <Row className="pt-3">
            <Col>
              <p className="m-0 h5">Fecha de nacimiento</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InputULU
                value={fechaNacimiento}
                type="date"
                onChange={(e) =>
                  setPropiedadUser("fechaNacimiento", e.target.value)
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <p className="m-0 h3">Escolaridad</p>
        </Col>
      </Row>
      <RadioULU
        property="rol"
        label="Profesión"
        options={roles}
        modifier={setPropiedadUser}
        selected={rol}
      />
      <EscuelasForm
        escuela={nombre_escuela}
        escuelas={escuelas}
        niveles={niveles}
        user={user}
        modifier={(key, value) => {
          setPropiedadUser(key, value);
          if (key === "nombreEscuelaManual") {
            setPropiedadUser("nombre_escuela", value);
          }
          if (key === "nombre_escuela") {
            enableEscuelaManual(false);
            handleChangeEscuela("escuela", { target: { value } }, false);
          }
          if (key === "escuelaManual") {
            handleChangeEscuela("escuelaManual", { target: { value } }, false);
          }
        }}
        queryEstado={queryEstado}
        queryMunicipio={queryMunicipio}
        queryNivel={queryNivel}
        queryEscuela={queryEscuela}
        setPropiedadUser={setPropiedadUser}
        showEscuelas={showEscuelas}
        changeQuery={handleChangeEscuela}
        handleChangeEmpresa={handleChangeEmpresa}
        estados={estados}
        municipios={municipios}
        nivel={nivel}
        escuelaManual={escuelaManual}
        nombreEscuelaManual={nombreEscuelaManual}
        nombreEmpresa={nombreEmpresa}
      />
      <Row className="pt-4">
        <Col>
          <p className="m-0 h3">Búsqueda</p>
        </Col>
      </Row>
      <SwitchULU
        label={'¿Permitir que otros usuarios me encuentren en "Explorar"?'}
        args="busquedaActivado"
        value={busquedaActivado}
        modifier={setPropiedadUser}
        checkClassName="custom-control-lg"
      />
      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <Col>
              <p className="m-0 h3" style={{ color: "#ff3833" }}>
                <b>Borrar cuenta</b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="outline-danger"
                className="border-red-new px-4 py-2"
                onClick={deleteUser}
                size="lg"
              >
                <h6 className="m-0 py-2">
                  <i className="far fa-trash-alt pr-2"></i>
                  <span>Borrar</span>
                </h6>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <InfoCard
            warning
            texts={[
              {
                title: "Advertencia",
                message:
                  "Si borras tu cuenta podrás seguir jugando los ULUs que te compartan, pero no podrás tener acceso a tus juegos ni podrás ver juegos creados por otros usuarios.",
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
