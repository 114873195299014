import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import ReduxThunk from "redux-thunk";
import Main from "./Main";
import ModalConfirm from "./components/common/ModalConfirm";
import ErrorAlert from "./components/common/ErrorAlert";
import SuccessAlert from "./components/common/SuccessAlert";
import axios from 'axios';
import { ToastContainer } from "react-toastify";
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(ReduxThunk))
);

function App() {
  axios.defaults.withCredentials = true;
  
  return (
    <Provider store={store}>
      <Main path="/*" />
      <ModalConfirm />
      <ErrorAlert />
      <SuccessAlert />
      <ToastContainer />
    </Provider>
  );
}

export default App;
