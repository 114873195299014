import api from "./api";

const route = "/jugar";

export default {
  hostJuego: idJuego => api.post(route, { idJuego }),
  finishJuego: (idSesion, tipo) => api.post(`${route}/finish`, { idSesion, tipo }),
  enviarSugerencias: ({ 
    calificacion,
    recomendacion,
    sugerencias,
    idSesion,
    apodo 
  }) => api.post(`${route}/sugerencias`, {
      calificacion,
      recomendacion,
      sugerencias,
      idSesion,
      apodo
    })
};
