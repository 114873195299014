import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderHost from "../../../components/HeaderHost";
import { BASE_URL } from "../../../utils";

class HostPregunta extends Component {
  componentDidMount() {
    let segundos = 5;
    this.props.setCounter(segundos, this.props.displayOpciones);
  }

  renderPatrocinador() {
    if (
      this.props.patrocinador !== null &&
      this.props.patrocinador.nombre &&
      this.props.patrocinador.nombre
    ) {
      const src =
        this.props.patrocinador.idAdjunto !== null
          ? `${BASE_URL}/adjuntos/${this.props.patrocinador.idAdjunto}`
          : null;
      return (
        <Row className="justify-content-center my-3 mx-5 px-5">
          <Container className="text-center px-5">
            <h6 className='mb-2'>ULU impulsado por</h6>
            {src !== null && (
              <Image src={src} className="logo-width" />
            )}
            <h6 className='mt-2'>{this.props.patrocinador.nombre}</h6>
          </Container>
        </Row>
      );
    }
  }

  renderPregunta() {
    if (this.props.pregunta !== null) return this.props.pregunta.texto;
  }

  render() {
    return (
      <Container className="mx-0 solucion-width">
        <HeaderHost
          tipo={this.props.juego.tipo}
          titulo={this.props.juego.titulo}
          respuestas={this.props.respuestas}
          tiempo={this.props.tiempo}
          hideClock={this.props.hideClock}
        />
        <div className='fondo-gris-full vertical-center'>
          <Row className="mx-auto text-center justify-content-center align-items-center fixed-pregunta">
            <h1>{this.renderPregunta()}</h1>
            <div className="animated-bar blue"></div>
          </Row>
          <Row className="justify-content-center">
            {this.renderPatrocinador()}
          </Row>
        </div>
      </Container>
    );
  }
}

export default HostPregunta;
