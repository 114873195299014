import React from "react";
import {
  SHOW_ALERT,
  CLEAR_ALERT,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
} from "../actions/types";
import {
  Asterisco,
  Circulo,
  Estrella,
  Hexagono,
  Pin,
  Rayas,
  Rectangulo,
} from "../assets/icons.js";
import { navigate } from "@reach/router";
import { use100vh } from 'react-div-100vh';
import userRed from "../assets/usuario-rojo.png";
import userYellow from "../assets/usuario-amarillo.png";
import userBlue from "../assets/usuario-azul.png";
import userPurple from "../assets/usuario-morado.png";
import userOrange from "../assets/usuario-naranja.png";
import userGreen from "../assets/usuario-verde.png";

export const colors = ["var(--ulu-red)", "var(--crossword)", "var(--ulu-yellow)", "var(--trivia)", "var(--hangman)"];

export const colorName = ["red", "green", "yellow", "purple", "blue"];

export const userImgs = [userRed, userBlue, userGreen, userOrange, userPurple, userYellow];

//export const BASE_URL = "http://playulu.com/api";

export const BASE_URL = "/api";
export const BASE_URL_S3_BUCKET = "https://playulu-bucket.s3.us-east-2.amazonaws.com/";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className="black-text custom-toggle"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <i className="fas fa-angle-down pl-1"></i>
  </span>
));

export const options = [
  { value: "idJuego-desc", name: "Más reciente" },
  { value: "titulo-asc", name: "Nombre (A-Z)" },
  { value: "titulo-desc", name: "Nombre (Z-A)" },
  { value: "tipo-asc", name: "Tipo (A-Z)" },
  { value: "tipo-desc", name: "Tipo (Z-A)" },
];

export const tipos = [
  {
    title: "Todos",
    slug: null,
    filter: null,
    color: "#FF3833",
    icon: (props) => <Circulo color="#FF3833" {...props} />,
  },
  {
    title: "Favoritos",
    slug: "favoritos",
    color: "#FFD833",
    icon: (props) => <Estrella color="#FFD833" {...props} />,
  },
  {
    title: "Ahorcado",
    slug: "ahorcado",
    color: "#1DA1F3",
    icon: (props) => <Asterisco color="var(--hangman)" {...props} />,
  },
  {
    title: "Trivia",
    slug: "trivia",
    color: "#7E2CFF",
    icon: (props) => <Rayas color="#7E2CFF" {...props} />,
  },
  {
    title: "Crucigrama",
    slug: "crucigrama",
    color: "#26DD8E",
    icon: (props) => <Rectangulo color="#26DD8E" {...props} />,
  },
];

export const formatPatrocinador = (patrocinador) => ({
  ...patrocinador,
  segmentos: [
    {
      nombre: "Primaria",
      porcentaje: parseInt(patrocinador.primaria),
    },
    {
      nombre: "Secundaria",
      porcentaje: parseInt(patrocinador.secundaria),
    },
    {
      nombre: "Preparatoria",
      porcentaje: parseInt(patrocinador.preparatoria),
    },
    {
      nombre: "Universidad",
      porcentaje: parseInt(patrocinador.universidad),
    },
    {
      nombre: "Profesional",
      porcentaje: parseInt(patrocinador.profesional),
    },
  ],
});

export const getTipo = (juego) => {
  let tipo = "";
  if (juego !== null && juego) tipo = juego.tipo;
  tipo = tipo.toLowerCase();
  return tipo;
};

export const displayError = (dispatch, error) => {
  if (typeof error === "object") {
    if (error.response) {
      if (error.response.status === 401) {
        //navigate("/401");TODO HANDLE ACCESS TO ZONAS RESTRINGIDAS
      }
      if (error.response.status === 404) {
        navigate("/404");
      }
      if (error.response.status === 409) {
        error = error.response.data;
      }
    }
    error = error.toString();
  }
  dispatch({ type: SHOW_ALERT, payload: error });
  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 3000);
};

export const displaySuccess = (dispatch, message) => {
  dispatch({ type: SHOW_SUCCESS, payload: message });
  setTimeout(() => dispatch({ type: CLEAR_SUCCESS }), 3000);
};

export const duplicarPreguntas = (preguntas) => {
  return preguntas.map((pregunta) => ({
    ...pregunta,
    idPregunta: `nueva-${pregunta.idPregunta}`,
  }));
};

export const eliminarPreguntasNuevas = (preguntas) => {
  preguntas = preguntas.map((pregunta) => {
    let { idPregunta } = pregunta;
    if (String(idPregunta)[0] === "d") {
      if (String(idPregunta).includes("nueva")) return null;
    }
    return pregunta;
  });
  return preguntas.filter((pregunta) => pregunta !== null);
};

export const procesarPreguntasOpciones = (preguntas, opciones) => {
  return preguntas
    .filter(
      (pregunta) => String(pregunta.idPregunta).substring(0, 8) !== "delete-n"
    )
    .map((pregunta) => {
      let options = opciones.filter(
        (opcion) => String(opcion.idPregunta) === String(pregunta.idPregunta)
      );
      options.forEach((opcion) => {
        if (opcion.texto === "") opcion.idOpcion = `delete-${opcion.idOpcion}`;
      });
      return { ...pregunta, opciones: options };
    });
};

export const procesarOpcionesTrivia = (opciones) => {
  return opciones.map((opcion) => ({
    ...opcion,
    idPregunta: `nueva-${opcion.idPregunta}`,
    idOpcion: `nueva-${opcion.idOpcion}`,
  }));
};

export const procesarOpcionesJuegos = (preguntas) => {
  return preguntas.map((pregunta) => ({
    idOpcion: `nueva-${pregunta.idOpcion}`,
    texto: pregunta.respuesta,
    idPregunta: pregunta.idPregunta,
  }));
};

export const eliminarOpcionesVacias = (opciones) => {
  opciones = opciones.map((opcion) => {
    if (
      opcion.texto === "" &&
      isNaN(opcion.idOpcion) &&
      String(opcion.idOpcion)[0] !== "n"
    )
      return null;
    if (opcion.texto === "" && String(opcion.idOpcion)[0] === "n") return null;
    if (opcion.texto === "")
      opcion = { ...opcion, idOpcion: `delete-${opcion.idOpcion}` };
    return opcion;
  });
  return opciones.filter((opcion) => opcion !== null);
};

export const searchPatrocinadoresCards = (query, patrocinadores) => {
  return patrocinadores.filter((e) => e.nombre.includes(query));
};

export const searchRows = (query, rows) => {
  if (!rows) return;
  if (isNaN(query)) query = query.toLowerCase();
  let searchResult = rows.filter((row) => {
    let result = Object.keys(row).filter((column) => {
      if (Array.isArray(row[column])) {
        return row[column].filter((subcolumn) => {
          if (isNaN(subcolumn)) {
            if (subcolumn.toLowerCase().includes(query)) return row;
          } else if (subcolumn === query) return row;
          return null;
        });
      }
      if (isNaN(row[column])) {
        if (row[column].toLowerCase().includes(query)) {
          return row;
        }
      } else if (row[column] === query) {
        return row;
      } else if (row[column] === Number(query)) {
        return row;
      }
      return null;
    });
    return result.length > 0;
  });
  return searchResult;
};

export const getIconPos = (index, top, left) => {
  let icon = getIcon(index);
  icon.props.style.left = left;
  icon.props.style.top = top;
  return icon;
};

export const getIcon = (index, props) => {
  if (props === undefined) {
    props = {};
  }
  switch (index) {
    case 0:
      return (
        <Estrella
          color={props.color ? props.color : colors[0]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 1:
      return (
        <Rectangulo
          color={props.color ? props.color : colors[1]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 2:
      return (
        <Hexagono
          color={props.color ? props.color : colors[2]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 3:
      return (
        <Rayas
          color={props.color ? props.color : colors[3]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 4:
      return (
        <Pin
          color={props.color ? props.color : colors[4]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 5:
      return (
        <Asterisco
          color={props.color ? props.color : colors[0]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 6:
      return (
        <Circulo
          color={props.color ? props.color : colors[1]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 7:
      return (
        <Estrella
          color={props.color ? props.color : colors[2]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 8:
      return (
        <Rectangulo
          color={props.color ? props.color : colors[3]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 9:
      return (
        <Hexagono
          color={props.color ? props.color : colors[4]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 10:
      return (
        <Rayas
          color={props.color ? props.color : colors[0]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 11:
      return (
        <Pin
          color={props.color ? props.color : colors[1]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 12:
      return (
        <Asterisco
          color={props.color ? props.color : colors[2]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 13:
      return (
        <Circulo
          color={props.color ? props.color : colors[3]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 14:
      return (
        <Estrella
          color={props.color ? props.color : colors[4]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 15:
      return (
        <Rectangulo
          color={props.color ? props.color : colors[0]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 16:
      return (
        <Hexagono
          color={props.color ? props.color : colors[1]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 17:
      return (
        <Rayas
          color={props.color ? props.color : colors[2]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    case 18:
      return (
        <Pin
          color={props.color ? props.color : colors[3]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
    default:
      return (
        <Asterisco
          color={props.color ? props.color : colors[4]}
          className={props.newclass ? props.newclass : "mb-2"}
          {...props}
        />
      );
  }
};

export const getColor = (index) => {
  if (index === 0 || index === 5 || index === 10 || index === 15)
    return colors[0];
  if (index === 1 || index === 6 || index === 11 || index === 16)
    return colors[1];
  if (index === 2 || index === 7 || index === 12 || index === 17)
    return colors[2];
  if (index === 3 || index === 8 || index === 13 || index === 18)
    return colors[3];
  return colors[4];
};

export const getColorName = (index) => {
  if (index === 0) return colorName[0];
  if (index === 1) return colorName[1];
  if (index === 2) return colorName[2];
  if (index === 3) return colorName[3];
  return colorName[4];
};

export const convert = (picture, tipo) => {
  const base64 = btoa(
    new Uint8Array(picture).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  return `data:${tipo ? tipo : "image/jpg"};base64,${base64}`;
};

export const procesarCodigos = (codigos) => {
  const nuevos = codigos.filter((codigo) => String(codigo.idCodigo)[0] === "n");
  const eliminar = codigos
    .filter((codigo) => String(codigo.idCodigo)[0] === "d")
    .map((codigo) => ({
      ...codigo,
      idCodigo: parseInt(String(codigo.idCodigo).substring(7)),
    }));
  const editar = codigos.filter((codigo) => !isNaN(codigo.idCodigo));
  return { nuevos, editar, eliminar };
};

export const sexos = [
  { name: "Hombre", value: "hombre" },
  { name: "Mujer", value: "mujer" },
  { name: "Otro", value: "otro" },
];

export const roles = [
  {
    name: "Alumno",
    value: "alumno",
  },
  {
    name: "Maestro",
    value: "maestro",
  },
  { 
    name: "Profesional",
    value: "profesional" 
  }
];

export const validarUsuario = (usuario) => {
  if (usuario.correo === "" || !validarCorreo(usuario.correo))
    return "Por favor, ingresa un correo electrónico válido";
};

export const procesarUsuario = (usuario) => {
  if (usuario.nombre === "") usuario.nombre = null;
  if (usuario.usuario === "") usuario.usuario = null;
  if (usuario.sexo === "") usuario.sexo = null;
  if (usuario.alias === "") usuario.alias = null;
  return usuario;
};

export const validarCorreo = (correo) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(correo);
};

export const agregarSegmentos = (patrocinador, segmentos) => {
  const segmentosPatrocinador = {};
  segmentos.forEach((segmento) => {
    let { nombre, porcentaje } = segmento;
    nombre = String(nombre).toLowerCase();
    segmentosPatrocinador[nombre] = porcentaje;
  });
  patrocinador = { ...patrocinador, ...segmentosPatrocinador };
  return patrocinador;
};

export const procesarFrases = (frases) => {
  const nuevos = frases.filter((frase) => String(frase.idFrase)[0] === "n");
  let eliminar = frases.filter((frase) => String(frase.idFrase)[0] === "d");
  eliminar = eliminar.map((frase) => ({
    ...frase,
    idFrase: String(frase.idFrase).substring(7),
  }));
  const editar = frases.filter((frase) => !isNaN(frase.idFrase));
  return { nuevos, editar, eliminar };
};

export const procesarLogos = (logos) => {
  const nuevos = logos.filter((logo) => String(logo.idAdjunto)[0] === "n");
  let eliminar = logos.filter((logo) => String(logo.idAdjunto)[0] === "d");
  eliminar = eliminar.map((logo) => ({
    ...logo,
    idAdjunto: String(logo.idAdjunto).substring(7),
  }));
  const editar = logos.filter((logo) => !isNaN(logo.idAdjunto));
  return { nuevos, editar, eliminar };
};

export const validarSegmentos = (patrocinador, disponibles, modifier) => {
  let valid = true;
  Object.keys(disponibles).forEach((segmento) => {
    if (patrocinador[segmento] > disponibles[segmento]) valid = false;
    if (patrocinador[segmento] === "") modifier(segmento, 0);
    if (isNaN(patrocinador[segmento])) valid = false;
  });
  return valid;
};

export const niveles = [
  "Preescolar",
  "Primaria",
  "Secundaria",
  "Media Superior",
  "Superior",
].map((nivel) => ({ name: nivel, value: nivel }));

export const isMobile = () => {
  let mobile = false;
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    mobile = true; 
  return mobile;
}

export const errorMessageSoloTrivia = {
  "connection": "¡Ups! Parece que hubo un problema al conectarte",
  "nextPregunta": "¡Ups! Parece que hubo un problema al obtener la siguiente pregunta",
  "respuesta": "¡Ups! Parece que hubo un problema al registrar tu respuesta",
  "apodo": "¡Ups! Parece que hubo un problema al registrar tu apodo",
  "terminarJuego":  "¡Ups! Parece que hubo un problema al terminar el juego"
}

export const errorMessageSoloAhorcado = {
  "connection": "¡Ups! Parece que hubo un problema al conectarte",
  "nextPregunta": "¡Ups! Parece que hubo un problema al obtener la siguiente pregunta",
  "respuesta": "¡Ups! Parece que hubo un problema al registrar tu respuesta",
  "apodo": "¡Ups! Parece que hubo un problema al registrar tu apodo",
  "terminarJuego":  "¡Ups! Parece que hubo un problema al terminar el juego",
  "terminarPregunta": "¡Ups! Parece que hubo un problema al terminar la pregunta",
}

export const errorMessageSoloCrucigrama = {
  "connection": "¡Ups! Parece que hubo un problema al conectarte",
  "respuesta": "¡Ups! Parece que hubo un problema al registrar tu respuesta",
  "apodo": "¡Ups! Parece que hubo un problema al registrar tu apodo",
  "terminarJuego":  "¡Ups! Parece que hubo un problema al terminar el juego",
}

export const setCharAt = (str,index,chr) => {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}

export const useRealHeight = () => {
  let realHeight = use100vh();
  return realHeight;
}

export const selectCreatorImg = (user) => {
  let creatorImg;
  creatorImg = userImgs[user?.idPerfil % 6];
  return creatorImg;

}

export const playerCount = (playerRawAmount)=>{
  //Returns anything above 1K players or 1M players shortened.
  if(playerRawAmount >= 1000 && playerRawAmount <1000000){
    playerRawAmount /= 1000;
    playerRawAmount = playerRawAmount.toFixed(1) + 'K';
  }else if(playerRawAmount >=1000000){
    playerRawAmount /= 1000000;
    playerRawAmount = playerRawAmount.toFixed(1) + 'M';
  }
  
  return playerRawAmount
}