import {
  PREGUNTAS_CRUCIGRAMA_RECIBIDAS,
  RESPUESTA_CRUCIGRAMA_RECIBIDA,
  CLEAR_JUEGO,
} from "../actions/types";

const INITIAL_STATE = {
  preguntas: null,
  respuestas: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case PREGUNTAS_CRUCIGRAMA_RECIBIDAS:
      return { ...state, preguntas: payload };
    case RESPUESTA_CRUCIGRAMA_RECIBIDA:
      const { respuestas } = state;
      const { idRespuesta, puntos } = payload;
      respuestas.push({...payload, idRespuesta: parseInt(payload.idRespuesta) });
      if (puntos > 0) {
        let { preguntas } = state;
        let pregunta = preguntas.find(
          (pregunta) => parseInt(pregunta.idRespuesta) === parseInt(idRespuesta)
        );
        if (pregunta) {
          if (pregunta.respuestas) pregunta.respuestas++;
          else pregunta.respuestas = 1;
        }
        return { ...state, preguntas, respuestas };
      }
      return { ...state, respuestas };
    case CLEAR_JUEGO:
      return {
        preguntas: null,
        respuestas: [],
      };
    default:
      return { ...state };
  }
};
