import {
  SET_APODO,
  PIN_RECIBIDO,
  INICIAR_JUEGO,
  PREGUNTA_RECIBIDA,
  FEEDBACK_RECIBIDO,
  NEXT,
  JUGADOR_RECIBIDO,
  OPCIONES_RECIBIDAS,
  RESPUESTA_RECIBIDA,
  SCOREBOARD_RECIBIDO,
  SET_COUNTER,
  SET_SCORE,
  SET_HORA_SERVER,
  SET_ID_JUEGO,
  SET_ID_SESION,
  CLEAR_JUEGO,
  SET_DATOS_JUEGO,
  SET_LUGAR,
  CLEAR_CONTESTADAS,
  RESPUESTA_CRUCIGRAMA_RECIBIDA,
  SET_PATROCINADOR_JUEGO,
  DISABLE_REPORTE,
  ENABLE_REPORTE,
  FINISHED_JUEGO,
} from "../actions/types";

const INITIAL_STATE = {
  idJuego: null,
  idSesion: null,
  pin: null,
  apodo: null,
  //Flags
  iniciado: false,
  next: false,
  //Juego
  juego: null,
  opciones: null,
  pregunta: null,
  numPregunta: 0,
  totalPreguntas: 0,
  segundos: 0,
  puntos: 0,
  acumulado: 0,
  horaServer: null,
  jugadores: [],
  //Respuestas
  scoreboard: [],
  respuestas: [],
  //Patrocinador
  patrocinador: null,
  reporte: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ID_JUEGO:
      return { ...state, idJuego: action.payload };
    case SET_APODO:
      const apodo = action.payload;
      return { ...state, apodo };
    case PIN_RECIBIDO:
      return { ...state, ...action.payload };
    case INICIAR_JUEGO:
      return { ...state, iniciado: true };
    case PREGUNTA_RECIBIDA:
      const pregunta = action.payload;
      return {
        ...state,
        pregunta,
        next: false,
        numPregunta: state.numPregunta + 1,
        respuestas: [],
        mensaje: "Prepárate",
      };
    case OPCIONES_RECIBIDAS:
      return { ...state, opciones: action.payload };
    case FEEDBACK_RECIBIDO:
      const { puntos, mensaje } = action.payload;
      const suma =
        state.acumulado && state.acumulado !== null
          ? parseInt(state.acumulado) + parseInt(puntos)
          : puntos;
      return { ...state, acumulado: suma, puntos, mensaje };
    case NEXT:
      return { ...state, next: true };
    case JUGADOR_RECIBIDO:
      return { ...state, jugadores: [...state.jugadores, action.payload] };
    case RESPUESTA_RECIBIDA:
      let { respuestas } = state;
      if (!respuestas) respuestas = [];
      respuestas.push(action.payload);
      return { ...state, respuestas };
    case SCOREBOARD_RECIBIDO:
      return { ...state, scoreboard: action.payload };
    case SET_COUNTER:
      return { ...state, segundos: action.payload };
    case SET_SCORE:
      return { ...state, puntos: action.payload };
    case SET_HORA_SERVER:
      return { ...state, horaServer: action.payload };
    case SET_ID_SESION:
      return { ...state, idSesion: action.payload };
    case CLEAR_JUEGO:
      return { ...INITIAL_STATE };
    case SET_DATOS_JUEGO:
      return { ...state, juego: action.payload };
    case SET_LUGAR:
      return { ...state, ...action.payload };
    case CLEAR_CONTESTADAS:
      return { ...state, puntos: 0 };
    case RESPUESTA_CRUCIGRAMA_RECIBIDA:
      return {
        ...state,
        puntos: action.payload.puntos,
        acumulado: state.acumulado + action.payload.puntos,
      };
    case SET_PATROCINADOR_JUEGO:
      return { ...state, patrocinador: action.payload };
    case DISABLE_REPORTE:
      return { ...state, reporte: false };
    case ENABLE_REPORTE:
      return { ...state, reporte: true };
    case FINISHED_JUEGO:
      return { ...state, finished: true };
    default:
      return { ...state };
  }
};
