import { navigate } from "@reach/router";
import AdminService from "../services/AdminService";
import {
    IS_ADMIN,
} from "./types";

export const isAdmin = () => dispatch => {
    AdminService.isAdmin().then(res => {
    dispatch({
        type: IS_ADMIN,
        payload: true,
    });
    }).catch(error => {
        dispatch({
            type: IS_ADMIN,
            payload: false,
        });
        navigate("/401", { replace: true });
    });
};