import React from "react";
import ReactGA from "react-ga";
import { Navbar, Nav, Image, Col, Button } from "react-bootstrap";
import logo from "../assets/logo.png";
import logoCaraBlanca from "../assets/logo-cara-blanca.png";
import { navigate } from "@reach/router";
import { BASE_URL } from "../utils";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movil: false,
      navExpanded: false,
    };
    this.resize = this.resize.bind(this);
    this.setNavExpanded = this.setNavExpanded.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let currentNav = window.innerWidth <= 760;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  async handleClickJuegosPatrocinadores() {
    if (this.props.location && this.props.location.pathname.includes("admin")) {
      await navigate("/admin/patrocinadores");
    } else {
      await navigate("/misjuegos");
      ReactGA.event({
        category: "Mis Juegos",
        action: "Click en Mis Juegos",
        label: "Click en Mis Juegos",
      });
    }
    document.documentElement.scrollTop = 0;
  }

  async handleClickExplorarConfiguracion() {
    if (this.props.location && this.props.location.pathname.includes("admin")) {
      await navigate("/admin/configuracion");
    } else {
      await navigate("/explorar");
      ReactGA.event({
        category: "Explorar",
        action: "Click en Explorar",
        label: "Click en Explorar",
      });
    }
    document.documentElement.scrollTop = 0;
  }

  async handleClickLogo() {
    await navigate("/explorar");
    document.documentElement.scrollTop = 0;
    ReactGA.event({
      category: "Logo",
      action: "Click en Logo",
      label: "Click en Logo",
    });
  }

  renderTabs() {
    if (!this.props.spinner && this.props.user !== null)
      return (
        <Nav className={!this.state.movil && 'd-inline-block'}>
          {(this.props.location && this.props.location.pathname.includes("admin")) ?
            <Nav.Link
              onClick={() => {this.handleClickExplorarConfiguracion(); this.closeNav();}}
              className='d-inline-block menu-link my-2 my-md-0'
              active={(this.props.location && this.props.location.pathname.includes("/admin/configuracion"))}
            >
              <i className='fas fa-cog'></i>
              <span className="ml-2 mr-3"><b>Configuración de juegos</b></span>
            </Nav.Link>
            :
            <Nav.Link
              onClick={() => {this.handleClickExplorarConfiguracion(); this.closeNav();}}
              className='d-inline-block menu-link'
              active={(this.props.location && this.props.location.pathname.includes("explorar"))}
            >
              <i className='fas fa-map-signs fa-rotate--15'></i>
              <span className="ml-2 mr-3"><b>Explorar</b></span>
            </Nav.Link>
          }
          <Nav.Link
            onClick={() => {this.handleClickJuegosPatrocinadores(); this.closeNav();}}
            className="d-inline-block menu-link"
            active={(this.props.location && this.props.location.pathname.includes("misjuegos")) || (this.props.location && this.props.location.pathname.includes("patrocinadores"))}
          >
            <i
              className={`fas ${
                this.props.location &&
                this.props.location.pathname.includes("admin")
                  ? "fa-money-bill-wave"
                  : "fa-dice"
              }`}
            ></i>
            <span className="ml-2 link-navbar">
              {this.props.location &&
              this.props.location.pathname.includes("admin")
                ? <b>Impulsores</b>
                : <b>Mis Juegos</b>}
            </span>
          </Nav.Link>
        </Nav>
      );
  }

  renderProfile() {
    if (this.props.user !== null && this.props.user) {
      if (this.props.user.idAdjunto !== null) {
        const src = `${BASE_URL}/adjuntos/${this.props.user.idAdjunto}`;
        return (
          <Button
            variant="light"
            className="shadow-button rounded-circle py-2 text-center bg-image perfil-button"
            size="lg"
            onClick={() => {
              navigate("/perfil");
              ReactGA.event({
                category: "Perfil",
                action: "Tab de Perfil",
                label: "Tab de Perfil",
              });
              this.closeNav();
            }}
            style={{ backgroundImage: `url(${src})`, width: 40, height: 40 }}
          />
        );
      }
      return (
        <Button
          variant="light"
          className="shadow-button rounded-circle py-1 px-1 text-center"
          size="lg"
          style={{ width: 40, height: 40 }}
          onClick={() => {
            navigate("/perfil");
            ReactGA.event({
              category: "Perfil",
              action: "Tab de Perfil",
              label: "Tab de Perfil",
            });
            this.closeNav();
          }}
        >
          <i className="fas fa-user"></i>
        </Button>
      );
    }
  }

  renderMobileButton() {
    if (this.state.movil)
      return (
        <Col className="pl-0 pr-2 text-right">{this.renderMenuButton()}</Col>
      );
  }

  renderMenuButton() {
    if (
      this.props.location &&
      !this.props.location.pathname.includes("admin")
    ) {
      if (
        (this.props.location.pathname.includes("crear") ||
          this.props.location.pathname.includes("editar")) &&
        !this.state.movil &&
        this.props.juego
      )
        return (
          <Button
            disabled={this.props.disabled}
            variant="outline-danger"
            className={`border-red-new px-3 ${
              this.state.movil ? "" : "mr-4"
            }`}
            onClick={() => {
              this.props.guardarJuego((idJuego) => {
                this.props.setJuego(idJuego);
                this.props.navigate("/host");
                ReactGA.event({
                  category: "Guardar",
                  label: "Guardar y Jugar",
                  action: "Guardar y Jugar",
                });
              });
              this.closeNav();
            }}
          >
            <h6 className='m-0'>Guardar y jugar</h6>
          </Button>
        );
      if(this.props.user) {
        return (
          <Button
            variant="outline-danger"
            className="border-red-new mr-4"
            size='lg'
            onClick={() => {navigate('/jugar'); this.closeNav();}}
          >
            <h6 className='m-0'>PIN de juego</h6>
          </Button>
        );
      } else {
        return (
          <>
            <Button
              variant="btn-link"
              className="link-gray mr-2"
              size='lg'
              onClick={() => {navigate('/jugar'); this.closeNav();}}
            >
              <h6 className='m-0'>Pin de juego</h6>
            </Button>
            <Button
              variant="outline-danger"
              className="border-red-new mr-2"
              size='lg'
              onClick={() => {navigate('/login'); this.closeNav();}}
            >
              <h6 className='m-0'>Iniciar sesión</h6>
            </Button>
            <Button
              variant="outline-danger"
              className="new-red-button"
              size='lg'
              onClick={() => {navigate('/signup'); this.closeNav();}}
            >
              <h6 className='m-0'>¡Regístrate!</h6>
            </Button>
          </>
        );
      }
    }
  }

  setNavExpanded(expanded) {
    this.setState({ navExpanded: expanded });
  }

  closeNav() {
    this.setState({ navExpanded: false });
  }

  render() {
    return (
      <>
      {this.state.movil ? 
        (this.props.user ?
          (this.props.location && this.props.location.pathname.includes("admin") ?
            <Navbar 
              className="bg-white shadow-sm"
              expand="md"
              fixed="bottom"
              style={{zIndex: 99999999}}
            >
            {/*
              <Navbar.Brand onClick={() => this.handleClickLogo()}>
                <Image src={logo} height={20} />
              </Navbar.Brand>
            */}
              <Nav className="mobile-menu">
                <Nav.Link 
                  className='vertical-center menu-link text-small'
                  onClick={() => navigate('/admin/configuracion')}
                  active={(this.props.location && this.props.location.pathname.includes('admin/configuracion'))}
                >
                  <i className='fas fa-cog mt-0'></i>
                  Juegos
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-small'
                  onClick={() => navigate('/admin/patrocinadores')}
                  active={(this.props.location && this.props.location.pathname.includes('admin/patrocinadores'))}
                >
                  <i className='fas fa-money-bill-wave mt-0'></i>
                  Impulsores
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-small'
                  onClick={() => navigate('/perfil')}
                  active={(this.props.location && this.props.location.pathname.includes('perfil'))}
                >
                  <i className='fas fa-user mt-0'></i>
                  Perfil
                </Nav.Link>
              </Nav>
            </Navbar>
            :
            <Navbar 
              className="bg-white shadow-sm"
              expand="md"
              fixed="bottom"
              style={{zIndex: 99999999}}
            >
            {/*
              <Navbar.Brand onClick={() => this.handleClickLogo()}>
                <Image src={logo} height={20} />
              </Navbar.Brand>
            */}
              <Nav className="mobile-menu">
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/explorar')}
                  active={(this.props.location && this.props.location.pathname.includes('explorar'))}
                >
                  <i className='fas fa-map-signs fa-rotate--15 mt-0'></i>
                  Explorar
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/misjuegos')}
                  active={(this.props.location && this.props.location.pathname.includes('misjuegos'))}
                >
                  <i className='fas fa-dice mt-0'></i>
                  Mis juegos
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link play-button-a'
                  onClick={() => navigate('/jugar')}
                  active={(this.props.location && this.props.location.pathname.includes('jugar'))}
                >
                  <div className='play-button-mobile vertical-center'>
                    <img src={logoCaraBlanca} className='d-block mx-auto' width='60%' />
                  </div>
                  <span className='text-smaller'>PIN de juego</span>
                </Nav.Link>
                <Nav.Link href="#d" className='vertical-center menu-link text-smaller' style={{display: 'none'}}>
                  <i className='fas fa-search mt-0'></i>
                  Buscar
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/seleccionar')}
                  active={(this.props.location && this.props.location.pathname.includes('seleccionar'))}
                >
                  <i className='fas fa-plus-square mt-0'></i>
                  Crear
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/perfil')}
                  active={(this.props.location && this.props.location.pathname.includes('perfil'))}
                >
                  <i className='fas fa-user mt-0'></i>
                  Perfil
                </Nav.Link>
              </Nav>
            </Navbar>
          )
          :
          <Navbar 
              className="bg-white shadow-sm"
              expand="md"
              fixed="bottom"
              style={{zIndex: 99999999}}
            >
            {/*
              <Navbar.Brand onClick={() => this.handleClickLogo()}>
                <Image src={logo} height={20} />
              </Navbar.Brand>
            */}
              <Nav className="mobile-menu">
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/explorar')}
                  active={(this.props.location && this.props.location.pathname.includes('explorar'))}
                >
                  <i className='fas fa-map-signs fa-rotate--15 mt-0'></i>
                  Explorar
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/signup')}
                >
                  <i className='fas fa-plus-square mt-0'></i>
                  Crear
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link play-button-a'
                  onClick={() => navigate('/jugar')}
                  active={(this.props.location && this.props.location.pathname.includes('jugar'))}
                >
                  <div className='play-button-mobile vertical-center'>
                    <img src={logoCaraBlanca} className='d-block mx-auto' width='60%' />
                  </div>
                  <span className='text-smaller'>PIN de juego</span>
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/login')}
                >
                  <i class="fas fa-sign-in-alt mt-0"></i>
                  Iniciar sesión
                </Nav.Link>
                <Nav.Link 
                  className='vertical-center menu-link text-smaller'
                  onClick={() => navigate('/signup')}
                >
                  <i class="fas fa-user-plus mt-0"></i>
                  Registro
                </Nav.Link>
              </Nav>
            </Navbar>
        )
        :
        <Navbar
          id="menu"
          className="bg-white shadow-sm"
          expand="md"
          sticky="top"
          style={{zIndex: 999999}}
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
        >
          <Navbar.Brand onClick={() => this.handleClickLogo()}>
            <Image src={logo} height={20} />
          </Navbar.Brand>
          {this.renderMobileButton()}
          <Navbar.Toggle />
          <Navbar.Collapse 
            className={this.state.movil ? 'text-center vertical-center fondo-blanco-90vh' : 'justify-content-between'} 
          >
            <div className='order-2 order-md-1 my-md-0 my-2'>
              {!this.props.user ?
                <Nav.Link
                  onClick={() => {this.handleClickExplorarConfiguracion(); this.closeNav();}}
                  className='d-inline-block menu-link'
                  active={(this.props.location && this.props.location.pathname.includes("explorar"))}
                >
                  <i className='fas fa-map-signs fa-rotate--15'></i>
                  <span className="ml-2 mr-3"><b>Explorar</b></span>
                </Nav.Link>
              : 
                this.renderTabs()
              }
            </div>
            <div className="d-inline-block navbar-nav order-1 order-2-md my-md-0 my-2">
              {this.state.movil ? "" : this.renderMenuButton()}
              {this.renderProfile()}
            </div>
          </Navbar.Collapse>
        </Navbar>
      }
      </>
    );
  }
}

export default Menu;
