import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingFace from "../../components/LoadingFace";
import OpcionButton from '../../components/OpcionButton';
import Question from './Question';
import { BASE_URL } from '../../utils';

class TriviaQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return(
            <Row className='justify-content-md-between justify-content-center'>
                <Col xs={5} md={4} className='text-center text-md-left mb-2'>
                    {this.props.juego.idAdjunto && this.props.juego.idAdjunto !== null ?
                        <img loading='lazy' alt="imagen pregunta" src={`${BASE_URL}/adjuntos/${this.props.juego.idAdjunto}`} className='question-img' />
                        :
                        <>
                        {!this.props.juego.contestada ? 
                            <LoadingFace time={this.props.tiempoPregunta} width='80%' height='80%' />
                            :
                            <>
                            {!this.props.isMobile &&
                                <LoadingFace width='80%' height='80%' />
                            }
                            </>
                        }
                        </>
                    }
                </Col>
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} className='text-center text-md-left'>
                            <Question 
                                isMobile={this.props.isMobile}
                                question={this.props.juego.pregunta}
                            />
                        </Col>
                    </Row>
                    <Row className='no-gutters mt-3'>
                        {this.props.juego.opciones.map((answer, index) => (
                            <OpcionButton
                                isMobile = {this.props.isMobile}
                                key = {`opcion-solo-${answer.idOpcion}`}
                                index = {index}
                                idOpcion = {answer.idOpcion}
                                texto = {answer.opcion}
                                gameType = {'playAlone'}
                                component = {'question'}
                                answered ={this.props.juego.contestada}
                                correcta={this.props.juego.correctas.includes(answer.idOpcion)}
                                selectedAnswer={this.props.juego.selectedAnswer}
                                responder={() => this.props.responder(answer)}//callback to send answer to back
                            />
                        ))}
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default TriviaQuestion;