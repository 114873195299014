import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import OpcionButton from "../../../components/OpcionButton";
import HeaderHost from "../../../components/HeaderHost";
import { BASE_URL } from "../../../utils";
import LoadingFace from "../../../components/LoadingFace";

class HostOpciones extends Component {
  constructor(props){
    super(props);
    this.state = {
      movil: false
    }
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    let { tiempo } = this.props.juego;
    if(!tiempo) tiempo = this.props.pregunta.tiempo;
    this.props.setCounter(tiempo, this.props.displayScore);
    this.resize();
  }

  resize() {
    let currentNav = window.innerWidth <= 576;
    if (currentNav !== this.state.movil) {
      this.setState({ movil: currentNav });
    }
  }

  renderBotones() {
    if (this.props.opciones)
      return this.props.opciones.map((opcion, index) => (
        <OpcionButton
          key={opcion.idOpcion}
          index={index}
          idOpcion={opcion.idOpcion}
          texto={opcion.texto}
          width={this.state.movil ? "full" : ""}
          correcta
        />
      ));
  }

  renderOpciones() {
    return (
      <Row className='no-gutters'>
        {this.renderBotones()}
      </Row>
    );
  }

  renderPregunta() {
    if (this.props.pregunta !== null) {
      if (this.props.pregunta.idAdjunto !== null) {
        return (
          <>
            <Col xs={6} md={4}>
              <Image
                src={`${BASE_URL}/adjuntos/${this.props.pregunta.idAdjunto}`}
                className="question-img"
              />
            </Col>
            <Col xs={6} md={8}>
              <h4>{this.props.pregunta.texto}</h4>
              {this.renderOpciones()}
            </Col>
          </>
        );
      }
      return (
        <>
          <Col xs={6} md={4}>
            <LoadingFace time={this.props.pregunta.tiempo} />
          </Col>
          <Col xs={6} md={8}>
            <h4>{this.props.pregunta.texto}</h4>
            {this.renderOpciones()}
          </Col>
        </>
      );
    }
  }

  render() {
    let tiempo = this.props.tiempo;
    return (
      <>
        <HeaderHost
          tipo={this.props.juego.tipo}
          titulo={this.props.juego.titulo}
          respuestas={this.props.respuestas}
          tiempo={tiempo}
          hideClock={this.props.hideClock}
        />
        <div className='fondo-gris-full vertical-center'>
          <Row className='justify-content-between question-height'>
          {this.renderPregunta()}
          </Row>
        </div>
      </>
    );
  }
}

export default HostOpciones;
