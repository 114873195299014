import {
    IS_ADMIN
  } from "../actions/types";
  
  const INITIAL_STATE = {
    isAdmin: false,
    loadingAdmin: true
  };
  
  export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
      case IS_ADMIN:
        return { ...state, isAdmin: payload, loadingAdmin: false };
      default:
        return { ...state };
    }
  };
  