import React, { Component } from "react";
import MisJuegos from "./view/MisJuegos";
import Crear from "./view/Crear";
import Host from "./view/Host";
import Pregunta from "./view/Pregunta";
import InformacionGeneral from "./view/InformacionGeneral";
import { Router, Redirect } from "@reach/router";
import CreacionCrucigrama from "./view/CreacionCrucigrama";
import ConfiguracionJuegos from "./view/ConfiguracionJuegos";
import Patrocinadores from "./view/Patrocinadores";
import ConfiguracionPatrocinador from "./view/ConfiguracionPatrocinador";
import PerfilUsuario from "./view/PerfilUsuario";
import Reportes from "./view/Reportes";
import Explore from './view/Explore';
import GameProfile  from './GameProfile';
import { connect } from "react-redux";
import Error404 from "./view/Error404";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    if(this.props.user){ //Can only access if logged on
      return (
        <>
            <Router>
            <Error404
                path="/404"
                user={this.props.user}
                disabled={this.props.disabled}
                signOut={this.props.signOut}
                showOpciones={this.props.showOpciones}
                setJuego={this.props.setJuego}
                juego={this.props.juego}
                guardarJuego={(callback) => this.postPreguntas(callback)}
                spinner={this.props.spinner}
                menu={true}
                default
              />
              {/*General Views*/}
              <MisJuegos path="/misjuegos" />
              <Explore path='/explorar'/>
              <GameProfile path='/user/:idPerfil' />
              <Reportes path="/reportes/:idJuego" />
              {/*Create Views*/}
              <Crear path="/seleccionar" />
              {/* Crear */}
              <InformacionGeneral path="/crear" />
              <Pregunta path="/crear/pregunta/:idPregunta" />
              <CreacionCrucigrama path="/crear/crucigrama" />
              {/* Editar */}
              <InformacionGeneral path="/editar/:idJuego" />
              <Pregunta path="/editar/:idJuego/pregunta/:idPregunta" />
              <CreacionCrucigrama path="/editar/:idJuego/crucigrama" />
              {/*Game Views*/}
              <Host path="/host/*" />
              {/*Panel Admin*/}
              <Redirect from="/admin" to="/admin/configuracion" noThrow />
              <ConfiguracionJuegos path="/admin/configuracion" />
              <Patrocinadores path="/admin/patrocinadores" />
              <ConfiguracionPatrocinador path="/admin/patrocinadores/:idPatrocinador/configuracion" />
                <PerfilUsuario
                  path="/perfil"
                  faq={[
                    {
                      idFAQ: 1,
                      pregunta: "Lorem ipsum",
                      respuesta:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                    },
                  ]}
                />
            </Router>
        </>
        
      );
    }
    else{
      return (
        <>
          <Router>
            <Error404
                  path="/404"
                  user={this.props.user}
                  disabled={this.props.disabled}
                  signOut={this.props.signOut}
                  showOpciones={this.props.showOpciones}
                  setJuego={this.props.setJuego}
                  juego={this.props.juego}
                  guardarJuego={(callback) => this.postPreguntas(callback)}
                  spinner={this.props.spinner}
                  menu={true}
                  default
                />
              {/*General Views*/}
              {/* <MisJuegos path="/misjuegos" /> */}
              <Explore path='/explorar'/>
              <GameProfile path='/user/:idPerfil' />
              {/* <Reportes path="/reportes/:idJuego" /> */}
              {/*Create Views*/}
              <Crear path="/seleccionar" />
              {/* Crear */}
              <InformacionGeneral path="/crear" />
              <Pregunta path="/crear/pregunta/:idPregunta" />
              {/* <CreacionCrucigrama path="/crear/crucigrama" /> */}
              {/* Editar */}
              <InformacionGeneral path="/editar/:idJuego" />
              <Pregunta path="/editar/:idJuego/pregunta/:idPregunta" />
              {/* <CreacionCrucigrama path="/editar/:idJuego/crucigrama" /> */}
              {/*Game Views*/}
              <Host path="/host/*" />
              {/*Panel Admin*/}
              <Redirect from="/admin" to="/admin/configuracion" noThrow />
              <ConfiguracionJuegos path="/admin/configuracion" />
              <Patrocinadores path="/admin/patrocinadores" />
              <ConfiguracionPatrocinador path="/admin/patrocinadores/:idPatrocinador/configuracion" />
                {/* <PerfilUsuario
                  path="/perfil"
                  faq={[
                    {
                      idFAQ: 1,
                      pregunta: "Lorem ipsum",
                      respuesta:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                    },
                  ]}
                /> */}
            </Router>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  user: state.user.user,
});
export default connect(mapStateToProps, {
})(Home);;
