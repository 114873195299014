import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import StarRating from 'react-rating';
import estrellaLlena from "../../assets/estrella_llena.svg";
import estrellaOutline from "../../assets/Estrella_outline.svg";
import estrellaVacia from "../../assets/estrella_vacia.svg";

class Rating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: 0,
            width: '25px',
            height: '25px',
        }

        this.handleRating = this.handleRating.bind(this);
    }

    handleRating(rating){
        this.setState({rating});
        this.props.setSugerenciaProperty({
            key: "recomendacionJuego",
            value: rating
        });
      }

    render() {
        let {rating, width, height} = this.state;
        return(
            <>
                <Row className='justify-content-center mt-3'>
                    <Col xs={12} md={6} className='text-center'>
                        <h6>¿Cómo calificarías este ULU?</h6>
                    </Col>
                </Row>
                <Row className='justify-content-center mb-3'>
                    <Col xs={12} md={6} className='text-center'>
                    <StarRating
                        initialRating={rating}
                        emptySymbol={<img src={estrellaVacia} className="icon pr-1" style={{height: height,width: width}} alt='ícono de estrella vacía' />}
                        placeholderSymbol={<img src={estrellaOutline} className="icon pr-1" style={{height: height,width: width}} alt='ícono de estrella delineada' />}
                        fullSymbol={<img src={estrellaLlena}  className="icon pr-1" style={{height: height,width: width}} alt='ícono de estrella llena' />}
                        onChange={this.handleRating}
                    />
                    </Col>
                </Row>
            </>
        );
    }
}

export default Rating;