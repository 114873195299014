import React from "react";
import ReactGA from "react-ga";
import { Container, Row, Col, Button } from "react-bootstrap";

class FooterHost extends React.Component {
  continuar() {
    if (this.props.component === "scoreboard") this.props.continuar();
    else if (
      this.props.component === "pregunta" &&
      this.props.tipo === "trivia"
    )
      this.props.displayOpciones();
    else this.props.displayScore();
    ReactGA.event({
      category: "Continuar",
      action: "Continuar",
      label: "Continuar Juego",
    });
  }

  render() {
    return (
      <Container className="pb-4 fixed-bottom">
        <Row className="align-items-center">
          <Col xs={!this.props.hidePregunta ? 4 : 6} className="pl-5">
            <Row className="mb-0 pl-3">
              <h5 className='mb-0'>
                PIN del juego:
              </h5>
            </Row>
            <Row className="pt-0 pl-3 mt-0">
              <p>{this.props.pin}</p>
            </Row>
          </Col>
          {!this.props.hidePregunta && (
            <Col xs={4} className="text-center">
              <h3>
                Pregunta {this.props.numPregunta}/{this.props.total} -{" "}
                {this.props.puntosPregunta}pts
              </h3>
            </Col>
          )}
          <Col
            xs={!this.props.hidePregunta ? 4 : 6}
            className="text-right pr-5"
          >
            <Button
              variant="link"
              className="link-gray px-4 py-4"
              size='lg'
              onClick={this.props.salir}
            >
              <h5 className='mb-0'>Salir del juego</h5>
            </Button>
            {!(
              this.props.component === "pregunta" &&
              this.props.tipo === "trivia"
            ) && (
              <Button
                variant="danger"
                className="new-red-button px-4 py-4"
                size='lg'
                onClick={() => this.continuar()}
              >
                <h5 className='mb-0'>Continuar</h5>
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FooterHost;
