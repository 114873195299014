import {
  PATROCINADORES_RECIBIDOS,
  EDIT_PATROCINADOR,
  CREATE_PATROCINADOR,
  SET_PROPIEDAD_PATROCINADOR,
  SAVE_PATROCINADOR,
  SHOW_OPCIONES_PATROCINADOR,
  PATROCINADOR_RECIBIDO,
  SEGMENTOS_DISPONIBLES,
  SHOW_LOGO_PATROCINADOR,
  AGREGAR_LOGO_PATROCINADOR,
  EDIT_LOGO,
  SET_PROPIEDAD_LOGO,
  SAVE_LOGO,
  SET_LOGO,
  LOGOS_RECIBIDOS,
  DELETE_LOGO,
  CLEAR_EDITADO,
  SET_SEGMENTO_PATROCINADOR,
} from "../actions/types";

const schema = {
  idPatrocinador: "nuevo",
  nombre: "",
  idAdjunto: null,
  file: null,
  primaria: 0,
  secundaria: 0,
  preparatoria: 0,
  universidad: 0,
  profesional: 0,
  premiosActivos: 0,
  premiosTotales: 0,
  activo: true,
};

const initialState = {
  patrocinadores: null,
  patrocinador: null,
  editado: null,
  opciones: undefined,
  disponibles: undefined,
  logo: null,
  logos: null,
  file: null,
  editadoLogo: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PATROCINADORES_RECIBIDOS:
      return { ...state, patrocinadores: payload };
    case EDIT_PATROCINADOR:
      return { ...state, patrocinador: payload };
    case CREATE_PATROCINADOR:
      return { ...state, editado: schema };
    case CLEAR_EDITADO:
      return { ...state, editado: null, patrocinador: null };
    case SET_PROPIEDAD_PATROCINADOR:
      let { key, value } = payload;
      const editado = { ...state.editado };
      const patrocinador = { ...state.patrocinador };
      const segmentos = [
        "primaria",
        "secundaria",
        "preparatoria",
        "universidad",
        "profesional",
      ];
      for (let i = 0; i < segmentos.length; i++) {
        if (key === segmentos[i])
          if (Number(value) < 0) value = 0;
          else if (Number(value) > 100) value = 100;
      }
      if (key === "idAdjunto" && typeof value === "object") {
        key = "file";
        patrocinador[key] = value;
        editado[key] = value;
      } else {
        patrocinador[key] = value;
        editado[key] = value;
      }
      return { ...state, editado, patrocinador };
    case SAVE_PATROCINADOR: {
      const patrocinadores = [...state.patrocinadores];
      const editado = { ...state.editado };
      const segmentos = [
        "primaria",
        "secundaria",
        "preparatoria",
        "universidad",
        "profesional",
      ];
      for (let i = 0; i < segmentos.length; i++) {
        payload[segmentos[i]] = Number(payload[segmentos[i]]);
      }
      if (editado.idPatrocinador === "nuevo") {
        patrocinadores.push(payload);
      } else {
        const index = patrocinadores.findIndex(
          (patrocinador) =>
            patrocinador.idPatrocinador === payload.idPatrocinador
        );
        patrocinadores[index] = payload;
      }
      return { ...state, patrocinadores, editado: null };
    }
    case SHOW_OPCIONES_PATROCINADOR:
      return { ...state, opciones: payload };
    case PATROCINADOR_RECIBIDO:
      const desactivar = payload.fechaVencimiento !== null;
      return { ...state, patrocinador: { ...payload, desactivar } };
    case SEGMENTOS_DISPONIBLES:
      const disponibles = {};
      payload.forEach((segmento) => {
        let { nombre, disponible } = segmento;
        nombre = nombre.toLowerCase();
        disponibles[nombre] = disponible;
      });
      return { ...state, disponibles };
    /**
     * Logos
     */
    case LOGOS_RECIBIDOS:
      return { ...state, logos: payload };
    case SHOW_LOGO_PATROCINADOR:
      return { ...state, logo: payload };
    case AGREGAR_LOGO_PATROCINADOR: {
      const logos = [...state.logos];
      logos.push(payload);
      return {
        ...state,
        logos,
        logo: null,
        file: null,
      };
    }
    case SET_LOGO:
      if (!payload) return { ...state, file: undefined };
      return {
        ...state,
        file: { ...payload, idAdjunto: `nuevo-${state.logos.length}` },
      };
    case SET_PROPIEDAD_LOGO: {
      const { key, value } = payload;
      const editadoLogo = { ...state.editadoLogo };
      if (key === "activo" && !value) {
        if (editadoLogo) {
          if (editadoLogo.idAdjunto === state.patrocinador.idAdjunto) {
            return { ...state };
          }
        }
      }
      editadoLogo[key] = value;
      return { ...state, editadoLogo };
    }
    case EDIT_LOGO:
      return { ...state, editadoLogo: payload };
    case SAVE_LOGO: {
      const logos = [...state.logos];
      const index = logos.findIndex(
        (logo) => String(logo.idAdjunto) === String(payload.idAdjunto)
      );
      if (index !== -1) logos[index] = { ...payload };
      else logos.push(payload);
      return { ...state, logos, file: null, logo: null, editadoLogo: null };
    }
    case DELETE_LOGO: {
      const logos = [...state.logos];
      const index = logos.findIndex(
        (logo) => String(logo.idAdjunto) === String(payload)
      );
      if (index !== -1)
        logos[index].idAdjunto = `delete-${logos[index].idAdjunto}`;
      return { ...state, logos };
    }
    case SET_SEGMENTO_PATROCINADOR: {
      const patrocinadores = [...state.patrocinadores];
      const patrocinador = patrocinadores.find(
        (patrocinador) =>
          parseInt(patrocinador.idPatrocinador) ===
          parseInt(payload.idPatrocinador)
      );
      if (patrocinador) {
        const segmento = patrocinador.segmentos.find(
          (segmento) =>
            String(segmento.nombre).toLowerCase() === payload.segmento
        );
        if (segmento) {
          const porcentaje =
            payload.porcentaje === "" ? 0 : parseInt(payload.porcentaje);
          let total = 0;
          const patrocinadoresActivos = patrocinadores.filter((p) => p.activo === 1);
          patrocinadoresActivos.forEach((sponsor) => {
            let seg = sponsor.segmentos.find(
              (segment) => segment.nombre === segmento.nombre
            );
            if (sponsor.idPatrocinador !== patrocinador.idPatrocinador) {
              total += seg.porcentaje;
            }
          });
          if (total + porcentaje <= 100) {
            segmento.porcentaje = porcentaje;
          }
        }
      }
      return { ...state, patrocinadores };
    }
    default:
      return state;
  }
};
