import React, { Component, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

class CheckBox extends Component {
  toggle() {
    let checked = !this.props.checked;
    if (this.props.modifier) {
      if (this.props.args !== undefined) {
        if (Array.isArray(this.props.args))
          this.props.modifier(...this.props.args, checked);
        else this.props.modifier(this.props.args, checked);
      } else this.props.modifier(checked);
    }
  }

  renderLabel() {
    if (this.props.label)
      return (
        <Form.Label className="d-block" htmlFor={this.props.name}>
          {this.props.label}
        </Form.Label>
      );
  }

  render() {
    return (
      <Fragment>
        {this.renderLabel()}
        <Button
          className={
            (this.props.checked ? "checked" : "unchecked") +
            " checkbox text-light mr-2"
          }
          disabled={this.props.disabled}
          onClick={() => this.toggle()}
          style={{ width: 25, height: 25, padding: 0 }}
          variant="light"
        >
          <i className="fas fa-check"></i>
        </Button>
      </Fragment>
    );
  }
}

export default CheckBox;
