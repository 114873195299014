import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { SadFace } from '../../assets/icons';
import HappyFace from '../../assets/logo-cara-blanca.png';
import logo from '../../assets/logo-cara.png';
import { toast } from 'react-toastify';
import { navigate }  from '@reach/router';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidUpdate() {
        if(this.props.component !== 'question') {
            return;
        }

        if(!this.props.isMobile && this.props.juego.contestada) {
            if(
                this.props.component === 'question' &&
                ((this.props.juego.correctas?.includes(this.props.juego.selectedAnswer)) || (this.props.juego.amountCorrectLetters >= (this.props.juego.respuesta?.length / 2)))
            ) {
                toast.success('¡Contestate correctamente!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('¡Aún tienes oportunidades para ganar!', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    render() {
        if(this.props.isMobile) {
            if(this.props.component !== 'gameending') {
                return(
                    <>
                        <div className='header bg-white vertical-center'>
                            <Container className='my-3'>
                                <Row className='justify-content-between'>
                                    <Col xs={4} className='vertical-center'>
                                        <p className='text-small'>Pregunta {this.props.juego.preguntaActual} / {this.props.juego.preguntasTotales}</p>
                                    </Col>
                                    <Col xs='auto' className='vertical-center'>
                                        <p className='text-small'><i className={`fas ${this.props.juego.streak ? 'fa-fire-alt' : 'fa-trophy'}`} /> {this.props.juego.puntos} pts</p>
                                    </Col>
                                    <Col xs={4} className='vertical-center text-right'>
                                        <p className='text-small'><i className="far fa-clock" /> {this.props.component === 'lobbyquestion' ? this.props.tiempoLobby : this.props.tiempoPregunta} s</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className={`answered-flag ${this.props.juego.contestada ? 'animate-answered-flag-in' : 'animate-answered-flag-out'}`}>
                            {(this.props.juego.correctas?.includes(this.props.juego.selectedAnswer)) || (this.props.juego.amountCorrectLetters >= (this.props.juego.respuesta?.length / 2)) ?
                                <div className='correct-answer vertical-center'>
                                    {/*<HappyFace style={{width: '20px', height: '20px'}} className={'mx-auto mb-2'} />*/}
                                    <img src={HappyFace} height='20px' className='mx-auto mb-2' />
                                    <p>¡Contestate correctamente!</p>
                                </div>
                            :
                                <div className='incorrect-answer vertical-center'>
                                    <SadFace style={{width: '20px', height: '20px'}} className={'mx-auto mb-2'} />
                                    <p>¡Aún tienes oportunidades para ganar!</p>
                                </div>
                            }
                        </div>
                        
                    </>
                );
            } else {
                return(
                    <Container>
                        <Row className='justify-content-center my-2'>
                            <Col xs='auto'>
                                <img src={logo} alt='ulu logo' width={50} onClick={() => navigate('/explorar')} />
                            </Col>
                        </Row>
                    </Container>
                );
            }
        }
        else
            return(
                <div className='header'>
                    <Container className='mt-2'>
                        <Row className='justify-content-between'>
                            <Col xs='auto' className='mt-2'>
                                <img src={logo} width={50} alt='ulu logo' onClick={() => navigate('/explorar')} />
                            </Col>
                            <Col xs={5}>
                                <Row>
                                    <Col xs={12} className='text-center'>
                                        <h4 className='host-game-title'>{this.props.icon} {this.props.titulo}</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='text-center'>
                                        {this.props.component !== 'gameending' &&
                                            <h6 className='non-bold'><i className="far fa-clock mr-1" />{this.props.component === 'lobbyquestion' ? `ULU comenzando en: ${this.props.tiempoLobby}` : `Tiempo Restante: ${this.props.tiempoPregunta}`} s</h6>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs='auto' className='mt-2'>
                                {this.props.component !== 'gameending' &&
                                    <h6 className='non-bold'><i className={`fas ${this.props.juego.streak ? 'fa-fire-alt' : 'fa-trophy'}`} /> {this.props.juego.puntos} pts</h6>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
    }
}

export default Header;