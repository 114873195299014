import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import AdminTable from "../AdminTable";
import Dropzone from "../Dropzone";
import SwitchULU from "../global/SwitchULU";

export default function ({
  file,
  logos,
  logo,
  setLogo,
  saveLogo,
  editLogo,
  deleteLogo,
  agregarLogo,
  patrocinador,
  setPropiedadLogo,
  showLogoPatrocinador,
  setPropiedadPatrocinador,
}) {
  let principal = patrocinador.idAdjunto;
  if (logos && logos !== null) {
    logos = logos.filter((logo) => String(logo.idAdjunto)[0] !== "d");
    logos = logos.map((logo) => ({
      ...logo,
      principal: logo.idAdjunto === principal,
    }));
    logos = logos.sort((a, b) => (a.activo ? -1 : 1));
    if (logos.length > 0 && principal !== null) {
      let principalIndex = logos.findIndex((logo) => logo.principal);
      if (principalIndex !== -1) {
        let logoPrincipal = logos[principalIndex];
        logos.splice(principalIndex, 1);
        logos = [logoPrincipal, ...logos];
      }
    }
  }
  return (
    <>
      <Row>
        <Col xs={11} sm={9} lg={8} className="pl-3 pl-sm-4">
          <p className="m-0 pt-3 h5">Logos</p>
          <div className="mb-3 admin-table tabla-codigos">
            <AdminTable
              idRow="idAdjunto"
              headers={["Archivo", "Estado", "Principal"]}
              rows={logos}
              editRow={editLogo}
              editedRow={logo}
              deleteRow={deleteLogo}
              saveRow={saveLogo}
              tableClass="mb-0"
              exclude={["file", "tipo"]}
              editColor="rgb(63,63,63)"
              components={{
                activo: ({ activo }) => (
                  <SwitchULU
                    value={activo}
                    modifier={setPropiedadLogo}
                    args="activo"
                  />
                ),
                principal: ({ idAdjunto, activo }) => (
                  <SwitchULU
                    value={principal === idAdjunto}
                    modifier={() => {
                      if (activo) {
                        setPropiedadPatrocinador("idAdjunto", idAdjunto);
                      }
                    }}
                  />
                ),
              }}
              inputModifier={setPropiedadLogo}
              iconsEstado
              archivoOnClick={showLogoPatrocinador}
            />
          </div>
        </Col>
      </Row>
      <Row
        className="pl-3 pl-sm-4"
        style={{ maxWidth: window.innerWidth < 576 ? "80vw" : "40vw" }}
      >
        <Dropzone
          modifier={setLogo}
          file={file}
          removeImage={() => setLogo()}
        />
      </Row>
      <Row className="pt-2 pb-3">
        <Col xs={6} sm={5} md={4} lg={3} xl={2} className="pl-3 pl-sm-4">
          {file !== null && (
            <Button
              variant="outline-danger"
              className="border-red-new py-2 mx-auto my-0"
              onClick={() => agregarLogo(file)}
            >
              Agregar foto
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}
