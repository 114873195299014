import React, { Fragment } from "react";

export default (props) => {
  if (props.contestadas) {
    if (props.contestadas.has(props.letra.toLowerCase()))
      return (
        <Fragment>
          {props.children}
          <span
            className="h4 px-2 border-bottom mr-1 py-2 my-1 font-weight-bold d-inline-block"
            
          >
            {props.letra}
          </span>
        </Fragment>
      );
  }
  return (
    <Fragment>
      {props.children}
      <span
        className="h4 px-2 border-bottom mr-1 py-2 my-1 font-weight-light d-inline-block"
        style={props.respuestas !== undefined ? (props.respuestas === 0 ? { color: "transparent"} : {}) : {}}
      >
        {props.respuestas !== undefined ? props.respuestas > 0 ? props.respuestas : "." : "."}
      </span>
    </Fragment>
  );
};
