import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/browser";

import * as serviceWorker from "./serviceWorker";
if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
// NOT DEV CODE
Sentry.init({
  dsn: "https://386aa6d817b248559379c869a519d141@o451625.ingest.sentry.io/5441505",
  ignoreErrors: ['Request failed with status code 401'],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      
      Sentry.showReportDialog({
        eventId: event.event_id,
        title: "Parece que ocurrió un problema",
        subtitle: "Nuestro equipo de desarrollo ha sido notificado.",
      });
    }
    return event;
  },
});
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
