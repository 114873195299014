import {
  LOGIN,
  LOGOUT,
  SHOW_SPINNER,
  HIDE_SPINNER,
  USER_CREATED,
  SHOW_ALERT,
  SET_PROPIEDAD_USER,
  SET_PROPIEDAD_LOGIN,
  PERFIL_RECIBIDO,
} from "../actions/types";

const INITIAL_STATE = {
  user: null,
  correo: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      const user = action.payload;
      return { ...state, user };
    case LOGOUT:
      return { ...INITIAL_STATE };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    case USER_CREATED:
      return { ...state, created: true };
    case SHOW_ALERT:
      return { ...state, error: action.payload };
    case SET_PROPIEDAD_USER: {
      const user = { ...state.user };
      const { key, value } = action.payload;
      user[key] = value;
      return { ...state, user };
    }
    case SET_PROPIEDAD_LOGIN:
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    case PERFIL_RECIBIDO:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return { ...state };
  }
};
