import React from "react";
import ReactGA from "react-ga";
import { Button, Image, Row } from "react-bootstrap";
import { tipos, getTipo } from "../utils";
import moment from "moment";
import estrella_vacia from "../assets/estrella_vacia.svg";
import estrella_llena from "../assets/estrella_llena.svg";
import base64url from 'base64url';
import { toast } from 'react-toastify';
import copy from 'clipboard-copy';

class FilaJuego extends React.Component {
  constructor(props) {
    super(props);
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;
    let queryURL = `juego=${this.props.juego.idJuego}&tipo=${this.props.juego.tipo.toLowerCase()}&segmento=${this.props.user ? this.props.user.nivel : "PRIMARIA"}`;
    queryURL = base64url(queryURL);
    this.state = {
        invitationUrl: `${baseUrl}/play/alone/${queryURL}`
    }
  }

  toggleOpciones(e) {
    if (this.props.opciones === this.props.juego.idJuego)
      this.props.showOpciones();
    else {
      e.stopPropagation();
      this.props.showOpciones(this.props.juego.idJuego);
    }
    ReactGA.event({
      category: "Opciones Juegos",
      action: "Kebab Click",
      label: "Click Kebab",
    });
  }

  estadoJuego() {
    const { privacidad } = this.props.juego;
    if (privacidad === "Privado") {
      return (
        <td className="py-3">
          <p className="ml-3 my-1 py-2">
            <i className="fas fa-user text-dark"></i>
          </p>
        </td>
      );
    } else {
      return (
        <td className="py-3">
          <p className="ml-3 my-1 py-2">
            <i className="fas fa-users"></i>
          </p>
        </td>
      );
    }
  }

  tipoJuego() {
    const { tipo } = this.props.juego;
    const tipoLower = tipo.toLowerCase();
    const juego = tipos.find((tipoJuego) => tipoJuego.slug === tipoLower);
    let Icon = juego.icon;
    return (
      <td xs={1}>
        <p className="my-1 py-2 align-items-center tipo-juego">
          <Icon />
        </p>
      </td>
    );
  }

  renderOptions() {
    if (this.props.opciones === this.props.juego.idJuego) {
      return (
        <div
          className="card border-0 shadow px-3 py-1"
          style={{
            position: "absolute",
            top: "35px",
            right: "25px",
            borderRadius: 10,
            zIndex: 1050,
            width: 120,
          }}
        >
          <Button
            variant="link"
            className="text-dark text-left px-0"
            onClick={() => {
              if (this.props.activo)
                this.props.editarJuego(this.props.juego.idJuego);
              else
                this.props.alert(
                  "Por el momento no se puede editar este tipo de ULU"
                );
              ReactGA.event({
                category: "Edit",
                action: "Edit Juego",
                label: "Edit Juego",
                value: this.props.juego.idJuego,
              });
            }}
          >
            <i className="fa fa-edit"></i> Editar
          </Button>
          <Button
            variant="link"
            className="text-dark text-left px-0"
            onClick={() => this.props.duplicarJuego(this.props.juego.idJuego)}
          >
            <i className="fa fa-copy"></i> Duplicar
          </Button>
          <hr style={{ marginTop: 5, marginBottom: 5 }} />
          <Button
            variant="link"
            className="text-danger text-left px-0"
            onClick={() => this.props.eliminarJuego(this.props.juego)}
          >
            <i className="fa fa-trash"></i> Eliminar
          </Button>
        </div>
      );
    }
  }

  renderPlay(idJuego) {
    if (window.innerWidth > 576) {
      return (
        <>
          <td className="px-2 text-center">
            <Button
                className="border-red-new py-2 px-2"
                block
                onClick={() => {
                  this.props.navigate(this.state.invitationUrl);
                }}
            >
                <h6 className='my-auto'>Jugar solo</h6>
            </Button>
          </td>
          <td className="px-2 text-center">
            <Button
              className="new-red-button py-2 px-1"
              onClick={() => {
                if (this.props.activo) {
                  this.props.setJuego(idJuego);
                  this.props.navigate("/host");
                } else
                  this.props.alert(
                    "Por el momento no se puede jugar este tipo de ULU"
                  );
              }}
            >
              <h6 className='my-auto'>Ser anfitrión</h6>
            </Button>
          </td>
        </>
      );
    } else {
      return(
        <>
          <td className="px-2 text-center">
            <Button
                className="border-red-new py-2 px-2"
                block
                onClick={() => {
                  this.props.navigate(this.state.invitationUrl);
                }}
            >
                <h6 className='my-auto'>Jugar solo</h6>
            </Button>
          </td>
        </>
      );
    }
  }

  renderJuego() {
    if (this.props.juego) {
      let { titulo, idJuego, favorito } = this.props.juego;
      let estrella = estrella_vacia;
      if (favorito) estrella = estrella_llena;
      /*if (titulo.length > 30 && window.innerWidth < 576)
        titulo = titulo.substring(0, 30) + "...";*/
      return (
        <>
          {this.estadoJuego()}
          <td colSpan={4} className="titulo-width py-3">
            <h5 className="my-1 py-2">{titulo}</h5>
          </td>
          {this.tipoJuego()}
          <td>
            <Button
              variant="link"
              className="link-black"
              onClick={() => this.props.navigate(`/reportes/${idJuego}`)}
            >
              <p>
                <i className="far fa-copy pr-2"></i>Reportes
              </p>
            </Button>
          </td>
          <td>
            <Button
              variant="link"
              className="icon-favoritos"
              onClick={() =>
                this.props.setPropiedadMiJuego(idJuego, "favorito", !favorito)
              }
            >
                <Image src={estrella} className="favorito-icon" />
            </Button>
          </td>
          <td>
            {/*<i className="fas fa-share-alt fa-lg py-2 my-2"></i>*/}
            <Button
                variant="link"
                className="text-dark text-left px-0"
                onClick={() => {
                    copy(this.state.invitationUrl);
                    toast.dark("Link copiado!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({cardOptions: false})
                }}
              >
                <i className="fas fa-link"></i>
              </Button>
          </td>
          {this.renderPlay(idJuego)}
          <td style={{ position: "relative" }}>
            <Button
              variant="link"
              className="text-dark"
              onClick={(e) => this.toggleOpciones(e)}
            >
              <i className="fas fa-ellipsis-v py-2"></i>
            </Button>
            {this.renderOptions()}
          </td>
        </>
      );
    }
  }

  renderReporte() {
    if (this.props.reporte) {
      let { reporte } = this.props;
      return (
        <>
          <td className="pl-4 py-4">
            <p className="h5 m-0 pt-1">
              <i
                className={`fas ${
                  reporte.jugadores === 1 ? "fa-user" : "fa-users"
                } pr-2`}
                style={{ color: "#000" }}
              ></i>
              <span>{reporte.cantJugadores}</span>
            </p>
          </td>
          <td className="py-4" style={{ width: "45vw" }}>
            <p className="h5 m-0 pt-1">
              <i className="far fa-calendar-alt fa-lg pr-2"></i>
              <span>
                {moment(reporte.fechaHoraInicio).format("DD/MMM/YYYY")}
              </span>
              <span className="ml-2">
                {moment.utc(reporte.fechaHoraInicio).format("HH:mm")}
              </span>
            </p>
          </td>
          <td className="py-4">
            <i className="fas fa-share-alt fa-lg py-2"></i>
          </td>
          <td style={{ width: "15vw", minWidth: 120 }} className="py-3">
            <Button
              variant="outline-danger"
              className="border-red-new btn-round"
              onClick={() =>
                this.props.downloadReporte(
                  reporte.idJuego,
                  reporte.idSesion,
                  getTipo(reporte)
                )
              }
            >
              <i className="fas fa-download pr-1"></i>
              <span className="">Descargar</span>
            </Button>{" "}
          </td>
        </>
      );
    }
  }

  render() {
    return (
      <tr className="align-items-center">
        {this.renderJuego()}
        {this.renderReporte()}
      </tr>
    );
  }
}

export default FilaJuego;
