import {
  CREATE_CODIGO,
  CODIGOS_RECIBIDOS,
  EDITAR_CODIGOS,
  CLEAR_CODIGOS,
  DELETE_CODIGO,
  SET_EXCEL,
  SET_PROPIEDAD_CODIGO,
  SET_PROPIEDAD_EXCEL,
  ERROR_CODIGOS,
  CAMBIAR_TIPO,
} from "./types";
import CodigosService from "../services/CodigosService";
import { displayError, displaySuccess, procesarCodigos } from "../utils";

export const getCodigos = (idPremio) => (dispatch) => {
  CodigosService.getCodigos(idPremio)
    .then((res) => {
      const { codigos } = res.data;
      dispatch({ type: CODIGOS_RECIBIDOS, payload: codigos });
    })
    .catch((error) => {
      displayError(dispatch, error);
    });
};

export const editarCodigos = (premio) => (dispatch) => {
  dispatch({ type: EDITAR_CODIGOS, payload: premio });
};

export const setPropiedadCodigo = (key, value) => (dispatch) => {
  dispatch({ type: SET_PROPIEDAD_CODIGO, payload: { key, value } });
};

export const setPropiedadExcel = (key, value) => (dispatch) => {
  dispatch({ type: SET_PROPIEDAD_EXCEL, payload: { key, value } });
};

export const setExcel = (file) => (dispatch) => {
  dispatch({ type: SET_EXCEL, payload: file });
};

export const clearCodigos = () => (dispatch) => {
  dispatch({ type: CLEAR_CODIGOS });
};

export const createCodigo = () => (dispatch) => {
  dispatch({ type: CREATE_CODIGO });
};

export const cambiarTipo = (key, tipo) => (dispatch) => {
  dispatch({ type: CAMBIAR_TIPO, payload: { key, tipo } });
};

export const postCodigos = (idPremio, codigos, file, excel, callback) => (
  dispatch
) => {
  const promises = [];
  const repetidosNuevos = [];
  const codigosNuevos = [];
  if (file) {
    if (file && file !== null) {
      if (file.name) {
        let tipoCodigo = excel.tipoCodigo;
        if (String(tipoCodigo).includes("barras"))
          if (excel.codificacion) tipoCodigo = excel.codificacion;
          else tipoCodigo = excel.tipoCodigoBarras;
        if (tipoCodigo !== "pharmacode" && tipoCodigo !== "codebar") {
          tipoCodigo = String(tipoCodigo).toUpperCase();
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.set("tipoCodigo", tipoCodigo);
        promises.push(
          new Promise((resolve, reject) => {
            CodigosService.putCodigosExcel(idPremio, formData).then((res) => {
              const { repetidos, codigos } = res.data;
              codigosNuevos.push(...codigos);
              repetidosNuevos.push(...repetidos);
              resolve();
            });
          })
        );
      }
    }
  }
  if (codigos[0]) {
    let tipoCodigo = codigos[0].tipoCodigo;
    if (tipoCodigo === "") tipoCodigo = "TEXTO";
    else if (String(tipoCodigo).includes("Barras"))
      tipoCodigo = codigos[0].codificacion;
    else if (String(tipoCodigo).includes("barras"))
      tipoCodigo = codigos[0].codificacion;
    else if (tipoCodigo !== "pharmacode" && tipoCodigo !== "codabar") {
      tipoCodigo = String(tipoCodigo).toUpperCase();
    }
    const { nuevos, editar, eliminar } = procesarCodigos(codigos);
    promises.push(
      new Promise((resolve, reject) => {
        CodigosService.putCodigos(
          idPremio,
          nuevos,
          editar,
          eliminar,
          tipoCodigo
        ).then((res) => {
          const { codigos, repetidos } = res.data;
          codigosNuevos.push(...codigos);
          repetidosNuevos.push(...repetidos);
          resolve();
        });
      })
    );
  }
  Promise.all(promises)
    .then(() => {
      if (repetidosNuevos.length > 0) {
        displayError(
          dispatch,
          `Los códigos no pueden repetirse. Codigos repetidos: ${repetidosNuevos
            .map((codigo) => codigo)
            .join(", ")}`
        );
      }
      if (callback) callback();
      displaySuccess(dispatch, "Códigos guardados con éxito");
    })
    .catch((error) => {
      dispatch({ type: ERROR_CODIGOS, payload: error });
    });
};

export const deleteCodigo = (codigo) => (dispatch) => {
  dispatch({ type: DELETE_CODIGO, payload: codigo.idCodigo });
};
