import {
  LETRA_RESPONDIDA,
  CLEAR_CONTESTADAS,
  AGREGAR_RESPUESTA_AHORCADO,
  JUGADOR_AHORCADO,
  JUGADOR_FINISHED,
} from "./types";

export const contestar = (letra) => (dispatch) => {
  dispatch({ type: LETRA_RESPONDIDA, payload: letra });
};

export const agregarRespuestaAhorcado = (apodo, letra, puntos) => (
  dispatch
) => {
  dispatch({
    type: AGREGAR_RESPUESTA_AHORCADO,
    payload: { apodo, letra, puntos },
  });
};

export const clearContestadas = () => (dispatch) => {
  dispatch({ type: CLEAR_CONTESTADAS });
};

export const jugadorAhorcado = (apodo) => (dispatch) => {
  dispatch({ type: JUGADOR_AHORCADO, payload: apodo });
};

export const jugadorFinished = (apodo) => (dispatch) => {
  dispatch({ type: JUGADOR_FINISHED, payload: apodo });
};
