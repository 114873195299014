import React, { Component } from "react";
import IngresarPin from "./IngresarPin";
import IngresarApodo from "./IngresarApodo";
import Espera from "../Espera";
import Menu from "../../components/Menu";
import { navigate } from "@reach/router";

class Unirse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esperando: true
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({esperando: false})
      }
      .bind(this),
      3200
    );
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.apodo && this.props.apodo) navigate(`/jugar`);
  }

  render() {
    if (this.props.spinner || this.state.esperando === true) {
      return (
        <>
          <Menu user={this.props.user} />
          <Espera user={this.props.user ? true : false }background={'main'} faceColor={'white'}/>
        </>
      );
    } else if(this.props.pin) {
      return <IngresarApodo setApodo={this.props.setApodo} />;
    } else {
      return (
        <IngresarPin
          joinJuego={this.props.joinJuego}
          alert={this.props.alert}
          user={this.props.user}
        />
      );
    }
  }
}

export default Unirse;
