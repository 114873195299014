import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { getIcon } from "../utils/index";

const colors = ["red", "green", "yellow", "purple"];

class OpcionButton extends React.Component {
  responder() {
    if (this.props.responder) {
      this.props.responder(this.props.idOpcion);
    }
  }

  renderTexto() {
    if (!this.props.hideTexto)
      return (
        <Col xs={12} md>
          <h4 className={`${this.props.gameType !== 'playAlone' ? 'pl-3 mb-2' : 'text-center'} texto-opcion`}>
            {this.props.texto}
          </h4>
        </Col>
      );
  }

  render() {
    let { index, correcta, scoreboard, hideTexto, width, answered, isMobile, selectedAnswer, idOpcion } = this.props;
    //let leftxs = this.props.hideTexto ? 12 : 3;
    //let leftmd = this.props.hideTexto ? 12 : 1;
    return (
      <Col
        xs={width === "full" ? 12 : 6}
        onClick={() => this.responder()}
        className='mb-2 pr-2'
      >
        <Button
          className={`answer-trivia ${selectedAnswer === idOpcion && 'selected-answer-trivia'} ${!answered || correcta || hideTexto ? `outline-fill-${colors[index]}`: `outline-fill-${colors[index]} incorrect-answer-trivia`}`}
          style={{ height: "22vh", width: "100%" }}
        >
          <Row className={`align-items-center text-left no-gutters ${hideTexto ? 'justify-content-center' : 'justify-content-between'}`} style={{height: '100%'}}>
            {!isMobile  &&
              <Col xs={hideTexto ? 5 : 3} md={1} className='mr-2'>
                {getIcon(index,{
                style: { width: "100%", },
                //color: (correcta || this.props.hideTexto) && "#ffffff",
                color: '#ffffff',
                newclass: 'mb-0'
              })}
              </Col>
            }
            {this.renderTexto()}
          </Row>
          { (scoreboard || answered)  &&
            <div className='answer-trivia-status'>
              { correcta ? 
                <i className="far fa-lg fa-check-circle"></i>
                :
                <i className="far fa-lg fa-times-circle"></i>
              }
            </div>
          }
        </Button>
      </Col>
    );
  }
}

export default OpcionButton;
