import React, { Component } from 'react';
import LobbyQuestion from './LobbyQuestion';
import TriviaQuestion from './TriviaQuestion';
import GameEnding from './GameEnding';
import { Container } from 'react-bootstrap';
import { use100vh } from 'react-div-100vh';

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const realHeight = use100vh();
        return <Component {...props} realHeight={realHeight} />;
    }
}

class TriviaContent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const component = this.props.component.toLowerCase();

        switch(component) {
            case 'lobbyquestion':
                return(
                    <Container className='vertical-center fondo-gris-full'>
                        <LobbyQuestion 
                            juego={this.props.juego}
                            isMobile = {this.props.isMobile}
                        />
                    </Container>
                );
            case 'question':
                if(this.props.isMobile) {
                    return(
                        <Container 
                            className='vertical-center'
                            style={{
                                position: 'absolute',
                                top: `${this.props.juego.contestada ? `100px` : `40px`}`,
                                height: `${this.props.juego.contestada ? `calc(${this.props.realHeight}px - 100px - 100px)` : `calc(${this.props.realHeight}px - 40px)`}`,
                                transition: 'height 1.5s ease-out 0s'
                            }}    
                        >
                            <TriviaQuestion 
                                juego={this.props.juego}
                                isMobile = {this.props.isMobile}
                                component = {this.props.component}
                                responder={this.props.responder}//callback to send answer to back
                                tiempoPregunta={this.props.tiempoPregunta}
                            />
                        </Container>
                    );
                } else {
                    return(
                        <Container className='vertical-center fondo-gris-full'>
                            <TriviaQuestion 
                                juego={this.props.juego}
                                isMobile = {this.props.isMobile}
                                component = {this.props.component}
                                responder={this.props.responder}//callback to send answer to back
                                tiempoPregunta={this.props.tiempoPregunta}
                            />
                        </Container>
                    );
                }
            case 'gameending':
                if(this.props.isMobile) {
                    return(
                        <Container
                            className='vertical-center'
                            style={{height: `calc(${this.props.realHeight}px - 100px - 40px)`}}
                        >
                            <GameEnding 
                                patrocinador={this.props.patrocinador}
                                apodo={this.props.apodo}
                                porcentajeCorrectas={this.props.juego.porcentajeCorrectas}
                                setSugerenciaProperty={this.props.setSugerenciaProperty}
                                showThankYouModal={this.props.showThankYouModal}
                                user={this.props.user}
                            />
                        </Container>
                    );
                } else {
                    return(
                        <Container className='vertical-center fondo-gris-full'>
                            <GameEnding 
                                apodo={this.props.apodo}
                                porcentajeCorrectas={this.props.juego.porcentajeCorrectas}
                                setSugerenciaProperty={this.props.setSugerenciaProperty}
                                showThankYouModal={this.props.showThankYouModal}
                                user={this.props.user}
                            />
                        </Container>
                    );
                }
            default:
                break;
        }
    }

}

export default withMyHook(TriviaContent);