import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

class InfoCard extends React.Component {
  renderText() {
    return this.props.texts.map((texto, index) => (
      <React.Fragment key={index}>
        <Row>
          <Col>
            <p className="m-0">
              {index === 0 && (
                <i
                  className={`fas fa-${
                    this.props.warning
                      ? "exclamation-triangle"
                      : "question-circle"
                  } pr-2`}
                  style={{
                    color: this.props.warning ? "#FF3833" : "#7E2CFF",
                  }}
                ></i>
              )}
              <span className={index !== 0 ? "pl-4" : ""}>{texto.title}</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="m-0 text-muted h6 pl-5">{texto.message}</p>
          </Col>
        </Row>
      </React.Fragment>
    ));
  }

  render() {
    return (
      <Card
        className={`card-${this.props.warning ? "advertencia" : "ayuda"} py-2`}
      >
        <Container>{this.renderText()}</Container>
      </Card>
    );
  }
}

export default InfoCard;
