import React, { Component } from "react";
import { Router } from "@reach/router";
import { Container } from "react-bootstrap";
import Login from "./view/Login";
import SignUp from "./view/SignUp";
//import Recovery from "./view/Recovery";
import Espera from "./view/Espera";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esperando: true
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({esperando: false})
      }
      .bind(this),
      3200
    );
    window.scrollTo(0, 0);
  }

  render() {
    if (this.props.spinner  || this.state.esperando === true){
      return (
        <Container style={{ marginTop: -100 }} fluid>
          <Espera />
        </Container>
      );
    } else {
      return (
        <Router>
          <Login path="/login" />
          <SignUp path="/signup" />
          {/* <Recovery path="/recovery" /> */}
          <Login default />
        </Router>
      );
    }
  }
}

export default Auth;
