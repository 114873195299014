import React, { Component } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import "@material/react-text-field/dist/text-field.css";

class SegmentosPatrocinador extends Component {
  render() {
    return (
      <Row className="mx-0 pl-sm-2">
        <Col>
          <OverlayTrigger
            placement="top-start"
            overlay={
              <Tooltip>Máximo: {this.props.disponibles ? this.props.disponibles.primaria : 'Loading...'}</Tooltip>
            }
          >
            <div style={{ width: "100%" }}>
              <TextField
                outlined
                className="w-100"
                label="Primaria"
                characterCounter={<CharacterCounter />}
                maxLength={3}
              >
                <Input
                  value={this.props.patrocinador.primaria}
                  maxLength={3}
                  onChange={(event) =>
                    this.props.modifier("primaria", event.target.value)
                  }
                  max={this.props.disponibles ? this.props.disponibles["primaria"] : 100}
                  type="number"
                />
              </TextField>
            </div>
          </OverlayTrigger>
        </Col>
        <Col>
          <OverlayTrigger
            placement="top-start"
            overlay={
              <Tooltip>Máximo: {this.props.disponibles ? this.props.disponibles.secundaria : "Loading..."}</Tooltip>
            }
          >
            <div style={{ width: "100%" }}>
              <TextField
                outlined
                className="w-100"
                label="Secundaria"
                characterCounter={<CharacterCounter />}
                maxLength={3}
              >
                <Input
                  value={this.props.patrocinador.secundaria}
                  maxLength={3}
                  onChange={(event) =>
                    this.props.modifier("secundaria", event.target.value)
                  }
                  max={this.props.disponibles ? this.props.disponibles["secundaria"] : 100}
                  type="number"
                />
              </TextField>
            </div>
          </OverlayTrigger>
        </Col>
        <Col>
          <OverlayTrigger
            placement="top-start"
            overlay={
              <Tooltip>Máximo: {this.props.disponibles ? this.props.disponibles.preparatoria : "Loading..."}</Tooltip>
            }
          >
            <div style={{ width: "100%" }}>
              <TextField
                outlined
                className="w-100"
                label="Preparatoria"
                characterCounter={<CharacterCounter />}
                maxLength={3}
              >
                <Input
                  value={this.props.patrocinador.preparatoria}
                  maxLength={3}
                  onChange={(event) =>
                    this.props.modifier("preparatoria", event.target.value)
                  }
                  max={this.props.disponibles ? this.props.disponibles["peparatoria"] : 100}
                  type="number"
                />
              </TextField>
            </div>
          </OverlayTrigger>
        </Col>
        <Col>
          <OverlayTrigger
            placement="top-start"
            overlay={
              <Tooltip>Máximo: {this.props.disponibles ? this.props.disponibles.universidad : "Loading..."}</Tooltip>
            }
          >
            <div style={{ width: "100%" }}>
              <TextField
                outlined
                className="w-100"
                label="Universidad"
                characterCounter={<CharacterCounter />}
                maxLength={3}
              >
                <Input
                  value={this.props.patrocinador.universidad}
                  maxLength={3}
                  onChange={(event) =>
                    this.props.modifier("universidad", event.target.value)
                  }
                  max={this.props.disponibles ? this.props.disponibles["universidad"] :100}
                  type="number"
                />
              </TextField>
            </div>
          </OverlayTrigger>
        </Col>
        <Col className="pr-0">
          <OverlayTrigger
            placement="top-start"
            overlay={
              <Tooltip>Máximo: {this.props.disponibles ? this.props.disponibles.profesional : "Loading..."}</Tooltip>
            }
          >
            <div style={{ width: "100%" }}>
              <TextField
                outlined
                className="w-100"
                label="Profesional"
                characterCounter={<CharacterCounter />}
                maxLength={3}
              >
                <Input
                  value={this.props.patrocinador.profesional}
                  maxLength={3}
                  onChange={(event) =>
                    this.props.modifier("profesional", event.target.value)
                  }
                  max={this.props.disponibles ? this.props.disponibles["profesional"] : 100}
                  type="number"
                />
              </TextField>
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
    );
  }
}

export default SegmentosPatrocinador;
