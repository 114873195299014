import React from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { getTipo } from "../utils";

class FilaReporte extends React.Component {

    render() {
        const { reporte } = this.props;
        return (
            <>
                <td className="pl-4 py-4">
                    <p className="h5 m-0 pt-1">
                        <i
                            className={`fas ${reporte.jugadores === 1 ? "fa-user" : "fa-users"
                                } pr-2`}
                            style={{ color: "#000" }}
                        ></i>
                        <span>{reporte.cantJugadores}</span>
                    </p>
                </td>
                <td className="py-4" style={{ width: "45vw" }}>
                    <p className="h5 m-0 pt-1">
                        <i className="far fa-calendar-alt fa-lg pr-2"></i>
                        <span>
                            {moment(reporte.fechaHoraInicio).format("DD/MMM/YYYY")}
                        </span>
                        <span className="ml-2">
                            {moment.utc(reporte.fechaHoraInicio).format("HH:mm")}
                        </span>
                    </p>
                </td>
                <td className="py-4">
                    <i className="fas fa-share-alt fa-lg py-2"></i>
                </td>
                <td style={{ width: "15vw", minWidth: 120 }} className="py-3">
                    <Button
                        variant="outline-danger"
                        className="border-red-new btn-round"
                        onClick={() =>
                            this.props.downloadReporte(
                                reporte.idJuego,
                                reporte.idSesion,
                                getTipo(reporte)
                            )
                        }
                    >
                        <i className="fas fa-download pr-1"></i>
                        <span className="">Descargar</span>
                    </Button>{" "}
                </td>
            </>

        );
    }
}

export default FilaReporte;