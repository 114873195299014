import api from "./api";

export default {
  signIn: (email, password) => api.post('/auth/signin', {
    correo: email,
    password
  }),
  userLoggedIn: () => {
    const user = localStorage.getItem('user');
    return user;
  },
  signOut: () => api.delete('/auth'),
  signUp: ({ nombre, correo, password, idEscuela, rol, profesional, usuario, alias, escuela }) =>
    api.post("/auth/signup", { nombre, correo, password, idEscuela, rol, profesional, alias: usuario, escuela }),
  refreshToken: () => api.put('/auth'),
  recoverPassword: (email) => api.put('/auth/restorePasswordURL', {
    mail: email
  }),
  resetPassword: ({newPassword, token}) => api.put('/auth/restorePassword', {
    newPassword,
    token
  }),
  updatePassword: ({password, newPassword}) => api.put('/auth/resetpassword', {
    oldPassword: password,
    newPassword
  })
};
