import {
  SOLO_CLEAR_STORE,
    SOLO_JUEGO_RECIBIDO
  } from "../actions/types";
  
  const INITIAL_STATE = {
    loadingLobby: true,
    patrocinador: undefined,
    tiempoEsperaLobby: undefined,
    tiempoEsperaPregunta: undefined,
    nombre: undefined,
    tipo: undefined,
    cantidadPreguntas: undefined,
    namespace: undefined,
    imagenJuego: undefined,
    creador: undefined
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SOLO_JUEGO_RECIBIDO:
        const {
            patrocinador,
            tiempoEsperaLobby,
            tiempoEsperaPregunta,
            nombre,
            tipo,
            cantidadPreguntas,
            namespace,
            imagenJuego,
            creador 
        } = action.payload;
        return {
            ...state, 
            patrocinador,
            tiempoEsperaLobby,
            tiempoEsperaPregunta,
            nombre,
            tipo,
            cantidadPreguntas,
            namespace,
            imagenJuego,
            loadingLobby: false,
            creador
        };
      case SOLO_CLEAR_STORE:
        return INITIAL_STATE;
      default:
        return { ...state };
    }
  };
  