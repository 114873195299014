import { combineReducers } from "redux";
import MisJuegosReducer from "./MisJuegosReducer";
import UserReducer from "./UserReducer";
import PreguntasReducer from "./PreguntasReducer";
import ModalReducer from "./ModalReducer";
import JugarReducer from "./JugarReducer";
import CrucigramaReducer from "./CrucigramaReducer";
import AhorcadoReducer from "./AhorcadoReducer";
import EscuelasReducer from "./EscuelasReducer";
import ConfiguracionReducer from "./ConfiguracionReducer";
import PatrocinadoresReducer from "./PatrocinadoresReducer";
import PremiosReducer from "./PremiosReducer";
import FrasesReducer from "./FrasesReducer";
import CodigosReducer from "./CodigosReducer";
import ReportesReducer from "./ReportesReducer";
import ExploreReducer from "./ExploreReducer";
import AdminReducer from "./AdminReducer";
import JugarSoloReducer from "./JugarSoloReducer";
import CrucigramaSoloReducer from "./CrucigramaSoloReducer";

export default combineReducers({
  misjuegos: MisJuegosReducer,
  user: UserReducer,
  juego: PreguntasReducer,
  modal: ModalReducer,
  jugar: JugarReducer,
  crucigrama: CrucigramaReducer,
  ahorcado: AhorcadoReducer,
  escuelas: EscuelasReducer,
  configuracion: ConfiguracionReducer,
  patrocinadores: PatrocinadoresReducer,
  premios: PremiosReducer,
  frases: FrasesReducer,
  codigos: CodigosReducer,
  reportes: ReportesReducer,
  explore: ExploreReducer,
  admin: AdminReducer,
  jugarSolo: JugarSoloReducer,
  crucigramaSolo: CrucigramaSoloReducer,
});
