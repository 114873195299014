import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import AdminTable from "./AdminTable";
import SwitchULU from "./global/SwitchULU";

export default function ({
  frases,
  frase,
  createFrase,
  editFrase,
  deleteFrase,
  saveFrase,
  setPropiedadFrase,
  alertar
}) {
  if (frases !== null) {
    frases = frases.filter((frase) => String(frase.idFrase)[0] !== "d");
  }

  function validarFrase(frase) {
    if(frase.texto && frase.texto !== "") saveFrase(frase);
    else alertar("La frase no puede estar vacía.");
  }

  return (
    <>
      <Row>
        <Col>
          <p className="pl-sm-2 h3">
            <i className="fas fa-comment-alt"></i>
            <span className="pl-3">Frases</span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={11} sm={9} lg={8} className="pl-3 pl-sm-4">
          <div className="mb-3 admin-table tabla-codigos">
            <AdminTable
              idRow="idFrase"
              headers={["Frase", "Estado"]}
              rows={frases}
              editedRow={frase}
              editRow={editFrase}
              deleteRow={deleteFrase}
              saveRow={validarFrase}
              components={{
                activo: ({ activo }) => (
                  <SwitchULU
                    value={activo}
                    modifier={setPropiedadFrase}
                    args="activo"
                  />
                ),
              }}
              inputModifier={setPropiedadFrase}
              tableClass="mb-0"
              exclude={["idFrase", "idPatrocinador"]}
              editColor="rgb(63,63,63)"
              iconsEstado
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={5} md={4} lg={3} xl={2}>
          <div className="pl-sm-2">
            <Button
              variant="outline-danger"
              className="border-red-new py-2 mx-auto my-0"
              onClick={createFrase}
            >
              Agregar frase
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
