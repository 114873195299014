import React from "react";
import Dropzone from 'react-dropzone'
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  Card,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Pagination
} from "react-bootstrap";
import rayas from "../assets/Rayas.svg";
import asterisco from "../assets/Asterisco.svg";
import rectangulo from "../assets/Rectangulo.svg";
import TextField, { Input, CharacterCounter } from "@material/react-text-field";
import Radio, { NativeRadioControl } from "@material/react-radio";
import "@material/react-radio/dist/radio.css";
import "@material/react-text-field/dist/text-field.css";
import fondo from "../assets/fondo-nuevo.png";
import logo from "../assets/logo.png";
import SearchBox from "../components/SearchBox";
import { BASE_URL_S3_BUCKET } from "../utils";

class DetalleForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activePage: 1,
      imgSearch: [],
      selectedImage: undefined,
      isImgSelected: false,
      unsplashSearch: false,
      dropzoneImgLoaded: false,
      dropzoneImgSelected: false,
      dropzonePreview: null,
      searchKeyword: ""
    }
    this.onDrop = this.onDrop.bind(this);
    this.selectDropZoneImg = this.selectDropZoneImg.bind(this);
    this.handleImgSearch = this.handleImgSearch.bind(this);
    this.deleteImgDropzone = this.deleteImgDropzone.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
  }

  renderContenidoVistaPrevia() {
    return (
      <Container className="box-border mx-0 px-0 vista-previa" fluid>
        <Image src={fondo} className="h-100 w-100 vista-previa-bg" />
        <Container className="vista-previa-elements">
          <Row
            style={{ maxHeight: "15%" }}
            className="bg-white shadow-sm py-2 rounded-top"
          >
            <Col>
              <Image src={logo} width={"30%"} />
            </Col>
            <Col className="text-center">
              <p style={{ fontSize: "14px" }}>playulu.com/jugar</p>
            </Col>
            <Col className="text-right">
              <p style={{ fontSize: "14px" }}>0 jugadores</p>
            </Col>
          </Row>
        </Container>
        <Container
          className="vista-previa-elements"
          style={{ marginTop: "8%" }}
          fluid
        >
          <Row className="pt-3">
            <Col xs={1} />
            <Col xs={10} className="mx-auto">
              <Card className="rounded-card shadow text-center py-3">
                <Row className="justify-content-center no-gutters pb-1">
                  <p className="px-2 py-0 mx-0 my-0">
                    {this.props.juego.titulo ? this.props.juego.titulo : ""}
                  </p>
                </Row>
                <Row className="justify-content-center no-gutters">
                  <p
                    className="px-0 py-0 mx-0 my-0 text-center"
                    style={{ fontSize: "14px" }}
                  >
                    Pin del Juego
                  </p>
                </Row>

                <Row className="justify-content-center no-gutters">
                  <p
                    className="px-0 py-0 mx-0 my-0"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    xxxx
                  </p>
                </Row>
                <Row className="justify-content-center no-gutters">
                  <Col xs={9}>
                    <Button
                      variant="danger"
                      bsPrefix="new-red-button"
                      className="no-min-width px-3"
                    >
                      <p
                        className="px-0 py-0 mx-0 my-0"
                        style={{ fontSize: "12px" }}
                      >
                        Play ULU
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={1} />
          </Row>
        </Container>
      </Container>
    );
  }

  renderVistaPrevia() {
    let minutos;
    let segundos;
    if (this.props.juego) {
      minutos = Math.floor(this.props.juego.tiempo / 60);
      segundos = this.props.juego.tiempo % 60;
    }
    return (
      <>
        <Row>
          <p className="h6 pt-2">Vista previa</p>
        </Row>
        <Row className="justify-content-start">
          {this.renderContenidoVistaPrevia()}
        </Row>
        <Row className="align-items-center pt-2">
          <p>
            <i
              className={
                this.props.juego.publico
                  ? "fas fa-users pr-1"
                  : "fas fa-user color-000 pr-1"
              }
            />
            <span className="pr-3">
              {this.props.juego.publico ? " Público" : " Privado"}
            </span>
            <i className="far fa-clock pr-1" />
            <span className="pr-3">
              {this.props.juego.tiempo
                ? minutos
                : this.props.tipo === "Crucigrama"
                ? "1"
                : "0"}
              m :{" "}
              {this.props.juego.tiempo
                ? segundos.toString().length === 2
                  ? segundos
                  : "0" + segundos
                : this.props.tipo === "Crucigrama"
                ? "00"
                : "15"}
              s
            </span>
          </p>
        </Row>
      </>
    );
  }

  duraciones = [
    { value: 5, name: "5 segundos" },
    { value: 10, name: "10 segundos" },
    { value: 15, name: "15 segundos" },
    { value: 20, name: "20 segundos" },
    { value: 30, name: "30 segundos" },
    { value: 45, name: "45 segundos" },
    { value: 60, name: "1 minuto" },
    { value: 90, name: "1 minuto y medio" },
    { value: 120, name: "2 minutos" },
    { value: 300, name: "5 minutos" },
    { value: 600, name: "10 minutos" },
  ];

  renderDuraciones() {
    return this.duraciones.map((duracion) => (
      <Dropdown.Item
        key={duracion.name}
        onClick={(e) => this.props.handleChange({ target: duracion }, "tiempo")}
      >
        {duracion.name}
      </Dropdown.Item>
    ));
  }

  getDuracion() {
    const duracion = this.duraciones.find(
      (duracion) => duracion.value === this.props.juego.tiempo
    );
    if (duracion) return duracion.name;
    return this.props.tipo.toLowerCase() === "crucigrama"
      ? "1 minuto (recomendado)"
      : "15 segundos (recomendado)";
  }

  renderTiempo() {
    return (
      <>
        <Row className="mx-0 px-0 mt-3">
          <Col className="ml-3 pl-0" xs={12}>
            <h6>
              {this.props.tipo.toLowerCase() === "crucigrama"
                ? "Tiempo total del juego"
                : "Tiempo por pregunta"}
            </h6>
          </Col>
        </Row>
        <Row className="mx-0 px-0">
          <Col xs={12}>
            <Dropdown drop="down">
              <Dropdown.Toggle
                id="minutos"
                bsPrefix="outline-no-fill"
                className="text-left"
              >
                {this.getDuracion()}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ overflowY: "scroll" }}>
                {this.renderDuraciones()}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </>
    );
  }

  renderPuntos() {
    return (
      <Row className="justify-content-md-left mx-0 px-0 pl-1 py-3">
        <Col className="ml-3 px-3" xs={12}>
          <Row>
            <Form.Label className="h6">Puntos por pregunta</Form.Label>
          </Row>
          <Row>
            <div>
              <TextField
                outlined
                label="Puntos"
                characterCounter={<CharacterCounter />}
                maxLength={4}
              >
                <Input
                  value={this.props.juego.puntos}
                  onChange={(event) => this.props.handleChange(event, "puntos")}
                  maxLength={4}
                />
              </TextField>
            </div>
          </Row>
        </Col>
      </Row>
    );
  }

  onDrop(acceptedFiles) {
    if(acceptedFiles.length < 1)
      return;
    let files = acceptedFiles.map(file => Object.assign(file, {
      tempURL: URL.createObjectURL(file)
    }));
    this.setState({
      dropzonePreview: files[0],
      dropzoneImgLoaded: true,
      dropzoneImgSelected: true,
      isImgSelected: true,
      selectedImage: undefined,
    });
    this.props.handleChange({target: { value: files[0]}}, "dropzonePreview");//save to redux
  }
  onDropRejected(rejections){
    if(rejections[0].errors[0].code === 'file-too-large'){
      alert("El archivo pesa más de 1mb");
    }
  }
  deleteImgDropzone() {
    this.setState({dropzonePreview: null,
      dropzoneImgLoaded: false,
      dropzoneImgSelected: false,
      isImgSelected: false,
    })
    this.props.handleChange({target: { value: null}}, "dropzonePreview");//save to redux
    this.props.handleChange({target: { value: null}}, "originalImage");//save to redux that original image needs to be deleted in db
    this.props.handleChange({target: { value: true}}, "deleteOriginalImage");//save to redux that original image needs to be deleted in db
  }

  selectDropZoneImg() {
    let dropzoneImgSelected = this.state.dropzoneImgSelected;
    let imgSearch = [...this.state.imgSearch];
    let isImgSelected;

    if(dropzoneImgSelected === true) {
      dropzoneImgSelected = 'disabled';
      isImgSelected = false;

    } else {
      dropzoneImgSelected = true;
      isImgSelected = true;
    }
    this.setState({imgSearch, isImgSelected, dropzoneImgSelected});
  }

  handleImgSearch() {
    
    this.props.searchImages({
      tipoJuego: this.props.juego.tipo,
      keyword: this.state.searchKeyword,
      page: this.state.activePage
    });
    this.setState({
      unsplashSearch: true
    });
  }
  handleImgClick(id, downloadURL, downloadLocation){
    if(this.state.dropzonePreview)//Si hay una imagen en dropzone no hacer nada
      return;
    if(this.state.selectedImage === id){
      this.setState({selectedImage: undefined});
      this.props.handleChange({target: { value: undefined}}, "selectedImage");
    }
    else{
      this.setState({selectedImage: id, unsplashImage: {
        downloadURL,
        downloadLocation
      }})
      this.props.handleChange({target: { value: id}}, "selectedImage");//save to redux
      this.props.handleChange({target: { value: {//save to redux
        downloadURL,
        downloadLocation
      }}}, "unsplashImage");    
    }
    
  }
  handlePaginationClick(page){
    this.setState({activePage: page}, () => {
      this.handleImgSearch()
    });
  }
  renderPagination() {
    const pages = this.props.imagesSearchPages;
    const min = this.state.activePage - 2;
    const max = Math.min(this.state.activePage + 2, pages);
    const items = [];
    for (let i = 1; i <= max; i++) {
      if(i >= min){
        items.push(
          <Pagination.Item onClick={() => this.handlePaginationClick(i)} key={i} active={i === this.state.activePage}>{i}</Pagination.Item>
        )
      }
    }
    return (
      <Pagination>
        {this.state.activePage !== 1 && <Pagination.Prev onClick={() => this.handlePaginationClick(this.state.activePage-1)}/>}
        {items}
        {this.state.activePage !== max && <Pagination.Next onClick={() => this.handlePaginationClick(this.state.activePage+1)}/>}
      </Pagination>
    );
  }
  render() {
    let { tipo } = this.props;
    tipo = tipo.toLowerCase();

    const maxSize = 1048576;
    return (
      <>
        <Row className="mx-0 px-0">
          <Col xs={12}>
            <h1 className="my-3">
              <Image
                src={
                  tipo === "ahorcado"
                    ? asterisco
                    : tipo === "trivia"
                    ? rayas
                    : rectangulo
                }
                style={{ width: 30 }}
              />
              <span className="ml-2">Información General</span>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Row className="mx-0 px-0">
              <Col xs={12}>
                <TextField
                  outlined
                  className="w-100"
                  label="Título"
                  characterCounter={<CharacterCounter />}
                  maxLength={140}
                >
                  <Input
                    value={this.props.juego.titulo}
                    maxLength={140}
                    onChange={(event) =>
                      this.props.handleChange(event, "titulo")
                    }
                  />
                </TextField>
              </Col>
            </Row>
            <div >
            <Row className="mx-0 px-0">
              <Col xs={'auto'}>
                <h6>
                  Foto principal
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={
                      <Tooltip id={'tooltip-right'} >
                        Esta será la foto que aparecerá en <b>Explorar</b>.
                      </Tooltip>
                    }
                  >
                    <i className="far fa-question-circle ml-1"></i>
                  </OverlayTrigger>
                </h6>
              </Col>
            </Row>
            <Row className="mx-0 px-0">
              <Col xs={10}>
                <SearchBox 
                  searchModifier={(query) => this.setState({searchKeyword: query})}
                  placeholder="&#61442; Busca una foto con Unsplash®"
                />
              </Col>
              <Col xs={2} className='p-0 m-0'>
                <Button
                  className="border-red-new py-2 p-0"
                  block
                  onClick={this.handleImgSearch}
                >
                  <h6 className='my-auto'>Buscar</h6>
                </Button>
              </Col>
            </Row>
            {this.state.unsplashSearch && 
              <Row className='mx-0 px-0 mt-2'>
                <Col xs={12}>
                  <p>Selecciona la foto que más te guste de <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/?utm_source=play_ulu&utm_medium=referral">Unsplash</a></p>
                </Col>
              </Row>
            }
            <Row className="mx-0 px-0 mt-1">
              <Col xs={12}>
                <ul className='create-ulu-img-grid'>
                  <li>
                    {this.state.dropzoneImgLoaded || this.props.juego.originalImage ? 
                      <>
                        <img 
                          className={`create-ulu-img ${this.state.dropzoneImgSelected === true ? 'img-selected' : this.state.dropzoneImgSelected === false ? 'img-unselected' : ''}`}
                          src={(this.state.dropzonePreview && this.state.dropzonePreview.tempURL) || `${BASE_URL_S3_BUCKET}${this.props.juego.originalImage}`}
                          alt={(this.state.dropzonePreview && this.state.dropzonePreview.name) || 'imagen del juego'}
                          onClick={this.selectDropZoneImg}
                        >
                        </img>
                        <Row className='my-1'>
                          <Col xs={'auto'}>
                            <p className='delete-img pt-2 px-2' onClick={this.deleteImgDropzone}>
                              <i className="fa fa-trash"></i> Eliminar
                            </p>
                          </Col>
                        </Row>
                      </>
                      :
                      <Dropzone
                        onDrop={this.onDrop}
                        onDropRejected={this.onDropRejected}
                        accept='image/*'
                        minSize={0}
                        maxSize={maxSize}
                        multiple={false}
                        disabled={this.state.isImgSelected}
                      >
                        {({getRootProps, getInputProps, isDragActive, isDragReject}) => {
                          return (
                            <div {...getRootProps()} className={`create-ulu-dropzone vertical-center ${this.state.isImgSelected && 'dropzone-disabled'}`}>
                              <input {...getInputProps()}  />
                              {!isDragActive && <p>¡Arrastra tu propia foto o da click aquí!</p>}
                              {isDragActive && !isDragReject && <p>¡Qué rico, una nueva foto!</p>}
                              {isDragReject && <p>Esa clase de archivos me caen pesado. ¡Intenta otro tipo!</p>}
                            </div>
                          )}
                        }
                      </Dropzone>
                    }
                  </li>
                  {this.props.loadingImages && <p>Cargando...</p>}
                  {!this.props.loadingImages && this.props.imagesSearchResults.map((img) => (
                      <li key={img.id} className={'create-ulu-li'}>
                        <img className={`create-ulu-img ${this.state.dropzoneImgLoaded ? 'img-unselected' : this.state.selectedImage === undefined ? '' : this.state.selectedImage === img.id ? 'img-selected' : 'img-unselected'}`} src={img.urls.small} alt={img.alt_description} onClick={() => this.handleImgClick(img.id, img.urls.small,img.links.download_location)}></img>
                        <a target="_blank" rel="noopener noreferrer" className='create-ulu-img-author text-small' href={`https://unsplash.com/@${img.user.username}?utm_source=play_ulu&utm_medium=referral`}> {img.user.name}</a>
                      </li>
                  ))}
                </ul>
              </Col>
            </Row>
            <Row className="mx-0 px-0 mt-2">
              <Col xs={12}>
                {this.state.unsplashSearch && this.renderPagination()}
              </Col>
            </Row>
            </div>
            <Row className="mx-0 px-0 mt-3">
              <Col xs={12}>
                <h6>¿Quién puede verlo?</h6>
              </Col>
            </Row>
            <Row className="mx-0 px-0">
              <Col xs={12}>
                <Radio value="todos" label="Público">
                  <NativeRadioControl
                    name="visibilidad"
                    value="publico"
                    id="publico"
                    checked={this.props.juego.publico}
                    onChange={(e) => this.props.handleChange(e, "publico")}
                  />
                </Radio>
              </Col>
            </Row>
            <Row className="mx-0 px-0">
              <Col xs={12}>
                <Radio value="yo" label="Privado">
                  <NativeRadioControl
                    name="visibilidad"
                    value="privado"
                    id="privado"
                    checked={!this.props.juego.publico}
                    onChange={(e) => this.props.handleChange(e, "publico")}
                  />
                </Radio>
              </Col>
            </Row>
            {this.renderTiempo()}
          </Col>
          <Col xs={12} md={6} className="pr-5 pl-4 hide-mobile">
            {this.renderVistaPrevia()}
          </Col>
        </Row>
      </>
    );
  }
}

export default DetalleForm;
